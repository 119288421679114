import { IonContent, IonSpinner, IonAlert, IonLoading, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Interactive from 'react-interactive'
import logo from '../assets/img/Spectra_logo.png'
import Snackbar from './Snackbar'
import { requestAPICall } from "../service/apiHandler";
import './Login.css'
import { connect } from 'react-redux';
import { UPDATE_FIELD_AUTH, USER_ROLE } from '../constants/actionTypes';
import { Plugins } from '@capacitor/core';
import Cookies from 'js-cookie'
import { isBrowser, isMobile } from 'react-device-detect';
import { PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications } = Plugins;
const { Network } = Plugins;
const { Device } = Plugins;




const INITIAL_STATE = {
  username: '',
  password: '',
  forgot: false,
  submit: false,
  register: false,
  showLoading: false,
  remember_me: false,
  alertbox: false,
  warnMessage: "",
  currenttime: '',
  userRole: "",
  permissionId: [],
  isLoggedIn: false,
  platform: "",
  login_password_type:"password"
};

const mapStateToProps = (state: any) => ({ ...state.user });

const mapDispatchToProps = (dispatch: any) => ({
  onChangeEmail: (value: any) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: (value: any) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  upadateUserPermissions: (value: any) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'permissions', value }),
  upadateUserRole: (value: any) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'role', value }),
  upadateUserCpiId: (value: any) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'cpiId', value }),
  upadateUserCpiName: (value: any) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'cpiName', value })

});

class Login extends Component {
  state: any = {};
  props: any = {};

  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.setPushNotificationMobile()
    this.onChange = this.onChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.callLoginAPI = this.callLoginAPI.bind(this)
    this.getCookies = this.getCookies.bind(this)
    this.remember_me_checked = this.remember_me_checked.bind(this)
  }

  componentDidMount() {
    console.log("============")
    this.getCookies();
    this.clearfields()

  }


  setShowAlert = (st: any) => {
    this.setState({ alertbox: st })
  }

  setShowLoading = (st: Boolean) => {
    this.setState({ showLoading: st })
  }

  clearfields = () => {
    this.setState({
      username: "",
      password: ""
    })
  }
  submitForm = async (e: any) => {
    this.formValidation()
    e.preventDefault()
    let status = await Network.getStatus();
    console.log(status)
    if (status.connected == true) {
      if (this.state.username != "" && this.state.password != "") {
        this.callLoginAPI()
      }

      console.log('submit')
      this.setState({
        submit: true
      })
    }
    else {
      this.setState({
        warnMessage: "Check for internet connectivity",
        alertbox: true
      })
    }
  }

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  formValidation() {
    if (this.state.username == "") {
      this.setState({
        warnMessage: "Please enter username"
      })
      this.setState({
        alertbox: true
      })

    }
    else if (this.state.password == "") {
      this.setState({
        warnMessage: "Please enter password"
      })
      this.setState({
        alertbox: true
      })

    } else {
      this.setState({
        submit: true
      })
    }
  }
  callLoginAPI() {
    this.setState({
      showLoading: true
    })
    let requestBody = {
      username: this.state.username,
      password: this.state.password,
    }
    requestAPICall("login", {}, requestBody).then(data => {
      this.setState({
        showLoading: false
      })
      console.log(data)
      if (data.responseCode == 200) {
        var currenttime = new Date()
        const currenttime_value = "" + currenttime.valueOf()
        if (this.state.remember_me == true) {
          console.log(Cookies.set('idToken', data.idToken, { expires: 365 }))
          Cookies.set('accessToken', data.accessToken, { expires: 365 })
          Cookies.set('logintime', currenttime_value, { expires: 365 })
          Cookies.set('username', requestBody['username'], { expires: 365 })
          Cookies.set('rememberMe', this.state.remember_me, { expires: 365 })
          Cookies.set('userRole', data.userRole, { expires: 365 })
          Cookies.set('permissionId', data.permissionId, { expires: 365 })
          // COMMON.update('PERMISSIONS',data.permissionId)
          // COMMON.update('ROLE',data.userRole)
        }
        else {
          var date = new Date();
          const currenttime_value = "" + currenttime.valueOf()
          date.setTime(date.getTime() + (3600000));
          Cookies.set('idToken', data.idToken, { expires: date })
          Cookies.set('accessToken', data.accessToken, { expires: date })
          Cookies.set('logintime', currenttime_value, { expires: date })
          Cookies.set('username', requestBody['username'], { expires: date })
          Cookies.set('rememberMe', this.state.remember_me, { expires: date })
          Cookies.set('userRole', data.userRole, { expires: date })
          Cookies.set('permissionId', data.permissionId, { expires: date })
          // COMMON.update('PERMISSIONS',data.permissionId)
          // COMMON.update('ROLE',data.userRole)
        }
        this.props.upadateUserPermissions(data.permissionId)
        this.props.upadateUserRole(data.userRole)
        this.props.upadateUserCpiId(data.cpiId)
        this.props.upadateUserCpiName(data.cpiName)
        if (isBrowser) {
          // if first login dont show anything on outdoor and page reloads to set all elements
          // quick fix on april 13
          // if is isLogin is present in the url parameter nothing should show on the outdoor page
          // if all the cookie set on the browser the getcookies func will direct to the right page 

          //REGISTER DEVICE FOR PUSH NOTIFICATION
          this.props.history.push('/map/outdoor');
        } else {
          this.setState({ isLoggedIn: true })
        }
        if (isMobile) {
          this.registerMobile()
          localStorage.removeItem('backButton');
          localStorage.removeItem('appState');
          // window.location.reload(false)
        }
      }
      else {
        this.setState({
          warnMessage: data.message,
          isLoggedIn: false
        })
        this.setState({
          alertbox: true
        })
      }
    }).catch(error => {
      this.setState({
        showLoading: false,
        isLoggedIn: false
      })
      console.log(error);
      this.setState({
        warnMessage: "Error occurred"
      })
      this.setState({
        alertbox: true
      })
    })

  }
  async deviceInfo() {
    const info = await Device.getInfo();
    console.log(info['platform']);
    localStorage.setItem("platform", info['platform'])
  }
  setPushNotificationMobile() {
    console.log("================ push notification")
    this.deviceInfo()
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        console.log(token)
        console.log(token.value)
        localStorage.setItem("deviceToken", token.value)

      });
    // Some issue with your setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );
  }
  registerMobile() {

    let requestBody = {
      deviceToken: localStorage.getItem("deviceToken"),
      platform: localStorage.getItem("platform"),
    }
    requestAPICall("register_device", {}, requestBody).then(data => {
      console.log(data)
      if (data.responseCode == 200) {
        console.log("Register device successfully")
      }

    })


  }

  remember_me_checked(e: any) {
    console.log(e.target.checked)
    this.setState({
      remember_me: true
    })
  }
  getCookies() {
    if ([undefined, null, 'undefined', 'null'].includes(Cookies.get('idToken')) || [undefined, null, 'undefined', 'null'].includes(Cookies.get('accessToken'))) {
      // this.props.history.push('/home');
    } else {
      if (isBrowser) {
        this.props.history.push('/map/outdoor');
      } else {
        this.setState({ isLoggedIn: true })
      }
    }



  }
  renderPages() {
    if (this.state.isLoggedIn) {
      return (<Redirect to="/map/outdoor/main" />)
    }
  }

  handleClick = (e: any, type: any) => {
    if (type === "login_password") {
        if (this.state.login_password_type === "text") {
            this.setState({
              login_password_type: "password"
            })
        } else {
            this.setState({
              login_password_type: "text"
            })
        }
    } 
    
}


  render() {

    const { username, password } = this.state;
    const isEnabled = username.length > 0 && password.length > 0;
    return (
      <IonPage>
        <IonContent>
          <div className="webonly">
            <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
              <div className='row m-r-0 m-l-0'>
                <div className='col-md-12'>
                  <div className='row'>

                    <div className='col-md-7 login-bg'>
                      <div className='row bg-text bg-slogan'>
                        <div className="col-md-12">
                          <span>Your Signal.</span><br />
                          <span style={{ lineHeight: '1.8' }}>Your Company.</span><br />
                          <span style={{ lineHeight: '1.8' }}>Your Promise to Customers.</span>

                        </div>
                      </div>
                    </div>
                    <div className='col-md-5 login-logo'>
                      <div className='row m-r-0 m-l-0 logo'>
                        <div className='col-md-12'>
                          <img className='img-center img' src={logo} />
                        </div>
                      </div>
                      <form onSubmit={this.submitForm}>
                        <div className='row m-r-0 m-l-0' style={{ paddingTop: "10%" }}>
                          <div className='col-md-12 col-sm-12 homecontent'>
                            <span className='login-text img-center'>Welcome back</span>
                            <span className='login-info m-t-10 img-center' style={{ color: '#353435' }}>Please sign in below.</span>
                            <div className="row m-t-10 justify-content-center" >
                              <div className="form-label-group m-0">
                                <input type="text" id="username" name="username" style={{ borderBottom: '1px solid #CED7DF' }} maxLength={45} className="text-user img-center" placeholder="Username" value={this.state.username} onChange={this.onChange} />
                                <label htmlFor="username">Username</label>
                              </div>
                            </div>

                            <div className="row justify-content-center" >
                              <div className="form-label-group m-0">
                                <input type="password" id="password" name="password" maxLength={20} className="text-pwd img-center b-t-0" placeholder="Password" onChange={this.onChange} />
                                <label htmlFor="password">Password</label>
                              </div>

                            </div>

                            <div className="form-group img-center m-t-20 checkbox" >
                              <div className="custom-control custom-checkbox d-inline-block float-left">
                                <input type="checkbox" className="custom-control-input" style={{ float: 'left', margin: '8px 5px 5px 0px' }} id="customCheck" name="example1" onChange={this.remember_me_checked} />
                                <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="customCheck">Remember Me</label>
                              </div>
                              {/* <input style={{ float: 'left', margin: '8px 5px 5px 0px' }} type="checkbox" onChange={this.remember_me_checked} />
                            <span className='check' style={{ float: 'left', margin: '5px 0px' }}  >Remember Me</span> */}
                              <Interactive as='div' onClick={() => { this.props.history.push('/forgot') }} ><span className='check' style={{ float: 'right', margin: '8px 0px 5px 5px' }}>Forgot Password?</span></Interactive>
                            </div>

                            <div className='img-center justify-content-center' style={{ display: 'grid' }}>
                              {/* <button className='btn btn-log m-t-20' type='submit' onClick={this.submitForm} disabled={!isEnabled}>Log In</button> */}
                              <button className='btn btn-log m-t-50' disabled={!isEnabled} type='submit'  >Log In</button>

                            </div>
                            <div className='login-info m-t-10'>
                              <span>Don't have an account?</span><Interactive as='span' onClick={() => { this.props.history.push('/register') }} ><span style={{ color: '#0059B2', fontWeight: 'bold', cursor: 'pointer', marginLeft: '5px' }}>Sign Up</span></Interactive>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobonly">
            {this.renderPages()}
            <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
              <div className='row m-r-0 m-l-0'>
                <div className='col-md-12'>
                  <div className='row'>

                    <div className='col-md-7 login-bg'>
                      <div className='row bg-text bg-slogan'>
                        <div className="col-md-12">
                          <span>Your Signal.</span><br />
                          <span style={{ lineHeight: '1.8' }}>Your Company.</span><br />
                          <span style={{ lineHeight: '1.8' }}>Your Promise to Customers.</span>

                        </div>
                      </div>
                    </div>
                    <div className='col-md-5 login-logo'>
                      <div className='row m-r-0 m-l-0 logo'>
                        <div className='col-md-12'>
                          <img className='img-center img' src={logo} />
                        </div>
                      </div>
                      <form onSubmit={this.submitForm}>
                        <div className='row m-r-0 m-l-0' style={{ paddingTop: "10%" }}>
                          <div className='col-md-12 col-sm-12 welcome' style={{ marginTop: '36px' }}>
                            <span className='login-text img-center'>Welcome Back</span>
                            <span className='login-info m-t-10 img-center' style={{ color: '#353435' }}>Please sign in below.</span>
                          </div>
                          <div className='col-md-12 col-sm-12 homecontent p-r-16 p-l-16'>
                            <div className="justify-content-center" >
                              <div className="form-label-group m-0">
                                <input type="text" id="usernamemob" name="username" maxLength={45} className="text-user img-center" placeholder="Username" value={this.state.username} onChange={this.onChange} />
                                <label htmlFor="usernamemob">Username</label>
                              </div>
                            </div>

                            <div className="justify-content-center" >
                            <i className="far fa-eye eyeicon" onClick={(e) => this.handleClick(e, "login_password")}></i>
                              <div className="form-label-group m-0">
                                <input type={this.state.login_password_type} id="passwordmob" name="password" maxLength={20} className="text-pwd img-center" placeholder="Password" onChange={this.onChange} />
                                <label htmlFor="passwordmob">Password</label>
                              </div>

                            </div>

                            <div className="form-group img-center m-t-14 checkbox" >
                              <div className="custom-control custom-checkbox d-inline-block float-left">
                                <input type="checkbox" className="custom-control-input" style={{ float: 'left', margin: '8px 5px 5px 0px' }} id="customCheckmob" name="example1" onChange={this.remember_me_checked} />
                                <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="customCheckmob">Remember Me</label>
                              </div>
                              {/* <input style={{ float: 'left', margin: '8px 5px 5px 0px' }} type="checkbox" onChange={this.remember_me_checked} />
                            <span className='check' style={{ float: 'left', margin: '5px 0px' }}  >Remember Me</span> */}
                              <Interactive as='div' onClick={() => { this.props.history.push('/forgot') }} ><span className='check' style={{ float: 'right', margin: '8px 0px 5px 5px' }}>Forgot Password?</span></Interactive>
                            </div>

                            <div className='img-center justify-content-center d-block d-grid'>
                              {/* <button className='btn btn-log m-t-20' type='submit' onClick={this.submitForm} disabled={!isEnabled}>Log In</button> */}
                              <button className='btn btn-log m-t-12' type='submit' disabled={!isEnabled} >Log In</button>

                            </div>
                            <div className='login-info m-t-24'>
                              <span>Don't have an account?</span><Interactive as='span' onClick={() => { this.props.history.push('/register') }} ><span className="fontw500" style={{ color: '#000', cursor: 'pointer', marginLeft: '5px' }}>Sign Up</span></Interactive>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <IonAlert
            isOpen={this.state.alertbox}
            onDidDismiss={() => this.setShowAlert(false)}
            message={this.state.warnMessage}
            buttons={['OK',]} />

          <IonLoading
            isOpen={this.state.showLoading}
            onDidDismiss={() => this.setShowLoading(false)}
            message={'Loading...'}
            // duration={100}
            animated={true}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);