import React from 'react'
//import Chart from "react-google-charts";
import { IonProgressBar, IonItem, IonLabel, IonTextarea, IonToggle, IonAlert, IonLoading } from '@ionic/react';
//import "react-sweet-progress/lib/style.css";
import { Chart } from 'react-google-charts'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { relative } from 'path';
import Modal from './modal'
import FloorDropz from './Floordropz';
import filter from '../assets/img/down-arrow.svg'
import wifi from '../assets/img/wifi.png'
import calendar from '../assets/img/calendar.png'
import sort from '../assets/img/checked.png'
import office from '../assets/img/ShopsatColumbus1.png'
import { requestAPICall } from "../service/apiHandler";
import Dropzone from 'react-dropzone';
import store from '../store';
import { connect } from 'react-redux';
import { UPDATE_FIELD_AUTH, CURRENT_TAB } from '../constants/actionTypes';

var sector_property: any = []
var cbsd_list: any = []
var installation_list: any = []
var optional_list: any = []
var cpe_list: any = []
const INITIAL_STATE = {
    card: '',
    show: true,
    showLoading: true,
    assetActivityList: [],
    assertlist: [],
    provider_list: [],
    asset_id: 0,
    asset_type_id: 0,
    sector_id: [],
    cpe_list: [],
    sasList: [],
    vendorList: [],
    image_source: "",
    dmpList: [],
    cbsd_list: [],
    files: [],
    a: 0,
    b: 0,
    aval: 0,
    bval: 0,
    outdoorCount: 0,
    indoorCount: 0,
    vendorcount: [],
    total_vendor_count: 0,
    categoryA: 0,
    categoryB: 0,
    categoryCBSD: 0,
    categoryAPercent: 0,
    categoryBPercent: 0,
    categoryCPSDPercent: 0,
    assetsList: [],
    assetClickId: '',
    progressData: [],
    all_user_list: [],
    start_date: "",
    end_date: "",
    installer: "",
    asset_type: "",
    status_for: [2, 4, 3, 5, 6, 1],
    offset_days: 2,
    checked: false,
    togglechecked: false,
    InstallationData: [],
    rejectParameterId: "",
    reason: "",
    showrec: false,
    approveParameterId: "",
    approve: false,
    netfileter: "Technology Filter"

};
const mapStateToProps = (state: any) => ({ ...state.auth });
const mapDispatchToProps = (dispatch: any) => ({
    upadatePendingNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'pendingNotificationCount', value }),
    upadateRejectedNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'rejectedNotificationCount', value }),
    clickNotify: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'notification', value }),
});
class Analytics extends React.Component {
    state: any = {};
    props: any = {};
    reduxStore: any = store.getState()['auth'];
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.state = { ...INITIAL_STATE }
    }

    componentDidUpdate() {

        this.reduxStore = store.getState()['auth']
        if (this.reduxStore['notification'] === true) {
            this.scrollToRef()

            this.reduxStore['notification'] = ""
        }


    }

    setShowLoading = (st: Boolean) => {
        this.setState({ showLoading: st })
    }

    componentDidMount() {
        this.reduxStore = store.getState()['auth']
        this.applyDateFilter('', '')

    }
    componentWillMount() {



    }
    show_modal = () => {

        this.setState({
            showrec: true
        })


    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }
    scrollToRef = () => {

        const element = document.getElementById('activitytab');
        console.log(element?.scrollIntoView())

    }
    getUserProfile() {
        console.log("GET USER PROFILE============")
        let userList: any = {}
        requestAPICall("get_user_profile", {}, {}).then(data => {
            if (data.responseCode === 200) {
                console.log(data)
                userList = data['user_list']

                var image = userList['userImage']
                this.setState({
                    cpiId: userList["cpiId"],
                    cpiName: userList["cpiName"]
                })
            }
        })
        console.log(this.state.cpiId)
        console.log(this.state.cpiName)
    }
    showModal = (e: any) => {
        e.preventDefault();
        this.setState({
            card: e.target.id
        })
        console.log()
    }
    hideModal = () => {
        this.setState({
            card: '',
            showrec: false,
            reason: "",
            approve: false,
            cpiId: "",
            cpiName: "",
            files: [],


        })
    }
    cpiSaveCahnge = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    onDrop = (files: any) => {
        this.setState({ files: files })
    };

    setChecked = (togglechecked: any) => {

        this.setState({
            togglechecked: togglechecked
        })
        console.log(this.state.togglechecked)
        console.log(this.state.progressData)

        setTimeout(() => {
            if (this.state.togglechecked) {
                this.get_deployment_progress()
            }

        }, 200)

    }
    listvendorDetails = () => {

        requestAPICall("list_analytics_vendor_details", { start_date: this.state.start_date, end_date: this.state.end_date, installer: this.state.installer }, {})
            .then(data => {
                if (data.responseCode == 200) {

                    this.setState({
                        vendorList: data['vendor_list'],
                    })
                }
            })
    }

    listSASParams() {

        requestAPICall("list_anaytics_params", { provider_type_id: 1 }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({
                        sasList: data.providers,

                    })


                }

            })

    }

    listDMPParams() {

        requestAPICall("list_anaytics_params", { provider_type_id: 2 }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {

                    this.setState({
                        dmpList: data.providers,

                    })
                }
            })

    }
    cbsd_category() {

        requestAPICall("analytics_cbsd_category", { start_date: this.state.start_date, end_date: this.state.end_date, installer: this.state.installer }, {})
            .then(data => {
                if (data.responseCode == 200) {

                    this.setState({
                        categoryA: data['Category_A'],
                        categoryB: data['Category_B'],
                        // categoryCBSD: data['Category_CPSD'],
                    })

                    if (this.state.categoryA > 0) {
                        this.setState({
                            categoryAPercent: ((this.state.categoryA / (this.state.categoryA + this.state.categoryB + this.state.categoryCBSD)) * 100).toFixed(2),
                        })
                    }
                    else {
                        this.setState({
                            categoryAPercent: 0
                        })
                    }
                    if (this.state.categoryB > 0) {
                        this.setState({
                            categoryBPercent: ((this.state.categoryB / (this.state.categoryA + this.state.categoryB + this.state.categoryCBSD)) * 100).toFixed(2),

                        })
                    }
                    else {
                        this.setState({
                            categoryBPercent: 0
                        })
                    }
                    // if (this.state.categoryCBSD > 0) {
                    //     this.setState({
                    //         categoryCPSDPercent: ((this.state.categoryCBSD / (this.state.categoryA + this.state.categoryB + this.state.categoryCBSD)) * 100).toFixed(2)
                    //     })
                    // }
                    // else {
                    //     this.setState({
                    //         categoryCPSDPercent: 0
                    //     })
                    // }

                }

            })

    }


    propretycall = (val: any, assetClickId: any) => {
        console.log(val.assetId)
        this.setState({
            asset_id: val.assetId,
            asset_type_id: val.assetTypeId,
            assetClickId: assetClickId
        }, () => this.get_asset_details(val.assetId, ""))
    }
    get_asset_details = (selectedAssetId: any, text: any) => {

        let requestBody = {}
        this.setState({
            sector_id: [], cpe_list: []
        })
        installation_list = [];
        optional_list = [];
        cbsd_list = [];
        var cpe_list = [];
        requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody)
            .then(data => {
                console.log(installation_list, optional_list, cbsd_list)
                var resp = data['properties']



                resp.forEach((element: any) => {
                    if (element['dataType'] === "Image") {
                        this.setState({
                            image_source: element['value'],
                            asset_type_id: data['assetTypeId']

                        })

                    }

                });

                if (data['assetTypeId'] === 3 || data['assetTypeId'] === 2 || data['assetTypeId'] === 4) {

                    installation_list = data["installation_params"]["installation"]
                    optional_list = data["installation_params"]["optional"]
                    cbsd_list = data["installation_params"]["cbsd"]

                    this.setState({
                        cpe_list: data['properties']

                    })

                    cpe_list = data['properties']

                }

                else {
                    console.log(typeof (this.state.sector_id))
                    this.setState({
                        sector_id: data['sector_id']

                    })
                    this.get_sector_properties(this.state.sector_id[0]["sector_id"])
                }


            })
    }



    get_sector_properties = (sector_id: any) => {

        this.setState({ selectedSector: sector_id })
        requestAPICall("get_sector_details", { asset_type_id: this.state.asset_type_id, asset_id: this.state.asset_id, sector_id: this.state.selectedSector }, {}).then(data => {
            installation_list = data["properties"]["installation"]
            optional_list = data["properties"]["optional"]
            cbsd_list = data["properties"]["cbsd"]
            this.setState({
                showvalue: true
            })
        })
    }

    listAssets = (status: any) => {
        var outdoorassetsList: any = [];
        var indoorassetsList: any = [];
        this.setState({
            outdoorCount: 0,
            indoorCount: 0,
            a: 0,
            b: 0,
            aval: 0,
            bval: 0,

        })
        requestAPICall("list_analytics_asset", { start_date: this.state.start_date, end_date: this.state.end_date, asset_type: this.state.asset_type, installer: this.state.installer }, {}).then(data => {
            if (data.responseCode == 200) {
                console.log(data.asset_list)
                this.setState({ assertlist: data.asset_list })
                data.asset_list.map((asset: any) => {
                    if (asset['assetType'] === 'AP-outdoor' || asset['assetType'] === 'CPE-outdoor') {
                        outdoorassetsList.push(asset)
                    }
                    else {
                        indoorassetsList.push(asset)
                    }
                })

            }
            console.log(outdoorassetsList.length);

            if (outdoorassetsList.length > 0 || indoorassetsList.length > 0) {
                this.state.percList = this.calculatePerc(outdoorassetsList.length, indoorassetsList.length)
                this.setState({
                    outdoorCount: outdoorassetsList.length,
                    indoorCount: indoorassetsList.length,

                    a: this.state.percList[0],
                    b: this.state.percList[1]

                })
                this.setState({
                    aval: this.state.a / 100,
                    bval: this.state.b / 100
                })
            }
        })
        console.log(this.state.assertlist)
    }
    calculatePerc = (a: number, b: number) => {
        let vala;
        let valb;
        if (a > 0) {
            vala = ((a / (a + b)) * 100).toFixed(2)
        }
        if (b > 0) {
            valb = ((b / (a + b)) * 100).toFixed(2)

        }

        let list = [vala, valb]
        console.log(list)
        return list
    }

    applyDateFilter(e: any, dateObj: any) {
        this.setState({
            showLoading: true
        })
        function format(num: any) {
            return num > 9 ? num.toString() : '0' + num.toString()
        }
        var startDateObj;
        var endDateObj;
        if (dateObj !== '') {
            startDateObj = dateObj.startDate._d
            endDateObj = dateObj.endDate._d

        } else {
            startDateObj = new Date()
            endDateObj = new Date()
        }
        var startDate = startDateObj.getFullYear().toString() + "-" + format(startDateObj.getMonth() + 1) + "-" + format(startDateObj.getDate())
        var endDate = endDateObj.getFullYear().toString() + "-" + format(endDateObj.getMonth() + 1) + "-" + format(endDateObj.getDate())
        console.log(startDate);
        console.log(endDate);

        this.setState(
            {
                start_date: startDate,
                end_date: endDate,
                sdate: format(startDateObj.getMonth() + 1) + "." + format(startDateObj.getDate()) + "." + format(startDateObj.getFullYear().toString()),
                edate: format(endDateObj.getMonth() + 1) + "." + format(endDateObj.getDate()) + "." + format(endDateObj.getFullYear().toString())
            }, () => {
                this.get_deployment_progress()
                this.listvendorDetails()
                this.listAssets('create-list')
                this.cbsd_category()

            })


    }

    get_deployment_progress() {
        requestAPICall("get_depolyment_progress", { start_date: this.state.start_date, end_date: this.state.end_date, offset_days: this.state.offset_days, status_for: this.state.status_for, installer: this.state.installer }, {})

            .then(data => {
                console.log(data)
                this.setState({ showLoading: false })
                if (data.responseCode == 200) {
                    this.setState({ progressData: data.progress })
                }
            })
    }
    get_installtions() {
        requestAPICall("get_installations", { start_date: this.state.start_date, end_date: this.state.end_date, offset_days: this.state.offset_days, status_for: this.state.status_for, installer: this.state.installer }, {})

            .then(data => {
                if (data.responseCode == 200) {
                    this.setState({ InstallationData: data.graph_data })
                }
            })
    }

    handleChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'
        return (
            <div className='container-fluid'>
                <div className='row' style={{ backgroundColor: '#F9FAFC' }} >
                    <div className="col-md-12">
                        <div className='row m-t-20' >
                            <div className='col-md-8' >
                                <span style={{ fontWeight: "bold", fontSize: '20px' }}>Overview</span>
                            </div>
                            <div className='col-md-4' style={{ display: "flex", alignItems: "center" }}>
                                <span className="dropdown techfilter">
                                    <img className=" p-r-10" src={wifi} style={{ width: '30px', filter: 'opacity(0.4)' }} />
                                    <span className="p-r-10 filtername" style={{ fontSize: '14px' }}>{this.state.netfileter}</span>
                                    <img className="filterarrow" src={filter} style={{ width: '15px' }} data-toggle="dropdown" />
                                    <div className="dropdown-menu">

                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "Technology Filter" }) }; }} >Technology Filter</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "LTE" }) }; }}>LTE</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "Wifi" }) }; }}>Wifi</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "NR" }) }; }}>NR</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "Others" }) }; }}>Others</a>

                                    </div>
                                </span>
                                <div className="p-l-30">
                                    <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                        <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                        <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                        <img src={filter} style={{ width: '15px' }} />
                                    </DateRangePicker>
                                </div>

                            </div>
                        </div>
                        <div className='row m-t-30' >
                            <div className='col-md-6 p-r-8'>
                                <div className='card lf' >
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <span className="font14">LOCATIONS</span>

                                        <div className='row blueprogress'>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>

                                                <span className='float-left bardes'>Outdoor</span>
                                                <span className='float-right bardes' >{this.state.outdoorCount}</span>
                                                <IonProgressBar value={this.state.aval} style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.a}%</span>
                                            </div>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: "center" }}>

                                                <span className='float-left bardes'>Indoor</span>
                                                <span className='float-right bardes'>{this.state.indoorCount}</span>
                                                <IonProgressBar value={this.state.bval} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.b}%</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-l-8' >
                                <div className='card rt' >
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <span className="font14">CBSD CATEGORY</span>
                                        <div className='row orangeprogress'>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>Category A</span>
                                                <span className='float-right bardes' >{this.state.categoryA}</span>
                                                <IonProgressBar value={this.state.categoryAPercent} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.categoryAPercent}%</span>
                                            </div>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>Category B</span>
                                                <span className='float-right bardes' >{this.state.categoryB}</span>
                                                <IonProgressBar value={this.state.categoryBPercent} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.categoryBPercent}% </span>
                                            </div>
                                            {/* <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>CPE-CPSD</span>
                                                <span className='float-right bardes' >{this.state.categoryCBSD}</span>
                                                <IonProgressBar value={this.state.categoryCPSDPercent} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.categoryCPSDPercent}%</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row m-t-16' >
                            <div className='col-md-6 p-r-8'>
                                <div className='card lf m-t-0'>
                                    {this.state.sasList.length > 0 ?
                                        <div className='card-body' style={{ padding: '20px 30px' }}>
                                            <span className="font14">SAS</span>
                                            <div className='row orangeprogress'>

                                                {this.state.sasList.map((sas: any) => (
                                                    <div className='col-md-4' style={{ paddingTop: '15px', textAlign: 'center' }}>

                                                        {sas.param.length > 0 ?
                                                            <div>

                                                                <span className='float-left bardes'>{sas.providerName}</span>
                                                                <span className='float-right bardes' >{sas.param.length}</span>
                                                                <IonProgressBar value={sas.value} style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">{sas.percent}</span>
                                                            </div> :
                                                            <div>

                                                                <span className='float-left bardes'>{sas.providerName}</span>
                                                                <span className='float-right bardes' >{sas.param.length}</span>
                                                                <IonProgressBar value={0} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">0%</span>
                                                            </div>
                                                        }
                                                    </div>))}

                                            </div>
                                        </div> : <div className='card-body' style={{ padding: '20px 30px' }}>
                                            NO SAS FOUND</div>}
                                </div>
                            </div>
                            <div className='col-md-6 p-r-8' >
                                <div className='card lf m-t-0' >
                                    {this.state.dmpList.length > 0 ?
                                        <div className='card-body' style={{ padding: '20px 30px' }}>
                                            <span className="font14">DOMAIN PROXY</span>
                                            <div className='row greenprogress'>
                                                {this.state.dmpList.map((dmp: any) => (
                                                    <div className='col-md-4' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                        {dmp.param.length > 0 ?
                                                            <div>

                                                                <span className='float-left bardes'>{dmp.providerName}</span>
                                                                <span className='float-right bardes' >{dmp.param.length}</span>
                                                                <IonProgressBar value={dmp.value} style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">{dmp.percent}</span>
                                                            </div> :
                                                            <div>

                                                                <span className='float-left bardes'>{dmp.providerName}</span>
                                                                <span className='float-right bardes' >{dmp.param.length}</span>
                                                                <IonProgressBar value={0} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">0%</span>
                                                            </div>
                                                        }
                                                    </div>))}
                                            </div>
                                        </div> : <div className='card-body' style={{ padding: '20px 30px' }}>NO DOMAIN PROXY FROUND</div>}
                                </div>
                            </div>


                        </div>
                        <div className='row m-t-16' >
                            {this.state.vendorList.map((val: any) => (
                                <div className='col-md-6 p-l-8'>
                                    <div className='card rt m-t-0'>

                                        <div className='card-body' style={{ padding: '20px 30px' }}>

                                            <span className="font14">{val.vendor_name}</span>
                                            <div className='row greenprogress'>
                                                {val.vendor_model.map((count: any) => (
                                                    <div className='col-md-4' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                        {count.vendor_asset_count > 0 ?
                                                            <div>

                                                                <span className='float-left bardes'>Model  {count.vendor_model_name.split("-")[1]}</span>
                                                                <span className='float-right bardes' >{count.vendor_asset_count}</span>
                                                                <IonProgressBar value={(count.vendor_asset_count / val.total_asset_vendor_count) * 100} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">{((count.vendor_asset_count / val.total_asset_vendor_count) * 100).toFixed(2)}%</span>
                                                            </div> :
                                                            <div>

                                                                <span className='float-left bardes'>Model  {count.vendor_model_name.split("-")[1]}</span>
                                                                <span className='float-right bardes' >{count.vendor_asset_count}</span>
                                                                <IonProgressBar value={0} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">0%</span>
                                                            </div>}
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>))}
                        </div>

                        <div className='row m-t-16' >
                            <div className='col-md-12'>
                                <div className="card" style={{ width: '100%', height: '420px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        {this.state.togglechecked ?
                                            <span className="font14">DEPLOYMENT PROGRESS</span> :
                                            <span className="font14">INSTALLATIONS</span>
                                        }

                                        <span><IonItem>
                                            <IonLabel></IonLabel>
                                            <IonToggle checked={this.state.togglechecked} onIonChange={(e: any) => this.setChecked(e.detail.checked)} />
                                        </IonItem></span>
                                        {!(this.state.togglechecked) ?

                                            <Chart
                                                width={'100%'}
                                                height={'500px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.InstallationData}
                                                // data={[
                                                //     ['Year', 'Sales', 'Expenses', 'Profit'],
                                                //     ['2014', 1000, 400, 200],
                                                //     ['2015', 1170, 460, 250],
                                                //     ['2016', 660, 1120, 300],
                                                //     ['2017', 1030, 540, 350],
                                                //     ['2018', 1000, 400, 200],
                                                //     ['2019', 1170, 460, 250],
                                                //     ['2020', 660, 1120, 300],
                                                //     ['2021', 1030, 540, 350],
                                                //     ['2014', 1000, 400, 200],
                                                //     ['2015', 1170, 460, 250],
                                                //     ['2016', 660, 1120, 300],
                                                //     ['2017', 1030, 540, 350],
                                                //     ['2018', 1000, 400, 200],
                                                //     ['2019', 1170, 460, 250],
                                                //     ['2020', 660, 1120, 300],
                                                //     ['2021', 1030, 540, 350],
                                                //     ['2014', 1000, 400, 200],
                                                //     ['2015', 1170, 460, 250],
                                                //     ['2016', 660, 1120, 300],
                                                //     ['2017', 1030, 540, 350],
                                                //     ['2018', 1000, 400, 200],
                                                //     ['2019', 1170, 460, 250],
                                                //     ['2020', 660, 1120, 300],
                                                //     ['2021', 1030, 540, 350],
                                                //     ['2014', 1000, 400, 200],
                                                //     ['2015', 1170, 460, 250],
                                                //     ['2016', 660, 1120, 300],
                                                //     ['2017', 1030, 540, 350],
                                                //     ['2018', 1000, 400, 200],
                                                //     ['2019', 1170, 460, 250],
                                                //     ['2020', 660, 1120, 300],
                                                //     ['2021', 1030, 540, 350],
                                                // ]}
                                                options={{
                                                    isStacked: true,
                                                    colors: ['#0059B2', '#ADD8E6', '#6EDAFF'],
                                                    legend: { position: 'top' },
                                                    //bars:'vertical',
                                                    height: 300,
                                                    width: 1200,
                                                    circle: { groupWidth: '50%' },
                                                    bar: { groupWidth: '30%' },


                                                    // title: 'Population of Largest U.S. Cities',
                                                    chartArea: { width: '80%' },

                                                    hAxis: {
                                                        title: '',
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        title: '',
                                                    },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                            : ""}
                                        {(this.state.togglechecked && this.state.progressData.length != 0) ?

                                            < Chart
                                                width={'100%'}
                                                height={'500px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.progressData}
                                                options={{
                                                    isStacked: true,
                                                    colors: ['#00D56E', '#FCD369', '#0059B2', '#0000A0', '#D8D8D8', '#ff0000'],
                                                    legend: { display: true, position: 'top' },
                                                    height: 300,
                                                    width: 1200,
                                                    bar: { groupWidth: '10%' },


                                                    title: '',
                                                    chartArea: { width: '80%' },

                                                    hAxis: {
                                                        title: 'Month',
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        title: '# of Sites',
                                                    },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>





                        <Modal show={this.state.show && this.state.card === 'heat'} handleClose={this.hideModal}>
                            {/* <div className="modal fade" id="heatModal" role="dialog" aria-hidden="true"> */}
                            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className='row' style={{ padding: '0 10px' }}>
                                            <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>IMPORT FILES</h6>
                                            <i className="far fa-times-circle modalclose" onClick={this.hideModal} data-dismiss="modal" aria-label="Close"></i>
                                        </div>
                                        <div className='row m-t-10 justify-content-center' style={{ padding: '0 10px' }}>
                                            {/* <div className="form-group" >
                                        <select className="form-control paramtext" style={{ borderTop: '0.5px solid #8F9FAC', borderRadius: '6px 6px 0px 0px' }}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                        <input type="text" className="paramtext" placeholder="CBSD Info" />
                                        <input type="text" className="paramtext" placeholder="Hardware Version" />
                                        <select className="form-control paramtext" style={{ borderRadius: '0px 0px 6px 6px' }}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div> */}
                                            <div className='col-md-12'>
                                                <FloorDropz >
                                                    <span>Drag and Drop files here</span>
                                                </FloorDropz></div>
                                        </div>
                                        <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                            <button className='btn cardbtn' style={{ width: '268px' }}>IMPORT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </Modal>
                    </div >
                </div >

                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />
                <IonLoading
                    isOpen={this.state.showLoading}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                    // duration={100}
                    animated={true}
                />
            </div >
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
