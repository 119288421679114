import React from 'react';
import { GoogleMap, StandaloneSearchBox, Marker, HeatmapLayer, Polyline, InfoWindow, InfoBox, Circle, KmlLayer } from '@react-google-maps/api'
import { isBrowser, isMobile } from "react-device-detect";
import { withRouter } from 'react-router-dom'
import { IonLoading, IonAlert } from '@ionic/react';

import AP from '../assets/img/Apicon.png'
//import floorPlan from '../assets/json/cc_floorplan.json'
import APSec1 from '../assets/img/blue_leaf_1.png'; import APSec2 from '../assets/img/blue_leaf_2.png';
import APSec3 from '../assets/img/blue_leaf_3.png'; import APSec4 from '../assets/img/blue_leaf_4.png';
import APSec5 from '../assets/img/blue_leaf_5.png'; import APSec6 from '../assets/img/blue_leaf_6.png';
import CPEMap from '../assets/img/CPE_ICON.png'
import AP1Gray from '../assets/img/status-img/access-point-1-gray.png'; import AP2Gray from '../assets/img/status-img/access-point-2-gray.png';
import AP3Gray from '../assets/img/status-img/access-point-3-gray.png'; import AP4Gray from '../assets/img/status-img/access-point-4-gray.png';
import AP5Gray from '../assets/img/status-img/access-point-5-gray.png'; import AP6Gray from '../assets/img/status-img/access-point-6-gray.png';
import CPEGray from '../assets/img/status-img/cpe-gray.png';
import AP1DGray from '../assets/img/status-img/access-point-1.png'; import AP2DGray from '../assets/img/status-img/access-point-2.png';
import AP3DGray from '../assets/img/status-img/access-point-3.png'; import AP4DGray from '../assets/img/status-img/access-point-4.png';
import AP5DGray from '../assets/img/status-img/access-point-5.png'; import AP6DGray from '../assets/img/status-img/access-point-6.png';
import CPEDGray from '../assets/img/status-img/cpe-dark-gray.png';
import locmarkpng from '../assets/img/current-location.png';
import gps_icon from '../assets/img/add-cpe-icon-img-blue.png'
import Dashboard from './dashboard'
import AddAP from './AddAP';
import Search from './search';
import AddCPE from './AddCPE';
import Measure from './measure';
import Calculate from './calculate';
import APdetails from './access-point-details';
import { requestAPICall } from "../service/apiHandler";
import { Redirect } from 'react-router-dom';
import APdetails1 from './access-point-details1';
import Search1 from './search1';
import { connect } from 'react-redux';
import store from '../store';
import ADDCP2 from './AddCPE2';
import ADDCP3 from './AddCPE3';
import AddAP2 from './AddAP2';
import AddAP3 from './AddAP3';
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from '../constants/actionTypes';
import { getSectorImage, getSectorImageMeasure } from '../service/rotateIcon'
import { Plugins, AppState } from "@capacitor/core";

// const { LocationStatusPlugin } = Plugins;
const { GetPermissionsPlugin } = Plugins;
const { Geolocation } = Plugins;
const { App } = Plugins;
const { Toast } = Plugins;
const { BackgroundTask } = Plugins

const ASSET_INITIAL_STATE = {
    center: null
};

// const styles = require('../assets/GoogleMapStyle.json')

var markerIcon = {
    url: require('../assets/img/gps-icon-img2.png'),
    scaledSize: new window.google.maps.Size(14, 26)
}
var bounds = {
    north: 44.599,
    south: 44.490,
    east: -78.443,
    west: -78.649
};
var rectangle = new google.maps.Rectangle({
    bounds: bounds,
    editable: true,
    draggable: true
});
// let center = {
//     lat: 38.9072, lng: -77.0369
// }
let API_VALUES = {
    code: 0
};

var lineSymbol = {
    path: 'M 0,-1 0,1',
    scale: 4,
    strokeColor: '#FF9445',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF9445',
    fillOpacity: 0.35,
    radius: 30000,
    zIndex: 1
};

const circleOptions = {
    strokeColor: '#0059B2AD',
    strokeOpacity: 0.35,
    strokeWeight: 1,
    fillColor: '#0059B2AD',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    zIndex: 1
}

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
    upadateGMapClickEvent: (value: any) =>
        dispatch({ type: CURRENT_MAP_CLICK_INSTANCE, key: 'gMapClickInstance', value }),
    upadateMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'coordinates', value }),
    upadateSearchMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'asset_search', value }),
    updateAssetTypeId: (value: any) =>
        dispatch({ type: ASSET, key: 'assetTypeId', value }),
    updateAssetAction: (value: any) =>
        dispatch({ type: ASSET, key: 'assetAction', value }),
    updateAssetId: (value: any) =>
        dispatch({ type: ASSET, key: 'assetId', value }),
    updateSearchText: (value: any) =>
        dispatch({ type: COORDINATES, key: 'search', value }),
    updateTabType: (value: any) =>
        dispatch({ type: ASSET, key: 'tabType', value }),
    upadateUrlPath: (value: any) =>
        dispatch({ type: CURRENT_TAB, key: 'updateUrlPath', value })


});

var isFirstLoadComplete = false;
// var asset_show_list = []
var lat: any;
var lng: any;
var map: any;
var locations: any = [new window.google.maps.LatLng(13.0832332, 79.203232323),
new window.google.maps.LatLng(13.183232323, 78.3032323),
new window.google.maps.LatLng(11.3843232323, 79.4032322),
new window.google.maps.LatLng(11.177683, 78.343232),
new window.google.maps.LatLng(12.4645323, 77.703233),
new window.google.maps.LatLng(13.03534443, 88.2037323),
new window.google.maps.LatLng(12.178678323, 79.0333235)]

class Outdoor extends React.Component {
    state: any = {};
    props: any = {};
    headerList: any = {};
    apiValues: any = {};
    locations: any = {};
    data: any = {};
    watchId: any = '';
    asset_show_list: any = []
    dragMap: any;
    childCPE: React.RefObject<any>
    childAP: React.RefObject<any>
    childAssetDetails: React.RefObject<any>
    apdetails_ref: React.RefObject<any>
    childMeasure: React.RefObject<any>
    dashRef: React.RefObject<any>
    _map: any;
    marker_event1: any
    marker_event2: any
    inBetween: any
    geocoder: any;
    timer: any;
    backButton_timer: any = null
    measure_timer: any;
    reduxStore: any = store.getState()['general']
    reduxAuthStore: any = store.getState()['auth']
    listAssetsTimer: any;
    firstAssetIndex: any = { "ap": 0, "cpe": 0 }
    checkIconsLoaded: any;
    callAssetIcons: any;
    callMeasureIcon1: any;
    callMeasureIcon2: any;
    permissionCheckInterval: any;
    counter: any = 0

    backButton_event: any
    appState_event: any
    constructor(props: any) {
        super(props);
        this.headerList = { ...ASSET_INITIAL_STATE };
        this.apiValues = { ...API_VALUES };
        this.watchId = ''
        this.state = {
            card: 1,
            subcard: null,
            marks: [],
            username: "",
            APname: [],
            Description: '',
            AddImage: '',
            Serial: '',
            properties: [],
            asset_details: [],
            temp: 0,
            cord: {},
            param1: '',
            param2: '',
            para: [],
            latlonglist: [],
            azimuth1: 0,
            azimuth2: 0,
            height1: 0,
            height2: 0,
            tilt1: 0,
            tilt2: 0,
            distance: 0,
            lat: 0,
            long: 0,
            lng: 0,
            typeId: 0,
            cardlist: [],
            rawAssetList: [],
            assetList: [],
            disabledAssetList: [],
            addAssetList: [],
            currentMapClickEvent: {},
            selectedAssetId: 0,
            assetAction: 'add',
            noOfSectors: 0,
            browserLatitude: 0.0,
            browserLongitude: 0.0,
            map: {},
            radiation_center: [],
            sector_id_list: [],
            serial: '',
            assetPointDropList: [],
            isAssetDropEnabled: false,
            measure_pair: [],
            locmark: false,
            rad_center: null,
            warnMessage: "",
            isLoading: false,
            alertbox: false,
            add_ap2_properties: [],
            add_ap3_properties: [],
            add_cp2_properties: [],
            add_cp3_properties: [],
            gps_lat: 0,
            gps_lng: 0,
            gps_accuracy: 0,
            accuracy_visible: false,
            show_overlay: false,
            assetPointclickList: [],
            assetPointclickEnabled: false,
            assetCardShow: true,
            assetCardLatLongList: [],
            assetHeight: 0,
            assetStatus: "",
            onHover: false,
            assetIdentifierId: "",
            assethoverLatLongList: [],
            polygon_files: null,
            ap_cpe_locamrk: false,
            search_asset: {},
            sasStatus: "",
            pciCardShow: false,
            editSerial: true,
            isSafari: false,
            displaySASIcon: false,
            transparentIcon: {
                url: require('../assets/img/status-img/transparent-circle-marker.png'),
                scaledSize: new window.google.maps.Size(0, 0),
                anchor: new window.google.maps.Point(5, 5),
                labelAnchor: new window.google.maps.Size(0, 0),
                labelOrigin: new window.google.maps.Point(5, 45)
            }
        }
        this.onMapClick = this.onMapClick.bind(this)
        this.onMarkerDrag = this.onMarkerDrag.bind(this)
        this.hideOverlay = this.hideOverlay.bind(this)
        this.onHover = this.onHover.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.search = this.search.bind(this)
        this.geocodeAddress = this.geocodeAddress.bind(this)
        this.refreshAsset = this.refreshAsset.bind(this)
        this.childCPE = React.createRef()
        this.childAP = React.createRef()
        this.childAssetDetails = React.createRef()
        this.apdetails_ref = React.createRef()
        this.childMeasure = React.createRef()
        this.dashRef = React.createRef()
        this.dragMap = React.createRef()
    }
    clearWatch() {
        if (this.watchId != null) {
            Geolocation.clearWatch({ id: this.watchId });
        }

    }

    async getLocationUsingWatchPosition(): Promise<any> {
        return new Promise<any>((resolve) => {
            this.watchId = Geolocation.watchPosition({}, (position: any, err: any) => {
                if (err) {
                    console.log(err)
                }
                else {
                    console.log(position)
                    this.clearWatch()
                    resolve(position)
                }
            })
        })
    }

    async firstLocation() {

        if ([undefined, null, 'undefined', 'null'].includes(localStorage.getItem('outdoor_map_center_lat')) || [undefined, null, 'undefined', 'null'].includes(localStorage.getItem('outdoor_map_center_lng'))) {
            console.log("=========getting device location===============")
            // const coordinates = await Geolocation.getCurrentPosition();
            const coordinates = await this.getLocationUsingWatchPosition()
            console.log("===CurrentPosition====", coordinates)
            this.setState({
                gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
                gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
                gps_accuracy: coordinates.coords.accuracy
            })
            this.setState({
                center: {
                    lat: parseFloat(coordinates.coords.latitude.toFixed(6)), lng: parseFloat(coordinates.coords.longitude.toFixed(6))
                }
            }, () => {

                this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
            })
            localStorage.setItem("outdoor_map_center_lat", coordinates.coords.latitude.toFixed(6))
            localStorage.setItem("outdoor_map_center_lng", coordinates.coords.longitude.toFixed(6))

        }
        else {
            lat = localStorage.getItem('outdoor_map_center_lat')
            lng = localStorage.getItem('outdoor_map_center_lng')
            this.setState({
                center: {
                    lat: parseFloat(lat), lng: parseFloat(lng)
                }
            }, () => {
                this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
            })
        }

    }

    onBackKeyDown = (e: any) => {
        console.log("===========onBackKeyDown")
        e.preventDefault();
        e.stopPropagation();
        if (this.state.card == 1 && localStorage.getItem("urlPath") === "/map/outdoor/main") {
            if (this.state.subcard == null) {
                if (this.counter == 0) {
                    this.counter++;
                    this.presentToast();
                    setTimeout(() => { this.counter = 0 }, 2000)
                } else {
                    console.log('exiting app.........')
                    App.exitApp();
                }
            }
            else if (this.state.subcard == 'search') {
                console.log(this.state.subcard, "sub card details...............")
                this.dashRef.current.switch()
            }
            else if (this.state.subcard == 'network') {
                console.log("========*****==NETWORk==============")
                this.dashRef.current.hideNetworkOverlay()
            }
        }
        if (this.state.card == 2 || this.state.card == 4) {
            console.log('hiding overlay..........................')
            this.hideOverlay()
        }
        if (this.state.card == 5 || this.state.card == 6) {
            console.log('switching to dash........................')
            this.dash()
        }
        if (this.state.card == 7) {
            if (this.state.subcard == null) {
                console.log('switching to dash........................')
                this.dash()
            }
            else {
                if (this.state.subcard == 'details') {
                    console.log('from details to parent card...............')
                    this.childAssetDetails.current.hideOverlay()
                }
                if (this.state.subcard == 'parameter') {
                    console.log('from parameter to parent card...............')
                    this.childAssetDetails.current.hideParamOverlay()
                }
                if (this.state.subcard == 'network') {
                    console.log('from network to parent card...............')
                    this.childAssetDetails.current.hideNetworkOverlay()
                }

            }
        }
        localStorage.setItem("urlPath", this.props.location.pathname)
    }

    onDeviceReady = (e: any) => {
        console.log("===========onDeviceReady")
        localStorage.setItem('backButton', "true")
        localStorage.setItem('appState', "true")
        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }
    set_backButton_listener = () => {

        var backButtonListenerExists = localStorage.getItem('backButton')
        // console.log('checking for back button listener..')
        if (backButtonListenerExists === null) {
            localStorage.setItem('backButton', "true")
            // console.log('creating back button listener')
            this.backButton_event = App.addListener('backButton', (state: any) => {
                console.log("===========", this.state.subcard, this.counter, this.state.card)
                // state.isActive contains the active state
                // console.log('back button pressed..............');
                // console.log(this.state.card)
                if (this.state.card == 1 && localStorage.getItem("urlPath") === "/map/outdoor/main") {
                    if (this.state.subcard == null) {
                        if (this.counter == 0) {
                            this.counter++;
                            console.log("========*****==&&&&&&==============")
                            this.presentToast();
                            setTimeout(() => { this.counter = 0 }, 2000)
                        } else {
                            console.log('exiting app.........')
                            App.exitApp();
                        }
                    }
                    else if (this.state.subcard == 'search') {
                        console.log(this.state.subcard, "sub card details...............")
                        this.dashRef.current.switch()
                    }
                    else if (this.state.subcard == 'network') {
                        console.log("========*****==NETWORk==============")
                        this.dashRef.current.hideNetworkOverlay()
                    }
                }
                if (this.state.card == 2 || this.state.card == 4) {
                    console.log('hiding overlay..........................')
                    this.hideOverlay()
                }
                if (this.state.card == 5 || this.state.card == 6) {
                    console.log('switching to dash........................')
                    this.dash()
                }
                if (this.state.card == 7) {
                    if (this.state.subcard == null) {
                        console.log('switching to dash........................')
                        this.dash()
                    }
                    else {
                        if (this.state.subcard == 'details') {
                            console.log('from details to parent card...............')
                            this.childAssetDetails.current.hideOverlay()
                        }
                        if (this.state.subcard == 'parameter') {
                            console.log('from parameter to parent card...............')
                            this.childAssetDetails.current.hideParamOverlay()
                        }
                        if (this.state.subcard == 'network') {
                            console.log('from network to parent card...............')
                            this.childAssetDetails.current.hideNetworkOverlay()
                        }

                    }
                }
            });
        }
        else {
            // console.log('backButton listener already exists')
        }
        if (this.backButton_timer == null) {
            console.log('creating timer.............')
            this.backButton_timer = setInterval(() => {
                this.set_backButton_listener()
            }, 2000)
        }
        else {
            // console.log('timer already exists.............')
        }
        var appStateListenerExists = localStorage.getItem('appState')
        // console.log('checking for app state listener..')
        if (appStateListenerExists == null) {
            // console.log('creating app state event listener....................')
            localStorage.setItem('appState', "true")
            this.appState_event = App.addListener('appStateChange', (state: AppState) => {
                if (state.isActive) {

                    this.set_backButton_listener()

                }
                if (!state.isActive) {
                    let taskId = BackgroundTask.beforeExit(async () => {
                        // In this function We might finish an upload, let a network request
                        // finish, persist some data, or perform some other task
                        console.log('inactive state.............')
                        // Example of long task

                        if (this.backButton_timer != null) {
                            console.log('clearing timer.......')
                            clearInterval(this.backButton_timer)
                            this.backButton_timer = null
                        }
                        if (this.backButton_event != null) {
                            console.log('clearing back button event..........')
                            this.backButton_event.remove()
                        }
                        localStorage.removeItem('backButton')
                        // Must call in order to end our task otherwise
                        // we risk our app being terminated, and possibly
                        // being labeled as impacting battery life
                        BackgroundTask.finish({
                            taskId
                        });
                    });
                }
            }
            )
        }
        else {
            // console.log('app state listener already exists............')
        }
        console.log("============", this.props.location.pathname)
        localStorage.setItem("urlPath", this.props.location.pathname)
    }
    componentDidMount() {
        //DETECT BROWSER
        if (localStorage.getItem("browser") == "Safari") {
            console.log("Found Safari")
            this.setState({
                isSafari: true
            })
        }
        console.log(this.props.location.pathname.split('/')[1])
        if (isBrowser) {
            if (navigator.userAgent.search("Chrome") >= 0) {
                this.firstLocation()
            }
            else {
                if ([undefined, null, 'undefined', 'null'].includes(localStorage.getItem('outdoor_map_center_lat')) || [undefined, null, 'undefined', 'null'].includes(localStorage.getItem('outdoor_map_center_lng'))) {
                    this.setState({
                        gps_accuracy: 0
                    })
                    this.setState({
                        center: {
                            lat: 38.907192, lng: -77.036873
                        }
                    }, () => {
                        this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
                    })
                    localStorage.setItem("outdoor_map_center_lat", "38.907192")
                    localStorage.setItem("outdoor_map_center_lng", "-77.036873")
                }
                else {
                    lat = localStorage.getItem('outdoor_map_center_lat')
                    lng = localStorage.getItem('outdoor_map_center_lng')
                    this.setState({
                        center: {
                            lat: parseFloat(lat), lng: parseFloat(lng)
                        }
                    }, () => {
                        this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
                    })
                }
            }
        }
        if (isMobile) {
            // localStorage.removeItem('backButton')
            // localStorage.removeItem('appState')
            this.firstLocation()
            //this.getMobilePermissions()
            $("#searchbox").hide()
            setTimeout(() => {
                console.log("first resize................................")
                $("#searchbox").css('width', '99.9%');
                $("#searchbox").show()
            }, 50);
            setTimeout(() => {
                console.log("second resize............................")
                $("#searchbox").css('width', '100%');
                $("#searchbox").show()
            }, 1000);
            document.addEventListener("deviceready", this.onDeviceReady, false);

            // this.set_backButton_listener()
        }
        console.log(store.getState())
        this.getAllAssets('create-list-angle')
        try {
            clearInterval(this.listAssetsTimer);
        } catch (error) { }
        this.listAssetsTimer = setInterval(() => {
            this.getAllAssets('create-list-angle')
        }, 15 * 60 * 1000)
        if (isMobile) {
            this.timer = setInterval(() => {
                this.getMobileLocation()
            }, 5000)
        }
        // if ([undefined, null, 'undefined', 'null'].includes(localStorage.getItem('outdoor_map_center_lat')) || [undefined, null, 'undefined', 'null'].includes(localStorage.getItem('outdoor_map_center_lng'))) {
        //     localStorage.setItem("outdoor_map_center_lat", center['lat'].toString())
        //     localStorage.setItem("outdoor_map_center_lng", center['lng'].toString())
        //     this.props.upadateMapCoordinates({ "latitude": center['lat'], "longitude": center['lng'] })
        // }
        // else {
        //     lat = localStorage.getItem('outdoor_map_center_lat')
        //     lng = localStorage.getItem('outdoor_map_center_lng')
        //     center['lat'] = parseFloat(lat);
        //     center['lng'] = parseFloat(lng);
        //     this.props.upadateMapCoordinates({ "latitude": center['lat'], "longitude": center['lng'] })
        // }
        // this.props.upadateMapCoordinates({ "latitude": center['lat'], "longitude": center['lng'] })
        this.props.updateTabType("outdoor")

        this.reduxStore = store.getState()['general']
        // if (this.reduxStore['search'] != null && this.reduxStore['search'] != undefined) {
        //     console.log("setting search...............................")
        //     this.setAddress(this.reduxStore['search'], this.reduxStore['lockmark'])

        // }
        // if (this.reduxStore['asset_search'] != null && this.reduxStore['asset_search'] != undefined) {
        //     console.log("setting asset search...............................")
        //     this.setState({
        //         center: {
        //             lat: parseFloat(this.reduxStore['asset_search']['lat']), lng: parseFloat(this.reduxStore['asset_search']['long'])
        //         }
        //     },()=>{
        //         localStorage.setItem("outdoor_map_center_lat", this.state.center['lat'].toString())
        //         localStorage.setItem("outdoor_map_center_lng", this.state.center['lng'].toString())
        //         this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
        //     })
        // }

    }
    componentWillUnmount() {
        if (isMobile) {
            document.removeEventListener("backbutton", this.onBackKeyDown, false);
            console.log('Component unmounting==============================')
            console.log('removing back button listener...........')
            if (this.backButton_timer != null) {
                console.log('clearing timer.......')
                clearInterval(this.backButton_timer)
                this.backButton_timer = null
            }
            if (this.backButton_event != null) {
                console.log('clearing back button event..........')
                this.backButton_event.remove()
            }
            if (this.appState_event != null) {
                console.log('clearing app state event..........')
                this.appState_event.remove()
            }
            localStorage.removeItem('backButton')
            localStorage.removeItem('appState')
        }
    }
    componentWillUpdate() {
        this.reduxStore = store.getState()['general']
        this.reduxAuthStore = store.getState()['auth']


    }

    async getMobilePermissions() {
        console.log("=================")

        try {
            clearInterval(this.permissionCheckInterval);
        } catch (error) { }
        let mobPermissions = await GetPermissionsPlugin.getPermissions({ message: "permissions" })
        if (mobPermissions['gpsLocationGranted'] === true) {
            this.firstLocation()
        } else if (mobPermissions['gpsLocationGranted'] === false) {
            this.permissionCheckInterval = setInterval(async () => {
                this.firstLocation();
                let reCheck = await GetPermissionsPlugin.getPermissions({ message: "permissions" })
                if (reCheck['gpsLocationGranted'] === true) {
                    clearInterval(this.permissionCheckInterval);
                }
            }, 2000)
            console.log(this.state.center, "current center")
        }
    }
    presentToast = async () => {
        await Toast.show({
            text: 'Press again to exit',
            duration: "short",
            position: "center"
        });
    }

    async getMobileLocation() {

        console.log("getting device location")
        // const coordinates = await Geolocation.getCurrentPosition();
        const coordinates = await this.getLocationUsingWatchPosition()
        this.setState({
            gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
            gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
            gps_accuracy: coordinates.coords.accuracy
        })

    }


    latlong_apcpe = (lat: any, lng: any, ap_cpe_locamrk: any, search_asset: any) => {

        this.setState({
            ap_cpe_locamrk: ap_cpe_locamrk,
            search_asset: search_asset,
            center: {
                lat: parseFloat(lat), lng: parseFloat(lng)
            }
        }, () => {
            localStorage.setItem("outdoor_map_center_lat", this.state.center['lat'].toString())
            localStorage.setItem("outdoor_map_center_lng", this.state.center['lng'].toString())
            this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
            console.log(this.state.center, "fdfdfdfdffdf")
        })
    }
    enableAssetDrop = (st: any) => {
        // console.log("============")
        this.setState({ isAssetDropEnabled: st }, () => {
            if (this.state.isAssetDropEnabled) {
                // this.listAssets('disabled-angle');
                this.listAssetsWithInterval('disabled-angle');

            }
        })
    }
    setAddress = (address: any, locmark: any) => {

        this.geocodeAddress(address, locmark)
    }
    geocodeAddress = (address: any, locmark: any) => {
        this.setState({
            locmark: false
        });
        console.log(this.state.locmark, "locmark")
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function handleResults(this: any, results: any, status: any) {

            if (status === google.maps.GeocoderStatus.OK) {
                console.log(results[0].geometry.location.lat())
                // console.log((results[0].geometry.viewport.Ua['i'] + results[0].geometry.viewport.Ua['j']) / 2, "position")

                this.setState({
                    center: {
                        lat: parseFloat(results[0].geometry.location.lat()), lng: parseFloat(results[0].geometry.location.lng())
                    }
                    // center: {
                    //     lat: parseFloat(String((results[0].geometry.viewport.Ua['i'] + results[0].geometry.viewport.Ua['j']) / 2)), lng: parseFloat(String((results[0].geometry.viewport.Ua['i'] + results[0].geometry.viewport.Ua['j']) / 2))
                    // }
                }, () => {
                    localStorage.setItem("outdoor_map_center_lat", this.state.center['lat'].toFixed(6).toString())
                    localStorage.setItem("outdoor_map_center_lng", this.state.center['lng'].toFixed(6).toString())
                    this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'].toFixed(6), "longitude": this.state.center['lng'].toFixed(6) })
                    console.log(typeof (this.state.center['lat']), "center")
                })

                this.setState({
                    locmark: true
                });

            }

            this.setState({
                foundAddress: null,
                isGeocodingError: true
            });


        }.bind(this))
        this.props.updateSearchText(null)
    }
    curren_geo_position = async () => {
        const coordinates = await this.getLocationUsingWatchPosition()
        // const coordinates = await Geolocation.getCurrentPosition();
        this.setState({
            browserLatitude: parseFloat(coordinates.coords.latitude.toFixed(6)),
            browserLongitude: parseFloat(coordinates.coords.longitude.toFixed(6)),
            gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
            gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
            gps_accuracy: coordinates.coords.accuracy
        }, () => {
            this.setState({
                center: {
                    lat: parseFloat(coordinates.coords.latitude.toFixed(6)), lng: parseFloat(coordinates.coords.longitude.toFixed(6))
                }
            }, () => {
                try {
                    this._map.setCenter(this.state.center)
                    this._map.setZoom(15)
                } catch (error) { }
            })
            localStorage.setItem("outdoor_map_center_lat", coordinates.coords.latitude.toFixed(6))
            localStorage.setItem("outdoor_map_center_lng", coordinates.coords.longitude.toFixed(6))
            this.props.upadateMapCoordinates({ "latitude": parseFloat(coordinates.coords.latitude.toFixed(6)), "longitude": parseFloat(coordinates.coords.longitude.toFixed(6)) })
        })
    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    setShowLoading = (st: any) => {
        this.setState({ isLoading: st })
    }
    handleMapLoad = (map: any) => {
        this._map = map;
        console.log(map);
    }
    displayHeatmap = (val: any) => {
        if (this._map != null && this._map != undefined) {
            if (val != null && val.length > 0) {
                val.map((folder_name: any) => {
                    var imageMapType = new google.maps.ImageMapType({
                        getTileUrl: function (tile, zoom) {
                            var ymax = 1 << zoom;
                            var y = ymax - tile.y - 1;
                            return 'https://cpiprodemo1.c3spectra.com/heatmaps/tiles/' + folder_name + '/' + zoom + '/' + tile.x + '/' + y + '.png'
                        },
                        tileSize: new google.maps.Size(256, 256),
                        opacity: 0.3
                    });
                    this._map.overlayMapTypes.push(imageMapType);
                })
            }
            if (val == null) {
                console.log('heatmap disabled')
                this._map.overlayMapTypes.clear()
            }
        }
    }
    displaySASstatus = (val: any) => {
        if (val == true) {
            console.log('SAS status icons enabled')
            this.listAssetsWithInterval('sas-status')
            this.setState({
                displaySASIcon: true
            })
        }
        if (val == false) {
            console.log('SAS status icons disabled')
            this.getAllAssets('create-list-angle')
            this.setState({
                displaySASIcon: false
            })
        }
    }
    handleTilesLoad = () => {
        if (!isFirstLoadComplete) {
            // this.listAssets('disabled-angle');
            // this.listAssets('create-list-angle');
        }
        isFirstLoadComplete = true;
        // setTimeout(() => {
        // this.listAssets('create-list-angle');
        // }, 250);
    }
    card7 = () => {
        this.setState({
            card: 7
        })
    }


    onMarkerDragEnd = (e: any) => {
        console.log("=============")
        console.log(e.latLng)
        this.setState({
            lat: e.latLng.lat().toFixed(6),
            lng: e.latLng.lng().toFixed(6)
        })
        localStorage.setItem("outdoor_map_center_lat", e.latLng.lat().toFixed(6))
        localStorage.setItem("outdoor_map_center_lng", e.latLng.lng().toFixed(6))
        this.props.upadateMapCoordinates({ "latitude": e.latLng.lat().toFixed(6), "longitude": e.latLng.lng().toFixed(6) })
        console.log(this.state.lat, this.state.lng)
    }

    onMapClick = async (e: any) => {
        const coordinates = await this.getLocationUsingWatchPosition()
        // const coordinates = await Geolocation.getCurrentPosition();
        this.setState({
            lat: coordinates.coords.latitude.toFixed(6),
            lng: coordinates.coords.longitude.toFixed(6)
        })
        localStorage.setItem("outdoor_map_center_lat", coordinates.coords.latitude.toFixed(6))
        localStorage.setItem("outdoor_map_center_lng", coordinates.coords.longitude.toFixed(6))
        this.props.upadateMapCoordinates({ "latitude": coordinates.coords.latitude.toFixed(6), "longitude": coordinates.coords.longitude.toFixed(6) })
        if (this.state.marks.length <= 0) {
            this.setState({ marks: [...this.state.marks, { lat: coordinates.coords.latitude, lng: coordinates.coords.longitude }] }, () => {
                console.log(this.state.marks)
            })
        }
        // this.setState({
        //     lat: center["lat"],
        //     lng: center["lng"],
        //     marks: [...this.state.marks, center]
        // })
    }

    dash = () => {
        console.log("======dash")
        clearInterval(this.measure_timer)
        if (isMobile) {
            this.props.history.push('/map/outdoor/main')
        } if (isBrowser) {
            setTimeout(() => {
                $(".gm-bundled-control").css('left', '95.6%');
                $(".gm-bundled-control").show()
            }, 5);
            this.props.history.push('/map/outdoor')
        }
        try {
            clearInterval(this.callMeasureIcon1)
            clearInterval(this.callMeasureIcon2)
        } catch (error) { }
        this.setState({
            card: 1,
            marks: [],
            param1: "",
            param2: "",
            temp: 0,
            latlonglist: [],
            azimuth1: 0,
            azimuth2: 0,
            tilt1: 0,
            tilt2: 0,
            height1: 0,
            height2: 0,
            distance: 0,
            assetPointDropList: [],
            addAssetList: [],
            isAssetDropEnabled: false,
            measure_pair: [],
            rad_center: null,
            isAssetClickEnabled: false,
        })
        this.marker_event1 = null
        this.marker_event2 = null
        this.inBetween = null
        // this.listAssets('create-list-angle')
        this.listAssetsWithInterval('create-list-angle')
    }
    dash1 = () => {
        this.setState({
            card: 7,
            marks: [],
            assetPointDropList: [],
            isAssetDropEnabled: false
        })
    }
    sleep(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    addAP = () => {
        console.log("====================")
        this.props.updateAssetTypeId(1)
        this.props.updateAssetAction('add')
        this.listAssetsWithInterval('disabled-angle');
        console.log(store.getState());
        this.sleep(500).then(() => {
            this.setState({
                show_overlay: true, card: 2, assetAction: 'add', noOfSectors: 0, typeId: 1, ap_cpe_locamrk: false
            })
        })
        console.log(this.state.show_overlay)
    }

    search = () => {
        // if(isMobile){
        //     this.props.history.push('/map/outdoor/search/page')
        // }
        this.setState({
            card: 3
        })
    }

    addCPE = () => {
        this.props.updateAssetTypeId(3)
        this.props.updateAssetAction('add')
        this.listAssetsWithInterval('disabled-angle');
        this.sleep(500).then(() => {
            this.setState({
                card: 4,
                typeId: 3,
                assetAction: 'add',
                show_overlay: true,
                ap_cpe_locamrk: false
            })
        })
    }
    addDAS = () => {
        this.props.updateAssetAction('add')
        this.props.updateAssetTypeId(5)
        this.setState({
            // card: 10,
            show_das_overlay: true,
            typeId: 5,
            assetAction: "add"
        })
    }

    set_asset_typeId(id: any) {
        this.setState({
            typeId: id
        })
    }
    measure = () => {
        this.setState({
            isAssetClickEnabled: false,
        })
        this.props.history.push("/map/outdoor/measure")
        this.listAssetsWithInterval('disabled-angle');
        this.setState({
            card: 5,
            ap_cpe_locamrk: false
        })
    }

    calculate = () => {
        this.props.history.push("/map/outdoor/calculate")
        setTimeout(() => {
            $(".gm-bundled-control").css('left', '95.6%');
            $(".gm-bundled-control").show()
        }, 5);
        // this.listAssets('disabled-angle');
        this.listAssetsWithInterval('disabled-angle');
        this.setState({
            card: 6,
            lat: 0,
            lng: 0,
            ap_cpe_locamrk: false
        })
    }

    set_calculate = (lat: any, long: any, radc: any) => {
        console.log(lat, long, radc)
        this.setState({
            lat: lat,
            lng: long,
            rad_center: radc,
            assetHeight: radc
        })

    }
    refreshAsset = (selectedAssetId: any) => {
        console.log(selectedAssetId)
        let requestBody = {}
        console.log(this.state.assetList)
        let new_request_list = this.state.assetList.filter((asset: any) => asset.assetId !== selectedAssetId)
        console.log(new_request_list)
        try {
            clearInterval(this.checkIconsLoaded);
        } catch (error) { }
        requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody)
            .then(asset => {
                console.log(asset)
                asset.properties.map((property: any) => {
                    if (property.propertyName == "no_of_sectors") {
                        asset['no_of_sectors'] = property.value

                    }
                    if (property.propertyName == "radiation_center") {
                        asset['radiation_center'] = property.value

                    }
                    if (property.propertyName == "latitude") {
                        asset.latitude = property.value

                    }
                    if (property.propertyName == "longitude") {
                        asset.longitude = property.value

                    }
                })
                var asset_idList: any = []
                asset['azimuth_list'].forEach((sector: any) => {
                    console.log(sector)
                    if (!(asset_idList.indexOf(asset['assetIdentifierId']) >= 0)) {
                        asset_idList.push(asset['assetIdentifierId'])
                        var label_Text = {
                            text: asset['assetIdentifierId'],

                        }
                    }
                    else {
                        var text_1: any = " "
                        var label_Text = {
                            text: text_1,

                        }

                    }
                    // To showwing Mobile app card
                    this.setState({
                        sasStatus: asset['sasStatus'],
                        assetHeight: asset['radiation_center']

                    })
                    //
                    var no_of_sectors = 0;
                    if (asset['no_of_sectors'] != undefined){
                        no_of_sectors = parseInt(asset['no_of_sectors']);
                    }
                    console.log("1111111111111111111111111111111111111");
                    new_request_list.push({
                        "assetType": asset['assetType'],
                        "assetId": asset['assetId'],
                        "sasStatus": asset['sasStatus'],
                        "assetOperationalStatus": asset['operationalStatus'],
                        "assetHeight": asset['radiation_center'],
                        "assetName": asset['assetName'],
                        "assetIdentifierId": asset['assetIdentifierId'],
                        "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                        "name": asset['assetName'],
                        "labelText": label_Text,
                        "markerIcon": getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": 'create-list-angle', "azimuth": sector, "assetId": asset['assetId'], "action": "create", "pciCardShow": this.state.pciCardShow }, () => { }),
                        "azimuth": asset['azimuth_list'],
                        "sector_azimuth": sector[1]
                    })
                })

                console.log(new_request_list)
                var no_of_sectors = 0;
                if (asset['no_of_sectors'] != undefined){
                    no_of_sectors = parseInt(asset['no_of_sectors']);
                }
                console.log("22222222222222222222222222222222222222222");
                this.setState({ assetList: new_request_list }, () => {
                    console.log(this.state.assetList)
                    this.state.assetList.map((asset: any) => {
                        asset['azimuth'].forEach((sector: any) => {
                            getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": 'create-list-angle', "azimuth": sector, "assetId": asset['assetId'], "action": "rotate", "pciCardShow": this.state.pciCardShow }, () => { })
                        });
                    })

                })
                console.log("3333333333333333333333333333333333333333333");
                this.checkIconsLoaded = setInterval(() => {
                    this.state.assetList.map((asset: any) => {
                        asset['azimuth'].forEach((sector: any) => {
                            getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": 'create-list-angle', "azimuth": sector, "assetId": asset['assetId'], "action": "rotate", "pciCardShow": this.state.pciCardShow }, () => { })
                        });
                    })

                }, 500);
            })
    }
    get_asset_details = (selectedAssetId: any, text: any) => {
        let requestBody = {}
        console.log("get_asset_details calling")
        requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody)
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.props.updateAssetTypeId(data.assetTypeId)
                    if (text === "Info") {
                        this.setState({
                            asset_details: data,
                            card: 7
                        }, () => {
                            this.apdetails_ref.current.set_property()
                            setTimeout(() => {
                                console.log("first resize................................")
                                $(".gm-bundled-control").css('left', '73%');
                                $(".gm-bundled-control").show()
                            }, 50);
                        })
                        data.properties.map((data: any) => {
                            if (data.propertyName == "radiation_center") {
                                this.setState({
                                    rad_center: data['value'],
                                })

                            }
                            if (data.propertyName == "lattitude") {
                                this.setState({
                                    lat: data['value'],
                                })

                            }
                            if (data.propertyName == "longitude") {
                                this.setState({
                                    lng: data['value'],
                                })

                            }

                        })

                    }
                    if (text === "Measure") {
                        data.properties.map((data: any) => {
                            if (data.propertyName == "radiation_center") {
                                this.state.radiation_center.push(parseFloat(data.value))
                            }
                        })
                    }
                }
            })
    }

    getAllAssets(status: any) {
        var assetsList: any = [];
        this.setState({ rawAssetList: [] })
        requestAPICall("list_asset", {}, {})
            .then(data => {
                console.log(data)
                assetsList = [];
                if (data.responseCode == 200) {

                    data.assets.map((asset: any, index: any) => {
                        if (asset['assetType'] === 'AP-outdoor' || asset['assetType'] === 'CPE-outdoor' ||asset['assetType'] === 'AP-omni') {
                            assetsList.push(asset)

                        }
                    })
                    if (assetsList.length > 0) {
                        this.setState({ rawAssetList: assetsList }, () => {
                            this.listAssets(status)
                        })
                    }

                }
            })
    }

    listAssetsWithInterval(status: any) {
        this.listAssets(status)
        try {
            clearInterval(this.callAssetIcons)
        } catch (error) { }
        try {
            this.callAssetIcons = setInterval(() => {
                if (this.state.assetList.length !== 0) {
                    if (this.state.assetList[this.firstAssetIndex['ap']].markerIcon.url.length < 600 && this.state.assetList[this.firstAssetIndex['cpe']].markerIcon.url.length < 600) {
                        this.listAssets(status)
                    }
                }
                if (this.state.assetList[0] !== undefined) {
                    if (this.state.assetList[this.firstAssetIndex['ap']].markerIcon.url.length > 600 && this.state.assetList[this.firstAssetIndex['cpe']].markerIcon.url.length > 600) {
                        clearInterval(this.callAssetIcons)
                    }
                }
                if (this.reduxAuthStore['tab'] !== 'outdoor') {
                    clearInterval(this.callAssetIcons)
                }
            }, 100)

        } catch (error) {
            clearInterval(this.callAssetIcons)
        }
    }

    listAssets(status: any) {
        console.log("===================")
        var assetsList: any = [];
        var label_Text: any = "";
        var pcilabel_Text: any = "";
        this.setState({ assetList: [] })
        this.setState({ disabledAssetList: [] })
        try {
            clearInterval(this.checkIconsLoaded);
        } catch (error) { }
        if (this.state.pciCardShow == true) {
            this.setState({
                transparentIcon: {
                    url: require('../assets/img/status-img/transparent-circle-marker.png'),
                    scaledSize: new window.google.maps.Size(0, 0),
                    anchor: new window.google.maps.Point(5, 5),
                    labelAnchor: new window.google.maps.Size(0, 0),
                    labelOrigin: new window.google.maps.Point(5, 65)
                }
            })
        }
        else {
            this.setState({
                transparentIcon: {
                    url: require('../assets/img/status-img/transparent-circle-marker.png'),
                    scaledSize: new window.google.maps.Size(0, 0),
                    anchor: new window.google.maps.Point(5, 5),
                    labelAnchor: new window.google.maps.Size(0, 0),
                    labelOrigin: new window.google.maps.Point(5, 45)
                }
            })
        }
        this.state.rawAssetList.map((asset: any) => {
            var asset_idList: any = []
            var flag = 0

            // flag = flag + 1 
            console.log("testing=================================1")
            asset['azimuth'].forEach((sector: any) => {
                console.log(sector)
                var pci: any = " ";
                if (asset['assetTypeId'] === 1 && 'pci' in asset && asset['pci'].length > 0) {
                    if (asset['pci'][flag] !== 0) {
                        pci = asset['pci'][flag].toString()
                    }
                }
                console.log(pci)
                if (!(asset_idList.indexOf(asset['assetIdentifierId']) >= 0)) {
                    asset_idList.push(asset['assetIdentifierId'])
                    label_Text = { text: asset['assetIdentifierId'] }
                }
                else {
                    var text_1: any = " "
                    label_Text = { text: text_1 }

                }
                if (this.state.pciCardShow && asset['assetTypeId'] == 1) {
                    pcilabel_Text = { text: pci }
                }
                var no_of_sectors = 0;
                if (asset['no_of_sectors'] != undefined){
                    no_of_sectors = parseInt(asset['no_of_sectors']);
                }

                console.log("4444444444444444444444444444444444444");

                assetsList.push({
                    "assetType": asset['assetType'],
                    "assetId": asset['assetId'],
                    "sasStatus": asset['sasStatus'],
                    "assetOperationalStatus": asset['operationalStatus'],
                    "assetHeight": asset['radiation_center'],
                    "assetName": asset['name'],
                    "assetIdentifierId": asset['assetIdentifierId'],
                    "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                    "name": asset['name'],
                    "labelText": label_Text,
                    "pciLabel": pcilabel_Text,
                    // "pci": asset['pci'][flag].toString(),
                    "markerIcon": getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "create", "pciCardShow": this.state.pciCardShow, "sasStatus": asset['sasStatus'] }, () => { }),
                    "azimuth": asset['azimuth'],
                    "sector_azimuth": sector[1]
                })

                flag++;

            });


        })

        console.log("55555555555555555555555555555555555555555");
        this.setState({ assetList: assetsList }, () => {
            console.log(this.state.assetList)
            this.state.assetList.map((asset: any) => {
                var no_of_sectors = 0;
                if (asset['no_of_sectors'] != undefined){
                    no_of_sectors = parseInt(asset['no_of_sectors']);
                }
                asset['azimuth'].forEach((sector: any) => {
                    getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate", "pciCardShow": this.state.pciCardShow, "sasStatus": asset['sasStatus'] }, () => { })
                });
            })
        })

        console.log("666666666666666666666666666666666666666666");
        this.checkIconsLoaded = setInterval(() => {
            // console.log($(`img[src="${this.state.assetList[0]['markerIcon']['url']}"]`).length);
            // if ($(`img[src="${this.state.assetList[0]['markerIcon']['url']}"]`).length === 0) {
            this.state.assetList.map((asset: any) => {
                var no_of_sectors = 0;
                if (asset['no_of_sectors'] != undefined){
                    no_of_sectors = parseInt(asset['no_of_sectors']);
                }
                asset['azimuth'].forEach((sector: any) => {
                    getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate", "pciCardShow": this.state.pciCardShow, "sasStatus": asset['sasStatus'] }, () => { })
                });
            })
            // clearInterval(this.checkIconsLoaded);

            // } else {
            //     clearInterval(this.checkIconsLoaded);
            // }
        }, 500);

        // if (isMobile) {
        //     setTimeout(() => {
        //         console.log("Rotation icon interval finished");
        //         clearInterval(this.checkIconsLoaded);
        //     }, 15000);
        // }



    }
    markerLoaded() {
        //  setTimeout(() => {
        //     console.log("Rotation icon interval finished");
        //         clearInterval(this.checkIconsLoaded);
        // }, 5000);
    }

    // callListAPI = (typeId: number) => {
    //     let requestBody = {}
    //     requestAPICall("list_asset_properties", { asset_type_id: typeId }, requestBody)
    //         .then(data => {
    //             console.log(data)
    //             if (data.responseCode == 200) {
    //                 this.setState({ properties: data.properties })
    //             }
    //         })
    // }

    setMeasureIcons(asset: any, icon: any) {
        console.log("7777777777777777777777777777777777777");
        asset['azimuth'].forEach((sector: any) => {
            getSectorImageMeasure({ "assetType": asset['assetType'], "noOfSectors": asset['azimuth'].length, "status": "measure-angle", "azimuth": sector }, async (data: any) => {
                this.setState({
                    addAssetList: this.state.addAssetList.concat([{
                        "assetType": asset['assetType'],
                        "assetId": asset['assetId'],
                        "assetName": asset['name'],
                        "assetIdentifierId": asset['assetIdentifierId'],
                        "coOrdinates": { lat: parseFloat(asset['coOrdinates']['lat']), lng: parseFloat(asset['coOrdinates']['lng']) },
                        "name": asset['name'],
                        "markerIcon": data,
                        "azimuth": asset['azimuth']
                    }])
                }, () => {
                    if (icon === "m1" && data.url.length > 600) {
                        clearInterval(this.callMeasureIcon1)
                    } else if (icon === "m2" && data.url.length > 600) {
                        clearInterval(this.callMeasureIcon2)
                    }
                })
            })
        });
    }

    markerpos = (event: any, asset: any, text: any) => {
        const { latLng } = event;
        this.get_asset_details(asset.assetId, text)
        if (this.state.temp === 0) {
            this.marker_event1 = latLng
            this.callMeasureIcon1 = setInterval(() => {
                this.setMeasureIcons(asset, "m1")
            }, 100)
            this.setState({
                param1: [parseFloat(latLng.lat().toFixed(6)), parseFloat(latLng.lng().toFixed(6))],
                temp: 1
            })
            console.log(this.state.param1)
            this.state.latlonglist.push({ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) })
            // this.state.measure_pair.push([parseFloat(latLng.lng().toFixed(6)),parseFloat(latLng.lat().toFixed(6))])
        }
        else if (this.state.temp === 1) {
            this.marker_event2 = latLng
            this.callMeasureIcon2 = setInterval(() => {
                this.setMeasureIcons(asset, "m2")
            }, 100)
            this.setState({
                param2: [parseFloat(latLng.lat().toFixed(6)), parseFloat(latLng.lng().toFixed(6))],
                temp: 2
            })
            console.log(this.state.latlonglist)
            console.log(this.state.param2)
            this.state.latlonglist.push({ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) })
            localStorage.setItem("outdoor_map_center_lat", latLng.lat().toFixed(6))
            localStorage.setItem("outdoor_map_center_lng", latLng.lng().toFixed(6))
            this.props.upadateMapCoordinates({ "latitude": latLng.lat().toFixed(6), "longitude": latLng.lng().toFixed(6) })
            // this.callmeasureAPI(event)
            this.setShowLoading(true)
            this.measure_timer = setInterval(() => {
                console.log('checking if values filled..........................')
                if (this.state.radiation_center.length == 2) {
                    console.log('inside check.........')
                    this.childMeasure.current.graph()
                    let requestBody = {
                        "param1": this.state.param1,
                        "param2": this.state.param2,
                        "rad1": this.state.radiation_center[0],
                        "rad2": this.state.radiation_center[1]
                    }
                    console.log(requestBody)
                    requestAPICall("azimuth", {}, requestBody)
                        .then(data => {
                            this.setShowLoading(false)
                            console.log(data)
                            if (data.responseCode == 200) {
                                this.setState({
                                    azimuth1: data.azimuth1.toFixed(1),
                                    azimuth2: data.azimuth2.toFixed(1),
                                    tilt1: data.tilt1.toFixed(1),
                                    tilt2: data.tilt2.toFixed(1)
                                })
                                const element = data.properties;
                                this.setState({ properties: element })
                            } else {
                                this.setState({
                                    warnMessage: data.message,
                                    alertbox: true
                                })
                            }
                        })
                    requestAPICall("distance", {}, requestBody)
                        .then(data => {
                            this.setShowLoading(false)
                            console.log(data, "data")
                            if (data.responseCode == 200) {
                                this.state.distance = data.distance
                            } else {
                                this.setState({
                                    warnMessage: data.message,
                                    alertbox: true
                                })
                            }
                        })
                    this.setState({
                        height1: (this.state.radiation_center[0] * 0.3048).toFixed(1),
                        height2: (this.state.radiation_center[1] * 0.3048).toFixed(1)
                    }, () => {
                        this.setState({
                            radiation_center: []
                        }, () => { console.log('clearing interval'); clearInterval(this.measure_timer) })
                    })
                }
            }, 2000)
        }
        this.inBetween = google.maps.geometry.spherical.interpolate(this.marker_event1, this.marker_event2, 0.5);
        console.log(this.inBetween)
    }

    setSectorIcon = (noOfSec: any) => {
        console.log("88888888888888888888888888888888888888");
        if (noOfSec > 0) {
            this.setState({
                addAssetList:
                    [{
                        "assetType": "AP-outdoor",
                        "coOrdinates": { lat: this.state.currentMapClickEvent.latLng.lat(), lng: this.state.currentMapClickEvent.latLng.lng() },
                        "name": "New Asset",
                        "markerIcon": getSectorImage({ "assetType": "AP-outdoor", "sectors": noOfSec, "status": "create-list", "azimuth": 0, "pciCardShow": this.state.pciCardShow }, () => { })
                    }]
            })
        } else {
            this.setState({ addAssetList: [] })
        }
    }
    assetCardDeatils(e: any, asset: any, text: any) {
        const { latLng } = e;
        if (text == "Info") {
            this.setState({
                isAssetClickEnabled: true,
                assetIdentifierId: asset.assetIdentifierId,
                assetStatus: asset.assetOperationalStatus,
                assetHeight: asset.assetHeight,
                // sasStatus: asset.sasStatus
            })
            this.setState({ assetPointclickList: [{ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) }] })

        }
    }
    onHover = (e: any, asset: any) => {
        const { latLng } = e;
        if (isBrowser) {
            this.setState({
                onHover: true,
                assetIdentifierId: asset.assetIdentifierId,
                assetStatus: asset.assetOperationalStatus,
                assetHeight: asset.assetHeight,
                // sasStatus: asset.sasStatus,
                assethoverLatLongList: [{ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) }]
            })

        }
    }
    onMouseOut() {
        if (isBrowser) {
            this.setState({
                onHover: false
            })

        }
    }
    assetDetail = (e: any, asset: any, text: any) => {
        console.log(asset)
        if (isMobile) {
            this.assetCardDeatils(e, asset, text)
        }
        this.setState({
            locmark: false,
            ap_cpe_locamrk: false
        });
        this.setState({
            "selectedAssetId": asset.assetId
        })
        this.setState({
            center: {
                lat: parseFloat(asset.coOrdinates['lat']), lng: parseFloat(asset.coOrdinates['lng'])
            }
        }, () => { this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] }) })
        localStorage.setItem("outdoor_map_center_lat", asset.coOrdinates['lat'])
        localStorage.setItem("outdoor_map_center_lng", asset.coOrdinates['lng'])
        console.log(this.state.center, "center")

        this.props.updateAssetId(asset.assetId)
        if (isMobile) {
            this.setState({ card: 7 }, () => {
                // console.log(this.childAssetDetails);
                this.childAssetDetails.current.get_asset_details()
            })

        } else {
            this.get_asset_details(asset.assetId, text)
        }
    }
    asset_delete_confirm = () => {
        console.log("trigger outdoor asset list")
        setTimeout(() => {
            $(".gm-bundled-control").css('left', '95.6%');
            $(".gm-bundled-control").show()
        }, 5);
        this.getAllAssets('create-list-angle')
        this.getAllAssets('create-list-angle')
        this.setState({
            card: 1,
            isAssetClickEnabled: false,
        })

    }
    editAsset = (details: any) => {
        console.log(details);
        setTimeout(() => {
            $(".gm-bundled-control").css('left', '95.6%');
            $(".gm-bundled-control").show()
        }, 5);
        this.setState({
            properties: details['properties'],
            assetAction: details['assetAction'],
            noOfSectors: details['noOfSectors'],
            selectedAssetId: details['assetId'],
            sector_id_list: details['sector_id_list'],
            serial: details['serialId'],
            editSerial: details['editSerial']
        }, () => {
            // this.setState({ card: 2 })
        })
        this.props.updateAssetAction("edit")
        if (details["assetType"] == 3) {
            this.setState({
                card: 4,
                typeId: 3,
                ap_cpe_locamrk: false
            })
        } else if (details["assetType"] == 1 || details["assetType"] == 6) {
            this.setState({
                card: 2,
                ap_cpe_locamrk: false,
                typeId: 1
            })
        }
    }
    set_asset_property = (asset_property: any, typeId: any) => {
        this.setState({
            asset_properties: asset_property,
            assetTypeId: typeId
        })
    }
    set_ap2_propety = (asset_property: any) => {
        this.setState({
            add_ap2_properties: asset_property
        })
    }

    set_ap3_property = (asset_property: any) => {
        this.setState({
            add_ap3_properties: asset_property
        })
    }
    set_cp2_propety = (asset_property: any) => {
        this.setState({
            add_cp2_properties: asset_property
        })
    }

    set_cp3_property = (asset_property: any) => {
        this.setState({
            add_cp3_properties: asset_property
        })
    }

    next_step_cp2 = (asset_property: any) => {
        this.setState({
            card: 10,
            add_cp2_properties: asset_property
        })
    }
    next_step_cp3 = () => {
        this.setState({
            card: 11
        })
    }
    next_step_ap3 = () => {
        this.setState({
            card: 9
        })
    }
    next_step_ap2 = (asset_property: any) => {
        this.setState({
            card: 8,
            add_ap2_properties: asset_property
        })
    }
    setAssetDisabledState(state: any) {
        this.setState({ isAssetsDisabled: state })
    }
    hideOverlay() {
        console.log("hide overlay")
        this.setState({
            card: 1,
            marks: [],
            param1: "",
            param2: "",
            temp: 0,
            latlonglist: [],
            azimuth1: 0,
            azimuth2: 0,
            tilt1: 0,
            tilt2: 0,
            distance: 0,
            assetPointDropList: [],
            addAssetList: [],
            isAssetDropEnabled: false,
            measure_pair: [],
            rad_center: null,
            show_overlay: false,
            isAssetClickEnabled: false
        })
        this.marker_event1 = null
        this.marker_event2 = null
        this.inBetween = null
        this.listAssetsWithInterval('create-list-angle')
    }

    changeMapCenter(lat: any, lng: any) {
        console.log('setting search asset center**************', lat, lng)
        try {
            this.setState({
                center: {
                    lat: parseFloat(lat), lng: parseFloat(lng)
                }
            }, () => {
                console.log(this.state.center)
                this._map.setCenter(this.state.center)
                localStorage.setItem("outdoor_map_center_lat", this.state.center['lat'].toString())
                localStorage.setItem("outdoor_map_center_lng", this.state.center['lng'].toString())
                this.props.upadateMapCoordinates({ "latitude": this.state.center['lat'], "longitude": this.state.center['lng'] })
            })
        } catch (error) { }
    }

    renderSwitch() {
        var param = this.state.card;
        switch (param) {
            case 1:
                return (
                    <Dashboard ref={this.dashRef} searchfn={this.search} set_subcard={(value: any) => { this.setState({ subcard: value }) }} addAP={this.addAP} addCPE={this.addCPE} measure={this.measure} setAddress={(address: any, locmark: any) => { this.setAddress(address, locmark) }} latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => { this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset) }}
                        calculate={this.calculate} setCurrentPosition={() => this.curren_geo_position()} enableAssetDrop={(st: any) => { this.enableAssetDrop(st) }} changeMapCenter={(lat: any, lng: any) => { this.changeMapCenter(lat, lng) }} polygon_set={(val: any) => { this.displayKml(val) }} heatmap_set={(val: any) => { this.displayHeatmap(val) }} sas_set={(val: any) => { this.displaySASstatus(val) }} />
                );
            case 2:
                if (isBrowser) {
                    return (
                        <AddAP ref={this.childAP} setCurrentPosition={() => this.curren_geo_position()} loc={this.state.locmark} serial={this.state.serial} typeId={this.state.typeId} latlong={this.state.para} assetAction={this.state.assetAction} assetId={1} properties={this.state.properties} parentState={this.state} selectedAssetId={this.state.selectedAssetId}
                            back={this.dash} name={this.state.username} listAssets={(status: any) => { this.getAllAssets(status) }} setSector={(e: any) => { this.setSectorIcon(e) }} sector_id_list={this.state.sector_id_list} get_asset_details={(selectAssetId: any) => this.get_asset_details(selectAssetId, "Info")} editSerial={this.state.editSerial}
                            next_step={this.next_step_ap2} set_ap2_proeprty={this.set_ap2_propety} set_ap3_property={this.set_ap3_property} set_asset_property={this.set_asset_property} setAssetDisabledState={(state: any) => { this.setAssetDisabledState(state) }} changeMapCenter={(lat: any, lng: any) => { this.changeMapCenter(lat, lng) }} />
                        // <AddAP ref={this.childAP} typeId ={this.state.typeId}latlong={this.state.para} assetId={1} properties={this.state.properties} back={this.dash} name={this.state.username} listAssets={(status: any) => { this.listAssets(status) }} setSector={(e: any) => { this.setSectorIcon(e) }} />
                    );
                }
                if (isMobile)



                    return (
                        <div className="fixed-top" id="overlay" style={{
                            height: "100%",
                            display: this.state.show_overlay === true ? 'block' : 'none'
                        }}>
                            <AddAP hideOverlay={this.hideOverlay} serial={this.state.serial} typeId={this.state.typeId} latlong={this.state.para} assetAction={this.state.assetAction} assetId={1} properties={this.state.properties} parentState={this.state} selectedAssetId={this.state.selectedAssetId}
                                back={this.dash} name={this.state.username} listAssets={(status: any) => { this.getAllAssets(status) }} setSector={(e: any) => { this.setSectorIcon(e) }} sector_id_list={this.state.sector_id_list}
                                set_asset_property={this.set_asset_property} changeMapCenter={(lat: any, lng: any) => { this.changeMapCenter(lat, lng) }} setAssetDisabledState={(state: any) => { this.setAssetDisabledState(state) }} />

                        </div>
                        //     <Redirect from='/map' to='/addAP/assetType/1' />
                    );
            case 3:
                if (isBrowser)
                    return (
                        <Search1 back={this.dash} setAddress={(address: any, locmark: any) => { this.setAddress(address, locmark) }} latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => { this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset) }} />
                    )
                if (isMobile)
                    return (
                        <Redirect from='/map' to='/search' />
                    )
            case 4:
                if (isBrowser)
                    return (
                        <AddCPE assetAction={this.state.assetAction} setCurrentPosition={() => this.curren_geo_position()} selectedAssetId={this.state.selectedAssetId} listAssets={(status: any) => { this.getAllAssets(status) }} ref={this.childCPE} typeId={this.state.typeId} select_floor_id={0} buildid={0}
                            next_step={this.next_step_cp2} set_cp2_property={this.set_cp2_propety} set_cp3_property={this.set_cp3_property} set_asset_property={this.set_asset_property} latlong={this.state.para} properties={this.state.properties} back={this.dash} editSerial={this.state.editSerial}
                            setAssetDisabledState={(state: any) => { this.setAssetDisabledState(state) }} changeMapCenter={(lat: any, lng: any) => { this.changeMapCenter(lat, lng) }} get_asset_details={(selectAssetId: any) => this.get_asset_details(selectAssetId, "Info")} />
                    )
                if (isMobile)
                    return (
                        <div className="fixed-top" id="overlay" style={{
                            height: "100%",
                            display: this.state.show_overlay === true ? 'block' : 'none'
                        }}>
                            <AddCPE hideOverlay={this.hideOverlay} assetAction={this.state.assetAction} selectedAssetId={this.state.selectedAssetId} listAssets={(status: any) => { this.getAllAssets(status) }} typeId={this.state.typeId} select_floor_id={0} buildid={0}
                                set_asset_property={this.set_asset_property} latlong={this.state.para} properties={this.state.properties}
                                setAssetDisabledState={(state: any) => { this.setAssetDisabledState(state) }} changeMapCenter={(lat: any, lng: any) => { this.changeMapCenter(lat, lng) }} />

                        </div>

                    );
            case 5:
                return (
                    <Measure back={this.dash} setCurrentPosition={() => this.curren_geo_position()} dist={this.state.distance} ref={this.childMeasure} measure_pair={this.state.latlonglist} distance={this.state.distance} temp={this.state.temp} />
                )
            case 6:
                return (
                    <Calculate back={this.dash} setCurrentPosition={() => this.curren_geo_position()} place={this.onMapClick} markers={this.state.marks} latitude={this.state.lat} longitude={this.state.lng} radiation_center={this.state.rad_center} changeProps={(target: any, value: any) => { this.setState({ [target]: value }) }} />
                )
            case 7:
                if (isBrowser)
                    return (
                        <APdetails1 ref={this.apdetails_ref} refreshAsset={(id: any) => this.refreshAsset(id)} back={() => { this.getAllAssets('create-list-angle'); this.dash(); }} calculate={this.calculate} set_values={(lat: any, long: any, radc: any) => { this.set_calculate(lat, long, radc) }} setCurrentPosition={() => this.curren_geo_position()} asset_delete_confirm={this.asset_delete_confirm} asset_details={this.state.asset_details} editAsset={(details: any) => { this.editAsset(details) }} />
                    )
                if (isMobile)
                    return (
                        <APdetails next6={this.asset_delete_confirm} refreshAsset={(id: any) => this.refreshAsset(id)} back={() => { this.getAllAssets('create-list-angle'); this.dash1(); }} set_subcard={(value: any) => { this.setState({ subcard: value }) }} setCurrentPosition={() => this.curren_geo_position()} addAP={this.addAP} addDAS={this.addDAS} addCPE={this.addCPE} measure={this.measure} enableAssetDrop={(st: any) => { this.enableAssetDrop(st) }} calculate={this.calculate} set_values={(lat: any, long: any, radc: any) => { this.set_calculate(lat, long, radc) }} ref={this.childAssetDetails} changeMapCenter={(lat: any, lng: any) => { this.changeMapCenter(lat, lng) }} updateSearchText={(value: any) => this.updateSearchText(value)} setAddress={(address: any, locmark: any) => { this.setAddress(address, locmark) }} latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => { this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset) }} />
                    )
            case 8:
                return (
                    <AddAP2 next_step3={this.next_step_ap3} back={this.addAP} asset_properties={this.state.asset_properties} asset_property={this.state.add_ap2_properties} set_asset_property={this.set_asset_property}></AddAP2>
                )
            case 9:
                return (
                    <AddAP3 asset_property={this.state.add_ap3_properties} back={this.next_step_ap2} serial={this.state.serial} typeId={this.state.assetTypeId} asset_properties={this.state.asset_properties}> </AddAP3>
                )
            case 10:
                return (
                    <ADDCP2 next_step3={this.next_step_cp3} asset_properties={this.state.asset_properties} asset_property={this.state.add_cp2_properties} set_asset_property={this.set_asset_property}></ADDCP2>
                )
            case 11:
                return (
                    <ADDCP3 asset_property={this.state.add_cp3_properties} asset_properties={this.state.asset_properties}></ADDCP3>
                )

        }
    }
    lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 4
    };


    handleClick = (event: any) => {
        const { latLng } = event;
        console.log(event.latLng)
        this.setState({
            lat: event.latLng.lat().toFixed(6),
            lng: event.latLng.lng().toFixed(6)
        })
        console.log(this.state.lat, this.state.lng)
        this.setState({
            para: [latLng.lat().toFixed(6), latLng.lng().toFixed(6)],
            // temp: 1,
            currentMapClickEvent: event,
            locmark: false,
            ap_cpe_locamrk: false
        })
        this.props.upadateGMapClickEvent(event)
        localStorage.setItem("outdoor_map_center_lat", latLng.lat().toFixed(6))
        localStorage.setItem("outdoor_map_center_lng", latLng.lng().toFixed(6))
        this.props.upadateMapCoordinates({ "latitude": latLng.lat().toFixed(6), "longitude": latLng.lng().toFixed(6) })
        console.log(this.state.para)
        if (isMobile) {
            this.setState({ assetPointDropList: [{ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) }] })
            // this.state.assetPointDropList.push({ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) })
            console.log(this.state.assetPointDropList);

        }
        if (this.state.card == 4) {
            if (isBrowser) {
                // this.childCPE.current.disableButton()
                this.setState({ assetPointDropList: [{ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) }] })
                console.log(this.state.assetPointDropList);
                console.log(this.childCPE);

                this.childCPE.current.componentRef.current.disableButton()
            }
        }
        else if (this.state.card == 2) {
            if (isBrowser) {
                this.setState({ assetPointDropList: [{ lat: parseFloat(latLng.lat().toFixed(6)), lng: parseFloat(latLng.lng().toFixed(6)) }] })
                console.log(this.state.assetPointDropList);
                // this.childAP.current.disableButton()
                this.childAP.current.componentRef.current.disableButton()
            }
        }
    }



    options = {
        icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px'
        }],
        strokeColor: '#FF9445',
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: '#FF9445',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: true,
        visible: true,
        radius: 30000,
        zIndex: 1
    };

    accuracy_visibility = () => {
        if (localStorage.getItem('refresh_asset') === 'true'){
            this.getAllAssets('create-list-angle')
            localStorage.removeItem('refresh_asset')
        }
        
        if (isMobile) {
            if (this.state.card !== 5) {
                if (this._map != null && this._map != undefined) {
                    var zoom = this._map.zoom
                    if (zoom > 19) {
                        this.setState({
                            accuracy_visible: true,
                        })
                    }
                    else {
                        this.setState({
                            accuracy_visible: false,
                        })
                    }
                    if (zoom > 15) {
                        this.setState({
                            pciCardShow: true,
                        }, () => this.listAssets('create-list-angle'))
                    } else {
                        this.setState({
                            pciCardShow: false,
                        }, () => this.listAssets('create-list-angle'))
                    }
                    if (zoom < 13) {
                        this.setState({
                            assetCardShow: false,
                        })
                    } else {
                        this.setState({
                            assetCardShow: true,
                        })
                    }

                }
            }
        }
        if (isBrowser) {
            console.log("state card = " + this.state.card)
            console.log("sasStatus = " + this.state.sasStatus)
            if (this.state.card !== 5) {
                if (this._map !== null && this._map !== undefined) {
                    var zoom = this._map.zoom
                    console.log(zoom)
                    if (zoom > 15) {
                        this.setState({
                            pciCardShow: true,
                        }, () => {
                            if (this.state.displaySASIcon){
                                this.listAssetsWithInterval('sas-status');
                            } else {
                                this.listAssets('create-list-angle');
                            }
                        })
                    }
                    else {
                        this.setState({
                            pciCardShow: false,
                        }, () => {
                            if (this.state.displaySASIcon){
                                this.listAssetsWithInterval('sas-status');
                            } else {
                                this.listAssets('create-list-angle');
                            }
                        })
                    }
                    if (zoom < 12) {
                        this.setState({
                            assetCardShow: false,
                        })
                    } else {
                        this.setState({
                            assetCardShow: true,
                        })
                    }

                }
            }

        }

    }
    displayKml = (val: any) => {
        console.log('kml data call.....................')
        console.log(val)
        if (val == null) {
            this.setState({
                polygon_files: null
            })
        }
        else {
            console.log('true')
            var requestBody = {
                'polygon_id': val
            }
            console.log(requestBody)
            requestAPICall("display_polygon_boundaries", {}, requestBody).then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({
                        polygon_files: data.polygon_files
                    }, () => { console.log(this.state.polygon_files) })
                }
                else {
                    this.setState({
                        alertbox: true,
                        warnMessage: data.message
                    })
                }
            })

        }
    }

    kmlMount = (kml: any) => {
        console.log(kml, 'kml mounted.........')
    }
    kmlUnmount = (kml: any) => {
        console.log(kml, 'kml unmounted.........')
    }
    onMarkerDrag = () => {

        google.maps.event.addListener(this._map, 'dragend', this.showNewRect);

    }


    showNewRect = () => {
        console.log(this._map.getCenter().lat().toFixed(6))
        localStorage.setItem("outdoor_map_center_lat", this._map.getCenter().lat().toFixed(6))
        localStorage.setItem("outdoor_map_center_lng", this._map.getCenter().lng().toFixed(6))

    }
    updateSearchText = (value: any) => {
        this.props.updateSearchText(value)
    }
    render() {
        return (
            <div className='container-fluid dash p-0'>
                <div className='row google-map'>
                    <div className='col-md-12'  >
                        {this.state.center != null && this.state.center != undefined ? <GoogleMap
                            id='searchbox'
                            mapContainerStyle={{ height: "calc(100vh - 58px)" }}
                            zoom={
                                this.state.card === 7 ?
                                    15
                                    :
                                    13
                            }
                            onClick={this.handleClick}
                            onTilesLoaded={this.handleTilesLoad}
                            center={this.state.center}
                            onLoad={this.handleMapLoad}
                            onDragEnd={this.onMarkerDrag}
                            onZoomChanged={this.accuracy_visibility}
                            options={isBrowser ? {
                                zoomControl: true, mapTypeControl: true, mapTypeControlOptions: {
                                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                    position: google.maps.ControlPosition.RIGHT_TOP
                                }, disableDefaultUI: true, styles: [
                                    {
                                        "featureType": "poi",
                                        "stylers": [
                                            { "visibility": "off" }
                                        ]
                                    }
                                ]
                            } : {
                                disableDefaultUI: true, styles: [
                                    {
                                        "featureType": "poi",
                                        "stylers": [
                                            { "visibility": "off" }
                                        ]
                                    }
                                ]
                            }}
                        >
                            <div>
                                {this.renderSwitch()}

                            </div>

                            {this.state.assetCardShow && this.state.assetList.map((asset: any, index: any) => {
                                if (asset.assetType == 'AP-outdoor') {
                                    return <Marker position={asset.coOrdinates} key={index} icon={asset.markerIcon}
                                        label={asset.pciLabel} title={this.state.isSafari ? "asset" + asset['assetId'] + asset['sector_azimuth'] : ""} draggable={false} onMouseOut={this.onMouseOut} onMouseOver={(e: any) => this.onHover(e, asset)} onClick={(e: any) => { this.state.card === 5 ? this.markerpos(e, asset, "Measure") : this.assetDetail(e, asset, "Info") }} onLoad={() => { this.markerLoaded() }} />
                                }

                                if (asset.assetType == 'CPE-outdoor' || asset.assetType == 'AP-omni') {
                                    return <Marker position={asset.coOrdinates} key={index} icon={asset.markerIcon}
                                        label={asset.labelText} title={asset.pci} draggable={false} onMouseOut={this.onMouseOut} onMouseOver={(e: any) => this.onHover(e, asset)} onClick={(e: any) => { this.state.card === 5 ? this.markerpos(e, asset, "Measure") : this.assetDetail(e, asset, "Info") }} onLoad={() => { this.markerLoaded() }} />
                                }
                            })}

                            {!this.state.assetCardShow && this.state.assetList.map((asset: any, index: any) => (
                                <Marker position={asset.coOrdinates} key={index} icon={asset.markerIcon} title={this.state.isSafari ? 'asset' + asset['assetId'] + asset['sector_azimuth'] : ""}
                                    draggable={false} onMouseOut={this.onMouseOut} onMouseOver={(e: any) => this.onHover(e, asset)} onClick={(e: any) => { this.state.card === 5 ? this.markerpos(e, asset, "Measure") : this.assetDetail(e, asset, "Info") }} onLoad={() => { this.markerLoaded() }} />


                            ))}


                            {this.state.assetList && this.state.transparentIcon ? this.state.assetList.map((asset: any, index: any) => {
                                if (asset.assetType == 'AP-outdoor' && this.state.assetCardShow) {
                                    return <Marker position={asset.coOrdinates} key={index} icon={this.state.transparentIcon} label={asset.labelText} draggable={false} />
                                }
                            }) : null}


                            {this.state.addAssetList.map((mark: any, index: any) => (
                                <Marker position={mark.coOrdinates} key={index} icon={mark.markerIcon} draggable={false}
                                />
                            ))
                            }

                            {
                                this.state.locmark === true ?
                                    <Marker position={this.state.center} onClick={this.handleClick} /> : ""
                            } {
                                this.state.ap_cpe_locamrk === true ?
                                    <Marker position={this.state.center} onClick={(e: any) => { this.state.card === 5 ? this.markerpos(e, this.state.search_asset, "Measure") : this.assetDetail(e, this.state.search_asset, "Info") }} onLoad={() => { this.markerLoaded() }} /> : ""
                            }
                            {
                                this.state.latlonglist.map((mark: any, index: any) =>
                                    <InfoWindow position={mark} >
                                        <div style={{}}>
                                            {index == 0 ?
                                                <div>
                                                    <div className="font14 fontw400"><span>Azimuth: <span style={{ color: "#FF9445" }}>{this.state.azimuth1}° </span> </span></div>
                                                    <div className="font14 fontw400 m-t-6"><span>Tilt: <span style={{ color: "#FF9445" }}>{this.state.tilt1}</span></span></div>
                                                    <div className="font14 fontw400 m-t-6"><span>Height: <span style={{ color: "#FF9445" }}>{this.state.height1} m</span></span></div>
                                                </div>
                                                :
                                                <div>
                                                    <div className="font14 fontw400"><span>Azimuth: <span style={{ color: "#FF9445" }}>{this.state.azimuth2}° </span> </span></div>
                                                    <div className="font14 fontw400 m-t-6"><span>Tilt: <span style={{ color: "#FF9445" }}>{this.state.tilt2}</span></span></div>
                                                    <div className="font14 fontw400 m-t-6"><span>Height: <span style={{ color: "#FF9445" }}>{this.state.height2} m</span></span></div>
                                                </div>
                                            }
                                        </div>
                                    </InfoWindow>
                                )
                            }
                            {
                                this.state.isAssetDropEnabled && this.state.assetPointDropList.map((mark: any, index: any) =>
                                    <InfoWindow position={mark}>
                                        <div style={{ margin: "0px 20px 10px 0px", fontWeight: "bold" }}>
                                            <span>ASSET DROP</span>
                                        </div>
                                    </InfoWindow>
                                )
                            }
                            {
                                this.state.isAssetClickEnabled && this.state.assetPointclickList.map((mark: any, index: any) =>

                                    < InfoWindow position={mark} >
                                        <div>
                                            <div>
                                                <span className="p-r-30 font14 fontw500" style={{ color: "#FF9445" }}>{this.state.assetIdentifierId} </span>
                                                <i className="material-icons infowindowclose" >clear</i>
                                            </div>
                                            <div className="font14 fontw400 m-t-5"><span>Status: <span style={{ color: "#FF9445" }}>{this.state.assetStatus} </span> </span></div>
                                            <div className="font14 fontw400 m-t-5"><span>Height: <span style={{ color: "#FF9445" }}>{this.state.assetHeight} ft</span> </span></div>
                                            {/* {this.state.sasStatus != "" ?
                                                <div className="font14 fontw400 m-t-5"><span>SAS Status: <span style={{ color: "#FF9445" }}>{this.state.sasStatus} </span> </span></div> : ""} */}
                                        </div>
                                    </InfoWindow>
                                )
                            }


                            {
                                this.state.onHover && this.state.assethoverLatLongList.map((mark: any, index: any) =>

                                    < InfoWindow position={mark} >
                                        <div>
                                            <div>
                                                <span className="p-r-30 font14 fontw500" style={{ color: "#FF9445" }}>{this.state.assetIdentifierId} </span>
                                                <i className="material-icons infowindowclose" >clear</i>
                                            </div>
                                            <div className="font14 fontw400 m-t-5"><span>Status: <span style={{ color: "#FF9445" }}>{this.state.assetStatus} </span> </span></div>
                                            <div className="font14 fontw400 m-t-5"><span>Height: <span style={{ color: "#FF9445" }}>{this.state.assetHeight} ft</span> </span></div>
                                            {/* {this.state.sasStatus != "" ?
                                                <div className="font14 fontw400 m-t-5"><span>SAS Status: <span style={{ color: "#FF9445" }}>{this.state.sasStatus} </span> </span></div> : ""} */}
                                        </div>
                                    </InfoWindow>
                                )
                            }


                            {
                                this.state.marks.map((mark: any, index: any) => (
                                    <Marker position={mark} key={index} icon={markerIcon} draggable={true}
                                        onDragEnd={this.onMarkerDragEnd} />
                                ))
                            }
                            {
                                this.state.polygon_files != undefined && this.state.polygon_files != null ?
                                    this.state.polygon_files.map((file_name: any, index: any) => (
                                        <KmlLayer url={"http://democ3spectra.k8s.whirldatascience.com/kml/" + file_name} onLoad={this.kmlMount} onUnmount={this.kmlUnmount} />

                                    )) : null

                            }
                            < Marker position={{ lat: this.state.gps_lat, lng: this.state.gps_lng }} icon={gps_icon}
                            />
                            <Circle center={{ lat: this.state.gps_lat, lng: this.state.gps_lng }} options={circleOptions} visible={this.state.accuracy_visible} radius={this.state.gps_accuracy} />

                            {this.state.temp == 2 ? <Polyline
                                options={this.options}
                                path={[
                                    { lat: this.state.param1[0], lng: this.state.param1[1] },
                                    { lat: this.state.param2[0], lng: this.state.param2[1] }
                                ]}
                            /> : null}
                            {
                                this.inBetween ?
                                    <InfoWindow position={this.inBetween} >
                                        <div style={{}}>
                                            <div className="font14 fontw400">
                                                <span>Distance:
                                                <span style={{ color: "#FF9445" }}>{this.state.distance} m</span>
                                                </span>
                                            </div>
                                        </div>
                                    </InfoWindow> : null
                            }
                        </GoogleMap> : null}
                    </div>
                </div>
                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />

                <IonLoading
                    isOpen={this.state.isLoading}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                    duration={5000}
                />


            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Outdoor));
$(document).ready(function () {
    $("#searchbox").hide()

    setTimeout(() => {
        console.log("first resize................................")
        $("#searchbox").css('width', '99.9%');
        $("#searchbox").show()
    }, 50);
    setTimeout(() => {
        console.log("second resize............................")
        $("#searchbox").css('width', '100%');
        $("#searchbox").show()
    }, 1000);


})
