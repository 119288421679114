import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFabButton, IonFabList, IonIcon, IonFab, IonGrid, IonRow, IonCol } from '@ionic/react';
import calc from '../assets/img/calculate.svg'
import AP from '../assets/img/Apicon.png'
import APcreate from '../assets/img/Apcreate1.png'
import close from '../assets/img/close.png'
// import measure from '../assets/img/Measureicon.png'
import SearchIcon from '../assets/img/search_icon.png'
import checkSuccess from '../assets/img/check-success.png'
import measureIcon from '../assets/img/Measure-icon-img.png'
import addCPEIcon from '../assets/img/add-cpe-icon-img.png'
import addAPIcon from '../assets/img/add-ap-icon-img.png'
import geo from '../assets/img/target-icon.png'
import heat from '../assets/img/heaticon.png'
import heatact from '../assets/img/heaticonactive.png'
import calcmob from '../assets/img/align-icon.png'
import React, { Component } from 'react';
import "./dashboard.css";
import Modal from './modal';
import Cookies from 'js-cookie'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import color from '@material-ui/core/colors/amber';
import Dropzone from 'react-dropzone';
import "../theme/variables.css"
import { connect } from 'react-redux';
import store from '../store';
import { CURRENT_MAP_CLICK_INSTANCE, CURRENT_TAB, COORDINATES } from '../constants/actionTypes';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { isBrowser, isMobile } from "react-device-detect";
import apicon from '../assets/img/apicon.png'
import { requestAPICall } from "../service/apiHandler";
import { Plugins } from '@capacitor/core';
import NetworkStatusPage from './network-status-mob'

const INITIAL_STATE = {
    show: false,
    show2: false,
    heatMap: false,
    button_name: "Send",
    isParameterSubmitted: false,
    isdraftSaved: true,
    bar: true,
    cpiId: '',
    cpiName: "",
    files: [],
    button: false,
    button1: false,
    asset_list: [],
    searches: '',
    predictionsList: [],
    search_list: [],
    ap_cpe_list: [],
    lat_search: 0,
    lng_search: 0,
    connectionStatus: false,
    connectionType: "",
    connectionNetworkType: "Unknown",
    sas_enabled: false,
    polygon_enabled: false,
    status_show_polygon: false,
    polygonListLength: 0,
    heatmap_enabled: false,
    status_show_heatmap: false,
    heatmapListLength: 0,
    showOptions: false,
    showLegends: false,
    show_calculator_overlay: false
}

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
    upadateTabState: (value: any) =>
        dispatch({ type: CURRENT_TAB, key: 'tab', value }),
    updateNetworkStatus: (value: any) =>
        dispatch({ type: CURRENT_TAB, key: 'showNetworkStatusOnly', value }),
    updateNetworkScreenType: (value: any) =>
        dispatch({ type: CURRENT_TAB, key: 'networkScreenType', value }),
    updateSearchText: (value: any) =>
        dispatch({ type: COORDINATES, key: 'search', value }),
    upadateSearchMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'asset_search', value }),
    upadateMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'coordinates', value }),
});
const { NetworkStatus, Network } = Plugins;

var addressPredictions: any = {}

interface Iprops {
    searchfn: Function,
    addAP: Function,
    addCPE: Function,
    measure: Function,
    calculate: Function,
    setCurrentPosition: Function,
    enableAssetDrop: Function,
    setAddress: Function,
    ref: any,
    set_subcard: any,
    changeMapCenter: any,
    polygon_set: any,
    heatmap_set: any,
    latlong_apcpe: any,
    sas_set: any,
}
class Dashboard extends Component<Iprops> {
    state: any = {};
    props: any = {};
    autocompleteInput: any
    selected_polygon_list: any = []
    selected_heatmap_list: any = []
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.addAP = this.addAP.bind(this)
        this.search = this.search.bind(this)
        this.addCPE = this.addCPE.bind(this)
        this.measure = this.measure.bind(this)
        this.calculate = this.calculate.bind(this)
        this.get_cookies = this.get_cookies.bind(this)
        this.onChange = this.onChange.bind(this)
        this.hideNetworkOverlay = this.hideNetworkOverlay.bind(this)
        this.hideCalculatorOverlay = this.hideCalculatorOverlay.bind(this)
        this.autocompleteInput = React.createRef();
    }

    componentDidMount() {
        console.log("============")
        this.setState({
            searches: "",
            ap_cpe_list: [],
            search_list: []

        })
        window.addEventListener('load', this.get_cookies);
        if (isMobile) {
            this.getNetworkType()
            this.getConnectionStatus()
            let handler = Network.addListener('networkStatusChange', (status) => {
                console.log("Network status changed", status);
                this.getNetworkType()
                this.setState({
                    connectionStatus: status['connected'],
                    connectionType: status['connectionType']
                })
            });
        }

    }


    get_cookies() {
        console.log(Cookies.get('userRole'))
        if (Cookies.get('userRole') == "CPI operator") {
            this.setState({
                button_name: "Submit"
            })
        }
    }
    async getNetworkType() {
        let res = await NetworkStatus.getNetworkType({ message: "signal" });
        console.log(res);
        this.setState({ connectionNetworkType: res['networkType'] })
    }

    async getConnectionStatus() {
        let status = await Network.getStatus();
        console.log(status);
        this.setState({
            connectionStatus: status['connected'],
            connectionType: status['connectionType']
        })
    }

    showModal = () => {
        this.setState({
            show: true
        })
        console.log(this.state.show)
    }
    showModal2 = () => {
        this.setState({
            show2: true
        })
        console.log(this.state.show)
    }
    hideModal = () => {
        this.setState({
            show: false,
            show2: false
        })
    }

    switch = () => {
        this.setState({
            bar: !this.state.bar,
            searches: "",
            ap_cpe_list: [],
            search_list: [],
            predictionsList: []

        }, () => {
            console.log('setting the switch....')
            if (this.state.bar == true) {
                console.log('setting null in switch')
                this.props.set_subcard(null)
            }
            else {
                console.log('setting search in switch....')
                this.props.set_subcard('search')
            }
            if (!this.state.bar) {
                this.list_asset()
            }
        })
    }

    onChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.list_asset_search(this.state.searches)
            console.log(this.state.searches)

        })
    }

    toggleHeatMap() {
        this.setState({
            heatMap: !this.state.heatMap
        })
    }

    onDrop = (files: any) => {
        this.setState({ files: files })
    };

    addAP = (e: any) => {
        e.preventDefault();
        this.props.enableAssetDrop(true)
        this.props.addAP();
    }

    search = (e: any) => {
        e.preventDefault();
        this.props.searchfn();
    }
    addCPE = (e: any) => {
        e.preventDefault();
        this.props.enableAssetDrop(true)
        this.props.addCPE();
    }
    measure = (e: any) => {
        e.preventDefault();
        this.props.measure();
    }
    calculate = (e: any) => {
        e.preventDefault();
        this.props.calculate();
    }
    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }

    saveDraft = () => {
        this.setState({
            isdraftSaved: true
        })
    }
    savePrameters = () => {
        this.setState({
            isParameterSubmitted: true
        })
    }
    changeCpiId = (e: any) => {
        this.setState({
            cpiId: e.target.value
        })
    }
    changeCpiName = (e: any) => {
        this.setState({
            cpiName: e.target.value
        })
    }
    button_change = (e: any) => {
        this.setState({
            button: !this.state.button
        })
    }
    button1_change = (e: any) => {
        this.setState({
            button1: !this.state.button1
        })
    }

    onSearch = (e: any) => {
        console.log(e.target.name, e.target.value, "ddfdf")
        this.setState({
            searches: e.target.value
        })
        console.log(this.autocompleteInput, "ggggggg")
        const predict = this.getQuery()
        this.setState({
            predictionsList: predict,
        })
        console.log(this.state.predictionsList, "list")
    }
    getQuery = () => {
        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
        return addressPredictions
    }
    predictionCallback = (predictions: any, status: any) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) return;

        addressPredictions = predictions;
    }
    list_asset_search = (searches: any) => {
        this.setState({
            search_list: this.state.asset_list.filter((asset: any) => asset.assetIdentifierId.toUpperCase().includes(this.state.searches.toUpperCase()))
        })

    }
    details = (details: any) => {
        this.setState({
            searches: details.assetIdentifierId,
        })
        // let list_filter = this.state.ap_cpe_list.filter((asset: any) => asset.name === details.name)
        var coordinates = {
            'latitude': details.latitude,
            'longitude': details.longitude

        }
        details['coOrdinates'] = coordinates
        this.setState({
            lat_search: details['latitude'],
            lng_search: details['longitude']
        }, () => {
            var locmark = true
            this.props.upadateSearchMapCoordinates({ "lat": parseFloat(details['latitude']), "long": parseFloat(details['longitude']) })
            this.props.upadateMapCoordinates({ "latitude": parseFloat(details['latitude']), "longitude": parseFloat(details['longitude']) })
            this.props.changeMapCenter(this.state.lat_search, this.state.lng_search)
            this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, details)
            // this.props.history.push('/map')
        })
    }
    setPlace = (value: any) => {
        this.setState({
            searches: value,
            bar: true
        }, () => {
            this.props.updateSearchText(this.state.searches)
            this.props.setAddress(value, true)
            // this.props.history.push('/map')
        })
    }
    list_asset = () => {
        let requestBody = {
        }
        requestAPICall("list_asset", {}, requestBody).then((data: any) => {
            var list: any = []
            data['assets'].forEach((element: any) => {
                if (element['assetTypeId'] === 1 || element['assetTypeId'] === 3 || element['assetTypeId'] === 6) {
                    list.push(element)
                }
            });
            this.setState({
                asset_list: list,
                ap_cpe_list: list
            })
            console.log(this.state.ap_cpe_list)
        })
    }

    renderer() {
        // this.state.assetAction
        switch (this.state.assetAction) {

            case 'network': return (

                // < div className="fixed-top" id="overlay" style={{
                //     height: "100%",
                //     display: this.state.show_network_overlay === true ? 'block' : 'none'
                // }
                // }>
                //     {this.state.show_network_overlay ? <NetworkStatusPage hideNetworkOverlay={this.hideNetworkOverlay} /> : null}

                // </div >
                <Redirect to='/network-status-mob' />
            );

        }
    }

    hideNetworkOverlay() {
        console.log("hide overlay")
        this.setState({
            show_network_overlay: false
        })
        this.setPage(null)
        this.props.set_subcard(null)
    }
    setPage = (page: any) => {
        // this.props.updateNetworkScreenType('overview')
        console.log("====update network======");
        localStorage.setItem('networkScreenType', 'overview')
        if (page != null) {
            console.log('setting network status redux true.........')
            this.props.updateNetworkStatus(true)
        }
        else {
            console.log('setting network status redux false.........')
            this.props.updateNetworkStatus(false)
        }
        this.setState({ assetAction: page })
        if (page == "network") {
            this.setState({
                show_network_overlay: true
            }, () => {
                // setTimeout(() => {
                console.log("network resize................................")
                $(".ion-page").removeClass("ion-page-invisible");
                // }, 500);
            })
            this.props.set_subcard('network')
        }

    }
    hideCalculatorOverlay() {
        this.setState({
            show_calculator_overlay: false
        })
        this.setPage(null)
        this.props.set_subcard(null)
    }
    showOptions = () => {
        this.setState({
            showOptions: !this.state.showOptions
        }, () => {
            if (this.state.showOptions == false) {
                this.selected_polygon_list = []
                this.selected_heatmap_list = []
                this.setState({
                    status_show_polygon: false,
                    polygon_list: null,
                    polygonListLength: 0,
                    status_show_heatmap: false,
                    heatmap_list: null,
                    heatmapListLength: 0
                })
            }
            if (this.state.showOptions == true) {
                this.setState({
                    showLegends: false,
                })
            }
        })
    }
    showLegends = () => {
        this.setState({
            showLegends: !this.state.showLegends
        }, () => {
            if (this.state.showLegends == true) {
                this.selected_polygon_list = []
                this.selected_heatmap_list = []
                this.setState({
                    showOptions: false,
                    status_show_polygon: false,
                    polygon_list: null,
                    polygonListLength: 0,
                    status_show_heatmap: false,
                    heatmap_list: null,
                    heatmapListLength: 0
                })
            }
        })
    }
    polygon_view = (e: any) => {
        console.log(e.target.checked)
        if (e.target.checked == true) {
            this.setState({
                polygon_enabled: true
            })
            requestAPICall("list_polygon_boundaries", {}, {}).then(data => {
                console.log(data)
                if (data.status == 200) {
                    this.setState({
                        polygon_list: data.polygonList,
                        status_show_polygon: true
                    })
                }
            })
        }
        if (e.target.checked == false) {
            this.selected_polygon_list = []
            this.setState({
                polygon_enabled: false,
                polygon_list: null,
                polygonListLength: 0
            }, () => {
                this.props.polygon_set(null)
            })
        }
    }
    polygon_selected = (e: any) => {
        let polygon_index = this.selected_polygon_list.indexOf(parseInt(e.target.id))
        if (e.target.checked == true) {
            this.selected_polygon_list.push(parseInt(e.target.id))
            this.setState({
                polygonListLength: this.selected_polygon_list.length
            })
            console.log(this.selected_polygon_list)
            console.log(this.selected_polygon_list.length)
        }
        else {
            if (this.selected_polygon_list.length != 0) {
                this.selected_polygon_list.splice(polygon_index, 1)
                console.log(this.selected_polygon_list)
                console.log(this.selected_polygon_list.length)
                this.setState({
                    polygonListLength: this.selected_polygon_list.length
                })

            }
        }
    }
    applyPolygon = () => {
        this.setState({
            polygon_enabled: true,
            status_show_polygon: false
        }, () => {
            // this.setState({
            //     showOptions: !this.state.showOptions
            // })
            this.props.polygon_set(this.selected_polygon_list)
        })
    }
    status_hide_polygonModal = () => {
        this.selected_polygon_list = []
        this.setState({
            status_show_polygon: false,
            polygon_enabled: false,
            polygon_list: null,
            polygonListLength: 0
        })
    }
    heatmap_view = (e: any) => {
        console.log(e.target.checked)
        if (e.target.checked == true) {
            this.setState({
                heatmap_enabled: true
            })
            requestAPICall("raster-list", {}, {}).then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({
                        heatmap_list: data.raster_list,
                        status_show_heatmap: true
                    })
                }
            })
        }
        if (e.target.checked == false) {
            this.selected_heatmap_list = []
            this.setState({
                heatmap_enabled: false,
                heatmap_list: null,
                heatmapListLength: 0
            }, () => {
                this.props.heatmap_set(null)
            })
        }
    }
    heatmap_mob_view = (val: any) => {
        if (val == true) {
            requestAPICall("raster-list", {}, {}).then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({
                        heatmap_list: data.raster_list,
                        status_show_heatmap: true
                    })
                }
            })
        }
        if (val == false) {
            this.selected_heatmap_list = []
            this.setState({
                heatmap_enabled: false,
                heatmap_list: null,
                heatmapListLength: 0
            }, () => {
                this.props.heatmap_set(null)
            })
        }
    }
    status_hide_heatmapModal = () => {
        this.selected_heatmap_list = []
        this.setState({
            status_show_heatmap: false,
            heatmap_enabled: false,
            heatmap_list: null,
            heatmapListLength: 0
        })
    }
    heatmap_selected = (e: any) => {
        let heatmap_index = this.selected_heatmap_list.indexOf(e.target.name)
        if (e.target.checked == true) {
            this.selected_heatmap_list.push(e.target.name)
            this.setState({
                heatmapListLength: this.selected_heatmap_list.length
            })
            console.log(this.selected_heatmap_list)
            console.log(this.selected_heatmap_list.length)
        }
        else {
            if (this.selected_heatmap_list.length != 0) {
                this.selected_heatmap_list.splice(heatmap_index, 1)
                console.log(this.selected_heatmap_list)
                console.log(this.selected_heatmap_list.length)
                this.setState({
                    heatmapListLength: this.selected_heatmap_list.length
                })

            }
        }
    }
    applyHeatmap = () => {
        this.setState({
            heatmap_enabled: true,
            status_show_heatmap: false
        }, () => {
            // this.setState({
            //     showOptions: !this.state.showOptions
            // })
            this.props.heatmap_set(this.selected_heatmap_list)
        })
    }
    sas_view = (e: any) => {
        if (e.target.checked == true) {
            this.setState({
                sas_enabled: true
            }, () => { this.props.sas_set(true) })
        }
        if (e.target.checked == false) {
            this.setState({
                sas_enabled: false
            }, () => { this.props.sas_set(false) })
        }
    }
    mob_sas_view = (val: any) => {
        if (val == true) {
            this.setState({
                sas_enabled: true
            }, () => { this.props.sas_set(true) })
        }
        if (val == false) {
            this.setState({
                sas_enabled: false
            }, () => { this.props.sas_set(false) })
        }
    }
    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'
        const isEnabled = this.state.button1 == false && this.state.button == false
        return (
            <div >
                {isBrowser ? <div className="webonly">99999999999999999999999999999999999999999999999999999999999999999999
                    <div className='row'>
                        <div className='' style={{ position: "fixed", left: '0px' }}>
                            <div className="card" style={{ height: '178px' }}>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div>
                                                <img className='search-web' src={SearchIcon} />
                                            </div>
                                            {/* <i className="fa fa-search searchicon" aria-hidden="true"></i> */}
                                            {/* <i className="fa fa-times closeicon"></i> */}
                                            {/* <StandaloneSearchBox> */}
                                            <input className='search' type='text' placeholder='Search Address | CBSD/CPE' onClick={this.search} />
                                            {/* </StandaloneSearchBox> */}

                                        </div>
                                    </div>
                                    <div className='row m-t-12'>
                                        <div className='col-md-6'>
                                            <button type='button' className='cardbutton-web' onClick={this.addCPE}>
                                                {/* <i className="fa fa-circle cpeicon-web"></i> */}
                                                <img className='calcicon-web' style={{ height: "15px" }} src={addCPEIcon} />
                                                Add CPE</button>
                                        </div>
                                        <div className='col-md-6' style={{ paddingLeft: '3px' }}>
                                            <button type='button' className='cardbutton-web' onClick={this.addAP}>
                                                <img className='apicon-web' style={{ height: "18px" }} src={addAPIcon} />
                                                Add CBSD</button>
                                        </div>
                                    </div>
                                    <div className='row m-t-12'>
                                        <div className='col-md-6'>
                                            <button type='button' className='cardbutton-web' onClick={this.measure}>
                                                <img className='measureicon-web' style={{ height: "20px" }} src={measureIcon} />
                                                Measure</button>
                                        </div>
                                        <div className='col-md-6' style={{ paddingLeft: '3px' }}>
                                            <button type='button' className='cardbutton-web' onClick={this.calculate}>
                                                <img className='calcicon-web' src={calc} />
                                                Calculate</button>
                                        </div>
                                    </div>
                                    {/* <div className='row m-t-12'>
                                        <div className='col-md-12'>
                                            {
                                                this.state.isdraftSaved ?
                                                    <button className='btn cardbtn btn-submitted'>
                                                        <i className="fa fa-check" style={{ fontSize: '12px', margin: '3px', color: '#00D56E' }}></i>
                                                        Save Draft</button>
                                                    :
                                                    <button className='btn cardbtn submitbtn' onClick={() => this.saveDraft()}>Save Draft</button>
                                            }
                                        </div>
                                    </div> */}
                                    {/* <div className='row m-t-12'>
                                        <div className='col-md-12'>
                                            {
                                                this.state.isParameterSubmitted ?
                                                    <button className='btn' style={{ background: "#F5F5F7", color: "black" }}>
                                                        <img className='btn-img' src={checkSuccess} />
                                                        Submitted</button>
                                                    :
                                                    <button className='btn cardbtn submitbtn' onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit Parameters</button>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className='' >
                            {/* <div className="col-md-12 justify-content-flex-end">
                                {this.state.heatMap ?
                                    <div className="heaticon-selected text-center" style={{ display: 'none' }} onClick={() => this.toggleHeatMap()}>
                                        <img className='heat invert-white' src={heat} />
                                    </div>
                                    :
                                    <div className="heaticon text-center" style={{ display: 'none' }} onClick={() => this.toggleHeatMap()}>
                                        <img className='heat' src={heat} />
                                    </div>
                                }
                            </div> */}
                            <div className="" style={{ position: "fixed", right: '20px', top: '60px' }}>
                                <div className="geoicon text-center" onClick={() => this.setCurrentPosition()}>
                                    <img style={{ padding: '5px' }} src={geo} />
                                </div>
                            </div>
                            <div className="" style={{ position: "fixed", right: '20px', top: '110px' }}>
                                <div className="geoicon text-center dashoption" onClick={this.showOptions}>
                                    <i className="fas fa-bars"> </i>
                                </div>
                            </div>
                            <div className="" style={{ position: "fixed", right: '20px', top: '160px' }}>
                                <div className="geoicon text-center dashoption" onClick={this.showLegends}>
                                    <i className="fas fa-info"> </i>
                                </div>
                            </div>
                            <div className="" style={{ position: 'absolute', top: '75px', right: '65px' }}>
                                {this.state.showOptions ? <div className="">
                                    <div className="card m-0 float-right mb10" style={{ height: 'inherit', width: '200px', position: 'relative' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" checked={this.state.heatmap_enabled} onChange={this.heatmap_view} className="custom-control-input" id="heatcheck" name="heatmap" />
                                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="heatcheck">Heat Map</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" checked={this.state.polygon_enabled} onChange={this.polygon_view} className="custom-control-input" id="polycheck" name="polygon" />
                                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="polycheck">Polygon Boundaries</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" checked={this.state.sas_enabled} onChange={this.sas_view} className="custom-control-input" id="sascheck" name="sas" />
                                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="sascheck">SAS Status</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                                {this.state.status_show_heatmap == true && this.state.heatmap_enabled == true ? <div >
                                    <div className="">
                                        <div className="card m-0 mt10" style={{ height: 'inherit', width: '246px', position: 'relative' }}>
                                            <div className="card-body" style={{ padding: '10px' }}>
                                                <div className='row' style={{ padding: '0 17px' }}>
                                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Coverage Layers List</h6>
                                                    <i className="far fa-times-circle modalclose" onClick={this.status_hide_heatmapModal} data-dismiss="modal" aria-label="Close"></i>
                                                </div>
                                                <div className="col-md-12 m-t-10 p-2" style={{ border: '1px solid #e3e3e3', borderRadius: '6px' }}>
                                                    <div className="col-md-12 p-0">
                                                        <span className="netacthead">
                                                            Heatmap Files
                                                </span>
                                                    </div>
                                                    <div className="col-12 m-t-5 p-0 text-center searchborder"></div>
                                                    <div className="row m-t-5" style={{ overflow: 'hidden auto', maxHeight: '100px' }}>
                                                        {
                                                            this.state.heatmap_list ?
                                                                Object.entries(this.state.heatmap_list).map((val: any) => {
                                                                    console.log(val)
                                                                    if (val[1] != 0) {
                                                                        return <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input" id={val[1]} name={val[0]} onChange={this.heatmap_selected} />
                                                                                    <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor={val[1]}>{val[0]}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        return <div className='col-md-12' style={{ textAlign: 'center' }}>
                                                                            <div className="form-group">
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input" id="dummyID" name="dummyName" onChange={this.heatmap_selected} />
                                                                                    <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="dummyID">FDD_12m</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                })
                                                                :
                                                                <div className="col-md-12">
                                                                    <span className="netacthead">
                                                                        'Loading'
                                                            </span>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 m-t-10">
                                                    <button className='btn cardbtn' style={{ width: '100%' }} onClick={this.applyHeatmap} disabled={this.state.heatmapListLength == 0 ? true : false}>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                                {this.state.status_show_polygon == true && this.state.polygon_enabled == true ? <div >
                                    <div className="">
                                        <div className="card m-0" style={{ height: 'inherit', width: '246px', position: 'relative' }}>
                                            <div className="card-body" style={{ padding: '10px' }}>
                                                <div className='row' style={{ padding: '0 17px' }}>
                                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Polygon Boundaries List</h6>
                                                    <i className="far fa-times-circle modalclose" onClick={this.status_hide_polygonModal} data-dismiss="modal" aria-label="Close"></i>
                                                </div>
                                                <div className="col-md-12 m-t-10 p-2" style={{ border: '1px solid #e3e3e3', borderRadius: '6px' }}>
                                                    <div className="col-md-12 p-0">
                                                        <span className="netacthead">
                                                            Polygon Files
                                                </span>
                                                    </div>
                                                    <div className="col-12 m-t-5 p-0 text-center searchborder"></div>
                                                    <div className="row m-t-5" style={{ overflow: 'hidden auto', maxHeight: '100px' }}>
                                                        {
                                                            this.state.polygon_list ?
                                                                this.state.polygon_list.map((val: any) => {
                                                                    console.log(val)
                                                                    return <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input" id={val.polygonId} name={val.polyganFilename} onChange={this.polygon_selected} />
                                                                                <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor={val.polygonId}>{val.polyganFilename}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                                :
                                                                <div className="col-md-12">
                                                                    <span className="netacthead">
                                                                        'Loading'
                                                            </span>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 m-t-10">
                                                    <button className='btn cardbtn' style={{ width: '100%' }} onClick={this.applyPolygon} disabled={this.state.polygonListLength == 0 ? true : false}>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            {this.state.showLegends ? <div className="" style={{ position: 'absolute', top: '122px', right: '65px' }}>
                                <div className="">
                                    <div className="card m-0" style={{ height: 'inherit', width: 'inherit', position: 'relative', marginLeft: '0px' }}>
                                        <div className="card-body" style={{ fontSize: 'smaller' }}>
                                            <div className='row' style={{ padding: '0 17px' }}>
                                                <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>SAS Status</h6>
                                                {/* <i className="far fa-times-circle modalclose" onClick={this.status_hide_heatmapModal} data-dismiss="modal" aria-label="Close"></i> */}
                                            </div>
                                            <div className="row m-t-10">
                                                <div className="col-md-2">
                                                    <div className="legend" style={{ background: '#000' }}> </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <span>Not Submitted</span>
                                                </div>
                                            </div>
                                            <div className="row m-t-10">
                                                <div className="col-md-2">
                                                    <div className="legend" style={{ background: '#FF1493' }}> </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <span>Ready for CPI</span>
                                                </div>
                                            </div>
                                            <div className="row m-t-10">
                                                <div className="col-md-2">
                                                    <div className="legend" style={{ background: '#FFFF00' }}> </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <span>Pending Registration</span>
                                                </div>
                                            </div>
                                            <div className="row m-t-10">
                                                <div className="col-md-2">
                                                    <div className="legend" style={{ background: '#FFA500' }}> </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <span>Registered</span>
                                                </div>
                                            </div>
                                            <div className="row m-t-10">
                                                <div className="col-md-2">
                                                    <div className="legend" style={{ background: '#008000' }}> </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <span>Authorized</span>
                                                </div>
                                            </div>
                                            <div className="m-t-10"><div className='row' style={{ padding: '0 17px' }}>
                                                <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Heatmap Legend</h6>
                                            </div>
                                                <div className="row m-t-10">
                                                    <div className="col-md-2">
                                                        <div className="legend" style={{ background: '#FFFF00' }}> </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span>{"-125 dBm < RSRP < -120 dBm"}</span>
                                                    </div>
                                                </div>
                                                <div className="row m-t-10">
                                                    <div className="col-md-2">
                                                        <div className="legend" style={{ background: '#0000FF' }}> </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span>{"-120 dBm < RSRP < -110 dBm"}</span>
                                                    </div>
                                                </div>
                                                <div className="row m-t-10">
                                                    <div className="col-md-2">
                                                        <div className="legend" style={{ background: '#ADD8E6' }}> </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span>{"-110 dBm < RSRP < -100 dBm"}</span>
                                                    </div>
                                                </div>
                                                <div className="row m-t-10">
                                                    <div className="col-md-2">
                                                        <div className="legend" style={{ background: '#006400' }}> </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span>{"-100 dBm < RSRP < -90 dBm"}</span>
                                                    </div>
                                                </div>
                                                <div className="row m-t-10">
                                                    <div className="col-md-2">
                                                        <div className="legend" style={{ background: '#90EE90' }}> </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span>{"-90 dBm < RSRP < -80 dBm"}</span>
                                                    </div>
                                                </div>
                                                <div className="row m-t-10">
                                                    <div className="col-md-2">
                                                        <div className="legend" style={{ background: '#FF0000' }}> </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span>Greater than -80 dBm</span>
                                                    </div>
                                                </div></div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>

                </div>
                    :
                    <div className="mobonly">
                        <div>
                            <IonGrid>
                                <IonRow >
                                    <IonCol>
                                    </IonCol>
                                </IonRow>
                                {
                                    this.renderer()
                                }


                                <div className="" style={{ position: 'fixed', bottom: '135px', right: '0px' }}>
                                    <IonFab horizontal="end" style={{ position: 'relative' }} >
                                        {this.state.heatmap_enabled ?
                                            <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} onClick={() => { this.heatmap_mob_view(false) }} />
                                            </IonFabButton>
                                            :
                                            <IonFabButton className='backg' disabled={!isEnabled} color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} onClick={() => { this.heatmap_mob_view(true) }} />
                                            </IonFabButton>
                                        }
                                    </IonFab>
                                    {this.state.bar ?
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            {this.state.sas_enabled ?
                                                <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }} onClick={() => { this.mob_sas_view(false) }}>
                                                    <i className="fas fa-bars sample"> </i>
                                                </IonFabButton>
                                                :
                                                <IonFabButton className='backg' disabled={!isEnabled} color="light" size="small" style={{ marginBottom: '10px' }} onClick={() => { this.mob_sas_view(true) }}>
                                                    <i className="fas fa-bars"> </i>
                                                </IonFabButton>
                                            }
                                        </IonFab> : null
                                    }
                                    {this.state.bar ?
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' disabled={!isEnabled} onClick={() => this.setCurrentPosition()} color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <img style={{ padding: '5px' }} src={geo} />
                                            </IonFabButton>
                                        </IonFab> : null
                                    }

                                    {this.state.bar ?
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' disabled={!isEnabled} onClick={() => this.setPage('network')} color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <i className="fas fa-wifi" style={{ color: "#5b5e62", fontSize: "15px" }}></i>
                                            </IonFabButton>
                                        </IonFab> : null
                                    }
                                    {this.state.bar ?
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' disabled={!isEnabled} color='light' size="small" style={{ marginBottom: '10px' }}>
                                                <img className='calcicon' src={calcmob} onClick={this.calculate} />
                                            </IonFabButton>
                                        </IonFab> : null
                                    }

                                    {/* <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                    <IonFabButton className='backg' disabled={!isEnabled} color="light" onClick={this.search} size="small" style={{ marginBottom: '10px' }}>
                                        <img className='search-mob' src={SearchIcon} />
                                    </IonFabButton>
                                </IonFab> */}
                                </div>
                                {this.state.bar ?
                                    <div className="" style={{ position: 'fixed', bottom: '95px', right: '0px' }}>
                                        <IonFab horizontal="end" style={{ position: 'relative' }}>
                                            <IonFabButton className='backg' color="light" onClick={this.switch} size="small">
                                                <img className='search-mob' src={SearchIcon} />


                                            </IonFabButton>
                                        </IonFab>
                                    </div> :
                                    <div className="dashbtn" style={{ bottom: "95px" }}>
                                        <div style={{ padding: '0px 7px', transition: '9s all ease' }}>
                                            <div>
                                                <img className='' style={{ position: "absolute", padding: "8px", filter: "brightness(0.5)", left: '7px' }} src={SearchIcon} />
                                            </div>
                                            <div>
                                                <span className="searchclose" onClick={this.switch}>
                                                    <span className="material-icons" style={{ margin: '9px', color: '#9c9c9c' }}> clear</span>
                                                </span>
                                                {/* <img className='' style={{ position: "absolute", margin: "1px", width: "38px", right: '7px' }} onClick={this.switch} src={close} /> */}
                                            </div>
                                            <input name={this.state.searches} id="autocomplete" onChange={(e: any) => { this.onSearch(e); this.onChange(e) }} value={this.state.searches} className='searchnew' type='text' placeholder='Search Address | CBSD/CPE' ref={this.autocompleteInput} />
                                            {!this.state.bar ?
                                                <div>
                                                    <div className=" searchcardmob" >
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                                            aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>ADDRESS</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                                            aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>CBSD/CPE</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="tab-content" id="searchTabContent">

                                                            <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">

                                                                {this.state.predictionsList.length > 0 ?
                                                                    <div className="card-body searchdetails" >
                                                                        {this.state.predictionsList.map((predict: any) => {
                                                                            return <div className='row' style={{ paddingRight: '18px' }}>
                                                                                <div className='col-2 p-r-10'>
                                                                                    <i className="fas fa-map-marker-alt searchcontenticon  float-right" style={{ fontSize: '20px' }}></i>
                                                                                </div>
                                                                                <div className='col-10 searchborder' style={{ padding: '10px 0px' }}>
                                                                                    <span onClick={() => this.setPlace(predict.description)} className='searchcontent' style={{ fontSize: '16px' }}>{predict.description}</span><br />
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                    : null}
                                                            </div>

                                                            <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                                                {this.state.search_list.length > 0 ?
                                                                    <div className="card-body searchdetails" >
                                                                        {this.state.search_list.map((index: any) => (
                                                                            <div id="indexname" onClick={() => this.details(index)}>

                                                                                <div className='row m-t-10 col-12 p-r-0' >
                                                                                    {index.assetType === "AP-outdoor" ?
                                                                                        <div className='col-2'>
                                                                                            <img className='searchapicon' src={apicon} />
                                                                                        </div> :
                                                                                        <div className='col-2'>
                                                                                            <i className="far fa-circle searchcontenticon-mob "></i>
                                                                                        </div>}
                                                                                    <div className='col-10 searchborder'>
                                                                                        <div className="font16" style={{ color: '#353435' }}>{index.assetIdentifierId}</div>
                                                                                        <div className="font14" style={{ color: '#8F9FAC' }}> {index.name}</div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>))}
                                                                    </div> : <div className="card-body searchdetails" >
                                                                        {this.state.ap_cpe_list.map((index: any) => (
                                                                            <div id="indexname" onClick={() => this.details(index)}>

                                                                                <div className='row m-t-10 col-12 p-r-0'>
                                                                                    {index.assetType === "AP-outdoor" ?
                                                                                        <div className='col-2'>
                                                                                            <img className='searchapicon' src={apicon} />
                                                                                        </div> :
                                                                                        <div className='col-2'>
                                                                                            <i className="far fa-circle searchcontenticon-mob "></i>
                                                                                        </div>}
                                                                                    <div className='col-10 searchborder'>
                                                                                        <div className="font16" style={{ color: '#353435' }}>{index.assetIdentifierId}</div>
                                                                                        <div className="font14" style={{ color: '#8F9FAC' }}>{index.name}</div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>))}
                                                                    </div>}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                }
                                {/* <IonRow >
                                <IonCol className="bottom-float" >
                                    <IonRow style={{ height: "8vh" }}>
                                        <IonCol>
                                            <IonFab horizontal="end">
                                                {this.state.heatMap ?
                                                    <IonFabButton className='backg' color="danger" size="small" >
                                                        <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} onClick={() => this.toggleHeatMap()} />
                                                    </IonFabButton>
                                                    :
                                                    <IonFabButton className='backg' color="light" size="small" >
                                                        <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} onClick={() => this.toggleHeatMap()} />
                                                    </IonFabButton>
                                                }
                                            </IonFab>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ height: "8vh" }} >
                                        <IonCol>
                                            <IonFab horizontal="end">
                                                <IonFabButton className='backg' color="light" size="small">
                                                    <img style={{ padding: '5px' }} src={geo} />
                                                </IonFabButton>
                                            </IonFab>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ height: "8vh" }} >
                                        <IonCol>
                                            <IonFab horizontal="end" >
                                                <IonFabButton className='backg' color='light' size="small">
                                                    <img className='calcicon' src={calcmob} onClick={this.calculate} />
                                                </IonFabButton>
                                            </IonFab>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ height: "8vh" }} >
                                        <IonCol style={{ padding: '0px 10px 0px 5px' }}>
                                            {this.state.bar ?

                                                <IonFab horizontal="end" >
                                                    <IonFabButton className='backg' color="light" onClick={this.switch} size="small">
                                                        <img className='search-mob' src={SearchIcon} />


                                                        <i className="fa fa-search searchicon" style={{ margin: "0px" }} aria-hidden="true"></i>
                                                    </IonFabButton>
                                                </IonFab> :
                                                <div>
                                                    <div>
                                                        <img className='search-web' src={SearchIcon} />
                                                    </div>
                                                    <input className='searchnew' type='text' placeholder='Search Address' />
                                                    <div>
                                                        <img className='closenew' onClick={this.switch} src={close} />
                                                    </div>
                                                </div>
                                            }

                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow> */}

                                <IonRow className="bottom-menu-mob" style={{ bottom: "41px" }}>
                                    {this.state.button == false ?
                                        <IonCol size='4' style={{ padding: '5px' }}>
                                            {/* <i className="fa fa-circle cpeicon"></i> */}
                                            <img className='cpeicon' src={addCPEIcon} style={{ width: '42px' }} />
                                            {this.state.button1 ?
                                                <button type='button' className='cardbutton p-l-25 ' style={{ background: '#00D56E', color: '#fff', border: "none" }} onClick={(e: any) => { this.button1_change(e); this.addCPE(e); this.props.enableAssetDrop(false) }}>Create</button>
                                                :
                                                <button type='button' className='cardbutton p-l-25 ' onClick={(e: any) => { this.button1_change(e); this.props.enableAssetDrop(true) }} >Add CPE</button>
                                            }
                                        </IonCol>
                                        :
                                        <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            {/* <i className="fa fa-circle cpeicon"></i> */}
                                            <img className='cpeicon' src={addCPEIcon} style={{ width: '40px', filter: "brightness(0) invert(0.9)" }} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none", color: '#9B9B9B' }} disabled >Add CPE</button>

                                        </IonCol>
                                    }
                                    {this.state.button1 == false ?
                                        < IonCol size='4' style={{ padding: '5px' }}>
                                            {this.state.button ? <img className='apcreate' src={APcreate} /> : <img className='apicon' src={AP} />}
                                            {this.state.button ?
                                                <button type='button' className='cardbutton p-l-25 ' style={{ background: '#0372fb', color: '#fff', border: "none" }} onClick={(e: any) => { this.button_change(e); this.addAP(e); this.props.enableAssetDrop(false) }}>Create</button>
                                                :
                                                <button type='button' className='cardbutton p-l-25 ' onClick={(e: any) => { this.button_change(e); this.props.enableAssetDrop(true) }}>Add CBSD</button>
                                            }
                                        </IonCol>
                                        :
                                        < IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            <img className='apicon' src={AP} style={{ filter: "brightness(0) invert(0.9)" }} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none", color: '#9B9B9B' }} disabled>Add CBSD</button>
                                        </IonCol>
                                    }
                                    {this.state.button == true || this.state.button1 == true ?
                                        <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            <img className='measureiconmob' src={measureIcon} style={{ filter: "grayscale(1)" }} />
                                            <button type='button' className='cardbutton p-l-25' style={{ border: "none", color: '#9B9B9B' }} disabled onClick={this.measure}>Measure</button>
                                        </IonCol>
                                        :
                                        <IonCol size='4' style={{ padding: '5px' }}>
                                            <img className='measureiconmob' src={measureIcon} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} onClick={this.measure}>Measure</button>
                                        </IonCol>
                                    }
                                </IonRow>
                                {
                                    this.state.button1 ?
                                        <IonRow className="bottom-menu-mob" style={{ bottom: "0px", align: "center", background: "white", height: "40px", padding: "6px", fontSize: "17px", color: "#5b5e62" }}>
                                            <IonCol size='12'>
                                                <div className="text-center">
                                                    {
                                                        this.state.button1 ? <span>Touch where you'd like to drop the CPE</span> : null
                                                    }
                                                </div>
                                            </IonCol>
                                        </IonRow> : null
                                }
                                {
                                    this.state.button ?
                                        <IonRow className="bottom-menu-mob" style={{ bottom: "0px", align: "center", background: "white", height: "40px", padding: "6px", fontSize: "17px", color: "#5b5e62" }}>
                                            <IonCol size='12'>
                                                <div className="text-center">
                                                    {
                                                        this.state.button ? <span>Touch where you'd like to drop the CBSD</span> : null
                                                    }
                                                </div>
                                            </IonCol>
                                        </IonRow> : null
                                }
                                {
                                    !this.state.button && !this.state.button1 ?
                                        <IonRow className="bottom-menu-mob" style={{ bottom: "0px", background: "white", height: "40px", padding: "6px", fontSize: "17px", color: "#5b5e62" }}>
                                            <IonCol size='12'>
                                                <div>
                                                    <span>Connection : {this.state.connectionType} {this.state.connectionType === 'cellular' ? this.state.connectionNetworkType : ""} {this.state.connectionStatus === true ? "online" : "offline"} </span>
                                                </div>
                                            </IonCol>
                                        </IonRow> : null
                                }

                                {/* <IonRow className="submit-btn-wrapper bottom-actions">

                                <div className='' style={{ width: "100%", paddingLeft: "2px" }}>
                                    {
                                        this.state.isParameterSubmitted ?
                                            <button className='btn cardbtn btn-submitted' style={{ color: '#222223', background: '#f5f5f7' }}>
                                                <span className="material-icons" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}>check</span>
                                                <span>Submitted</span> </button>
                                            :
                                            <button className='btn cardbtn submitbtn' onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit Parameters</button>
                                    }                                </div>
                            </IonRow> */}
                            </IonGrid>
                            {/* <IonFab vertical="bottom" horizontal="end" activated={true}>
                            <IonFabButton>
                                <IonIcon icon="share" />
                            </IonFabButton>
                        </IonFab>
                        <IonFab vertical="bottom" horizontal="end">
                            <IonFabButton>
                                <IonIcon icon="share" />
                            </IonFabButton>
                        </IonFab> */}
                        </div>

                        {/* <div className='row'>
                        <div className='col-md-9'>
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <i className="fa fa-search searchicon" aria-hidden="true"></i>
                                            <i className="fa fa-times closeicon"></i>
                                            <input className='search' type='text' placeholder='Search Address' onClick={this.search} />
                                        </div>
                                    </div>
                                    <div className='row m-t-10'>
                                        <div className='col-md-6'>
                                            <i className="fa fa-circle cpeicon"></i>
                                            <button type='button' className='cardbutton' onClick={this.addCPE}>Add CPE</button>
                                        </div>
                                        <div className='col-md-6' style={{ paddingLeft: '3px' }}>
                                            <img className='apicon' src={AP} />
                                            <button type='button' className='cardbutton' onClick={this.addAP}>Add CBSD</button>
                                        </div>
                                    </div>
                                    <div className='row m-t-10'>
                                        <div className='col-md-6'>
                                            <img className='measureicon' src={measure} />
                                            <button type='button' className='cardbutton' onClick={this.measure}>Measure</button>
                                        </div>
                                        <div className='col-md-6' style={{ paddingLeft: '3px' }}>
                                            <img className='calcicon' src={calc} />
                                            <button type='button' className='cardbutton' onClick={this.calculate}>Calculate</button>
                                        </div>
                                    </div>
                                    <div className='row m-t-10'>
                                        <div className='col-md-12'>
                                            <button className='btn cardbtn submitbtn'>Save Draft</button>
                                        </div>
                                    </div>
                                    <div className='row m-t-10'>
                                        <div className='col-md-12'>
                                            <button className='btn cardbtn submitbtn' data-toggle="modal" data-target="#submitModal" >Submit Parameters</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className="heaticon text-center">
                                <img className='heat' src={heat} />
                            </div>
                        </div>
                    </div> */}
                        <Modal show={this.state.status_show_heatmap}>
                            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className='row m-0' style={{}}>
                                            <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '14px' }}>Coverage Layers List</h6>
                                            <i className="far fa-times-circle modalclose" onClick={this.status_hide_heatmapModal} data-dismiss="modal" aria-label="Close"></i>
                                        </div>
                                        <div className="col-md-12 m-t-10 p-2" style={{ border: '1px solid #e3e3e3', borderRadius: '6px' }}>
                                            <div className="col-md-12 p-0">
                                                <span className="netacthead">
                                                    Heatmap Files
                                                </span>
                                            </div>
                                            <div className="col-12 m-t-5 p-0 text-center searchborder"></div>
                                            <div className="row m-t-5" style={{ overflow: 'hidden auto', maxHeight: '150px' }}>
                                                {
                                                    this.state.heatmap_list ?
                                                        Object.entries(this.state.heatmap_list).map((val: any) => {
                                                            console.log(val)
                                                            if (val[1] != 0) {
                                                                return <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" className="custom-control-input" id={val[1]} name={val[0]} onChange={this.heatmap_selected} />
                                                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor={val[1]}>{val[0]}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            else {
                                                                return <div className='col-md-12' style={{ textAlign: 'center' }}>
                                                                    <div className="form-group">
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" className="custom-control-input" id="dummyID" name="dummyName" onChange={this.heatmap_selected} />
                                                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="dummyID">FDD_12m</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        })
                                                        :
                                                        <div className="col-md-12">
                                                            <span className="netacthead">
                                                                'Loading'
                                                            </span>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 p-0 m-t-10">
                                            <button className='btn cardbtn' style={{ width: '100%' }} onClick={this.applyHeatmap} disabled={this.state.heatmapListLength == 0 ? true : false}>Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                }
                <Modal show={this.state.show} handleClose={this.hideModal}>
                    {/* <div className="modal fade" id="submitModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row m-0' style={{}}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '14px' }}>SUBMITTING PARAMETERS</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div className='row m-0' style={{}}>
                                    <span className='distance' style={{ marginTop: "10px", fontWeight: 400 }}>Please enter CPI credentials</span>
                                </div>
                                <div className=' m-t-5 justify-content-center'>
                                    <div className="form-group" >
                                        <div className="col-md-12 p-0">
                                            <div className="form-label-group m-0">
                                                <input type="text" id="cid" className="text-user img-center" placeholder="CPI ID" value={this.state.cpiId} onChange={(e) => this.changeCpiId(e)} />
                                                <label htmlFor="cid">CPI ID</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 p-0">
                                            <div className="form-label-group m-0">
                                                <input type="text" style={{ width: '100%', borderColor: "#CED7DF" }} id="cname" className="text-pwd img-center" placeholder="CPI Name" value={this.state.cpiName} onChange={(e) => this.changeCpiName(e)} />
                                                <label htmlFor="cname">CPI Name</label>
                                            </div>
                                        </div>
                                        <div className="col-12 p-0">
                                            <div className="form-label-group m-0">
                                                <div className='col-md-12 p-0 m-t-10 justify-content-center' style={{ fontSize: '12px' }}>
                                                    <Dropzone onDrop={this.onDrop} disabled={this.state.field_disable}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section className='dropzone-set'>
                                                                <div {...getRootProps({ className: 'dropzone' })}>

                                                                    <input {...getInputProps()} accept='.p12' />
                                                                    {files.length ?
                                                                        <span style={{ color: "#00D56E" }}>

                                                                            {files}

                                                                            {/* <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}

                                                                        </span>
                                                                        :
                                                                        <span style={{ fontSize: '14px' }}>+ Upload CPI Certificate </span>}
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-0 m-t-5'>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" name="example1" />
                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="customCheck1">Override mandatory CPI credentials</label>
                                        </div>
                                        {/* <input style={{ float: 'left', margin: '7px 7px 7px 0px' }} type="checkbox" /> */}
                                        {/* <span className='check' style={{ float: 'left', margin: '5px 0px' }}>Override mandatory CPI credentials</span> */}
                                    </div>
                                </div>
                                <div className='row m-0 m-t-5 justify-content-center' style={{}}>
                                    <button className='btn cardbtn' style={{ width: '100%' }} onClick={() => { this.savePrameters(); this.hideModal() }} disabled={this.state.cpiId === '' || this.state.cpiName === ''}>Submit Parameters</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
            </div >

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Dashboard);
// export default withRouter<PropsToPass ,RouteComponentProps>(connect<{}, {}, PropsToPass>(
//     mapStateToProps,
//     mapDispatchToProps
//   )(Dashboard) as any);
