import React, { Component } from 'react'
import { IonTextarea, IonPage, IonContent, IonAlert, IonLoading, withIonLifeCycle, IonItem, IonLabel } from '@ionic/react';
import Back from '../assets/img/left-arrow.svg'
import Heat from '../assets/img/heaticon.png'
import BackIcon from '../assets/img/go-back-icon-img.png'
import Dropz from './DropZ';
import "./AddAP.css"
import geo from '../assets/img/target-icon.png'
import qricon from '../assets/img/bar-code-img-icon.png'
import camicon from '../assets/img/camera-icon-img.png'
import "./dashboard.css";
import { requestAPICall } from "../service/apiHandler";
import Dropzone from 'react-dropzone'
import { Data } from '@react-google-maps/api';
import { isBrowser, isMobile } from "react-device-detect";
import store from '../store';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from '../constants/actionTypes';
import { connect } from 'react-redux';

const { Camera } = Plugins;

var sector_list_ids: any = []
var asset_name = ""
var is_submit_form = false
var secval = false
var add_ap2_properties: any = []
var add_ap3_properties: any = []
var AP_Data: any = []
var unit: any = ""
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
    set_ap2_value: (value: any) =>
        dispatch({ type: ASSET, key: 'ap2_property', value }),
    set_ap3_value: (value: any) =>
        dispatch({ type: ASSET, key: 'ap3_property', value }),
    set_asset_value: (value: any) =>
        dispatch({ type: ASSET, key: 'asset_property', value }),
    set_responseBody: (value: any) =>
        dispatch({ type: ASSET, key: 'requestBody', value }),
});
class AddAP extends React.Component {
    state: any = {};
    props: any = {};
    apFocus: any = []
    apsecFocus: any = []
    reduxStore: any = store.getState()['general'];
    serialFocus: any
    constructor(props: any) {
        super(props);

        this.apFocus = []
        this.apsecFocus = []
        this.serialFocus = React.createRef();
        console.log(props)
        this.state = {
            card: 1,
            sectorsCount: [1, 2, 3, 4, 5, 6],
            selectedSectorCount: 0,
            name: "",
            asset_id: "",
            description: "",
            image: "",
            alertbox: false,
            is_loader: false,
            serial_id: "",
            ap_name: "",
            sectorlist: [],
            files: [],
            assetAction: 'add',
            assetProperties: [],
            disable_button: true,
            field_disable: true,
            assetTypeId: 0,
            serial: "",
            asset_ap_id: "",
            asset_serial_id: "",
            add_ap1_properties: [],
            vendor_type: localStorage.getItem('vendor_type'),
            isShowSectorIcons: localStorage.getItem('vendor_type') == 'Directional'?true:false
        }
        this.load_sector = this.load_sector.bind(this)
        this.setSelectedSector = this.setSelectedSector.bind(this)
        this.submit_parameters = this.submit_parameters.bind(this)
        this.editAssetChanges = this.editAssetChanges.bind(this)
        this.validate_request = this.validate_request.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.sector_id_list = this.sector_id_list.bind(this)
    }

    componentDidMount() {
        console.log('componentDidMount event fired')
        if (isBrowser) {
            this.initializeComponent()
        }
        if (isMobile) {
            this.initializeComponent()
        }
    }

    initializeComponent = () => {
        console.log("initializeComponent")
        this.reduxStore = store.getState()['general']
        console.log(this.reduxStore['assetAction'])
        console.log(this.reduxStore);
        console.log(this.props);
        console.log(!(this.props.editSerial))
        if (this.state.action === 'edit') {
            this.setState({
                disable_button: false
            })
        }
        this.setState({ assetAction: this.reduxStore['assetAction'], assetProperties: [], files: [], assetTypeId: this.reduxStore['assetTypeId'] }, () => {
            this.getProperties()
        })


    }

    openScanner = async () => {
        const data = await BarcodeScanner.scan();
        this.setState({ serial_id: data.text })
        this.onChange({ "target": { "name": "serial_id", "value": data.text } })
        this.onChange({ "target": { "name": "serial_id", "value": data.text } })
    };

    takePicture = async () => {
        console.log('opening camera');
        var image: any = await Camera.getPhoto({
            quality: 50,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl
        });
        // var imageUrl = image.webPath;
        console.log(image);
        image['fileName'] = 'camera_upload'
        image['uploadType'] = 'capture'
        image['name'] = 'IMG.' + image['format']
        this.onDrop([image])
    }

    getProperties() {
        let requestBody = {}
        if (this.reduxStore['assetAction'] === "edit") {
            console.log("edittttttttttttttt")
            requestAPICall("get_asset_editable_data", { asset_type: this.reduxStore['assetTypeId'], asset_id: this.reduxStore['assetId'] }, requestBody)
                .then(data => {
                    try {

                        if (data.responseCode == 200) {
                            var number_of_sector = data["properties"].filter((property: any) => property.propertyName === "no_of_sectors")
                            var name = data["properties"].filter((property: any) => property.propertyName === "ap_name")
                            this.setState({
                                assetProperties: data["properties"],
                                asset_id: this.props.selectedAssetId,
                                ap_name: name[0].value
                            }, () => {
                                this.setDefaults()
                            })
                            this.setState({
                                selectedSectorCount: number_of_sector[0].value,
                                asset_id: this.props.selectedAssetId,
                                sectorlist: this.props.sector_id_list,
                                assetTypeId: this.props.typeId
                            }, () => {
                                this.load_sector();
                            })
                            this.setState({
                                vendor_type: data["vendor_type"]
                            });
                            if (data["vendor_type"] == 'Directional'){
                                this.setState({ isShowSectorIcons: true })
                            }else {
                                this.setState({ isShowSectorIcons: false })
                            }

                        }

                    } catch (error) {

                    }


                })
        }
        else {
            if (this.reduxStore['tabType'] == "outdoor") {
                AP_Data = localStorage.getItem('AP_Outdoor_Data');
            }

            if (this.reduxStore['tabType'] == "indoor") {
                AP_Data = localStorage.getItem('AP_Indoor_Data');
            }
            // console.log(AP_Data)
            if (AP_Data == null) {
                console.log("retrieving from db......")
                requestAPICall("list_asset_properties", { asset_type_id: this.reduxStore['assetTypeId'] }, requestBody)
                    .then(data => {
                        if (data.responseCode == 200) {
                            if (this.reduxStore['tabType'] == "outdoor") {
                                localStorage.setItem('AP_Outdoor_Data', JSON.stringify(data["properties"]));
                                localStorage.setItem('vendor_type', data["vendor_type"]);
                                this.setState({
                                    vendor_type: data["vendor_type"]
                                });
                                if (data["vendor_type"] == 'Directional'){
                                    this.setState({ isShowSectorIcons: true })
                                }else {
                                    this.setState({ isShowSectorIcons: false })
                                }
                            }
                            if (this.reduxStore['tabType'] == "indoor") {
                                localStorage.setItem('AP_Indoor_Data', JSON.stringify(data["properties"]));
                            }
                            try {
                                if (isMobile) {
                                    var properties = data.properties.map((property: any) => {
                                        if (property.propertyName === 'latitude') {
                                            property.value = this.reduxStore['coordinates']['latitude']
                                        } else if (property.propertyName === 'longitude') {
                                            property.value = this.reduxStore['coordinates']['longitude']
                                        }
                                        return property
                                    })
                                }
                                this.setState({ assetProperties: data.properties }, () => {
                                    this.setDefaults()

                                })
                            } catch (error) {

                            }
                        }
                    })
            } else {
                console.log("retrieving from stored data.........")
                let parse_data = JSON.parse(AP_Data)
                try {
                    if (isMobile) {
                        var properties = parse_data.map((property: any) => {
                            if (property.propertyName === 'latitude') {
                                property.value = this.reduxStore['coordinates']['latitude']
                            } else if (property.propertyName === 'longitude') {
                                property.value = this.reduxStore['coordinates']['longitude']
                            }
                            return property
                        })
                    }
                    this.setState({
                        assetProperties: parse_data
                    }, () => { this.setDefaults() })
                    this.setState({
                        vendor_type: localStorage.getItem('vendor_type')
                    });
                }
                catch (error) {

                }
            }
        }
    }

    setDefaults() {
        console.log(this.state.assetProperties)
        console.log(this.state.assetAction)
        if (isBrowser) {
            // this.props.listAssets('disabled');
            this.state.assetProperties.forEach((property: any) => {
                if (property.propertyName == "no_of_sectors") {
                    this.setState({
                        selectedSectorCount: property.value
                    })
                }
            });
            this.setState({ assetAction: this.reduxStore['assetAction'], assetId: this.props.assetId }, () => {
                if (this.state.assetAction === 'edit') {
                    this.setState({
                        // selectedSectorCount: this.props.parentState.noOfSectors,
                        asset_id: this.props.parentState.selectedAssetId, field_disable: false,
                        sectorlist: this.props.parentState.asset_details.properties.sector_id,
                        serial_id: this.props.parentState.asset_details.properties.serialId,
                        assetTypeId: this.props.parentState.asset_details.assetTypeId
                    }, () => {
                        this.load_sector();
                    })
                } else {
                    this.setState({ selectedSectorCount: 0 }, () => {
                        this.load_sector();
                    })
                }
            })
        }
        else if (isMobile) {
            console.log("================================")
            this.setState({ selectedSectorCount: 0 }, () => {
                this.load_sector();
            })
        }
    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
        // if (this.state.assetAction === 'edit') {
        //     this.goBack("")
        // }
    }

    goBack(deviceType: any) {
        if (isBrowser) {
            this.props.back()
        } else {
            this.props.history.push('/map')
        }
    }

    disableButton = () => {

        this.reduxStore = store.getState()['general']
        if (this.props.assetAction === 'add') {
            if (this.props.latlong.length === 0) {
                this.setState({
                    field_disable: true,
                    disable_button: true
                })
            } else {
                this.setState({
                    field_disable: false,
                    disable_button: false
                })
            }
        }
        var properties = this.state.assetProperties.map((property: any) => {
            if (property.propertyName === 'latitude') {
                property.value = this.reduxStore['coordinates']['latitude']
            } else if (property.propertyName === 'longitude') {
                property.value = this.reduxStore['coordinates']['longitude']
            }
            return property
        })
        this.setState({
            assetProperties: properties
        })

    }
    setShowLoading = (st: boolean) => {
        this.setState({ is_loader: st })
    }

    setSelectedSector = (count: number) => {
        sector_list_ids = []
        this.setState({ selectedSectorCount: count })
        if (this.props.assetAction !== "edit") {
            if (isBrowser) {
                this.props.setSector(count)
            }
        }
        this.load_sector()
        this.state.assetProperties.forEach((property: any) => {
            if (property.propertyName == "no_of_sectors") {
                property.value = count
            }
        });
        let diff = count - sector_list_ids.length
        if (this.props.assetAction === "edit") {
            if (diff > 0) {
                for (let index = sector_list_ids.length; index < count; index++) {
                    var json: any = {}
                    json["sector_id"] = 0
                    json["sector_value"] = 0
                    sector_list_ids.push(json)
                }
            } else {
                for (let index = 0; index < Math.abs(diff); index++) {
                    sector_list_ids.pop()
                }
            }
        } else {
            for (let index = sector_list_ids.length; index < count; index++) {
                var json: any = {}
                json["sector_id"] = index
                json["sector_value"] = 0
                sector_list_ids.push(json)
            }
        }

    }
    validate_request() {
        console.log("validation")
        let check = false
        this.state.assetProperties.forEach((property: any) => {
            if (property.value === "") {
                check = true

            }
        });


    }
    validateSectors() {
        let check = false
        let check_sector_value: any
        check_sector_value = []
        console.log("SSSSSSSSSSSSS", sector_list_ids)
        sector_list_ids.forEach((sector: any, key: any) => {
            if (!check) {
                if (sector.sector_value === 0 || sector.sector_value === "") {
                    this.setState({
                        warnMessage: "Sector" + " Id cannot be empty",
                        alertbox: true
                    })
                    this.apsecFocus[key].focus()
                    check = true

                }
                if (sector.sector_value !== "") {
                    if (!(/^[a-zA-Z0-9.-]+$/.test(sector.sector_value))) {
                        this.setState({
                            warnMessage: "Please enter only Alphanumeric values for Sector Id ",
                            alertbox: true
                        })
                        this.apFocus[key].focus()
                        check = true
                    }
                }

            }

        });
        sector_list_ids.map((element: any) => {
            check_sector_value.push(element.sector_value)
        })
        const sector_set = new Set(check_sector_value)
        if (check_sector_value.length !== sector_set.size) {
            this.setState({
                alertbox: true,
                warnMessage: "Sector value cannot be duplicate"
            })
            check = true
        }
        if (check === false) {
            secval = true

        }
        else if (check === true) {
            secval = false

        }
    }

    validationRequest() {
        let check = false
        this.state.assetProperties.forEach((property: any, key: any) => {
            if (!check) {
                if (property.value === "" && property.isRequired == true && property.isShowable == true && property.propertyName !== "no_of_sectors") {
                    console.log(property)
                    this.apFocus[key].focus()
                    check = true
                    this.setState({
                        warnMessage: property.displayName + " cannot be empty",
                        alertbox: true
                    })
                }
                console.log(this.reduxStore['assetTypeId'])
                if (property.propertyName == "no_of_sectors" && property.value == "" && property.isRequired == true && this.reduxStore['assetTypeId'] == 1 && this.state.vendor_type != "Omni") {

                    console.log(property)
                    check = true
                    this.setState({
                        warnMessage: "Please enter " + property.displayName,
                        alertbox: true
                    })

                }

            }
        });
        console.log(check)

        if (!check) {

            if (this.props.assetAction === "edit" && this.state.serial_id === "") {
                check = true
                this.serialFocus.current.focus()
            }
        }
        if (check == false) {
            is_submit_form = true
        } else {
            is_submit_form = false
        }

        if (is_submit_form && this.reduxStore['assetTypeId'] === 1 && this.state.vendor_type === 'Directional') {
            this.validateSectors()
        }

        else if (is_submit_form && (this.reduxStore['assetTypeId'] === 2 || this.reduxStore['assetTypeId'] === 6 || this.state.vendor_type === 'Omni')) {
            secval = true
        }
    }


    submit_parameters() {
        this.validationRequest()

        let request_list: any = []
        let new_request_list: any = []
        new_request_list = this.state.assetProperties.map((property: any) => {
            // if (property.propertyName === 'latitude') {
            //     // this.props.latlong[0]
            //     property.value = this.reduxStore['coordinates']['latitude']
            // } else if (property.propertyName === 'longitude') {
            //     property.value = this.reduxStore['coordinates']['longitude']
            // } 
            if (property.propertyName === "radiation_center") {
                if (property.value === "") {
                    property.value = 0
                }
            }
            if (property.propertyName === "vendor_type"){
                property.value = this.state.vendor_type
            }
            if (this.state.files.length > 0) {
                if (property.propertyName == "image") {
                    property.value = this.state.files
                }
            }


            return property
        })
        var asset = this.state.assetProperties.filter((property: any) => property.propertyName === 'access_point_id')
        this.setState({
            asset_ap_id: asset[0].value
        })
        var asset_serail = this.state.assetProperties.filter((property: any) => property.propertyName === 'serial_id')
        console.log(asset_serail)
        this.setState({
            asset_serial_id: asset_serail[0].value
        })
        if (secval) {
            this.setState({
                is_loader: true
            })
            if (this.state.files.length > 0) {
                var picReader = new FileReader();
                let filerequestBody = {}
                let fileName = this.state.files[0].name
                let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
                if (this.state.files[0].uploadType === undefined) {
                    picReader.onload = (e) => {
                        filerequestBody = {
                            'fileName': fileName,
                            'fileExtension': fileExt,
                            'fileValue': picReader.result
                        }
                        this.uploadImageAndAddAsset(filerequestBody, new_request_list, request_list)
                    }
                    picReader.readAsDataURL(this.state.files[0]);
                } else {
                    filerequestBody = {
                        'fileName': fileName,
                        'fileExtension': this.state.files[0].format,
                        'fileValue': this.state.files[0].dataUrl
                    }
                    this.uploadImageAndAddAsset(filerequestBody, new_request_list, request_list)
                }
            } else {
                var assetTypeId = this.reduxStore['assetTypeId']
                if (!this.state.isShowSectorIcons){
                    assetTypeId = 6
                }
                let requestBody = {
                    "assetName": this.state.ap_name,
                    "asset_id_value": asset[0].value,
                    "serialId": asset_serail[0].value,
                    "assetTypeId": assetTypeId,
                    "properties": new_request_list,
                    "sectorIdList": sector_list_ids,
                    "building_id": this.reduxStore['buildingId'],
                    "floor_id": this.reduxStore['floorId'],
                }
                console.log(requestBody)
                requestAPICall("create_asset", { asset_type_id: assetTypeId }, requestBody).then(data => {
                    console.log(data)
                    this.setState({
                        is_loader: false
                    })
                    if (data.responseCode == 200) {
                        this.setState({
                            warnMessage: data.message,
                            alertbox: true
                        })

                        if (isBrowser) {
                            if (this.reduxStore['tabType'] == "outdoor") {
                                this.props.setSector(-1)
                                this.props.listAssets('create-list-angle')
                            }

                            else {
                                this.props.listAssets('create-list', this.reduxStore['floorId'])
                            }

                            this.goBack("Browser")
                        } else {
                            this.props.hideOverlay()
                            if (this.reduxStore['tabType'] == "outdoor") {
                                this.props.setSector(-1)
                                this.props.listAssets('create-list-angle')
                                this.props.changeMapCenter(this.reduxStore['coordinates']['latitude'], this.reduxStore['coordinates']['longitude'])
                            }

                            else {
                                this.props.listAssets('create-list', this.reduxStore['floorId'])
                            }

                        }
                    }
                    else {
                        this.setState({
                            warnMessage: data.message,
                            alertbox: true
                        })
                    }

                })
            }
        }
        secval = false
    }

    uploadImageAndAddAsset(imageData: any, properties: any, prop1: any) {
        requestAPICall("upload_file", {}, imageData).then(data => {
            console.log(data)
            if (data['responseCode'] == 200) {
                properties.forEach((property: any) => {
                    if (property.propertyName == "image") {
                        property.value = data.file_id
                    }
                });
                var asset = this.state.assetProperties.filter((property: any) => property.propertyName === 'access_point_id')
                this.setState({
                    asset_ap_id: asset[0].value
                })
                var assetTypeId = this.reduxStore['assetTypeId']
                if (!this.state.isShowSectorIcons){
                    assetTypeId = 6
                }
                let requestBody = {
                    "assetName": this.state.ap_name,
                    "serialId": this.state.asset_serial_id,
                    "asset_id_value": this.state.asset_ap_id,
                    "assetTypeId": assetTypeId,
                    "properties": properties,
                    "sectorIdList": sector_list_ids,
                    "building_id": this.reduxStore['buildingId'],
                    "floor_id": this.reduxStore['floorId'],
                }
                console.log(requestBody)
                requestAPICall("create_asset", { asset_type_id: assetTypeId }, requestBody).then(data => {
                    this.setState({
                        is_loader: false
                    })
                    if (data.responseCode == 200) {
                        this.setState({
                            warnMessage: data.message,
                            alertbox: true
                        })
                        if (isBrowser) {
                            if (this.reduxStore['tabType'] == "outdoor") {
                                this.props.setSector(-1)
                                this.props.listAssets('create-list-angle')
                            }

                            else {
                                this.props.listAssets('create-list', this.reduxStore['floorId'])
                            }
                            this.goBack("Browser")
                        } else {
                            this.props.hideOverlay()
                            if (this.reduxStore['tabType'] == "outdoor") {
                                this.props.setSector(-1)
                                this.props.listAssets('create-list-angle')
                                this.props.changeMapCenter(this.reduxStore['coordinates']['latitude'], this.reduxStore['coordinates']['longitude'])
                            }

                            else {
                                this.props.listAssets('create-list', this.reduxStore['floorId'])
                            }
                        }
                    }
                    else {
                        this.setState({
                            warnMessage: data.message,
                            alertbox: true
                        })
                    }

                })
            }
        })
    }
    saveChanges = () => {
        console.log("testing ================================ ")
        this.validationRequest()
        let request_list: any = []
        request_list = this.state.assetProperties.filter((property: any) => property.propertyName !== 'serial_id')
        this.state.assetProperties.forEach((property: any) => {
            if (property.propertyName == "name") {
                this.setState({ ap_name: property.value })
            } else if (property.propertyName === "radiation_center") {
                if (property.value === "") {
                    property.value = 0
                }
            }
            if (this.state.files.length > 0) {
                if (property.propertyName == "image") {
                    property.value = this.state.files
                }
            }
        })

        let image_value = this.state.assetProperties.filter((property: any) => property.propertyName === 'image')

        if (image_value.value === "" || image_value.value === "undefined" || typeof (image_value.value) === undefined || this.state.files.length > 0) {
            if (secval) {

                let filerequestBody = {}
                let fileName = this.state.files[0].name
                let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
                if (this.state.files[0].uploadType === 'undefined' || this.state.files[0].uploadType === undefined || typeof (this.state.files[0].uploadType) === undefined) {
                    console.log("========", this.state.files[0])
                    var picReader = new FileReader();
                    picReader.onload = (e) => {
                        console.log("========", e)
                        filerequestBody = {
                            'fileName': fileName,
                            'fileExtension': fileExt,
                            'fileValue': picReader.result
                        }
                        console.log(filerequestBody)
                        console.log('1')
                        this.uploadEditAssetImage(filerequestBody, request_list)
                    }
                    picReader.readAsDataURL(this.state.files[0]);

                } else {
                    console.log("========")
                    filerequestBody = {
                        'fileName': fileName,
                        'fileExtension': this.state.files[0].format,
                        'fileValue': this.state.files[0].dataUrl
                    }
                }
                console.log(filerequestBody)
                this.uploadEditAssetImage(filerequestBody, request_list)

            }

        }
        else {
            let new_request_list = this.state.assetProperties.filter((property: any) => property.propertyName !== 'image')
            let requestBody = {
                "assetName": asset_name,
                "serialId": this.state.serial_id,
                "assetTypeId": this.state.assetTypeId,
                "properties": new_request_list,
                "building_id": this.reduxStore.buildid,
                "sector_id": sector_list_ids,
                "status": "Offline"
            }
            if (secval) {
                console.log("aksjdhajsgdjagd")
                this.editAssetChanges(requestBody)
            }
        }

        secval = false
    }
    uploadEditAssetImage = (filerequestBody: any, request_list: any) => {
        requestAPICall("upload_file", {}, filerequestBody).then(data => {
            console.log(data)
            if (data['responseCode'] == 200) {
                let new_request_list = request_list.map((property: any) => {
                    if (property.propertyName === 'image') {
                        property.value = data.file_id
                    }
                    return property
                })
                let requestBody = {
                    "assetName": asset_name,
                    "serialId": this.state.serial_id,
                    "assetTypeId": this.state.assetTypeId,
                    "properties": new_request_list,
                    "building_id": this.reduxStore.buildid,
                    "sector_id": sector_list_ids,
                    "status": "Offline"
                }
                this.editAssetChanges(requestBody)
            }
        })
    }
    editAssetChanges = (requestBody: any) => {
        this.setState({
            is_loader: true
        })
        requestAPICall("edit_asset_details", { asset_type_id: this.props.typeId, asset_id: this.state.asset_id }, requestBody).then(data => {
            this.setState({
                is_loader: false
            })
            if (data.responseCode == 200) {

                this.setState({
                    warnMessage: data.message,
                    alertbox: true,
                    disable_button: false
                })
                let latitude: any
                let longitude: any
                requestBody['properties'].forEach((element: any) => {
                    if (element.propertyName === 'latitude') {
                        latitude = element.value
                    }
                    if (element.propertyName === 'longitude') {
                        longitude = element.value
                    }
                });
                if (this.reduxStore['tabType'] == "outdoor") {
                    this.props.changeMapCenter(latitude, longitude)
                }

                this.props.get_asset_details(this.state.asset_id)
                if (this.reduxStore['tabType'] == "indoor") {
                    this.props.listAssets('create-list', this.reduxStore['floorId'])
                } else {
                    this.props.listAssets('create-list-angle')
                }
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })


            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }

        })
    }
    load_sector() {
        const element_list = []
        var val = ''
        for (let index = 1; index <= this.state.selectedSectorCount; index++) {
            val = ''
            var element;
            if (this.state.assetAction === 'edit') {
                sector_list_ids = this.props.sector_id_list
                try {
                    val = this.props.sector_id_list[index - 1]["sector_value"]
                } catch (error) {
                    val = ""
                }

                // var id_value = this.props.sector_id_list[index - 1]["sector_id"]
                element =
                    <div className={`col-md-6 col-6 d-inline-block m-t-7  ${index % 2 !== 0 ? ' p-r-5' : 'p-l-5'} `} >
                        <div className="form-label-group m-0">
                            <input ref={(ref) => { this.apsecFocus[index - 1] = ref; return true; }} type="text" style={{ height: '38px' }} value={val} name={"Sector_" + index} className="serial" id={"Sector_" + index} placeholder={"Sector " + index + " ID #"} onChange={(e: any) => this.sector_id_list(e, index)} />
                            <label className="pages" htmlFor={"Sector_" + index}>{"Sector " + index + " ID #"}</label>
                        </div>
                    </div>

            } else {
                element =
                    <div className={`col-md-6 col-6  d-inline-block m-t-7 ${index % 2 !== 0 ? 'p-r-5' : 'p-l-5'} `} >
                        <div className="form-label-group m-0">
                            <input ref={(ref) => { this.apsecFocus[index - 1] = ref; return true; }} type="text" style={{ height: '38px' }} name={"Sector_" + index} className="serial" id={"Sector_" + index} placeholder={"Sector " + index + " ID #"} onChange={(e: any) => this.sector_id_list(e, index)} />
                            <label className="pages" htmlFor={"Sector_" + index}>{"Sector " + index + " ID #"}</label>
                        </div>
                    </div>
            }

            element_list.push(element)
        }
        return <div className="row">{element_list}</div>
    }

    onDrop = (files: any) => {
        this.setState({ files: files, clearImage: false })
        console.log(this.state.files)
        console.log(this.state.files[0].uploadType)
        // this.state.assetProperties.forEach((property: any) => {
        //     if (property.propertyName == "image") {
        //         property.value = this.state.files
        //     }
        // });
        this.validate_request()
    };

    onClear = (files: any) => {
        this.setState({ files: files, clearImage: true })
        console.log(this.state.files)
        // this.state.assetProperties.forEach((property: any) => {
        //     if (property.propertyName == "image") {
        //         property.value = this.state.files
        //     }
        // });
        this.validate_request()
    };

    handleChange = (evt: any) => {
        console.log(evt.target.value)
        if (evt.target.value == "Omni"){
            this.setState({ isShowSectorIcons: false })
        } else {
            this.setState({ isShowSectorIcons: true })
        }
        this.setState({ [evt.target.name]: evt.target.value });
        this.state.assetProperties.map((property: any) => {
            if (property.propertyName === 'vendor_type') {
                property.value = evt.target.value
            }
            return property
        })

    }


    onChange(e: any) {
        console.log()
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'serial_id') {
            this.setState({
                serial_id: e.target.value
            })
        }
        if (e.target.name === "ap_name") {
            asset_name = e.target.value
        }
        if (e.target.name === 'latitude') {
            this.state.assetProperties.map((property: any) => {
                if (property.propertyName === 'latitude') {
                    property.value = e.target.value
                }
                return property
            })
        }
        if (e.target.name === 'longitude') {
            this.state.assetProperties.map((property: any) => {
                if (property.propertyName === 'longitude') {
                    property.value = e.target.value
                }
                return property
            })
        }
        this.state.assetProperties.forEach((property: any) => {
            if (property.propertyName == e.target.name) {
                property.value = e.target.value
            }
            if (property.propertyName == "radiation_center" && e.target.name == "radiation_center") {
                if (e.target.value == '') {
                    property.value = e.target.value
                }
            }
            if (property.propertyName == "ap_name" && e.target.name == "ap_name") {
                this.setState({
                    ap_name: e.target.value
                })
            }
            if (property.propertyName == "serial" && e.target.name == "serial_id") {
                this.setState({
                    serial_id: e.target.value
                })
            }
            return property;
        });
        this.validate_request()
    }

    sector_id_list(e: any, index: any) {
        sector_list_ids[index - 1]["sector_value"] = e.target.value
        this.setState({
            [e.target.name]: e.target.value
        })
        this.state.assetProperties.forEach((property: any) => {
            if (property.propertyName == "sector_id") {
                property.value = sector_list_ids
            }
        });
        this.validate_request()
    }
    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }
    render() {

        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'
        let classImageUpload = files.length ? '-uploaded' : ''
        return (
            <div>
                {isBrowser ?
                    <div className='webonly'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className="card apcard">
                                    <div className="card-body" style={{ padding: '0px' }}>
                                        {/* apcardbody */}
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <img className='backicon' src={BackIcon} onClick={() => { this.goBack('browser') }} />
                                                <button type='button' className='backbutton' ></button>
                                                {/* <button type='button' className='backbutton material-icons' style={{ color: '#0059B2' }} onClick={this.props.back}>keyboard_backspace </button> */}
                                            </div>
                                            <div className='col-md-8' style={{ padding: "13px 0", marginLeft: "-5px" }}>
                                                <span className='apheader'>

                                                    {this.state.assetAction === 'add' ?
                                                        'ADDING CBSD' : 'EDIT CBSD'}
                                                </span>
                                                {/* <span className='apnote float-right' style={{ fontSize: '12px', margin: '5px' }} >1/3</span> */}

                                            </div>

                                        </div>
                                        <div className="col-md-12" style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 210px)' }}>


                                            <div className='col-md-12 p-0'>
                                                <div className='col-md-12 apborder p-0'>
                                                    <span className='apnote'>Click where you'd like to drop the CBSD</span>
                                                </div>
                                            </div>

                                            {
                                                this.state.assetProperties.map((property: any, key: any) => {
                                                    if (property.units !== "") {
                                                        unit = "(" + property.units + ")"
                                                    } else {
                                                        unit = ""
                                                    }
                                                    if (property.isShowable === true || this.props.loc === true) {

                                                        if (property.dataType === "string" || property.dataType == "float" || property.dataType === "int") {
                                                            if (property.isRequired == true && property.propertyName != 'latitude' && property.propertyName != 'longitude') {
                                                                if (property.propertyName === "access_point_id") {
                                                                    const element = <div className='col-md-12 p-0 m-t-7'>

                                                                        <div className="form-label-group m-0">
                                                                            <input ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} type="text" id={property.propertyName} name={property.propertyName} disabled={this.state.assetAction == "add" ? this.state.field_disable : true} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                        </div>
                                                                    </div>
                                                                    return element
                                                                }

                                                                else if (property.propertyName === "no_of_sectors" && this.state.assetId == 1 && this.state.isShowSectorIcons) {
                                                                    return <div className='row m-t-7 p-0 sectorIcon'>
                                                                        {this.state.sectorsCount.map((index: any) => (
                                                                            <div className="col-md-2">
                                                                                <i onClick={() => this.setSelectedSector(index)} className={`fa fa-map-marker mapmarker m-r-20 ${index <= this.state.selectedSectorCount ? 'mapmarker-selected' : ''}`}>
                                                                                </i>
                                                                            </div>
                                                                        ))}</div>

                                                                }
                                                                else {
                                                                    if (property.propertyName !== "no_of_sectors" && property.propertyName !== "serial_id") {
                                                                        const element = <div className='col-md-12 p-0 m-t-7'>

                                                                            <div className="form-label-group m-0">
                                                                                <input ref={(ref) => { this.apFocus[key] = ref; return true; }} type="text" id={property.propertyName} name={property.propertyName} maxLength={45} disabled={this.state.field_disable} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                                <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                            </div>
                                                                        </div>
                                                                        return element
                                                                    }
                                                                }


                                                            }
                                                            else if (property.isRequired == true && property.propertyName == 'latitude') {
                                                                if (this.reduxStore['assetAction'] === "edit") {
                                                                    const element = <div className='col-md-12 p-0 m-t-7'>
                                                                        <div className="form-label-group m-0">
                                                                            <input ref={(ref) => { this.apFocus[key] = ref; return true; }} type="number" id={property.propertyName} name={property.propertyName} className="accesspointname" value={this.reduxStore['assetAction'] === 'edit' ? property.value : this.reduxStore['coordinates']['latitude']} disabled={this.state.field_disable} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                        </div>
                                                                    </div>
                                                                    return element
                                                                } else {
                                                                    const element = <div className='col-md-12 p-0 m-t-7'>
                                                                        <div className="form-label-group m-0">
                                                                            <input ref={(ref) => { this.apFocus[key] = ref; return true; }} type="number" id={property.propertyName} name={property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={property.value === 0 ? this.reduxStore['coordinates']['latitude'] : property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                        </div>
                                                                    </div>
                                                                    return element
                                                                }
                                                            }
                                                            else if (property.isRequired == true && property.propertyName == 'longitude') {
                                                                if (this.reduxStore['assetAction'] === "edit") {
                                                                    const element = <div className='col-md-12 p-0 m-t-7'>
                                                                        <div ref={(ref) => { this.apFocus[key] = ref; return true; }} className="form-label-group m-0">
                                                                            <input type="number" id={property.propertyName} name={property.propertyName} className="accesspointname" value={property.value} disabled={this.state.field_disable} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                        </div>
                                                                    </div>
                                                                    return element
                                                                } else {
                                                                    const element = <div className='col-md-12 p-0 m-t-7'>
                                                                        <div className="form-label-group m-0">
                                                                            <input ref={(ref) => { this.apFocus[key] = ref; return true; }} type="number" id={property.propertyName} name={property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={property.value === 0 ? this.reduxStore['coordinates']['longitude'] : property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                        </div>
                                                                    </div>
                                                                    return element
                                                                }
                                                            } else if (property.propertyName === "radiation_center") {
                                                                const element = <div className='col-md-12 p-0 m-t-7'>

                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.apFocus[key] = ref; return true; }} type="number" id={property.propertyName} name={property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" style={{ padding: '4px 0px 0px 8px' }} htmlFor={property.propertyName}>{property.displayName} {unit}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            }
                                                            // Included on 10-7-20 due to serial id can not be editable after reg to SAS
                                                            else if (property.propertyName === "serial_id" && this.reduxStore['assetAction'] === "edit") {
                                                                const element = <div className='col-md-12 p-0 m-t-7'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} type="text" id={property.propertyName} name={property.propertyName} disabled={!(this.props.editSerial)} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            }
                                                            else {
                                                                const element = <div className='col-md-12 p-0 m-t-7'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} value={property.value} type="text" id={property.propertyName} name={property.propertyName} className="accesspointname" placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={property.propertyName}>{property.displayName}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            }
                                                        } else if (property.dataType === "image") {
                                                            return <div className='col-md-12 p-0 m-t-7 justify-content-center' style={{ padding: "0px 35px", fontSize: '14px' }}>
                                                                {
                                                                    (files.length) ? <i className="material-icons imgclose-file dropzclose" style={{ marginBottom: "10px", marginTop: "20px" }} onClick={(e: any) => { e.preventDefault(); files = []; this.onClear([]) }}>clear</i> : ""
                                                                }
                                                                <Dropzone onDrop={this.onDrop} disabled={this.state.field_disable}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section className='dropzone-set'>

                                                                            <div {...getRootProps({ className: classDName })} >

                                                                                <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />

                                                                                {/* <aside> */}
                                                                                {this.state.clearImage == true && property.value === "" ? <span style={{ fontSize: '14px' }}> + Add Image</span> :
                                                                                    property.value !== "" && files.length === 0 ? <span style={{ fontSize: '14px' }}> Image uploaded Already</span> :

                                                                                        (files.length) ? <span style={{ color: "#00D56E", display: 'flex' }}>

                                                                                            {files}

                                                                                        </span>
                                                                                            :
                                                                                            <span style={{ fontSize: '14px' }}>+ Add Image</span>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>

                                                            </div>
                                                            {/* <div className="col-2 p-l-0">
                                                                    <div className={"v-h-center image-field-action" + classImageUpload}>
                                                                        {files.length ?
                                                                            <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = []; this.onClear([]) }}>clear</i>
                                                                            : <img src={camicon} style={{ width: '18px' }} onClick={this.takePicture}></img>
                                                                        }
                                                                    </div>
                                                                </div> */}

                                                        } else if (property.dataType === "textArea") {

                                                            if (this.state.assetAction === 'edit') {
                                                                return <div className='col-md-12 p-0 m-t-10'>
                                                                    <IonItem lines="none" color="none" className="description">
                                                                        <IonLabel position="floating">{property.displayName}</IonLabel>
                                                                        <IonTextarea name={property.propertyName} id={property.propertyName} disabled={this.state.field_disable} rows={3} value={property.value} onInput={this.onChange} />
                                                                    </IonItem>
                                                                </div>
                                                            } else {
                                                                const element = <div className='col-md-12 p-0 m-t-10'>
                                                                    <IonItem lines="none" color="none" className="description">
                                                                        <IonLabel position="floating">Description</IonLabel>
                                                                        <IonTextarea style={{ marginTop: '0px' }} name={property.propertyName} maxlength={45} id={property.propertyName} disabled={this.state.field_disable} rows={4} defaultValue={property.value} onIonInput={this.onChange} />
                                                                    </IonItem>
                                                                </div>

                                                                return element
                                                            }
                                                    } else if (property.dataType === "list") {
                                                        if (property.isRequired == true && property.propertyName == 'vendor_type' && this.state.assetId == 1) {
                                                            const element = <div className='col-md-12 p-0 m-t-7'>

                                                                <div className="form-label-group m-0"> 
                                                                    <select className="form-control settingdropdown" id={property.propertyName}
                                                                        style={{ border: '1px solid #CED7DF', borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }} 
                                                                        name={property.propertyName} value={this.state.vendor_type} onChange={this.handleChange} disabled={true}>
                                                                            {property.validValues.map((value: any) => this.state.vendor_type == value?<option value={value} selected >{value}</option>:<option value={value} >{value}</option>)}
                                                                        {/* <option value="OMNI_DIRECTIONAL" selected={this.state.vendor_type === 'OMNI_DIRECTIONAL'?true:false} >Omni directional</option>
                                                                        <option value="MULTI_SECTORED" selected={this.state.vendor_type === 'MULTI_SECTORED'?true:false}>multi sector</option> */}
                                                                    </select>
                                                                    {/* <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label> */}
                                                                </div>
                                                            </div>
                                                            return element
                                                        }
                                                    }

                                                    }

                                                })

                                            }{
                                                this.load_sector()
                                            }
                                            <div className='row'>
                                                <div className="col-md-12 m-t-5">
                                                    {
                                                        this.state.assetAction == 'add' ?
                                                            <button className=' btn savebtn' style={{ width: '268px' }} onClick={this.submit_parameters} disabled={this.state.disable_button} >{"Save changes"}</button> :
                                                            <button className=' btn savebtn' style={{ width: '268px' }} disabled={!this.state.disable_button} onClick={this.saveChanges} >{"Update changes"}</button>
                                                    }

                                                </div>
                                            </div>


                                            {/* <button className=' btn savebtn' style={{ width: '268px' }} onClick={this.state.assetAction == 'add' ? 
                                                    this.submit_parameters : this.saveChanges} >{this.state.assetAction == 'add' ? "Save changes" : "Update changes"}</button> */}

                                            {/* <div className='row m-t-5' style={{ padding: "0px 35px" }}>
    <button className=' btn savebtn' style={{ bottom: '20px' }} disabled={this.state.disable_button} onClick={this.submit_parameters} >Save Changes</button>
</div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='col-md-3' >
                                <div className="col-md-12 justify-content-flex-end">
                                    <div className="heaticon text-center">
                                        <img className='heat' src={Heat} />
                                    </div>
                                </div>

                                <div className="col-md-12 float-right">
                                    <div className="geoicon text-center">
                                        <img style={{ padding: '5px' }} src={geo} onClick={this.setCurrentPosition} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :

                    <div className='mobonly'>
                        {/* <IonPage>
                            <IonContent> */}
                        <div className='param-info-mob' style={{ height: '100vh' }}>
                            <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                                <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={() => { this.props.hideOverlay() }} src={BackIcon} />
                                <span className="apheader">ADD CBSD</span>
                            </div>
                            <div className='container-fluid apdash'>
                                <div className="card apeditcard">
                                    <div className="card-body" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className=''>
                                                    <div className='col-12 p-0'>
                                                        <span className='apheader'>CBSD</span>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.assetProperties.map((property: any, key: any) => {
                                                        if (property.units !== "") {
                                                            unit = "(" + property.units + ")"
                                                        } else {
                                                            unit = ""
                                                        }
                                                        if (property.isShowable === true || this.props.loc === true) {
                                                            if ((property.dataType === "string" && property.propertyName !== 'serial_id') || property.dataType === "int") {
                                                                if (property.isRequired == true) {
                                                                    if (property.propertyName === "access_point_id") {
                                                                        const element = <div className='col-md-12 p-0 m-t-9'>

                                                                            <div className="form-label-group m-0">
                                                                                <input ref={(ref) => { this.apFocus[key] = ref; return true; }} type="text" id={property.propertyName} maxLength={45} name={property.propertyName} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                                <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                            </div>
                                                                        </div>
                                                                        return element
                                                                    } else if (property.propertyName === 'no_of_sectors' && this.reduxStore['assetTypeId'] == 1 && this.state.isShowSectorIcons) {
                                                                        return <div>
                                                                            <div className='row m-t-10'>
                                                                                <div className='col-12'>
                                                                                    <span className='cardtext'>Number of Sectors</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row m-t-10'>
                                                                                {this.state.sectorsCount.map((index: any) => (
                                                                                    <div className='col-2'>
                                                                                        <i onClick={() => this.setSelectedSector(index)} className={`fa fa-map-marker mapmarker m-r-20 ${index <= this.state.selectedSectorCount ? 'mapmarker-selected' : ''}`}>

                                                                                        </i>  </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        if (property.propertyName !== 'no_of_sectors') {
                                                                            return <div className='row m-t-10'>
                                                                                <div className="col-12">
                                                                                    <div className="form-label-group m-0">
                                                                                        <input type="text" ref={(ref) => { this.apFocus[key] = ref; return true; }} className="accesspointname font14" name={property.propertyName} id={property.propertyName} style={{ width: "100%" }} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                                        <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    }
                                                                } else if (property.dataType === "int") {
                                                                    return <div className='row m-t-5'>
                                                                        <div className="col-12">
                                                                            <div className="form-label-group m-0">
                                                                                <input type="number" ref={(ref) => { this.apFocus[key] = ref; return true; }} className="accesspointname font14" maxLength={45} value={property.value} name={property.propertyName} id={property.propertyName}
                                                                                    style={{ width: "100%" }} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                                <label className="pages" htmlFor={property.propertyName}>{property.displayName}{unit}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                else {
                                                                    return <div className='row m-t-10'>
                                                                        <div className="col-12">
                                                                            <div className="form-label-group m-0">
                                                                                <input type="text" ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} className="accesspointname font14" name={property.propertyName} id={property.propertyName} style={{ width: "100%" }} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                                <label className="pages" htmlFor={property.propertyName}>{property.displayName}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                }
                                                            }
                                                            else if (property.dataType === "float" || property.dataType === "int") {
                                                                if (property.isRequired == true && property.propertyName === 'latitude') {
                                                                    if (this.reduxStore['assetAction'] === "edit") {
                                                                        return <div className='row m-t-10'>
                                                                            <div className="col-12">
                                                                                <div className="form-label-group m-0">
                                                                                    <input type="number" ref={(ref) => { this.apFocus[key] = ref; return true; }} className="accesspointname font14" name={property.propertyName} id={property.propertyName}
                                                                                        style={{ width: "100%" }} placeholder={property.displayName} value={this.state.assetAction === 'edit' ? property.value : this.reduxStore['coordinates']['latitude']} onChange={this.onChange.bind(this)} />
                                                                                    <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    } else {
                                                                        return <div className='row m-t-10'>
                                                                            <div className="col-12">
                                                                                <div className="form-label-group m-0">
                                                                                    <input type="number" ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} className="accesspointname font14" name={property.propertyName} id={property.propertyName}
                                                                                        style={{ width: "100%" }} placeholder={property.displayName} value={property.value === 0 ? this.reduxStore['coordinates']['latitude'] : property.value} onChange={this.onChange.bind(this)} />
                                                                                    <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                }
                                                                else if (property.isRequired == true && property.propertyName === 'longitude') {
                                                                    if (this.reduxStore['assetAction'] === "edit") {
                                                                        return <div className='row m-t-10'>
                                                                            <div className="col-12">
                                                                                <div className="form-label-group m-0">
                                                                                    <input type="number" ref={(ref) => { this.apFocus[key] = ref; return true; }} className="accesspointname font14" name={property.propertyName} id={property.propertyName}
                                                                                        style={{ width: "100%" }} placeholder={property.displayName} value={this.state.assetAction === 'edit' ? property.value : this.reduxStore['coordinates']['longitude']} onChange={this.onChange.bind(this)} />
                                                                                    <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    } else {
                                                                        return <div className='row m-t-10'>
                                                                            <div className="col-12">
                                                                                <div className="form-label-group m-0">
                                                                                    <input type="number" ref={(ref) => { this.apFocus[key] = ref; return true; }} className="accesspointname font14" name={property.propertyName} id={property.propertyName}
                                                                                        style={{ width: "100%" }} placeholder={property.displayName} value={property.value === 0 ? this.reduxStore['coordinates']['longitude'] : property.value} onChange={this.onChange.bind(this)} />
                                                                                    <label className="pages" htmlFor={property.propertyName}>{property.displayName}{unit}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                }
                                                                else {
                                                                    return <div className='row m-t-5'>
                                                                        <div className="col-12">
                                                                            <div className="form-label-group m-0">
                                                                                <input type="number" ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} className="accesspointname font14" name={property.propertyName} id={property.propertyName}
                                                                                    style={{ width: "100%" }} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                                <label className="pages" htmlFor={property.propertyName}>{property.displayName}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                }
                                                            }
                                                            else if (property.dataType === "textArea") {
                                                                return <div className='row m-t-10'>
                                                                    <div className='col-12'><IonItem lines="none" color="none" className="description">
                                                                        <IonLabel position="floating">{property.displayName}</IonLabel>
                                                                        <IonTextarea style={{ width: "100%", margin: '0px' }} rows={3} maxlength={45} name={property.propertyName} onIonInput={this.onChange} />
                                                                    </IonItem>
                                                                    </div>
                                                                </div>
                                                            }
                                                            else if (property.dataType === "image") {
                                                                return <div className="row m-t-10">
                                                                    <div className='col-10 p-r-0' style={{ fontSize: '14px' }}>
                                                                        <Dropzone onDrop={this.onDrop} disabled={false}>
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <section className='dropzone-set' >
                                                                                    <div {...getRootProps({ className: classDName + "-mob" })}>
                                                                                        <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                                                                        {(files.length) ? <span style={{ color: "#00D56E", display: 'flex' }}>
                                                                                            {files}
                                                                                        </span>
                                                                                            : <div>
                                                                                                <span style={{ fontSize: '14px' }}>+ Add Image</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                    <div className="col-2 p-l-0">
                                                                        <div className={"v-h-center image-field-action" + classImageUpload}>
                                                                            {files.length ?
                                                                                <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = []; this.onDrop([]) }}>clear</i>
                                                                                : <img src={camicon} style={{ width: '21px' }} onClick={this.takePicture}></img>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            }
                                                            else if (property.propertyName === 'serial_id') {
                                                                return <div className='row m-t-10'>
                                                                    <div className='col-10 p-r-0'>
                                                                        <div className="form-label-group m-0">
                                                                            <input type="text" ref={(ref) => { this.apFocus[key] = ref; return true; }} maxLength={45} className="accesspointname font14" value={property.value} name={property.propertyName} id={property.propertyName} style={{ width: "100%", borderRadius: "6px 0px 0px 6px", borderRight: "none" }} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={property.propertyName}>{property.displayName} </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 p-l-0">
                                                                        <div className="v-h-center" style={{ height: "100%", borderRadius: "0px 6px 6px 0px", border: "0.5px solid #CED7DF", borderLeft: "none" }} onClick={() => { this.openScanner() }}>
                                                                            <img src={qricon} style={{ width: '25px' }} onClick={() => { this.openScanner() }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            else if (property.propertyName === 'vendor_type' && this.reduxStore['assetTypeId'] == 1) {
                                                                return <div className='row m-t-10'>
                                                                    <div className='col-12'>
                                                                        <div className="form-label-group m-0">
                                                                        <select className="form-control settingdropdown" id={property.propertyName}
                                                                            style={{ border: '1px solid #CED7DF', borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }} 
                                                                            name={property.propertyName} value={this.state.vendor_type} onChange={this.handleChange} disabled={true}>
                                                                                {property.validValues.map((value: any) => this.state.vendor_type == value?<option value={value} selected >{value}</option>:<option value={value} >{value}</option>)}
                                                                        </select>                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        }
                                                    })
                                                }
                                                {
                                                    this.load_sector()
                                                }



                                                <div className='row m-t-10'>
                                                    <div className='col-12'>
                                                        <button className='lightbtnmob p-t-5' onClick={this.submit_parameters}>Save Changes</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* </IonContent>
                        </IonPage> */}
                    </div>
                }
                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />

                <IonLoading
                    isOpen={this.state.is_loader}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                // duration={5000}
                />
            </div>
        );
    }
}

export default (withIonLifeCycle(AddAP));
// export default withIonLifeCycle(AddAP)
