import React, { Component } from 'react';
import sort from '../assets/img/checked.png'
import Modal from './modal'
import { requestAPICall } from "../service/apiHandler";
import Dropzone from 'react-dropzone';
import { IonTextarea, IonAlert, IonLoading, IonRow } from '@ionic/react';
import XLSX from 'xlsx';

const INITIAL_STATE = {
    card: '',
    show: false,
    status_show: false,
    path: "",
    status_list: []
};
var cbsd_list: any = []
var installation_list: any = []
var optional_list: any = []
var asset_property_list: any = []
var global_list: any = []
var sector_list: any = []
var others_list: any = []
var unit: any = ""
var asset_list: any = []
var status_list: any = []
var bind_list: any = []
var request_list: any = []
var asset_status_list: any = []
var select_vendor = ""

export default class SitedataDetails extends Component {

    props: any = {};
    state: any = {};
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE }
        this.state = {
            card1: false,
            show: false,
            files: [],
            list_asset: [],
            sector_details: [],
            status: "",
            asset_type_id: 1,
            asset_id: 0,
            sector_id: 0,
            asset_name: "",
            serial_id: 0,
            bind_list: [],
            showbutton: false,
            is_loader: false,
            alertbox: false,
            selectedSector: 0,
            assetType: 'AP-outdoor',
            sheet_name: "Outdoor_CBSD",
            allAssets: [],
            assetStatus: "",
            asset_property_list: [],
            sector_property_list: [],
            asset_cpe_List: [],
            installationList: [],
            assetVendorList: [],
            assetModelList: [],
            select_asset_type_id: 1,
            sectorClickId: "",
            selected_vendor_id: "",
            sector_id_list: [],
            selected_vendor_name: "",
            selected_model_name: "",
            all_vendor_list: [],
            delete_asset_id: "",
            delete_sector_id: "",
            selected_asset_id: 0,
            delete_type: "asset",
            selected_data: "",
            show_asset_details: false,
            show_sector_details: false,
            asset_list: [],
            antenna_files: [],
            height_image_files: [],
            azimuth_image_files: [],
            down_tilt_image_files: [],
            image_value: "",
            selected_image_type: "",
            check_arrow: false,
            sector_check_arrow: false
        }
        this.import_files = this.import_files.bind(this)
        this.list_asset = this.list_asset.bind(this)
        this.onChange = this.onChange.bind(this)
        this.update_asset = this.update_asset.bind(this)
    }
    componentDidMount() {
        this.list_asset()
        this.list_asset_status()

    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }
    setShowLoading = (st: boolean) => {
        this.setState({ is_loader: st })
    }
    setAssetId(assetType: any) {
        if (assetType === "AP-outdoor") {
            this.setState({
                sheet_name: "Outdoor_CBSD",
                assetType: assetType,
                asset_type_id: 1,
                select_asset_type_id: 1,
                show_asset_details: false,
                show_sector_details: false,
                check_arrow: false,
                sector_check_arrow: false
            })
        }
        else if (assetType === "AP-indoor") {
            this.setState({
                sheet_name: "Indoor_CBSD",
                assetType: assetType,
                asset_type_id: 2,
                select_asset_type_id: 2,
                show_asset_details: false,
                show_sector_details: false,
                check_arrow: false,
                sector_check_arrow: false
            })
        }
        else if (assetType === "CPE") {
            this.setState({
                sheet_name: "CPE",
                assetType: assetType,
                asset_type_id: 3,
                select_asset_type_id: 3,
                show_asset_details: false,
                show_sector_details: false,
                check_arrow: false,
                sector_check_arrow: false
            })
        }
        this.state.asset_list = []
        let asset_list: any = []
        this.state.allAssets.forEach((asset: any) => {
            if (asset['assetType'] === assetType) {
                asset_list.push(asset)
            } else if (asset['assetType'].split("-")[0] === assetType) {
                asset_list.push(asset)
            }
        });
        this.setState({
            asset_list: asset_list
        })
    }

    hideModal1 = () => {
        this.setState({
            card1: false
        })
    }
    listVendorAPI() {
        requestAPICall("list_particular_vendor", {
            asset_type_id: this.state.select_asset_type_id
        }, {}).then(data => {
            if (data['vendor_list'] !== undefined && data['vendor_list'].length !== 0) {
                this.setState({
                    assetVendorList: data['vendor'],
                    selected_vendor_name: data['vendor'][0],
                    all_vendor_list: data['vendor_list']
                })
                if (this.state.select_asset_type_id !== 1) {
                    var response_list = cbsd_list.filter((cbsd: any) => cbsd.propertyName === "cbsd_vendor_model")
                    if (response_list[0]['value'] !== undefined) {
                        let value = response_list[0]['value'].split('-')
                        let vendor = data['vendor_list'].filter((vendor: any) => vendor.venor_name === value[0])
                        this.setState({
                            assetModelList: vendor,
                            selected_vendor_name: value[0],
                            selected_model_name: value[1]

                        })
                    }
                    else {
                        let vendor = data['vendor_list'].filter((vendor: any) => vendor.venor_name === data['vendor'][0])
                        this.setState({
                            assetModelList: vendor
                        })
                    }
                } else {
                    let vendor = data['vendor_list'].filter((vendor: any) => vendor.venor_name === data['vendor'][0])
                    this.setState({
                        assetModelList: vendor
                    })
                }
            }
        })
    }
    list_asset = () => {
        this.state.asset_list = []
        let requestBody = {}
        requestAPICall("list_asset", {}, requestBody).then(data => {
            asset_list = []
            this.setState({
                allAssets: data['assets']
            })
            this.setState({ allAssets: data['assets'], is_loader: 0 }, () => this.setAssetId(this.state.assetType))
        })
    }
    load_property = (property: any) => {
        property.forEach((asset: any) => {
            if (asset['assetType'] === this.state.assetType) {
                this.state.asset_list.push(asset)
            } else if (asset['assetType'].split("-")[0] === this.state.assetType) {
                console.log("ppppppppppppp")
                this.state.asset_list.push(asset)
            }
        });
    }
    list_asset_status() {
        let requestBody = {}
        requestAPICall("list_asset_status", {}, requestBody).then(data => {
            asset_status_list = data["assets"]
        })
    }
    showModal = (e: any) => {
        e.preventDefault();
        this.setState({
            card: e.target.id,
            show: true
        })
    }

    hideModal = () => {
        this.setState({
            card: '',
            files: [],
            show: false
        })
    }
    status_hideModal = () => {
        this.setState({
            status_show: false
        })
    }
    onDrop = (files: any, type: any) => {
        if (type === "antenna_azimuth") {
            this.setState({
                azimuth_image_files: files
            }, () => this.uploadFile(this.state.azimuth_image_files, type))
        } else if (type === "height") {
            this.setState({
                height_image_files: files
            }, () => this.uploadFile(this.state.height_image_files, type))
        } else if (type === "down_tilt") {
            this.setState({
                down_tilt_image_files: files
            }, () => this.uploadFile(this.state.down_tilt_image_files, type))
        } else {
            this.setState({ files: files })
        }
    };
    uploadFile = (files: any, value: any) => {
        let filerequestBody = {}
        var picReader = new FileReader();
        let fileName = files[0].name
        let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
        if (files[0].uploadType === undefined) {
            picReader.onload = (e) => {
                filerequestBody = {
                    'fileName': fileName,
                    'fileExtension': fileExt,
                    'fileValue': picReader.result
                }
                this.setState({
                    isLoading: true,
                    image_value: picReader.result
                })
                this.uploadImage(filerequestBody, value)
            }
            picReader.readAsDataURL(files[0]);
        }
    }
    uploadImage = (filerequestBody: any, value: any) => {
        requestAPICall("upload_file", {}, filerequestBody).then(data => {
            if (data['responseCode'] == 200) {
                if (value == "height") {
                    installation_list.map((installation: any) => {
                        if (installation.propertyName === "height_image") {
                            installation.value = data.file_id
                        }
                    })
                    this.state.sector_property_list.map((sector: any) => {
                        if (sector.sectorId_id === this.state.selectedSector) {
                            sector.height_image = data.file_id
                        }
                    })
                } else if (value === "antenna_azimuth") {
                    installation_list.map((installation: any) => {
                        if (installation.propertyName === "azimuth_image") {
                            installation.value = data.file_id
                        }
                    })
                    this.state.sector_property_list.map((sector: any) => {
                        if (sector.sectorId_id === this.state.selectedSector) {
                            sector.azimuth_image = data.file_id
                        }
                    })
                } else if (value === "down_tilt") {
                    installation_list.map((installation: any) => {
                        if (installation.propertyName === "down_tilt_image") {
                            installation.value = data.file_id
                        }
                    })
                    this.state.sector_property_list.map((sector: any) => {
                        if (sector.sectorId_id === this.state.selectedSector) {
                            sector.down_tilt_image = data.file_id
                        }
                    })
                }

                this.state.sector_property_list.map((sector: any) => {
                    if (sector.sectorId_id === this.state.selectedSector) {
                        sector.down_tilt_image = data.file_id
                    }
                })
                this.updateSectorDetails()

                this.setState({ isLoading: false }, () => {
                    this.setState({
                        // warnMessage: "Image uploaded successfully",
                        // alertbox: true,
                        image_show: false
                    })
                })

            } else {
                this.setState({ isLoading: false })
                this.setState({ alertbox: true, warnMessage: data.message })
            }
        })
    }

    import_files() {
        let url = ""
        if (this.state.assetType === "AP-outdoor") {
            url = "import_site_data_asset"
        } else if (this.state.assetType === "CPE") {
            url = "import_site_data_cpe"
        } else if (this.state.assetType === "AP-indoor") {
            url = "import_site_data_indoor"
        }
        this.setState({
            is_loader: 1
        })
        status_list = []
        var picReader = new FileReader();
        let filerequestBody = {}
        let fileName = this.state.files[0].name
        let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
        picReader.onload = (e) => {
            filerequestBody = {
                'fileName': fileName,
                'fileExtension': fileExt,
                'fileValue': picReader.result
            }
            requestAPICall("upload_file", {}, filerequestBody).then(data => {
                let requestBody = {
                    "file_path_id": data.file_id,
                    "file_path": data.file_path
                }
                requestAPICall(url, {}, requestBody).then(data => {
                    status_list = data.asset_status
                    if (data.responseCode == 200) {
                        this.list_asset()
                        this.setState({
                            show: false,
                            status_show: true,
                            is_loader: 2
                        })
                    } else {
                        status_list = []
                        this.setState({
                            show: false,
                            status_show: false,
                            warnMessage: data.message,
                            alertbox: true,
                        })
                    }

                })
            })
        }
        picReader.readAsDataURL(this.state.files[0]);
        this.hideModal()
    }
    download_site_data = () => {
        let requestBody = {
            "sheet_name": this.state.sheet_name
        }
        requestAPICall("download_files", {}, requestBody).then(data => {
            var worksheet = XLSX.utils.aoa_to_sheet(data['file_content']);
            var new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, this.state.sheet_name);
            let requestBody = {
                "sheet_name": "Outdoor_Site_Sector"
            }
            if (this.state.assetType === "AP-outdoor") {
                requestAPICall("download_files", {}, requestBody).then(data => {
                    var worksheet1 = XLSX.utils.aoa_to_sheet(data['file_content']);
                    XLSX.utils.book_append_sheet(new_workbook, worksheet1, "Outdoor_Site_Sector");
                    XLSX.writeFile(new_workbook, this.state.sheet_name + ".xlsx")
                })
            } else {
                XLSX.writeFile(new_workbook, this.state.sheet_name + ".xlsx")
            }
        })
    }
    exportFile = (asset_id: number, type: any) => {
        let requestBody = {
            "asset_id": asset_id,
            "export_type": type,
            "asset_type_id": this.state.select_asset_type_id
        }
        requestAPICall("export_files", {}, requestBody).then(data => {
            if (this.state.select_asset_type_id === 1) {
                var worksheet = XLSX.utils.aoa_to_sheet(data['assets']);
                var new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, this.state.sheet_name);
                var worksheet1 = XLSX.utils.aoa_to_sheet(data['sector']);
                XLSX.utils.book_append_sheet(new_workbook, worksheet1, "Outdoor_site_sector");
                XLSX.writeFile(new_workbook, this.state.sheet_name + ".xlsx")
            } else {
                var worksheet = XLSX.utils.aoa_to_sheet(data['assets']);
                var new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, this.state.sheet_name);
                XLSX.writeFile(new_workbook, this.state.sheet_name + ".xlsx")
            }
        })
    }

    getSector = (val: any, assetClickId: any) => {

        this.setState({
            asset_id: val.assetId,
            select_asset_type_id: val.assetTypeId,
            assetClickId: assetClickId,
            check_arrow: true
        }, () => this.get_sector_details(val.assetId))
    }
    get_sector_details = (asset_id: number) => {
        bind_list = []
        let requestBody = {}
        installation_list = []
        optional_list = []
        cbsd_list = []
        sector_list = []
        asset_property_list = []
        if (this.state.select_asset_type_id === 3 || this.state.select_asset_type_id === 2) {
            requestAPICall("get_asset_details", { asset_id: asset_id }, requestBody).then(data => {
                if (data.responseCode == 200) {
                    request_list = data['properties']

                    sector_list = data['sector_id']
                    this.setState({
                        asset_type_id: data['assetTypeId'],
                        asset_id: data['assetId'],
                        asset_name: data['assetName'],
                        serial_id: data['serialId'],
                        assetStatus: data['operationalStatus']
                    }, () => {
                        this.getAssetEditableDetails()
                    })
                    if (data['assetTypeId'] === 3 || data['assetTypeId'] === 2 || data['assetTypeId'] === 4 || data['assetTypeId'] === 5) {
                        var response_list = data["installation_params"]["installation"].filter((installation: any) => installation.propertyName !== "antenna_pattern")
                        installation_list = data["installation_params"]["installation"]
                        optional_list = data["installation_params"]["optional"]
                        cbsd_list = data["installation_params"]["cbsd"]
                        global_list = data["installation_params"]["global"]
                        others_list = data["installation_params"]["others"]

                        this.setState({
                            cpe_list: data['properties'],
                        }, () => {
                            this.listVendorAPI()
                            if (this.state.select_asset_type_id === 3) {
                                this.set_dropdown_label("heightypeid2", "HEIGHT_TYPE_AGL")
                                this.set_dropdown_label("indoordep2", "True")
                                this.set_dropdown_label("optcalsign2", "1")
                                this.set_dropdown_label("optcbsdcat2", "DEVICE_CATEGORY_A")
                                this.set_dropdown_label("optairinter2", "RADIO_TECHNOLOGY_UNSPECIFIED")
                                this.set_dropdown_label("measurecapab2", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT")
                                this.set_dropdown_label("cbsdvendor2", this.state.assetVendorList[0])
                                this.set_dropdown_label("cbsdmodel2", this.state.assetModelList[0])
                            } else {
                                this.set_dropdown_label("heightypeid3", "HEIGHT_TYPE_AGL")
                                this.set_dropdown_label("indoordep3", "True")
                                this.set_dropdown_label("optcalsign3", "1")
                                this.set_dropdown_label("optcbsdcat3", "DEVICE_CATEGORY_A")
                                this.set_dropdown_label("optairinter3", "RADIO_TECHNOLOGY_UNSPECIFIED")
                                this.set_dropdown_label("measurecapab3", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT")
                                this.set_dropdown_label("cbsd_vendor3", this.state.assetVendorList[0])
                                this.set_dropdown_label("cbsd_model3", this.state.assetModelList[0])

                            }
                        }
                        )
                    }
                    else {

                        this.setState({
                            sector_id: data['sector_id'],
                        }, () => this.listVendorAPI())
                    }

                }
            })
        } else {
            this.get_sector_properties(asset_id)
            this.listVendorAPI()
        }


    }
    get_sector_properties = (assetId: number) => {
        requestAPICall("get_all_sector_details", { asset_type_id: this.state.select_asset_type_id, asset_id: assetId }, {}).then(data => {
            console.log(data)
            if (data.responseCode === 200) {
                bind_list = data['properties']
                this.setState({
                    sector_property_list: data['properties']
                })
            }
        })
    }
    getAssetEditableDetails = () => {
        requestAPICall("get_asset_editable_data", { asset_type: this.state.select_asset_type_id, asset_id: this.state.asset_id }, {}).then(data => {
            console.log(data)
            if (data.responseCode === 200) {
                asset_property_list = data['properties']
                this.setState({
                    asset_property_list: data['properties']
                })
            }
        })
    }
    getSectorDetails = (sector_id: any, sectorClickId: any) => {
        this.setState({
            selectedSector: sector_id,
            sectorClickId: sectorClickId,
            sector_check_arrow: true,
            sector_id: sector_id
        })
        requestAPICall("get_sector_details", { asset_type_id: this.state.select_asset_type_id, asset_id: this.state.asset_id, sector_id: sector_id }, {}).then(data => {
            if (data.responseCode === 200) {
                installation_list = data["properties"]["installation"]
                optional_list = data["properties"]["optional"]
                cbsd_list = data["properties"]["cbsd"]
                global_list = data["properties"]["global"]
                others_list = data["properties"]["others"]
                var response_list = cbsd_list.filter((cbsd: any) => cbsd.propertyName === "cbsd_vendor_model")
                if (response_list[0]['value'] !== undefined) {
                    let value = response_list[0]['value'].split('-')
                    let vendor = this.state.all_vendor_list.filter((vendor: any) => vendor.venor_name === value[0])
                    this.setState({
                        assetModelList: vendor
                    })
                    this.setState({
                        selected_vendor_name: value[0],
                        selected_model_name: value[1]
                    })
                }
                this.set_dropdown_label("heightypeid1", "HEIGHT_TYPE_AGL")
                this.set_dropdown_label("indoordep1", "True")
                this.set_dropdown_label("optcalsign1", "1")
                this.set_dropdown_label("optcbsdcat1", "DEVICE_CATEGORY_A")
                this.set_dropdown_label("optairinter1", "RADIO_TECHNOLOGY_UNSPECIFIED")
                this.set_dropdown_label("measurecapab1", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT")
                this.set_dropdown_label("cbsdvendor1", this.state.assetVendorList[0])
                this.set_dropdown_label("cbsdmodel1", this.state.assetModelList[0])
                this.setState({
                    showvalue: true
                })
            }
        })
    }
    onChange(e: any, asset: any) {
        this.setState({
            [e.target.name]: e.target.value,
            status: e.target.value,
        })
        this.state.list_asset.map((asset: any) => {
            let val = parseInt(e.target.name)
            if (asset.assetId === val) {
                asset.operationalStatus = e.target.value
            }
        })
        if (asset === 'installation') {
            installation_list.map((property: any) => {
                if (property.propertyName === e.target.name) {
                    property.value = e.target.value
                }
            })
        } else if (asset === 'cbsd') {
            cbsd_list.map((property: any) => {
                if (property.propertyName === e.target.name) {
                    property.value = e.target.value
                }
            })
        } else if (asset === 'optional') {
            optional_list.map((property: any) => {
                if (property.propertyName === e.target.name) {
                    property.value = e.target.value
                }
            })
        } else if (asset == 'global') {
            global_list.forEach((property: any) => {
                if (property.propertyName == e.target.name) {
                    property.value = e.target.value
                }
            });
        } else if (asset == 'asset') {
            asset_property_list.forEach((property: any) => {
                if (property.propertyName == e.target.name) {
                    property.value = e.target.value
                }
            });
        } else if (asset === 'others') {
            others_list.map((property: any) => {
                if (property.propertyName === e.target.name) {
                    property.value = e.target.value
                }
            })
        }
        else if (asset === 'sector') {
            this.state.sector_property_list.map((sector: any) => {
                if (sector.sectorId_id === this.state.sector_id) {
                    if (e.target.name === "cell_id") {
                        sector.cell_id = e.target.value
                    } else if (e.target.name === "pci") {
                        sector.pci = e.target.value
                    } else if (e.target.name === "antenna_height") {
                        sector.antenna_height = e.target.value
                    } else if (e.target.name === "frequence_band") {
                        sector.frequence_band = e.target.value
                    } else if (e.target.name === "fcc_id") {
                        sector.fcc_id = e.target.value
                    }
                }
            })
        }
        this.state.asset_list.map((property: any) => {
            if (property.assetId === this.state.asset_id) {
                if (e.target.name === "serial_id") {
                    property.serial_id = e.target.value
                } else if (e.target.name === "cluster_name") {
                    property.cluster_name = e.target.value
                } else if (e.target.name === "deployment_type") {
                    property.deployment_type = e.target.value
                } else if (e.target.name === "latitude") {
                    property.latitude = e.target.value
                } else if (e.target.name === "longitude") {
                    property.longitude = e.target.value
                } else if (e.target.name === "customer_id") {
                    property.customer_id = e.target.value
                } else if (e.target.name === "enb_id") {
                    property.enb_id = e.target.value
                } else if (e.target.name === "ap_name") {
                    property.ap_name = e.target.value
                } else if (e.target.name === "structure_height") {
                    property.structure_height = e.target.value
                } else if (e.target.name === "cpe_name") {
                    property.cpe_name = e.target.value
                }
            }
        })
    }

    updateSectorDetails = () => {
        let sector_list = this.state.sector_property_list.filter((sector: any) => sector.sectorId_id === this.state.sector_id)
        var requestBody = {
            properties: {
                "cbsd": cbsd_list,
                "optional": optional_list,
                "installtion": installation_list,
                "global": global_list,
                "others": others_list,
            },
            property: sector_list[0],
            vendorId: this.state.selected_vendor_id
        }
        var url = ""
        if (this.state.select_asset_type_id === 1) {
            url = "update_sector_details"
        } else {
            url = "update_asset_install_details"
        }
        requestAPICall(url, { asset_type_id: this.state.select_asset_type_id, asset_id: this.state.asset_id, sector_id: this.state.sector_id }, requestBody).then(data => {
            if (data.responseCode == 200) {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true,
                })
            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true,
                })
            }
        })
        this.list_asset()
    }

    select_onChange(e: any, type: any, property: any, id: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.set_dropdown_label(id, e.target.value)
        if (type === 'cbsd') {
            cbsd_list.map((cbsd: any) => {
                if (cbsd.propertyName === property) {
                    cbsd.value = e.target.value
                }
            })
        } else if (type === 'optional') {
            optional_list.map((optional: any) => {
                if (optional.propertyName === property) {
                    optional.value = e.target.value
                }
            })
        }
    }

    updateAssetProperties = () => {
        var requestBody = {
            properties: asset_property_list,
            sector_id: sector_list,
            assetName: this.state.asset_name,
            status: this.state.assetStatus
        }
        requestAPICall("edit_asset_details", { asset_type_id: this.state.select_asset_type_id, asset_id: this.state.asset_id }, requestBody).then(data => {
            this.setState({
            })
            if (data.responseCode == 200) {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true,
                    show_asset_details: false
                })
                if (this.state.asset_type_id === 3) {
                    this.updateSectorDetails()
                }
                this.setState({
                    warnMessage: data.message,
                    alertbox: true,
                    card1: false,
                    show_asset_details: false
                })
                this.get_sector_details(this.state.asset_id)
            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }
        })
    }

    update_asset(e: any, asset: any) {
        var assetId = asset.assetId
        let requestBody = {}
        var check = false
        requestAPICall("get_asset_details", { asset_id: assetId }, requestBody)
            .then(data => {
                request_list = data['properties']
                let new_request_list
                try {
                    new_request_list = request_list.filter((property: any) => property.propertyName !== 'image')
                } catch (error) {
                }
                let sector_id_list: any = []
                if (data['assetTypeId'] === 1) {
                    sector_id_list = data['sector_id'].filter((sector_prop: any) => sector_prop.sector_id !== this.state.delete_sector_id)
                    if (sector_id_list.length < 1) {
                        check = false
                    } else {
                        check = true
                    }
                } else {
                    check = true
                }
                let requestBody = {
                    "assetName": data["assetName"],
                    "assetTypeId": data['assetTypeId'],
                    "properties": new_request_list,
                    "status": this.state.assetStatus,
                    "sector_id": sector_id_list
                }
                if (check === true) {
                    requestAPICall("edit_asset_details", { asset_type_id: data['assetTypeId'], asset_id: assetId }, requestBody).then(data => {
                        this.setState({
                        })
                        if (data.responseCode == 200) {
                            if (data['assetTypeId'] === 3) {
                                this.updateSectorDetails()
                            }
                            this.setState({
                                warnMessage: data.message,
                                alertbox: true,
                                card1: false,
                                show_asset_details: false
                            })
                            this.get_sector_details(assetId)
                        }
                        else {
                            this.setState({
                                warnMessage: data.message,
                                alertbox: true
                            })
                        }
                    })
                } else {
                    this.setState({
                        warnMessage: "Asset should be mapped with one sector",
                        alertbox: true
                    })
                }
            })
    }

    setAssetVendor = (e: any, vendor: any, id: any) => {
        try {
            select_vendor = e.target.value
            this.setState({
                [e.target.name]: e.target.value
            })
            this.set_dropdown_label(id, e.target.value)
            let model = this.state.all_vendor_list.filter((vendor: any) => vendor.venor_name === e.target.value)
            this.setState({
                assetModelList: model,
                selected_vendor_name: e.target.value
            })
        } catch (error) {
        }
    }

    setAssetModel(e: any, id: any) {
        try {
            this.setState({
                [e.target.name]: e.target.value,
                selected_model_name: e.target.value
            })
            this.set_dropdown_label(id, e.target.value)
            let vendor_model = this.state.selected_vendor_name + '-' + e.target.value
            cbsd_list.map((cbsd: any) => {
                if (cbsd.propertyName === "cbsd_vendor_model") {
                    cbsd.value = vendor_model
                }
            })
        } catch (error) {

        }
    }

    setAssetStatus(e: any, asset: any) {
        this.setState({
            assetStatus: e.target.value
        })
        this.state.asset_list.map((property: any) => {
            if (property.assetId === asset.assetId) {
                property.operationalStatus = e.target.value
            }
        })
    }

    height_type_change = (e: any, id: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.set_dropdown_label(id, e.target.value)
        installation_list.map((property: any) => {
            if (property.propertyName === "height_type") {
                property.value = e.target.value
            }
        })
    }
    set_dropdown_label(id: any, value: any) {
        console.log(id, value)
        if (value === "") {
            $("#" + id).css({
                "top": "14px",
                "fontSize": "14px"
            })
            $("#" + id).show()
        } else {
            $("#" + id).css(
                {
                    "top": "5px",
                    "fontSize": "12px"
                }
            )
            $("#" + id).show()
        }
    }

    measurement_capability(e: any, id: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.set_dropdown_label(id, e.target.value)
        if (e.target.value) {
            installation_list.map((installation: any) => {
                if (installation.propertyName === "measurement_capability") {
                    installation.value = e.target.value
                }
            })
        }
    }

    cbsd_category(e: any, id: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.set_dropdown_label(id, e.target.value)
        optional_list.map((optional: any) => {
            if (optional.propertyName === "cbsd_category") {
                optional.value = e.target.value
            }
        })
    }

    air_interface(e: any, id: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.set_dropdown_label(id, e.target.value)
        optional_list.map((optional: any) => {
            if (optional.propertyName === "air_interface") {
                optional.value = e.target.value
            }
        })
    }

    indoor_deployment(e: any, id: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.set_dropdown_label(id, e.target.value)
        installation_list.map((installation: any) => {
            if (installation.propertyName === "indoor_deployment") {
                installation.value = e.target.value
            }
        })
    }

    delete_asset() {
        let requestbody = {}
        requestAPICall("delete_asset", { asset_id: this.state.delete_asset_id }, requestbody).then(data => {
            this.setShowLoading(false)
            if (data.responseCode == 200) {
                this.setState({
                    card1: false,
                    warnMessage: data.message,
                    alertbox: true
                })
                this.list_asset()
            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }
        })
    }
    showModal1 = (asset: any, sector: any) => {
        if (sector !== "") {
            this.setState({
                card1: true,
                delete_asset_id: asset.assetId,
                delete_sector_id: sector.sectorId_id,
                delete_type: "sector",
                selected_data: asset

            })
        } else if (asset == "image") {
            this.setState({
                card1: true,
                delete_type: "images",
            })
        } else {
            this.setState({
                card1: true,
                delete_asset_id: asset.assetId,
                delete_type: "asset",
                selected_data: asset
            })
        }
    }

    edit_asset_value = (asset: any) => {
        this.setState({
            show_asset_details: true,
            asset_id: asset.assetId,
            selected_asset_id: asset.assetId
        }, () => this.getAssetEditableDetails())
    }

    edit_sector_value = (sector: any, asset: any) => {
        this.setState({
            sector_id: sector.sectorId_id,
            asset_id: asset.assetId,
            show_sector_details: true,
            selected_asset_id: asset.assetId
        })
    }

    sortByPriceAsc = (key: any) => {
        this.setState({
            show_sector_details: false
        })
        let sortedProductsAsc;
        sortedProductsAsc = this.state.asset_list.sort((a: any, b: any) => {
            return parseInt(a.key) - parseInt(b.key);
        })
        let asset_list = this.asecnding(key)
        this.setState({
            asset_list: sortedProductsAsc
        })
    }
    sortByPriceDesc = (key: any) => {
        this.setState({
            show_sector_details: false
        })
        let sortedProductsDesc;
        sortedProductsDesc = this.state.asset_list.sort((a: any, b: any) => {
            return parseInt(a.key) - parseInt(b.key);
        })
        this.setState({
            asset_list: sortedProductsDesc
        })
        let list = this.decending(key)
    }

    asecnding = (key: any) => {
        return this.state.asset_list.sort(function (a: any, b: any) {
            if (a[key] > b[key]) {
                return 1;
            }
            if (b[key] > a[key]) {
                return -1;
            }
            return 0;
        })
    }
    decending = (key: any) => {
        return this.state.asset_list.sort(function (a: any, b: any) {
            if (b[key] > a[key]) {
                return 1;
            }
            if (a[key] > b[key]) {
                return -1;
            }
            return 0;
        })
    }
    showImageModel = (image: any, type: any) => {
        if (image !== "" && image !== undefined) {
            this.setState({
                image_show: true,
                image_value: image,
                selected_image_type: type
            })
        } else {
            this.setState({
                image_show: true,
                selected_image_type: type,
                image_value: ""
            })
        }

    }
    imageUploadModel = () => {
        console.log(this.state.selected_image_type)
        if (this.state.selected_image_type === "down_tilt") {
            $('#down_tilt_file').click();
        } else if (this.state.selected_image_type === "height") {
            $('#height_image_file').click();
        } else if (this.state.selected_image_type === "antenna_azimuth") {
            $('#azimuth_image_file').click();
        }
    }

    delete_image = () => {
        if (this.state.selected_image_type === "down_tilt") {
            installation_list.map((property: any) => {
                if (property.propertyName === "down_tilt_image") {
                    property.value = ""
                    this.state.sector_property_list.map((sector: any) => {
                        if (sector.sectorId_id === this.state.selectedSector) {
                            sector.down_tilt_image = ""
                        }
                    })
                } else if (property.propertyName === "down_tilt") {
                    property.image_value = ""
                }
            })
        } else if (this.state.selected_image_type === "height") {
            installation_list.map((property: any) => {
                if (property.propertyName === "height_image") {
                    property.value = ""
                    this.state.sector_property_list.map((sector: any) => {
                        if (sector.sectorId_id === this.state.selectedSector) {
                            sector.height_image = ""
                        }
                    })
                } else if (property.propertyName === "height") {
                    property.image_value = ""
                }
            })
        } else if (this.state.selected_image_type === "antenna_azimuth") {
            installation_list.map((property: any) => {
                if (property.propertyName === "azimuth_image") {
                    property.value = ""
                    this.state.sector_property_list.map((sector: any) => {
                        if (sector.sectorId_id === this.state.selectedSector) {
                            sector.azimuth_image = ""
                        }
                    })
                } else if (property.propertyName === "antenna_azimuth") {
                    property.image_value = ""
                }
            })
        }
        this.updateSectorDetails()
        this.setState({
            image_show: false,
            card1: false,
            // alertbox: true,
            // warnMessage: "Successfully deleted Image"
        })
    }
    hideImageModal = () => {
        this.setState({
            image_show: false
        })
    }
    hide_arrow = (type: any) => {
        if (type == "sector") {
            this.setState({
                sector_check_arrow: false
            })
        } else {
            this.setState({
                check_arrow: false
            })
        }
    }

    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'importdropzuploaded' : 'importdropz'
        return (
            <div className="card importcard">
                <div className="card-body">
                    <div className="row" style={{ padding: '0px 10px 0px 5px' }}>
                        <div className="col-md-6 p-l-0" style={{ top: '-10px' }}>
                            <ul className="nav nav-tabs sectab float-left" id="siteDataTab" role="tablist" style={{ top: '-10px' }}>
                                <li className="nav-item" onClick={() => { this.setState({ assetType: "AP-outdoor" }); this.setAssetId("AP-outdoor"); }}>
                                    <a className="nav-link active p-l-0 font12" style={{ padding: '.5rem 1rem', fontSize: '12px' }} id="outdoor-ap" data-toggle="tab" href="#outdoor-ap-details" role="tab" aria-controls="outdoor-ap-details"
                                        aria-selected="true">Outdoor CBSD</a>
                                </li>

                                <li className="nav-item" onClick={() => { this.setState({ assetType: "CPE" }); this.setAssetId("CPE") }}>
                                    <a className="nav-link" id="asset-cpe font12" style={{ padding: '.5rem 1rem', fontSize: '12px' }} data-toggle="tab" href="#cpe-details" role="tab" aria-controls="cpe-details"
                                        aria-selected="false">CPE</a>
                                </li>
                                <li className="nav-item" >
                                    <a className="nav-link" onClick={() => { this.setState({ assetType: "AP-indoor" }); this.setAssetId("AP-indoor") }} id="asset-indoor font12" style={{ padding: '.5rem 1rem', fontSize: '12px' }} data-toggle="tab" href="#indoor-details" role="tab" aria-controls="indoor-details"
                                        aria-selected="false">Indoor CBSD</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <i className="material-icons" style={{ color: '#0073FF', cursor: 'pointer' }}>get_app</i>
                            <span className="p-l-5 m-r-30 font13" style={{ color: '#0073FF', cursor: 'pointer', paddingTop: '2px' }} onClick={this.download_site_data}>Download Sample File</span>
                            <i className="material-icons p-l-5" style={{ color: '#0073FF', transform: 'rotate(180deg)', cursor: 'pointer', marginBottom: '12px' }}>get_app</i>
                            <span className="font13 m-r-30" style={{ color: '#0073FF', paddingTop: '2px', cursor: 'pointer' }} id='heat' onClick={this.showModal}>Import File</span>
                            <span className="material-icons  p-l-5" style={{ color: '#0073FF', cursor: 'pointer', marginBottom: '12px' }}>cloud_upload</span>
                            <span className="font13 p-l-10" style={{ color: '#0073FF', paddingTop: '2px', cursor: 'pointer' }} onClick={(e) => this.exportFile(1, "all")} >Export Data</span>

                        </div>
                    </div>
                    <div className="tab-content" id="site-data-details">
                        <div className="tab-pane fade show active" id="outdoor-ap-details" role="tabpanel" aria-labelledby="outdoor-ap-details-tab">
                            <div id="demo" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row m-t-10" style={{ padding: '0px 10px' }}>
                                            <div className="col-md-12">
                                                <table className="table sitetable assetdata">
                                                    <thead className="sitehead" style={{ background: "none", display: 'table' }}>
                                                        <tr >
                                                            <th className="w14 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>Region/Market Name
                                                                <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("regionName")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("regionName")} />
                                                            </th>
                                                            <th className="w8 table-bg-hdr-color" >Site Id
                                                              <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("access_point_id")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("access_point_id")} />
                                                            </th>
                                                            <th className="w9 table-bg-hdr-color" >eNB/gNb Id
                                                                  <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("enb_id")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("enb_id")} />
                                                            </th>
                                                            <th className="w8 table-bg-hdr-color" >Site Name</th>
                                                            <th className="w9 table-bg-hdr-color" >Site Status</th>
                                                            <th className="w9 table-bg-hdr-color" >Structure Type  </th>
                                                            <th className="w7 table-bg-hdr-color" >Latitude </th>
                                                            <th className="w7 table-bg-hdr-color" >Longitude </th>
                                                            <th className="w12 table-bg-hdr-color" >Structure Height</th>
                                                            <th className="w10 table-bg-hdr-color" >Serial No </th>
                                                            <th className="w7 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ display: 'block', overflow: 'hidden scroll', maxHeight: 'calc(100vh - 310px)', width: 'calc(100% + 8px)' }}>
                                                        {this.state.asset_list.map((asset: any, index: any) => {
                                                            return <React.Fragment>
                                                                {this.state.show_asset_details === true && this.state.selected_asset_id === asset.assetId ?
                                                                    <tr className="panel-group">
                                                                        <td className="w14 ellipsitooltip" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}><img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} onClick={() => this.getSector(asset, "#_" + asset.assetId + index)} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                            {asset.regionName}</td>
                                                                        <td className="w8 ellipsitooltip" data-toggle="tooltip" title={asset.access_point_id}>{asset.access_point_id}</td>
                                                                        <td className="w9 ellipsitooltip">< input className="accesspointname" style={{ height: '38px' }} name="enb_id" value={asset.enb_id} onChange={(e) => { this.onChange(e, "asset") }} /></td>
                                                                        <td className="w8 ellipsitooltip">< input className="accesspointname" style={{ height: '38px' }} name="ap_name" value={asset.ap_name} onChange={(e) => { this.onChange(e, "asset") }} /></td>
                                                                        <td className="w9">
                                                                            <select className="form-control" value={asset.operationalStatus} onChange={(e) => this.setAssetStatus(e, asset)}>
                                                                                {asset_status_list.map((status: any) =>
                                                                                    <option value={status} >{status}</option>
                                                                                )}
                                                                            </select>
                                                                        </td>
                                                                        <td className="w9 ellipsitooltip">{asset.assetType == "AP-outdoor" ? "CBSD" : ""}</td>
                                                                        <td className="w7 ellipsitooltip">< input className="accesspointname" style={{ height: '38px' }} name="latitude" value={asset.latitude} onChange={(e) => { this.onChange(e, "asset") }} /></td>
                                                                        <td className="w7 ellipsitooltip">< input className="accesspointname" style={{ height: '38px' }} name="longitude" value={asset.longitude} onChange={(e) => { this.onChange(e, "asset") }} /></td>
                                                                        <td className="w12 ellipsitooltip">< input className="accesspointname" style={{ height: '38px' }} name="structure_height" value={asset.structure_height} onChange={(e) => { this.onChange(e, "asset") }} /></td>
                                                                        <td className="w10 ellipsitooltip">< input className="accesspointname" style={{ height: '38px' }} name="serial_id" value={asset.serial_id} onChange={(e) => { this.onChange(e, "asset") }} /></td>
                                                                        <td className="w7">
                                                                            <i className="far fa-check-circle p-r-15" style={{ color: '#0272fb', fontSize: '22px' }} title="Save" onClick={(e) => this.updateAssetProperties()}></i>
                                                                            <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, "")}></i>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    <tr className="panel-group" style={{ display: 'flex' }}>
                                                                        {
                                                                            this.state.assetClickId === "#_" + asset.assetId + index && this.state.check_arrow === true ? <td className="w14 networkellipsis" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                                <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer", transform: "rotate(180deg)" }} onClick={(e: any) => this.hide_arrow("asset")} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                                {asset.regionName}
                                                                            </td> : <td className="w14 networkellipsis" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                                    <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} onClick={() => this.getSector(asset, "#_" + asset.assetId + index)} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                                    {asset.regionName}
                                                                                </td>
                                                                        }
                                                                        <td className="w8 networkellipsis" data-toggle="tooltip" title={asset.access_point_id}>{asset.access_point_id}</td>
                                                                        <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.enb_id}>{asset.enb_id}</td>
                                                                        <td className="w8 networkellipsis" data-toggle="tooltip" title={asset.name}>{asset.ap_name}</td>
                                                                        {
                                                                            asset.operationalStatus === "Offline" ? <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "rgb(226, 43, 43)" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "rgb(226, 43, 43)" }}></span>{asset.operationalStatus}</td> :
                                                                                asset.operationalStatus === "In Design" ? <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "#00D56E" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#00D56E" }}></span>{asset.operationalStatus}</td> :
                                                                                    asset.operationalStatus === "On Air" ? <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "#FCD369" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#FCD369" }}></span>{asset.operationalStatus}</td> :
                                                                                        asset.operationalStatus === "In Construction" ? <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "#0059B2" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#0059B2" }}></span>{asset.operationalStatus}</td> :
                                                                                            asset.operationalStatus === "In Zoning" ? <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "#0000A0" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#0000A0" }}></span>{asset.operationalStatus}</td> :
                                                                                                asset.operationalStatus === "Site Acquisition" ? <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "#8B4513" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#8B4513" }}></span>{asset.operationalStatus}</td> :
                                                                                                    <td className="w9 networkellipsis" data-toggle="tooltip" style={{ color: "rgb(226, 43, 43)" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "rgb(226, 43, 43)" }}></span>{asset.operationalStatus}</td>
                                                                        }
                                                                        <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.assetType == "AP-outdoor" ? "CBSD" : ""}>{asset.assetType == "AP-outdoor" ? "CBSD" : ""}</td>
                                                                        <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.latitude + "°"}>{asset.latitude}°</td>
                                                                        <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.longitude + "°"}>{asset.longitude}°</td>
                                                                        <td className="w12 networkellipsis" data-toggle="tooltip" title={asset.structure_height}>{asset.structure_height}</td>
                                                                        <td className="w10 networkellipsis" data-toggle="tooltip" title={asset.serial_id}>{asset.serial_id}</td>
                                                                        <td className="w7">
                                                                            <div className='col-3' >
                                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <i className="fas fa-ellipsis-v " style={{ color: '#B4B4B4', marginTop: '10px', height: "30px" }}></i>
                                                                                </a>
                                                                                <div className="dropdown-menu indoorfloor" style={{ backgroundColor: 'black', }} aria-labelledby="dropdownMenuLink">
                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} onClick={(e) => this.edit_asset_value(asset)}>Edit Asset</a>
                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} id='delete' onClick={(e) => this.showModal1(asset, "")} > Delete Asset</a>
                                                                                </div>
                                                                            </div>
                                                                            {/* <i className="fas fa-ellipsis-v float-right" style={{ color: '#B4B4B4' }} ></i> */}
                                                                            {/* <i className="fas fa-pen p-r-20" style={{ color: '#0272fb', fontSize: '16px' }} title="Edit" onClick={(e) => this.edit_asset_value(asset)} ></i>
                                                                            <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, "")}></i> */}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                <div id={"_" + asset.assetId + index} className="collapse">
                                                                    {this.state.assetClickId === "#_" + asset.assetId + index ?
                                                                        <div className="" style={{ padding: "0px 12px", background: "#f3f3f5", marginLeft: '20px' }}>

                                                                            <div className="" style={{ padding: "0px 12px", background: "#f3f3f5" }}>
                                                                                <table className="subtable" style={{ width: '100%' }}>
                                                                                    <thead>
                                                                                        <th className="w5 fontw500 b-t-0" style={{ paddingLeft: '0px' }}></th>
                                                                                        <th className="w10 fontw500 b-t-0" >sector_id</th>
                                                                                        <th className="w10 fontw500 b-t-0 " >Cell Id </th>
                                                                                        <th className="w10 fontw500 b-t-0 " >PCI </th>
                                                                                        <th className="w15 fontw500 b-t-0 " >Antenna Height </th>
                                                                                        <th className="w15 fontw500 b-t-0" >Frequency band</th>
                                                                                        <th className="w10 fontw500 b-t-0" >FCC ID </th>
                                                                                        <th className="w15 fontw500 b-t-0" >Serial Number </th>
                                                                                        <th className="w10 b-t-0" ></th>
                                                                                    </thead>
                                                                                    {this.state.sector_property_list.map((sector: any, index: any) => {
                                                                                        return <tbody>
                                                                                            {
                                                                                                this.state.show_sector_details === true && sector.sectorId_id === this.state.sector_id ?
                                                                                                    <tr className="panel-group" style={{ height: '60px' }} id={sector.sectorId_id}>
                                                                                                        <td className="w5" title="" style={{ paddingLeft: '0px' }}>
                                                                                                            <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} data-toggle="collapse" data-target={"#_" + asset.assetId + sector.sectorId_id + index} onClick={(e: any) => { this.getSectorDetails(sector.sectorId_id, "#_" + asset.assetId + sector.sectorId_id + index) }} />
                                                                                                        </td>
                                                                                                        <td className="w10">{sector.sector_id}</td>
                                                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '35px' }} name="cell_id" value={sector.cell_id} onChange={(e) => { this.onChange(e, "sector") }} /> </td>
                                                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '35px' }} name="pci" value={sector.pci} onChange={(e) => { this.onChange(e, "sector") }} /> </td>
                                                                                                        <td className="w15"> < input className="accesspointname" style={{ height: '35px' }} name="antenna_height" value={sector.antenna_height} onChange={(e) => { this.onChange(e, "sector") }} /> </td>
                                                                                                        <td className="w15"> < input className="accesspointname" style={{ height: '35px' }} name="frequence_band" value={sector.frequence_band} onChange={(e) => { this.onChange(e, "sector") }} /> </td>
                                                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '35px' }} name="fcc_id" value={sector.fcc_id} onChange={(e) => { this.onChange(e, "sector") }} /> </td>
                                                                                                        <td className="w15 ellipsitooltip" title={asset.serial_id}> {asset.serial_id}</td>
                                                                                                        <td className="w10 text-center">
                                                                                                            <i className="far fa-check-circle p-r-20" style={{ color: '#0272fb', fontSize: '22px' }} title="Save" onClick={this.updateSectorDetails}></i>
                                                                                                            <i className="fas fa-trash p-r-10" style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, sector)}></i>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr className="panel-group" style={{ height: '60px' }}>
                                                                                                        {
                                                                                                            this.state.sectorClickId === "#_" + asset.assetId + sector.sectorId_id + index && this.state.sector_check_arrow === true ?
                                                                                                                <td className="w5" title="" style={{ paddingLeft: '0px' }}>
                                                                                                                    <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer", transform: "rotate(180deg)" }} data-toggle="collapse" data-target={"#_" + asset.assetId + sector.sectorId_id + index} onClick={(e: any) => { this.hide_arrow("sector") }} />
                                                                                                                </td> :
                                                                                                                <td className="w5" title="" style={{ paddingLeft: '0px' }}>
                                                                                                                    <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} data-toggle="collapse" data-target={"#_" + asset.assetId + sector.sectorId_id + index} onClick={(e: any) => { this.getSectorDetails(sector.sectorId_id, "#_" + asset.assetId + sector.sectorId_id + index) }} />
                                                                                                                </td>
                                                                                                        }
                                                                                                        <td className="w10">{sector.sector_id}</td>
                                                                                                        <td className="w10 ellipsitooltip" title={sector.cell_id}>{sector.cell_id}</td>
                                                                                                        <td className="w10 ellipsitooltip" title={sector.pci}>{sector.pci}</td>
                                                                                                        <td className="w15">{sector.antenna_height}</td>
                                                                                                        <td className="w15">{sector.frequence_band}</td>
                                                                                                        <td className="w10 ellipsitooltip" title={sector.fcc_id}>{sector.fcc_id}</td>
                                                                                                        <td className="w15 ellipsitooltip" title={asset.serial_id}>{asset.serial_id}</td>
                                                                                                        <td className="w10 text-center">
                                                                                                            <div className='col-3' >
                                                                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                                    <i className="fas fa-ellipsis-v " style={{ color: '#B4B4B4', marginTop: '10px', height: "30px" }}></i>
                                                                                                                </a>
                                                                                                                <div className="dropdown-menu indoorfloor" style={{ backgroundColor: 'black', }} aria-labelledby="dropdownMenuLink">
                                                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} onClick={(e) => this.edit_sector_value(sector, asset)}>Edit Sector</a>
                                                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} id='delete' onClick={(e) => this.showModal1(asset, sector)} > Delete Sector</a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {/* <i className="fas fa-pen p-r-20" style={{ color: '#0272fb', fontSize: '16px' }} title="Edit" onClick={(e) => this.edit_sector_value(sector, asset)}></i>
                                                                                                            <i className="fas fa-trash p-r-10" style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, sector)}></i> */}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                            }

                                                                                            <tr style={{ height: '0px' }}>
                                                                                                <td style={{ padding: '0px', border: 'none' }} colSpan={11}>
                                                                                                    <div id={"_" + asset.assetId + sector.sectorId_id + index} className="collapse">
                                                                                                        {this.state.sectorClickId === "#_" + asset.assetId + sector.sectorId_id + index ?
                                                                                                            <div className="" style={{ padding: "0px 12px", background: "#f3f3f5" }}>
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='row m-t-10'>
                                                                                                                        <div className='col-md-12'>
                                                                                                                            <ul className="nav nav-tabs sectab" id="secTab" role="tablist">
                                                                                                                                <li className="nav-item">
                                                                                                                                    <a className="nav-link active p-l-0" id={"_" + sector.sectorId_id + index + "_installation"} data-toggle="tab" href={"#" + "_" + sector.sectorId_id + index + "_sector1"} role="tab" aria-controls={"_" + sector.sectorId_id + index + "_sector1"}
                                                                                                                                        aria-selected="true">INSTALLATIONS</a>
                                                                                                                                </li>
                                                                                                                                <li className="nav-item">
                                                                                                                                    <a className="nav-link" id={"_" + sector.sectorId_id + index + "_cbsd"} data-toggle="tab" href={"#" + "_" + sector.sectorId_id + index + "_sector2"} role="tab" aria-controls={"_" + sector.sectorId_id + index + "_sector2"}
                                                                                                                                        aria-selected="false">CBSD</a>
                                                                                                                                </li>
                                                                                                                                <li className="nav-item">
                                                                                                                                    <a className="nav-link" id={"_" + sector.sectorId_id + index + "_optional"} data-toggle="tab" href={"#" + "_" + sector.sectorId_id + index + "_sector3"} role="tab" aria-controls={"_" + sector.sectorId_id + index + "_sector3"}
                                                                                                                                        aria-selected="false">OPTIONAL</a>
                                                                                                                                </li>
                                                                                                                                <li className="nav-item">
                                                                                                                                    <a className="nav-link" id={"_" + sector.sectorId_id + index + "_others"} data-toggle="tab" href={"#" + "_" + sector.sectorId_id + index + "_sector4"} role="tab" aria-controls={"_" + sector.sectorId_id + index + "_sector4"}
                                                                                                                                        aria-selected="false">OTHERS</a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="tab-content" id="secTab">
                                                                                                                        <div className="tab-pane fade show active" id={"_" + sector.sectorId_id + index + "_sector1"} role="tabpanel" aria-labelledby={"_" + sector.sectorId_id + index + "_installation"}>
                                                                                                                            <div className='row '>
                                                                                                                                <div className='col-md-12' >
                                                                                                                                    <div className="row m-t-20 justify-content-left" style={{ paddingLeft: '24px' }}>
                                                                                                                                        {installation_list.map((installation: any) => {
                                                                                                                                            if (installation.units !== "") {
                                                                                                                                                unit = "(" + installation.units + ")"
                                                                                                                                            } else {
                                                                                                                                                unit = ""
                                                                                                                                            }
                                                                                                                                            if (installation.propertyName === "latitude" || installation.propertyName === "longitude" || installation.propertyName === "horizantal_accuracy" || installation.propertyName === "vertical_accuracy") {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                                                </div>
                                                                                                                                            } else if (installation.propertyName === "height") {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                                                        <span>
                                                                                                                                                            <i className="imagelink fa fa-picture-o" id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                                                        </span>
                                                                                                                                                    </label>
                                                                                                                                                </div>
                                                                                                                                            }
                                                                                                                                            else if (installation.propertyName === "haat") {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                                                </div>
                                                                                                                                            }
                                                                                                                                            else if (installation.propertyName === "height_type") {
                                                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                                                    <select className="floating-select selectarrow b-r-0" value={installation.value} style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => { this.height_type_change(e, "heightypeid1") }}>
                                                                                                                                                        <option value="" hidden>{installation.displayName} {unit}</option>
                                                                                                                                                        <option value="HEIGHT_TYPE_AGL">AGL</option>
                                                                                                                                                        <option value="HEIGHT_TYPE_AMSL">AMSL</option>
                                                                                                                                                    </select>
                                                                                                                                                    <label id="heightypeid1">{installation.displayName}</label>
                                                                                                                                                </div>
                                                                                                                                            }
                                                                                                                                        })}
                                                                                                                                    </div>
                                                                                                                                    <div className="row justify-content-left" style={{ paddingLeft: '24px' }}>
                                                                                                                                        {installation_list.map((installation: any) => {
                                                                                                                                            if (installation.units !== "") {
                                                                                                                                                unit = "(" + installation.units + ")"
                                                                                                                                            } else {
                                                                                                                                                unit = ""
                                                                                                                                            }
                                                                                                                                            if (installation.propertyName === "antenna_gain" || installation.propertyName === "antenna_beamwidth" || installation.propertyName === "eirp_capability" || installation.propertyName === "antenna_model") {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-r-0 b-t-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                                                </div>
                                                                                                                                            }
                                                                                                                                            else if (installation.propertyName === "indoor_deployment") {
                                                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                                                    <select className="floating-select selectarrow b-t-0" value={installation.value} style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => { this.indoor_deployment(e, "indoordep1") }}>
                                                                                                                                                        <option value="" hidden>{installation.displayName} {unit}</option>
                                                                                                                                                        <option value="1">True</option>
                                                                                                                                                        <option value="0">False</option>
                                                                                                                                                    </select>
                                                                                                                                                    <label id="indoordep1">{installation.displayName}</label>
                                                                                                                                                </div>
                                                                                                                                            } else if (installation.propertyName === "antenna_azimuth") {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0 b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" style={{ paddingRight: '0px' }} htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                                                        <span>
                                                                                                                                                            <i className="imagelink fa fa-picture-o" style={{ paddingLeft: '3px' }} id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                                                        </span>
                                                                                                                                                    </label>

                                                                                                                                                </div>
                                                                                                                                            } else if (installation.propertyName === "down_tilt") {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0 b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                                                        <span>
                                                                                                                                                            <i className="imagelink fa fa-picture-o" id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                                                        </span>
                                                                                                                                                    </label>

                                                                                                                                                </div>
                                                                                                                                            }

                                                                                                                                        })}
                                                                                                                                    </div>
                                                                                                                                    <div className="row m-b-10 justify-content-left" style={{ paddingLeft: '24px' }}>
                                                                                                                                        {installation_list.map((installation: any) => {
                                                                                                                                            if (installation.units !== "") {
                                                                                                                                                unit = "(" + installation.units + ")"
                                                                                                                                            } else {
                                                                                                                                                unit = ""
                                                                                                                                            }
                                                                                                                                            if (installation.propertyName === 'antenna_pattern') {
                                                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0" style={{ width: '163px' }} id={installation.propertyName} value={installation.value} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                                                </div>
                                                                                                                                            }

                                                                                                                                        })}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="tab-pane fade show" id={"_" + sector.sectorId_id + index + "_sector2"} role="tabpanel" aria-labelledby={"_" + sector.sectorId_id + index + "_cbsd"}>
                                                                                                                            <div className='row '>
                                                                                                                                <div className='col-md-1'>
                                                                                                                                </div>
                                                                                                                                <div className='col-md-11'>
                                                                                                                                    <div className='row m-t-20 m-b-10 m-l-30'>

                                                                                                                                        {
                                                                                                                                            cbsd_list.map((cbsd: any, index: any) => {
                                                                                                                                                if (cbsd.dataType === "string") {
                                                                                                                                                    return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                                                        <input type="text" name={cbsd.propertyName} className={`paramtext-mob ${cbsd_list.length - 1 === index ? '' : 'b-r-0'}`} id={cbsd.propertyName} value={cbsd.value} style={{ borderRadius: '0px' }} placeholder={cbsd.displayName} onChange={(e: any) => { this.onChange(e, 'cbsd') }} />
                                                                                                                                                        <label className="importdetails" htmlFor={cbsd.propertyName}>{cbsd.displayName}</label>
                                                                                                                                                    </div>
                                                                                                                                                } else {

                                                                                                                                                    return <div>
                                                                                                                                                        <div className="floating-label sectordetails">
                                                                                                                                                            <select name={cbsd.propertyName} value={this.state.selected_vendor_name} style={{ borderRadius: '0px', }} className="floating-select selectarrow b-r-0" onChange={(e) => this.setAssetVendor(e, "vendor", "cbsdvendor1")} >
                                                                                                                                                                <option hidden>Vendor</option>
                                                                                                                                                                {this.state.assetVendorList.map((vendor: any) =>
                                                                                                                                                                    <option value={vendor} >{vendor}</option>
                                                                                                                                                                )}
                                                                                                                                                            </select>
                                                                                                                                                            <label id="cbsdvendor1">Vendor</label>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="floating-label sectordetails">
                                                                                                                                                            <select name={cbsd.propertyName} value={this.state.selected_model_name} style={{ borderRadius: '0px' }} className="floating-select selectarrow b-r-0" onChange={(e) => this.setAssetModel(e, "cbsdmodel1")} >
                                                                                                                                                                <option hidden>Model</option>
                                                                                                                                                                {this.state.assetModelList.map((vendor: any) =>
                                                                                                                                                                    <option value={vendor['model_name']} >{vendor['model_name']}</option>
                                                                                                                                                                )}
                                                                                                                                                            </select>
                                                                                                                                                            <label id="cbsdmodel1">Model</label>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="tab-pane fade show" id={"_" + sector.sectorId_id + index + "_sector3"} role="tabpanel" aria-labelledby={"_" + sector.sectorId_id + index + "_optional"}>
                                                                                                                            <div className='row '>
                                                                                                                                {/* <div className='col-md-1'>
                                                                                                                                </div> */}
                                                                                                                                <div className='col-md-12' >
                                                                                                                                    <div className="row m-t-20 m-l-30 justify-content-left" style={{ paddingLeft: '24px' }}>
                                                                                                                                        {
                                                                                                                                            optional_list.map((optional: any, index: any) => {
                                                                                                                                                if (optional.dataType === "string") {
                                                                                                                                                    return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                                                        <input type="text" className={`paramtext-mob ${optional_list.length - 1 === index ? '' : 'b-r-0'}`} name={optional.propertyName} id={optional.propertyName} value={optional.value} style={{ borderRadius: '0px' }} placeholder={optional.displayName} onChange={(e: any) => { this.onChange(e, 'optional') }} />
                                                                                                                                                        <label className="importdetails" htmlFor={optional.propertyName}>{optional.displayName + optional.units}</label>
                                                                                                                                                    </div>
                                                                                                                                                } else {
                                                                                                                                                    if (optional.propertyName === "call_sign") {
                                                                                                                                                        return <div className="floating-label sectordetails">
                                                                                                                                                            <select className="floating-select selectarrow b-r-0" style={{ borderRadius: '0px', height: '45px' }} onChange={(e) => { this.select_onChange(e, "optional", optional.propertyName, "optcalsign1") }}>
                                                                                                                                                                <option value="" hidden>{optional.displayName + optional.units}</option>
                                                                                                                                                                <option value="1">1</option>
                                                                                                                                                                <option value="2">2</option>
                                                                                                                                                            </select>
                                                                                                                                                            <label id="optcalsign1">{optional.displayName}</label>
                                                                                                                                                        </div>
                                                                                                                                                    } else if (optional.propertyName === "cbsd_category") {
                                                                                                                                                        return <div className="floating-label">
                                                                                                                                                            <select value={optional.value} className="floating-select selectarrow b-r-0" style={{ width: '180px', borderRadius: '0px', height: '45px' }} onChange={(e) => { this.cbsd_category(e, "optcbsdcat1") }}>
                                                                                                                                                                <option value="" hidden>{optional.displayName + optional.units}</option>
                                                                                                                                                                <option value="DEVICE_CATEGORY_A">DEVICE_CATEGORY_A</option>
                                                                                                                                                                <option value="DEVICE_CATEGORY_B">DEVICE_CATEGORY_B</option>
                                                                                                                                                            </select>
                                                                                                                                                            <label id="optcbsdcat1">{optional.displayName}</label>
                                                                                                                                                        </div>
                                                                                                                                                    } else if (optional.propertyName === "air_interface") {
                                                                                                                                                        return <div className="floating-label sectordetails">
                                                                                                                                                            <select value={optional.value} className="floating-select selectarrow b-r-0" style={{ borderRadius: '0px', height: '45px' }} onChange={(e) => { this.air_interface(e, "optairinter1") }}>
                                                                                                                                                                <option value="" hidden>{optional.displayName + optional.units}</option>
                                                                                                                                                                <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                                                                                                                                                                <option value="E_UTRA">E_UTRA</option>
                                                                                                                                                                <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                                                                                                                                                                <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                                                                                                                                                                <option value="NR">NR</option>
                                                                                                                                                                <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                                                                                                                                                                <option value="REDLINE">REDLINE</option>
                                                                                                                                                                <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                                                                                                                                                            </select>
                                                                                                                                                            <label id="optairinter1">{optional.displayName}</label>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </div>

                                                                                                                                    <div className="row  m-b-10 m-l-30 justify-content-left" style={{ paddingLeft: '24px' }}>
                                                                                                                                        {
                                                                                                                                            installation_list.map((installation: any) => {
                                                                                                                                                if (installation.propertyName === "measurement_capability") {
                                                                                                                                                    return <div className="floating-label sectordetails">
                                                                                                                                                        <select style={{ borderRadius: '0px', width: '163px' }} value={installation.value} className="floating-select selectarrow b-t-0" onChange={(e: any) => this.measurement_capability(e, "measurecapab1")} >
                                                                                                                                                            <option hidden>WITH GRANT</option>
                                                                                                                                                            <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT" >WITH_GRANT</option>
                                                                                                                                                            <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT" >WITHOUT_GRANT</option>
                                                                                                                                                        </select>
                                                                                                                                                        <label id="measurecapab1">{installation.displayName}</label>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="tab-pane fade show" id={"_" + sector.sectorId_id + index + "_sector4"} role="tabpanel" aria-labelledby={"_" + sector.sectorId_id + index + "_others"}>
                                                                                                                            <div className='row '>
                                                                                                                                <div className='col-md-3'>
                                                                                                                                </div>
                                                                                                                                <div className='col-md-9' >
                                                                                                                                    <div className="row m-t-20 m-b-10 m-l-36 justify-content-left" style={{ paddingLeft: '24px' }}>
                                                                                                                                        {
                                                                                                                                            others_list.map((others: any, index: any) => {
                                                                                                                                                if (others.propertyName == "cable_length" || others.propertyName === "cell_id" ) {
                                                                                                                                                    return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                                                        <input type="text" name={others.propertyName} autoComplete="off" className={`paramtext-mob ${others_list.length - 1 === index ? '' : 'b-r-0'}`} id={others.propertyName} value={others.value} style={{ borderRadius: '0px' }} placeholder={others.displayName} onChange={(e: any) => { this.onChange(e, 'others') }} />
                                                                                                                                                        <label className="importdetails" htmlFor={others.propertyName}>{others.displayName + others.units}</label>
                                                                                                                                                    </div>
                                                                                                                                                }else if(others.propertyName === "pci"){
                                                                                                                                                    return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                                                        <input type="text" name={others.propertyName} className="paramtext-mob" id={others.propertyName} value={others.value} style={{ borderRadius: '0px' }} placeholder={others.displayName} onChange={(e: any) => { this.onChange(e, 'others') }} />
                                                                                                                                                        <label className="importdetails" htmlFor={others.propertyName}>{others.displayName + others.units}</label>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : null}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    })}
                                                                                </table>
                                                                            </div>
                                                                        </div> : null}
                                                                </div>
                                                            </React.Fragment>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row m-t-10" style={{ padding: '0px 10px' }}>
                                            <div className="col-md-12" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 258px)' }}>
                                                <table className="table sitetable">
                                                    <thead className="sitehead" style={{ background: "none" }}>
                                                        <tr >
                                                            <th className="w8 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>Cluster Name
                                                              <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Site Address</th>
                                                            <th className="w10 table-bg-hdr-color" >Site Access </th>
                                                            <th className="w15 table-bg-hdr-color" >Structure Additional field  </th>
                                                            <th className="w10 table-bg-hdr-color" >Feeder Length </th>
                                                            <th className="w8 table-bg-hdr-color" >Backhaul Type   </th>
                                                            <th className="w8 table-bg-hdr-color" >Equipment Type </th>
                                                            <th className="w10 table-bg-hdr-color" >Cabinet Type</th>
                                                            <th className="w6 table-bg-hdr-color" >PLMN ID </th>
                                                            <th className="w5 table-bg-hdr-color" >MNC</th>
                                                            <th className="w5 table-bg-hdr-color" >MCC </th>
                                                            <th className="w5 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }} >TAC</th>

                                                        </tr>
                                                    </thead>
                                                    {this.state.list_asset.map((asset: any, index: any) => {
                                                        return <tbody>
                                                            <tr>
                                                                <td className="w8">{asset.cluster_name}</td>
                                                                <td className="w10">{asset.street_address}</td>
                                                                <td className="w10">{asset.ap_access}</td>
                                                                <td className="w15">{asset.structure_additional_field}</td>
                                                                <td className="w10">{asset.feeder_length}</td>
                                                                <td className="w8">{asset.backhaul_type}</td>
                                                                <td className="w8">{asset.equipment_type}</td>
                                                                <td className="w10">{asset.cabinet_type}</td>
                                                                <td className="w6">{asset.plmn_id}</td>
                                                                <td className="w5">{asset.mnc}</td>
                                                                <td className="w5">{asset.mcc}</td>
                                                                <td className="w5">{asset.tac}</td>
                                                            </tr>
                                                        </tbody>
                                                    })
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                                <a className="carousel-left carousel-control-prev" href="#demo" data-slide="prev">
                                    <i className="fa fa-angle-left" style={{ color: '#000' }}></i>
                                </a>
                                <a className=" carousel-right carousel-control-next" href="#demo" data-slide="next">
                                    <i className="fa fa-angle-right" style={{ color: '#000' }}></i>
                                </a>
                            </div >

                        </div >
                        <div className="tab-pane fade" id="cpe-details" role="tabpanel" aria-labelledby="cpe-details-tab">
                            <div id="" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">

                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row m-t-10" style={{ padding: '0px 10px' }}>
                                            <div className="col-md-12" >
                                                <table className="table sitetable">
                                                    <thead className="sitehead" style={{ background: "none", display: 'table' }}>
                                                        <tr >
                                                            <th className="w14 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>Region/Market Name
                                                                <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("regionName")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("regionName")} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Cluster Name
                                                              <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("cluster_name")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("cluster_name")} />
                                                            </th>
                                                            <th className="w7 table-bg-hdr-color" >CPE Id
                                                              <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("cpe_id")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("cpe_id")} />
                                                            </th>
                                                            <th className="w9 table-bg-hdr-color" >CPE Name
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Site Status</th>
                                                            <th className="w10 table-bg-hdr-color" >Customer ID </th>
                                                            <th className="w12 table-bg-hdr-color" >Deployment Type </th>
                                                            <th className="w7 table-bg-hdr-color" >Latitude </th>
                                                            <th className="w7 table-bg-hdr-color" >Longitude </th>
                                                            <th className="w7 table-bg-hdr-color" >Serial No </th>
                                                            <th className="w7 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ display: 'block', overflow: 'hidden scroll', maxHeight: 'calc(100vh - 310px)', width: 'calc(100% + 8px)' }}>
                                                        {this.state.asset_list.map((asset: any, index: any) => {
                                                            return <React.Fragment>
                                                                {this.state.show_asset_details === true && this.state.selected_asset_id === asset.assetId ?
                                                                    <tr id={asset.assetId} className="panel-group" >
                                                                        <td className="w14 ellipsitooltip" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                            <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} data-toggle="collapse" data-target={"#_" + asset.assetId + index} onClick={() => this.getSector(asset, "#_" + asset.assetId + index)} />
                                                                            {asset.regionName}
                                                                        </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="cluster_name" value={asset.cluster_name} onChange={(e: any) => { this.onChange(e, "asset") }} /> </td>
                                                                        <td className="w7 ellipsitooltip" data-toggle="tooltip" title={asset.cpe_id}>{asset.cpe_id}</td>
                                                                        <td className="w9"> < input className="accesspointname" style={{ height: '38px' }} name="cpe_name" value={asset.cpe_name} onChange={(e: any) => { this.onChange(e, 'asset') }} /></td>
                                                                        <td className="w10">
                                                                            <select className="form-control" value={asset.operationalStatus} onChange={(e: any) => this.setAssetStatus(e, asset)}>
                                                                                {asset_status_list.map((status: any) =>
                                                                                    <option value={status} >{status}</option>
                                                                                )}
                                                                            </select>
                                                                        </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="customer_id" value={asset.customer_id} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w12"> < input className="accesspointname" style={{ height: '38px' }} name="deployment_type" value={asset.deployment_type} onChange={(e: any) => { this.onChange(e, "asset") }} /> </td>
                                                                        <td className="w7"> < input className="accesspointname" style={{ height: '38px' }} name="latitude" value={asset.latitude} onChange={(e: any) => { this.onChange(e, "asset") }} /> </td>
                                                                        <td className="w7"> < input className="accesspointname" style={{ height: '38px' }} name="longitude" value={asset.longitude} onChange={(e: any) => { this.onChange(e, "asset") }} /> </td>
                                                                        <td className="w7"> < input className="accesspointname" style={{ height: '38px' }} name="serial_id" value={asset.serial_id} onChange={(e: any) => { this.onChange(e, "asset") }} /> </td>
                                                                        <td className="w7">
                                                                            <i className="far fa-check-circle  p-r-15" style={{ color: '#0272fb', fontSize: '22px' }} title="Save" onClick={(e) => this.updateAssetProperties()}></i>
                                                                            <i className="fas fa-trash" style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, "")}></i>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    <tr className="panel-group" id={asset.assetId} style={{ display: 'flex' }}>
                                                                        {
                                                                            this.state.assetClickId === "#_" + asset.assetId + index && this.state.check_arrow === true ? <td className="w14 networkellipsis" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                                <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer", transform: "rotate(180deg)" }} onClick={this.hide_arrow} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                                {asset.regionName}
                                                                            </td> : <td className="w14 networkellipsis" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                                    <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} onClick={() => this.getSector(asset, "#_" + asset.assetId + index)} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                                    {asset.regionName}
                                                                                </td>
                                                                        }
                                                                        <td className="w10 networkellipsis" data-toggle="tooltip" title={asset.cluster_name}>{asset.cluster_name}</td>
                                                                        <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.cpe_id}>{asset.cpe_id}</td>
                                                                        <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.cpe_name}>{asset.cpe_name}</td>
                                                                        {
                                                                            asset.operationalStatus === "Offline" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "rgb(226, 43, 43)" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "rgb(226, 43, 43)" }}></span>{asset.operationalStatus}</td> :
                                                                                asset.operationalStatus === "In Design" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#00D56E" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#00D56E" }}></span>{asset.operationalStatus}</td> :
                                                                                    asset.operationalStatus === "On Air" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#FCD369" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#FCD369" }}></span>{asset.operationalStatus}</td> :
                                                                                        asset.operationalStatus === "In Construction" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#0059B2" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#0059B2" }}></span>{asset.operationalStatus}</td> :
                                                                                            asset.operationalStatus === "In Zoning" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#0000A0" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#0000A0" }}></span>{asset.operationalStatus}</td> :
                                                                                                asset.operationalStatus === "Site Acquisition" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#8B4513" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#8B4513" }}></span>{asset.operationalStatus}</td> :
                                                                                                    <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "rgb(226, 43, 43)" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "rgb(226, 43, 43)" }}></span>{asset.operationalStatus}</td>
                                                                        }
                                                                        <td className="w10 networkellipsis" data-toggle="tooltip" title={asset.customer_id}>{asset.customer_id}</td>
                                                                        <td className="w12 networkellipsis" data-toggle="tooltip" title={asset.deployment_type}>{asset.deployment_type}</td>
                                                                        <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.latitude + "°"}>{asset.latitude}°</td>
                                                                        <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.longitude + "°"}>{asset.longitude}°</td>
                                                                        <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.serial_id}>{asset.serial_id}</td>
                                                                        <td className="w7">
                                                                            <div className='col-3' >
                                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <i className="fas fa-ellipsis-v " style={{ color: '#B4B4B4', marginTop: '10px', height: "30px" }}></i>
                                                                                </a>
                                                                                <div className="dropdown-menu indoorfloor" style={{ backgroundColor: 'black', }} aria-labelledby="dropdownMenuLink">
                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} onClick={(e) => this.edit_asset_value(asset)}>Edit Asset</a>
                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} id='delete' onClick={(e) => this.showModal1(asset, "")} > Delete Asset</a>
                                                                                </div>
                                                                            </div>
                                                                            {/* {
                                                                                this.state.show_asset_details === false ? <i className="fas fa-pen p-r-15" style={{ color: '#0272fb', fontSize: '16px' }} title="Edit" onClick={(e) => this.edit_asset_value(asset)}></i> :
                                                                                    <i className="far fa-check-circle p-r-15" style={{ color: '#0272fb', fontSize: '22px' }} title="Save" ></i>
                                                                            }
                                                                            <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, "")}></i> */}
                                                                        </td>
                                                                    </tr>

                                                                }

                                                                <div id={"_" + asset.assetId + index} className="collapse">
                                                                    {this.state.assetClickId === "#_" + asset.assetId + index ?
                                                                        <div className="" style={{ padding: "0px 12px", background: "#f3f3f5", marginLeft: '20px' }}>
                                                                            <div className="col-md-12">
                                                                                <div className='row'>
                                                                                    <div className='col-md-12'>
                                                                                        <ul className="nav nav-tabs sectab" id="secTab" role="tablist">
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link active p-l-0" id="cpe1-tab" data-toggle="tab" href="#cpe1" role="tab" aria-controls="sec1"
                                                                                                    aria-selected="true">INSTALLATIONS</a>
                                                                                            </li>
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link" id="cpe2-tab" data-toggle="tab" href="#cpe2" role="tab" aria-controls="sec2"
                                                                                                    aria-selected="false">CBSD</a>
                                                                                            </li>
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link" id="cpe3-tab" data-toggle="tab" href="#cpe3" role="tab" aria-controls="sec3"
                                                                                                    aria-selected="false">OPTIONAL</a>
                                                                                            </li>
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link" id="cpe4-tab" data-toggle="tab" href="#cpe4" role="tab" aria-controls="sec4"
                                                                                                    aria-selected="false">OTHERS</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-content" id="secTab">
                                                                                    <div className="tab-pane fade show active" id="cpe1" role="tabpanel" aria-labelledby="cpe1-tab">
                                                                                        <div className='row'>
                                                                                            <div className='row '>
                                                                                                <div className='col-md-12' >
                                                                                                    <div className="row m-t-20 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                        {installation_list.map((installation: any) => {
                                                                                                            if (installation.units !== "") {
                                                                                                                unit = "(" + installation.units + ")"
                                                                                                            } else {
                                                                                                                unit = ""
                                                                                                            }
                                                                                                            if (installation.propertyName === "latitude" || installation.propertyName === "longitude" || installation.propertyName === "horizantal_accuracy" || installation.propertyName === "vertical_accuracy") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            } else if (installation.propertyName === "height") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                        <span>
                                                                                                                            <i className="imagelink fa fa-picture-o" id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                        </span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            }
                                                                                                            else if (installation.propertyName === "haat") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                            else if (installation.propertyName === "height_type") {
                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                    <select className="floating-select selectarrow b-r-0" value={installation.value} style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => { this.height_type_change(e, "heightypeid2") }}>
                                                                                                                        <option value="" hidden>{installation.displayName} {unit}</option>
                                                                                                                        <option value="HEIGHT_TYPE_AGL">AGL</option>
                                                                                                                        <option value="HEIGHT_TYPE_AMSL">AMSL</option>
                                                                                                                    </select>
                                                                                                                    <label id="heightypeid2">{installation.displayName}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                    <div className="row justify-content-left m-l-30" style={{ paddingLeft: '54px' }}>
                                                                                                        {installation_list.map((installation: any) => {
                                                                                                            if (installation.units !== "") {
                                                                                                                unit = "(" + installation.units + ")"
                                                                                                            } else {
                                                                                                                unit = ""
                                                                                                            }
                                                                                                            if (installation.propertyName === "antenna_gain" || installation.propertyName === "antenna_beamwidth" || installation.propertyName === "eirp_capability" || installation.propertyName === "antenna_model") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-r-0 b-t-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                            else if (installation.propertyName === "indoor_deployment") {
                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                    <select className="floating-select selectarrow b-t-0" value={installation.value} style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => { this.indoor_deployment(e, "indoordep2") }}>
                                                                                                                        <option value="" hidden>{installation.displayName} {unit}</option>
                                                                                                                        <option value="1">True</option>
                                                                                                                        <option value="0">False</option>
                                                                                                                    </select>
                                                                                                                    <label id="indoordep2">{installation.displayName}</label>
                                                                                                                </div>
                                                                                                            } else if (installation.propertyName === "antenna_azimuth") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0 b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" style={{ paddingRight: '0px' }} htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                        <span>
                                                                                                                            <i className="imagelink fa fa-picture-o" style={{ paddingLeft: '3px' }} id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                        </span>
                                                                                                                    </label>

                                                                                                                </div>
                                                                                                            } else if (installation.propertyName === "down_tilt") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0 b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                        <span>
                                                                                                                            <i className="imagelink fa fa-picture-o" id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                        </span>
                                                                                                                    </label>

                                                                                                                </div>

                                                                                                            }

                                                                                                        })}
                                                                                                    </div>
                                                                                                    <div className="row m-b-10 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                        {installation_list.map((installation: any) => {
                                                                                                            if (installation.units !== "") {
                                                                                                                unit = "(" + installation.units + ")"
                                                                                                            } else {
                                                                                                                unit = ""
                                                                                                            }
                                                                                                            if (installation.propertyName === 'antenna_pattern') {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} autoComplete="off" className="paramtext-mob b-t-0" style={{ width: '163px' }} id={installation.propertyName} value={installation.value} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            }

                                                                                                        })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="tab-pane fade show" id="cpe2" role="tabpanel" aria-labelledby="cpe2-tab">
                                                                                        <div className='row '>
                                                                                            <div className='col-md-1'>
                                                                                            </div>
                                                                                            <div className="col-md-11">
                                                                                                <div className='row m-t-20 m-b-10 m-l-30' style={{ paddingLeft: "40px" }}>
                                                                                                    {
                                                                                                        cbsd_list.map((cbsd: any, index: any) => {
                                                                                                            if (cbsd.dataType === "string") {
                                                                                                                return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                    <input type="text" name={cbsd.propertyName} className={`paramtext-mob ${cbsd_list.length - 1 === index ? '' : 'b-r-0'}`} id={cbsd.propertyName} value={cbsd.value} style={{ borderRadius: '0px' }} placeholder={cbsd.displayName} onChange={(e: any) => { this.onChange(e, 'cbsd') }} />
                                                                                                                    <label className="importdetails" htmlFor={cbsd.propertyName}>{cbsd.displayName}</label>
                                                                                                                </div>
                                                                                                            } else {
                                                                                                                return <div>
                                                                                                                    <div className="floating-label sectordetails">
                                                                                                                        <select name={cbsd.propertyName} value={this.state.selected_vendor_name} style={{ borderRadius: '0px', }} className="floating-select selectarrow b-r-0" onChange={(e) => this.setAssetVendor(e, "vendor", "cbsdvendor2")} >
                                                                                                                            <option hidden>Vendor</option>
                                                                                                                            {this.state.assetVendorList.map((vendor: any) =>
                                                                                                                                <option value={vendor} >{vendor}</option>
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                        <label id="cbsdvendor2">Vendor</label>
                                                                                                                    </div>
                                                                                                                    <div className="floating-label sectordetails">
                                                                                                                        <select name={cbsd.propertyName} value={this.state.selected_model_name} style={{ borderRadius: '0px' }} className="floating-select selectarrow b-r-0" onChange={(e) => this.setAssetModel(e, "cbsdmodel2")} >
                                                                                                                            <option hidden>Model</option>
                                                                                                                            {this.state.assetModelList.map((vendor: any) =>
                                                                                                                                <option value={vendor['model_name']} >{vendor['model_name']}</option>
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                        <label id="cbsdmodel2">Model</label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="tab-pane fade show" id="cpe3" role="tabpanel" aria-labelledby="cpe3-tab">
                                                                                        <div className='row '>
                                                                                            {/* <div className='col-md-1'>
                                                                                            </div> */}
                                                                                            <div className='col-md-12'>
                                                                                                {/* <div className='row  m-t-20 m-b-10'> */}
                                                                                                <div className="row m-t-20 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                    {
                                                                                                        optional_list.map((optional: any, index: any) => {
                                                                                                            if (optional.dataType === "string") {
                                                                                                                return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                    <input type="text" className={`paramtext-mob ${optional_list.length - 1 === index ? '' : 'b-r-0'}`} name={optional.propertyName} id={optional.propertyName} value={optional.value} style={{ borderRadius: '0px' }} placeholder={optional.displayName} onChange={(e: any) => { this.onChange(e, 'optional') }} />
                                                                                                                    <label className="importdetails" htmlFor={optional.propertyName}>{optional.displayName}</label>
                                                                                                                </div>
                                                                                                            } else {
                                                                                                                if (optional.propertyName === "call_sign") {
                                                                                                                    return <div className="floating-label sectordetails">
                                                                                                                        <select className="floating-select selectarrow b-r-0" style={{ borderRadius: '0px', height: '45px' }} onChange={(e) => { this.select_onChange(e, "optional", optional.propertyName, "optcalsign2") }}>
                                                                                                                            <option value="" hidden>1</option>
                                                                                                                            <option value="1">1</option>
                                                                                                                            <option value="2">2</option>
                                                                                                                        </select>
                                                                                                                        <label id="optcalsign2">{optional.displayName}</label>
                                                                                                                    </div>
                                                                                                                } else if (optional.propertyName === "air_interface") {
                                                                                                                    return <div className="floating-label sectordetails">
                                                                                                                        <select value={optional.value} className="floating-select selectarrow b-r-0" style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => this.air_interface(e, "optairinter2")}>
                                                                                                                            <option value="" hidden>{optional.displayName}</option>
                                                                                                                            <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                                                                                                                            <option value="E_UTRA">E_UTRA</option>
                                                                                                                            <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                                                                                                                            <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                                                                                                                            <option value="NR">NR</option>
                                                                                                                            <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                                                                                                                            <option value="REDLINE">REDLINE</option>
                                                                                                                            <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                                                                                                                        </select>
                                                                                                                        <label id="optairinter2">{optional.displayName}</label>
                                                                                                                    </div>
                                                                                                                } else if (optional.propertyName === "cbsd_category") {
                                                                                                                    return <div className="floating-label">
                                                                                                                        <select value={optional.value} className="floating-select selectarrow" style={{ width: '180px', borderRadius: '0px', height: '45px' }} onChange={(e) => { this.cbsd_category(e, "optcbsdcat2") }}>
                                                                                                                            <option value="" hidden>{optional.displayName + optional.units}</option>
                                                                                                                            <option value="DEVICE_CATEGORY_A">DEVICE_CATEGORY_A</option>
                                                                                                                            <option value="DEVICE_CATEGORY_B">DEVICE_CATEGORY_B</option>
                                                                                                                        </select>
                                                                                                                        <label id="optcbsdcat2">{optional.displayName}</label>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="row  m-b-10 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                    {
                                                                                                        installation_list.map((installation: any) => {
                                                                                                            if (installation.propertyName === "measurement_capability") {
                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                    <select style={{ borderRadius: '0px', width:"163px" }} value={installation.value} className="floating-select selectarrow b-t-0" onChange={(e: any) => this.measurement_capability(e, "measurecapab2")} >
                                                                                                                        <option hidden>WITH GRANT</option>
                                                                                                                        <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT" >WITH_GRANT</option>
                                                                                                                        <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT" >WITHOUT_GRANT</option>
                                                                                                                    </select>
                                                                                                                    <label id="measurecapab2">{installation.displayName}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="tab-pane fade show" id="cpe4" role="tabpanel" aria-labelledby="cpe4-tab">
                                                                                        <div className='row '>
                                                                                            <div className='col-md-5'>
                                                                                            </div>
                                                                                            <div className='col-md-7' >
                                                                                                <div className='row m-t-20 m-b-10'>
                                                                                                    {
                                                                                                        others_list.map((others: any, index: any) => {
                                                                                                            if (others.propertyName == "earfcn") {
                                                                                                                return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                    <input type="text" name={others.propertyName} className={`paramtext-mob ${others_list.length - 1 === index ? '' : 'b-r-0'}`} id={others.propertyName} value={others.value} style={{ borderRadius: '0px' }} placeholder={others.displayName} onChange={(e: any) => { this.onChange(e, 'others') }} />
                                                                                                                    <label className="importdetails" htmlFor={others.propertyName}>{others.displayName}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null}
                                                                </div>
                                                            </React.Fragment>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row m-t-10" style={{ padding: '0px 10px' }}>
                                            <div className="col-md-12" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 258px)' }}>
                                                <table className="table sitetable">
                                                    <thead className="sitehead" style={{ background: "none" }}>
                                                        <tr >
                                                            <th className="w8 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>CPE Id
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w8 table-bg-hdr-color" >Type
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Region Name
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Market Name
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w12 table-bg-hdr-color" >Boundary Name
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >No of Sectors
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Site Status
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Latitude
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Longitude
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >CPI
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th>
                                                            <th className="w5 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </div>
                        <div className="tab-pane fade" id="indoor-details" role="tabpanel" aria-labelledby="indoor-details-tab">
                            <div id="" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">

                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row m-t-10" style={{ padding: '0px 10px' }}>
                                            <div className="col-md-12" >
                                                <table className="table sitetable">
                                                    <thead className="sitehead" style={{ background: "none", display: 'table' }}>
                                                        <tr >
                                                            <th className="w14 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>Region/Market Name
                                                                <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("regionName")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("regionName")} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Cluster Name
                                                              <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("cluster_name")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("cluster_name")} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >CBSD Id
                                                              <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByPriceDesc("assetIdentifierId")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByPriceAsc("assetIdentifierId")} />
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >CBSD Name
                                                            </th>
                                                            <th className="w10 table-bg-hdr-color" >Site Status</th>
                                                            <th className="w10 table-bg-hdr-color" >Deployment Type </th>
                                                            <th className="w10 table-bg-hdr-color" >Latitude </th>
                                                            <th className="w10 table-bg-hdr-color" >Longitude </th>
                                                            <th className="w9 table-bg-hdr-color" >Serial No </th>
                                                            <th className="w7 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ display: 'block', overflow: 'hidden scroll', maxHeight: 'calc(100vh - 310px)', width: 'calc(100% + 8px)' }}>
                                                        {this.state.asset_list.map((asset: any, index: any) => {
                                                            return <React.Fragment>
                                                                {this.state.show_asset_details === true && this.state.selected_asset_id === asset.assetId ?
                                                                    <tr style={{ display: 'flex' }}>
                                                                        <td className="w14 networkellipsis" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                            <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} onClick={() => this.getSector(asset, "#_" + asset.assetId + index)} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                            {asset.regionName}
                                                                        </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="cluster_name" value={asset.cluster_name} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="assetIdentifierId" value={asset.assetIdentifierId} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="ap_name" value={asset.ap_name} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w10">
                                                                            <select className="form-control" value={asset.operationalStatus} onChange={(e: any) => this.setAssetStatus(e, asset)}>
                                                                                {asset_status_list.map((status: any) =>
                                                                                    <option value={status} >{status}</option>
                                                                                )}
                                                                            </select>
                                                                        </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="deployment_type" value={asset.deployment_type} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="latitude" value={asset.latitude} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="longitude" value={asset.longitude} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w10"> < input className="accesspointname" style={{ height: '38px' }} name="serial_id" value={asset.serial_id} onChange={(e: any) => { this.onChange(e, 'asset') }} /> </td>
                                                                        <td className="w7">
                                                                            <i className="far fa-check-circle p-r-15" style={{ color: '#0272fb', fontSize: '20px' }} title="Save" onClick={(e) => this.updateAssetProperties()} ></i>
                                                                            <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, "")}></i>
                                                                        </td>
                                                                    </tr> : <tr style={{ display: 'flex' }}>
                                                                        <td className="w14 networkellipsis" data-toggle="tooltip" title={asset.regionName} style={{ paddingLeft: '0px' }}>
                                                                            <img src={sort} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} onClick={() => this.getSector(asset, "#_" + asset.assetId + index)} data-toggle="collapse" data-target={"#_" + asset.assetId + index} />
                                                                            {asset.regionName}
                                                                        </td>
                                                                        <td className="w10 networkellipsis" title={asset.cluster_name} >{asset.cluster_name}</td>
                                                                        <td className="w10 networkellipsis" title={asset.assetIdentifierId} >{asset.assetIdentifierId}</td>
                                                                        <td className="w10 networkellipsis" title={asset.ap_name} >{asset.ap_name}</td>
                                                                        {
                                                                            asset.operationalStatus === "Offline" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "rgb(226, 43, 43)" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "rgb(226, 43, 43)" }}></span>{asset.operationalStatus}</td> :
                                                                                asset.operationalStatus === "In Design" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#00D56E" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#00D56E" }}></span>{asset.operationalStatus}</td> :
                                                                                    asset.operationalStatus === "On Air" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#FCD369" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#FCD369" }}></span>{asset.operationalStatus}</td> :
                                                                                        asset.operationalStatus === "In Construction" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#0059B2" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#0059B2" }}></span>{asset.operationalStatus}</td> :
                                                                                            asset.operationalStatus === "In Zoning" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#0000A0" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#0000A0" }}></span>{asset.operationalStatus}</td> :
                                                                                                asset.operationalStatus === "Site Acquisition" ? <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "#8B4513" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "#8B4513" }}></span>{asset.operationalStatus}</td> :
                                                                                                    <td className="w10 networkellipsis" data-toggle="tooltip" style={{ color: "rgb(226, 43, 43)" }} title={asset.operationalStatus}><span className="dot m-r-5" style={{ backgroundColor: "rgb(226, 43, 43)" }}></span>{asset.operationalStatus}</td>
                                                                        }
                                                                        <td className="w10 networkellipsis" title={asset.deployment_type} >{asset.deployment_type}</td>
                                                                        <td className="w10 networkellipsis" title={asset.latitude} >{asset.latitude}</td>
                                                                        <td className="w10 networkellipsis" title={asset.longitude} >{asset.longitude}</td>
                                                                        <td className="w9 networkellipsis" title={asset.serial_id} >{asset.serial_id}</td>
                                                                        <td className="w7">
                                                                            <div className='col-3' >
                                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <i className="fas fa-ellipsis-v " style={{ color: '#B4B4B4', marginTop: '10px', height: "30px" }}></i>
                                                                                </a>
                                                                                <div className="dropdown-menu indoorfloor" style={{ backgroundColor: 'black', }} aria-labelledby="dropdownMenuLink">
                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} onClick={(e) => this.edit_asset_value(asset)}>Edit Asset</a>
                                                                                    <a className="dropdown-item cursor" style={{ color: 'white' }} id='delete' onClick={(e) => this.showModal1(asset, "")} > Delete Asset</a>
                                                                                </div>
                                                                            </div>
                                                                            {/* <i className="fas fa-pen p-r-20" style={{ color: '#0272fb', fontSize: '20px' }} title="Edit" onClick={(e) => this.edit_asset_value(asset)} ></i>
                                                                            <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showModal1(asset, "")}></i> */}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                <div id={"_" + asset.assetId + index} className="collapse">
                                                                    {this.state.assetClickId === "#_" + asset.assetId + index ?
                                                                        <div className="" style={{ padding: "0px 12px", background: "#f3f3f5", marginLeft: '20px' }}>
                                                                            <div className="col-md-12">
                                                                                <div className='row'>
                                                                                    <div className='col-md-12'>
                                                                                        <ul className="nav nav-tabs sectab" id="secTab" role="tablist">
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link active p-l-0" id="indoor1-tab" data-toggle="tab" href="#indoor1" role="tab" aria-controls="sec1"
                                                                                                    aria-selected="true">INSTALLATIONS</a>
                                                                                            </li>
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link" id="indoor2-tab" data-toggle="tab" href="#indoor2" role="tab" aria-controls="sec2"
                                                                                                    aria-selected="false">CBSD</a>
                                                                                            </li>
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link" id="indoor3-tab" data-toggle="tab" href="#indoor3" role="tab" aria-controls="sec3"
                                                                                                    aria-selected="false">OPTIONAL</a>
                                                                                            </li>
                                                                                            <li className="nav-item">
                                                                                                <a className="nav-link" id="indoor4-tab" data-toggle="tab" href="#indoor4" role="tab" aria-controls="sec4"
                                                                                                    aria-selected="false">OTHERS</a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-content" id="secTab">
                                                                                    <div className="tab-pane fade show active" id="indoor1" role="tabpanel" aria-labelledby="indoor1-tab">
                                                                                        <div className='row'>
                                                                                            <div className='row '>
                                                                                                <div className='col-md-12' >
                                                                                                    <div className="row m-t-20 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                        {installation_list.map((installation: any) => {
                                                                                                            if (installation.units !== "") {
                                                                                                                unit = "(" + installation.units + ")"
                                                                                                            } else {
                                                                                                                unit = ""
                                                                                                            }
                                                                                                            if (installation.propertyName === "latitude" || installation.propertyName === "longitude" || installation.propertyName === "horizantal_accuracy" || installation.propertyName === "vertical_accuracy") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            } else if (installation.propertyName === "height") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                        <span>
                                                                                                                            <i className="imagelink fa fa-picture-o" id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                        </span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            }
                                                                                                            else if (installation.propertyName === "haat") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                            else if (installation.propertyName === "height_type") {
                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                    <select className="floating-select selectarrow b-r-0" value={installation.value} style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => { this.height_type_change(e, "heightypeid3") }}>
                                                                                                                        <option value="" hidden>{installation.displayName} {unit}</option>
                                                                                                                        <option value="HEIGHT_TYPE_AGL">AGL</option>
                                                                                                                        <option value="HEIGHT_TYPE_AMSL">AMSL</option>
                                                                                                                    </select>
                                                                                                                    <label id="heightypeid3">{installation.displayName}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                    <div className="row justify-content-left m-l-30" style={{ paddingLeft: '54px' }}>
                                                                                                        {installation_list.map((installation: any) => {
                                                                                                            if (installation.units !== "") {
                                                                                                                unit = "(" + installation.units + ")"
                                                                                                            } else {
                                                                                                                unit = ""
                                                                                                            }
                                                                                                            if (installation.propertyName === "antenna_gain" || installation.propertyName === "antenna_beamwidth" || installation.propertyName === "eirp_capability" || installation.propertyName === "antenna_model") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-r-0 b-t-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                            else if (installation.propertyName === "indoor_deployment") {
                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                    <select className="floating-select selectarrow b-t-0" value={installation.value} style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => { this.indoor_deployment(e, "indoordep3") }}>
                                                                                                                        <option value="" hidden>{installation.displayName} {unit}</option>
                                                                                                                        <option value="1">True</option>
                                                                                                                        <option value="0">False</option>
                                                                                                                    </select>
                                                                                                                    <label id="indoordep3">{installation.displayName}</label>
                                                                                                                </div>
                                                                                                            } else if (installation.propertyName === "antenna_azimuth") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0 b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" style={{ paddingRight: '0px' }} htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                        <span>
                                                                                                                            <i className="imagelink fa fa-picture-o" style={{ paddingLeft: '3px' }} id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                        </span>
                                                                                                                    </label>

                                                                                                                </div>
                                                                                                            } else if (installation.propertyName === "down_tilt") {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0  b-r-0" id={installation.propertyName} value={installation.value} style={{ borderRadius: '0px' }} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}
                                                                                                                        <span>
                                                                                                                            <i className="imagelink fa fa-picture-o" id="data-tab" onClick={(e: any) => this.showImageModel(installation.image_value, installation.propertyName)}></i>
                                                                                                                        </span>
                                                                                                                    </label>

                                                                                                                </div>

                                                                                                            }

                                                                                                        })}
                                                                                                    </div>
                                                                                                    <div className="row m-b-10 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                        {installation_list.map((installation: any) => {
                                                                                                            if (installation.units !== "") {
                                                                                                                unit = "(" + installation.units + ")"
                                                                                                            } else {
                                                                                                                unit = ""
                                                                                                            }
                                                                                                            if (installation.propertyName === 'antenna_pattern') {
                                                                                                                return <div className="form-label-group m-0 sectordetails">
                                                                                                                    <input type="text" name={installation.propertyName} className="paramtext-mob b-t-0" style={{ width: '163px' }} id={installation.propertyName} value={installation.value} placeholder={installation.displayName} onChange={(e: any) => { this.onChange(e, 'installation') }} />
                                                                                                                    <label className="importdetails" htmlFor={installation.propertyName}>{installation.displayName} {unit}</label>
                                                                                                                </div>
                                                                                                            }

                                                                                                        })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="tab-pane fade show" id="indoor2" role="tabpanel" aria-labelledby="indoor2-tab">
                                                                                        <div className='row '>
                                                                                            <div className='col-md-1'>
                                                                                            </div>
                                                                                            <div className="col-md-11">
                                                                                                <div className='row m-t-20 m-b-10 m-l-30' style={{ paddingLeft: "40px" }}>
                                                                                                    {
                                                                                                        cbsd_list.map((cbsd: any, index: any) => {
                                                                                                            if (cbsd.dataType === "string") {
                                                                                                                return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                    <input type="text" name={cbsd.propertyName} className={`paramtext-mob ${cbsd_list.length - 1 === index ? '' : 'b-r-0'}`} id={cbsd.propertyName} value={cbsd.value} style={{ borderRadius: '0px' }} placeholder={cbsd.displayName} onChange={(e: any) => { this.onChange(e, 'cbsd') }} />
                                                                                                                    <label className="importdetails" htmlFor={cbsd.propertyName}>{cbsd.displayName}</label>
                                                                                                                </div>
                                                                                                            } else {
                                                                                                                return <div>
                                                                                                                    <div className="floating-label sectordetails">
                                                                                                                        <select name={cbsd.propertyName} value={this.state.selected_vendor_name} style={{ borderRadius: '0px' }} className="floating-select selectarrow b-r-0" onChange={(e) => this.setAssetVendor(e, "vendor", "cbsd_vendor3")} >
                                                                                                                            <option hidden>Vendor</option>
                                                                                                                            {this.state.assetVendorList.map((vendor: any) =>
                                                                                                                                <option value={vendor} >{vendor}</option>
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                        <label id="cbsd_vendor3">Vendor</label>
                                                                                                                    </div>
                                                                                                                    <div className="floating-label sectordetails">
                                                                                                                        <select name={cbsd.propertyName} value={this.state.selected_model_name} style={{ borderRadius: '0px' }} className="floating-select selectarrow b-r-0" onChange={(e) => this.setAssetModel(e, "cbsd_model3")} >
                                                                                                                            <option hidden>Model</option>
                                                                                                                            {this.state.assetModelList.map((vendor: any) =>
                                                                                                                                <option value={vendor['model_name']} >{vendor['model_name']}</option>
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                        <label id="cbsd_model3">Model</label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="tab-pane fade show" id="indoor3" role="tabpanel" aria-labelledby="indoor3-tab">
                                                                                        <div className='row '>
                                                                                            <div className='col-md-12'>
                                                                                                <div className="row m-t-20 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                    {
                                                                                                        optional_list.map((optional: any, index: any) => {
                                                                                                            if (optional.dataType === "string") {
                                                                                                                return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                    <input type="text" className={`paramtext-mob ${optional_list.length - 1 === index ? '' : 'b-r-0'}`} name={optional.propertyName} id={optional.propertyName} value={optional.value} style={{ borderRadius: '0px' }} placeholder={optional.displayName} onChange={(e: any) => { this.onChange(e, 'optional') }} />
                                                                                                                    <label className="importdetails" htmlFor={optional.propertyName}>{optional.displayName}</label>
                                                                                                                </div>
                                                                                                            } else {
                                                                                                                if (optional.propertyName === "call_sign") {
                                                                                                                    return <div className="floating-label sectordetails">
                                                                                                                        <select className="floating-select selectarrow b-r-0" style={{ borderRadius: '0px', height: '45px' }} onChange={(e) => { this.select_onChange(e, "optional", optional.propertyName, "optcalsign3") }}>
                                                                                                                            <option value="" hidden>{optional.displayName}</option>
                                                                                                                            <option value="1">1</option>
                                                                                                                            <option value="2">2</option>
                                                                                                                        </select>
                                                                                                                        <label id="optcalsign3">{optional.displayName}</label>
                                                                                                                    </div>
                                                                                                                } else if (optional.propertyName === "air_interface") {
                                                                                                                    return <div className="floating-label sectordetails">
                                                                                                                        <select value={optional.value} className="floating-select selectarrow b-r-0" style={{ borderRadius: '0px', height: '45px' }} onChange={(e: any) => this.air_interface(e, "optairinter3")}>
                                                                                                                            <option value="" hidden>{optional.displayName}</option>
                                                                                                                            <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                                                                                                                            <option value="E_UTRA">E_UTRA</option>
                                                                                                                            <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                                                                                                                            <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                                                                                                                            <option value="NR">NR</option>
                                                                                                                            <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                                                                                                                            <option value="REDLINE">REDLINE</option>
                                                                                                                            <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                                                                                                                        </select>
                                                                                                                        <label id="optairinter3">{optional.displayName}</label>
                                                                                                                    </div>
                                                                                                                } else if (optional.propertyName === "cbsd_category") {
                                                                                                                    return <div className="floating-label ">
                                                                                                                        <select value={optional.value} className="floating-select selectarrow b-r-0" style={{ width: '180px', borderRadius: '0px', height: '45px' }} onChange={(e) => { this.cbsd_category(e, "optcbsdcat3") }}>
                                                                                                                            <option value="" hidden>{optional.displayName + optional.units}</option>
                                                                                                                            <option value="DEVICE_CATEGORY_A">DEVICE_CATEGORY_A</option>
                                                                                                                            <option value="DEVICE_CATEGORY_B">DEVICE_CATEGORY_B</option>
                                                                                                                        </select>
                                                                                                                        <label id="optcbsdcat3">{optional.displayName}</label>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="row m-b-10 m-l-30 justify-content-left" style={{ paddingLeft: '54px' }}>
                                                                                                    {
                                                                                                        installation_list.map((installation: any) => {
                                                                                                            if (installation.propertyName === "measurement_capability") {
                                                                                                                return <div className="floating-label sectordetails">
                                                                                                                    <select style={{ borderRadius: '0px', width: '163px' }} value={installation.value} className="floating-select selectarrow b-t-0" onChange={(e: any) => this.measurement_capability(e, "measurecapab3")} >
                                                                                                                        <option hidden>WITH GRANT</option>
                                                                                                                        <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT" >WITH_GRANT</option>
                                                                                                                        <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT" >WITHOUT_GRANT</option>
                                                                                                                    </select>
                                                                                                                    <label id="measurecapab3">{installation.displayName}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="tab-pane fade show" id="indoor4" role="tabpanel" aria-labelledby="indoor4-tab">
                                                                                        <div className='row '>
                                                                                            <div className='col-md-5'>
                                                                                            </div>
                                                                                            <div className='col-md-7' >
                                                                                                <div className='row m-t-20 m-b-10'>
                                                                                                    {
                                                                                                        others_list.map((others: any, index: any) => {
                                                                                                            if (others.propertyName == "earfcn") {
                                                                                                                return <div className="form-label-group m-0 sectordetails brd2">
                                                                                                                    <input type="text" name={others.propertyName} className={`paramtext-mob ${others_list.length - 1 === index ? '' : 'b-r-0'}`} id={others.propertyName} value={others.value} style={{ borderRadius: '0px' }} placeholder={others.displayName} onChange={(e: any) => { this.onChange(e, 'others') }} />
                                                                                                                    <label className="importdetails" htmlFor={others.propertyName}>{others.displayName}</label>
                                                                                                                </div>
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </React.Fragment>

                                                        })
                                                        }

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >

                    <Modal show={this.state.show} handleClose={this.hideModal}>
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row' style={{ padding: '0 10px' }}>
                                        <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>IMPORT FILE</h6>
                                        <i className="material-icons modalclose imgclose-file m-l-5" onClick={this.hideModal} data-dismiss="modal" aria-label="Close">clear</i>
                                    </div>
                                    <div className='row m-t-20 justify-content-center' style={{ padding: '0 10px' }}>

                                        <div className='col-md-12 p-0'>
                                            <Dropzone onDrop={this.onDrop}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className='dropzone-set'>
                                                        <div style={{ height: '125px', width: "260px", marginLeft: "8px" }}{...getRootProps({ className: classDName })}>
                                                            <input {...getInputProps()} />
                                                            {files.length ?
                                                                <div>
                                                                    <div className="m-t-30">
                                                                        <span className="justify-content-center" style={{ color: "#00D56E", display: 'flex' }}>
                                                                            {files}
                                                                        </span>
                                                                    </div>
                                                                    <div className="m-t-10">
                                                                        <span><a style={{ color: "#8F9FAC", fontSize: "14px" }}>Change File</a></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div className="m-t-5" style={{ textAlign: "center" }}>
                                                                        <i className="fas fa-plus-circle text-center" style={{ fontSize: '40px', color: '#ccc' }}></i>
                                                                    </div>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <span className='text-center' style={{ fontSize: '14px', color: '#9b9b9b' }}>Drag and drop files here</span><br />
                                                                    </div>
                                                                    <div className="m-t-5" style={{ textAlign: "center", paddingBottom: "10px" }}>
                                                                        <span className="font14 cursor" style={{ color: '#0059b2' }}>Browse</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    {files.length ?
                                        <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                            <button className='btn cardbtn' style={{ width: '268px' }} onClick={() => { this.import_files() }}>IMPORT</button>
                                        </div> :
                                        <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                            <button className='btn cardbtn' style={{ width: '268px' }} disabled={true} >IMPORT</button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <IonAlert
                        isOpen={this.state.alertbox}
                        onDidDismiss={() => this.setShowAlert(false)}
                        message={this.state.warnMessage}
                        buttons={['OK',]}
                    />
                    <IonLoading
                        isOpen={this.state.is_loader === 1}
                        onDidDismiss={() => this.setShowLoading(false)}
                        message={'Loading...'}
                        duration={5000}
                    />
                    <Modal show={this.state.status_show}>
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row' style={{ padding: '0 10px' }}>
                                        <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>IMPORT SITE DATA STATUS</h6>
                                        <i className="material-icons modalclose imgclose-file m-l-5" onClick={this.status_hideModal} data-dismiss="modal" aria-label="Close">clear</i>
                                    </div>
                                    <table className="table sitecollapse m-t-20">
                                        <thead>
                                            <tr >
                                                <th className="w15" >Asset Name</th>
                                                <th className="w20" >Status</th>
                                            </tr>
                                            {
                                                status_list.map((status: any) => {
                                                    return <tr>
                                                        <td>{status.name}</td>
                                                        <td>{status.status}</td>
                                                    </tr>
                                                })
                                            }
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Dropzone onDrop={(e: any) => { this.onDrop(e, this.state.selected_image_type) }} >
                        {({ getRootProps, getInputProps }) => (
                            <section className='dropzone-set'>
                                {
                                    this.state.selected_image_type === "down_tilt" ? <div style={{ 'display': 'none' }} id="down_tilt_file" {...getRootProps({ className: classDName })} >
                                        <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                    </div> : this.state.selected_image_type === "height" ? <div style={{ 'display': 'none' }} id="height_image_file" {...getRootProps({ className: classDName })} >
                                        <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                    </div> : <div style={{ 'display': 'none' }} id="azimuth_image_file" {...getRootProps({ className: classDName })} >
                                                <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                            </div>
                                }
                                <div>
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    <Modal show={this.state.image_show}>
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row' style={{ padding: '0 10px' }}>
                                        <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '14px' }}>{this.state.selected_image_type === "antenna_azimuth" ? "ANTENNA AZIMUTH IMAGE" : this.state.selected_image_type === "height" ? "HEIGHT IMAGE" : "DOWN TILT IMAGE"}</h6>
                                        <i className="fas fa-pen fa-upload p-r-15" style={{ color: '#0272fb', fontSize: '16px', cursor: "pointer", marginLeft: "10px", marginTop: "2px" }} title="Edit" onClick={this.imageUploadModel} ></i>
                                        <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px', cursor: "pointer", marginRight: "8px" }} title="Delete" onClick={(e) => this.showModal1("image", "")}></i>
                                        <i className="material-icons resetcloseicon imgclose-file m-l-5" onClick={this.hideImageModal} data-dismiss="modal" aria-label="Close">clear</i>
                                    </div>
                                    {
                                        this.state.image_value !== "" ? <div className='m-t-10 text-center' style={{ position: 'relative' }}>
                                            <img className='apimage' src={this.state.image_value} height="300" width="450" />
                                        </div> : <div className='m-t-10 text-center' style={{ position: 'relative', height: "300", width: "450" }}>
                                                <span>Upload Image to View</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal show={this.state.card1} handleClose={this.hideModal1}>
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row' style={{ padding: '0 10px' }}>
                                        <h6 className="modal-title" style={{ marginTop: '0px' }}>CONFIRMATION</h6>
                                        <i className="material-icons modalclose imgclose-file" onClick={(e: any) => { e.preventDefault(); this.hideModal1() }} data-dismiss="modal" aria-label="Close">clear</i>
                                    </div>
                                    <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                        {
                                            this.state.delete_type === "asset" && this.state.select_asset_type_id === 1 || this.state.select_asset_type_id === 2 ? <span className='modaltext'>Are you sure you want to delete this CBSD?</span> : this.state.delete_type === "images" ?
                                                <span className='modaltext'>Are you sure you want to delete this Image?</span> : this.state.delete_type === "asset" && this.state.select_asset_type_id === 3 ? <span className='modaltext'>Are you sure you want to delete this CPE?</span> : <span className='modaltext'>Are you sure you want to delete this Sector?</span>
                                        }
                                    </div>
                                    <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                        <div className='col-6 p-r-5 p-l-0'>
                                            <button className='btn modalbtn btn-secondary-c3' onClick={(e: any) => { e.preventDefault(); this.hideModal1() }}  >No</button>
                                        </div>
                                        <div className='col-6 p-l-5'>
                                            {
                                                this.state.delete_type === "asset" ? <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.delete_asset() }}  >Yes</button> :
                                                    this.state.delete_type === "images" ? <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.delete_image() }}  >Yes</button> : <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.update_asset(e, this.state.selected_data) }}  >Yes</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div >
            </div >
        );
    }
}
