import React, { Component } from 'react'
import "./ap-parameters.css";
import { IonPage, IonContent, withIonLifeCycle, IonAlert, IonLoading } from '@ionic/react';
import BackIcon from '../assets/img/go-back-icon-img.png'

class RFcalculator extends React.Component {

    state: any = {};
    props: any = {};
    constructor(props: any) {

        super(props);
        this.state = {

            px: "",
            db: "",
            dbmToWatts: "",
            processUnit: '1',
            eirpUnit: "1",
            freq: "",
            wave: "",
            waveInches: "",
            freqUnit: "3"
        };
        this.handleChange = this.handleChange.bind(this)
        this.eirpCalculate = this.eirpCalculate.bind(this)
        this.waveCalculate = this.waveCalculate.bind(this)
        this.reset = this.reset.bind(this)
        this.freqreset = this.freqreset.bind(this)

    }
    handleChange = (e: any) => {
        this.setState({
            processUnit: e.target.value
        })
        setTimeout(() => {
            if (this.state.px != "") {
                this.calculate(this.state.px)

            }
        }, 300)


    }

    onKeyDown = (e: any) => {
        this.setState({
            px: e.target.value
        })

        if (e.target.value > 0)
            this.calculate(e.target.value)


    }
    calculate = (valInput: any) => {
        let val: any = ""
        if (this.state.processUnit === '2') {
            val = valInput * 1000
        } else {
            val = valInput
        }
        this.convertTodbm(val)
        this.convertTodbw(val)
    }

    convertTodbm = (val: any) => {
        let dbm = 10 * Math.log10(val)
        this.setState({
            dbm: dbm.toFixed(2)
        }, () => this.calculateDbmScalar())
    }
    convertTodbw = (val: any) => {
        let dbw = 10 * Math.log10(val / 1000)
        this.setState({
            dbw: dbw.toFixed(2)
        }, () => this.calculateDbwScalar())
    }
    calculateDbmScalar = () => {
        let dbmScalar = Math.pow(10, (this.state.dbm / 10))
        this.setState({
            dbmScalar: dbmScalar.toFixed(2)
        })
    }
    calculateDbwScalar = () => {
        let dbwScalar = Math.pow(10, (this.state.dbw / 10))
        this.setState({
            dbwScalar: dbwScalar.toFixed(2)
        })
    }


    // EIRP && wavelength
    eirpDDChange = (e: any) => {
        this.setState({
            eirpUnit: e.target.value
        })

    }
    freqChange = (e: any) => {
        this.setState({
            freqUnit: e.target.value
        })

    }
    inputChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    convertdBmTowatts = (val: any) => {

        return ((Math.pow(10, val / 10) / 1000))
    }
    eirpCalculate() {
        let eirp: any = ""
        if (this.state.eirpUnit == "1") {
            let dbmToWatts = this.convertdBmTowatts(this.state.watt)
            console.log(dbmToWatts)
            eirp = 10 * Math.log10(dbmToWatts * 1000) - parseInt(this.state.loss) + parseInt(this.state.gain)

        } else {
            eirp = 10 * Math.log10(parseInt(this.state.watt) * 1000) - parseInt(this.state.loss) + parseInt(this.state.gain)
        }

        console.log(eirp)
        this.setState({
            eirp: eirp.toFixed(2)
        })
    }
    convertdtomHZ = (val: any) => {
        console.log(this.state.freqUnit)
        let mHz: any = ""
        if (this.state.freqUnit == "1") {
            mHz = val / 1000000
        }

        if (this.state.freqUnit == "2") {
            mHz = val / 1000
        }
        if (this.state.freqUnit == "4") {
            mHz = val * 1000
        }
        return mHz

    }
    waveCalculate() {
        let wave: any = ""
        let waveInches: any = ""
        if (this.state.freqUnit == "3") {
            wave = 300000000 / (this.state.freq * 1000000)

        }


        else {
            let mHz = this.convertdtomHZ(this.state.freq)
            console.log(mHz)
            wave = 300000000 / (mHz * 1000000)
        }
        waveInches = wave * 39.37007874
        console.log(wave)
        this.setState({
            wave: wave.toFixed(2),
            waveInches: waveInches.toFixed(2)
        })
    }
    reset() {
        this.setState({
            loss: "",
            eirp: "",
            gain: "",
            watt: ""
        })
    }
    freqreset() {
        this.setState({
            freq: "",
            wave: "",
            waveInches: ""

        })
    }
    render() {
        return (
            <div className="mobonly">
                <IonPage>
                    <IonContent>
                        <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                            <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={this.props.hideRFOverlay} src={BackIcon} />
                            <span className="apheader">RF Calculator</span>
                        </div>
                        <div className='container-fluid apdash calcfont' style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 47px)' }}>
                            <h4 className="m-t-5">RF Power</h4>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div className="row m-t-10">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" id="px" onChange={(e: any) => { this.onKeyDown(e) }} name="px" className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.handleChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">mWatt(s)</option>
                                                <option value="2">Watt(s)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row m-t-10">
                                        <div className="col-6 p-r-5">
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" value={this.state.dbm} id="dbm" name="dbm" className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dBm" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="dbw" name="dbw" value={this.state.dbw} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dBW" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Logarithm to Scalar</h6>
                                    <div className="row">
                                        <div className="col-6 calculator p-r-5">
                                            <input type="number" id="freq" name="freq" value={this.state.dbmScalar} className="calculatorinput" style={{ borderRadius: '6px' }} />
                                        </div>
                                        <div className="col-6 calculator p-l-5">
                                            <input type="number" id="freq" name="freq" value={this.state.dbwScalar} className="calculatorinput" style={{ borderRadius: '6px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 className="m-t-5">Effective Isotropic Radiated Power(EIRP)</h4>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <h6 className="font14 m-t-10">Output Power - P<sub>T</sub> (dBm)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" id="watt" name="watt" value={this.state.watt} className="calculatorinput" onChange={(e: any) => { this.inputChange(e) }} />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => { this.eirpDDChange(e) }} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">dBm</option>
                                                <option value="2">Watt(s)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Cable & Connector Loss - L<sub>c</sub> (dB)</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="loss" name="loss" value={this.state.loss} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="watt" name="watt" value="dB" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Antenna Gain - G<sub>a</sub> (dBi)</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="gain" name="gain" value={this.state.gain} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="watt" name="watt" value="dBi" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <div className="row m-t-20 text-center">
                                        <div className="col-6 p-r-5">
                                            <button className="btn modalbtn" onClick={this.eirpCalculate}>Calculate</button>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <button className="lightbtnmob modalbtn" onClick={this.reset}>Reset</button>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">EIRP (dBm)</h6>
                                    <div className="row m-b-5">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" value={this.state.eirp} id="eirp" name="eirp" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="watt" name="watt" value="dBm" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 className="m-t-5">Frequency to Wavelength</h4>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <h6 className="font14 m-t-10">Frequency (f)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" value={this.state.freq} onChange={(e: any) => { this.inputChange(e) }} id="freq" name="freq" className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => { this.freqChange(e) }} className="form-control calculatorselect calcinlineselect">
                                                <option value="3">MHz</option>
                                                <option value="1">Hz</option>
                                                <option value="2">KHz</option>
                                                <option value="4">GHz</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row m-t-20 text-center">
                                        <div className="col-6 p-r-5">
                                            <button className="btn modalbtn" onClick={this.waveCalculate}>Calculate</button>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <button className="lightbtnmob modalbtn" onClick={this.freqreset}>Reset</button>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Wavelength</h6>
                                    <div className="row m-b-5">
                                        <div className="col-9 p-r-0 calculator  ">
                                            <input type="number" value={this.state.wave} id="watt" name="watt" className="calculatorinput" />
                                        </div>
                                        <div className="col-3 p-l-0">
                                            <input type="text" disabled id="watt" name="watt" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <div className="row m-b-5">
                                        <div className="col-9 p-r-0 calculator  ">
                                            <input type="number" value={this.state.waveInches} id="watt" name="watt" className="calculatorinput" />
                                        </div>
                                        <div className="col-3 p-l-0">
                                            <input type="text" disabled id="watt" name="watt" value="inches" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonContent>
                </IonPage>
            </div >
        );
    }
}



export default withIonLifeCycle(RFcalculator);