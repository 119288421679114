import React, { Component } from 'react'
import Back from '../assets/img/left-arrow.svg'
import heat from '../assets/img/heaticon.png'
import $ from "jquery";
import "./building.css"
import Modal from './modal';
import { IonPage, IonContent } from '@ionic/react';

const INITIAL_STATE = {
    show: false,
    modalName: '',
    libreSpeedTestEnable: true,
    presetDownloadEnable: false,
    presetUploadEnable: false
}

export default class AppSettings extends React.Component {
    state: any = {};
    props: any = {};
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    showModal = (param: any) => {
        console.log(param);
        
        if (!this.state.libreSpeedTestEnable) {
            if (this.state.presetDownloadEnable && param == 'preset-download') {
                this.setState({ show: true, modalName: param })
            }
            if (!this.state.presetDownloadEnable && param == 'download-server') {
                this.setState({ show: true, modalName: param })
            }
            if (this.state.presetUploadEnable && param == 'preset-upload') {
                this.setState({ show: true, modalName: param })
            }
            if (!this.state.presetUploadEnable && param == 'upload-server') {
                this.setState({ show: true, modalName: param })
            }
        }
        if (param == 'website') {
            this.setState({ show: true, modalName: param })
        }

    }
    hideModal = () => {
        this.setState({
            show: false,
            modalName: ''
        })
    }
    speedTestLibre = (e: any) => {
        // console.log(e.refs.libreSpeedTest.checked);
        this.setState({ libreSpeedTestEnable: e.refs.libreSpeedTest.checked })
    }
    changePresetUpload = (e: any) => {
        // console.log(e.refs.libreSpeedTest.checked);
        this.setState({ presetUploadEnable: e.refs.presetUpload.checked })
    }
    changePresetDownload = (e: any) => {
        // console.log(e.refs.libreSpeedTest.checked);
        this.setState({ presetDownloadEnable: e.refs.presetDownload.checked })
    }
    render() {

        return (
            <IonPage>
                <IonContent>
            <div>
                <div className="webonly">
                </div>
                <div className="mobonly">
                    <div className=''>
                        <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                            <img className='backicon-mob'  style={{ width: '43px', paddingTop: '6px' }} src={Back} onClick={()=>{this.props.history.push('/map')}}/>
                            <span className="apheader">APP SETTINGS</span>
                        </div>

                    </div>
                    <div className="card-body build-info-mob" style={{ position: 'relative',boxShadow:"none" }}>
                        <div className='row'>
                            <div className='col-12' style={{ marginLeft: "20px" }}>
                                <span className='app-settings-header color-theme-blue'>Server</span>
                            </div>
                        </div>
                        <div className='row m-t-10 form-group' style={{ padding: "0px 35px" }} onClick={(e: any) => { e.preventDefault(); this.showModal('website') }} >
                            <div className="row" style={{ width: "100%", fontWeight: "bold" }}>
                                <div className="col-12">
                                    Website
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    Octaware
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-12' style={{ marginLeft: "20px" }}>
                                <span className='app-settings-header color-theme-blue'>Network Type</span>
                            </div>
                        </div>
                        <div className='row m-t-10 form-group' style={{ padding: "0px 20px" }}>
                            <div className="col-10" style={{ fontWeight: "bold" }}>
                                Any Connection
                            </div>
                            <div className="col-2">
                                <input type="checkbox" name="anyConnection" id="anyConnection" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12' style={{ marginLeft: "20px" }}>
                                <span className='app-settings-header color-theme-blue'>Speed Test Library</span>
                            </div>
                        </div>
                        <div className='row m-t-10 form-group' style={{ padding: "0px 20px" }}>
                            <div className="col-10" style={{ fontWeight: "bold" }}>
                                Libre Speed Test
                            </div>
                            <div className="col-2">
                                <input type="checkbox" name="speedTest" id="speedTest" ref="libreSpeedTest" checked={this.state.libreSpeedTestEnable} onChange={(e: any) => { this.speedTestLibre(this) }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12' style={{ marginLeft: "20px" }}>
                                <span className={`app-settings-header ${this.state.libreSpeedTestEnable ? 'color-disabled' : 'color-theme-blue'}`}>Download Endpoint</span>
                            </div>
                        </div>
                        <div className={`row m-t-10 form-group  ${this.state.libreSpeedTestEnable || this.state.presetDownloadEnable ? 'color-disabled' : ''}`} style={{ padding: "0px 35px" }} onClick={(e: any) => { e.preventDefault(); this.showModal('download-server') }}>
                            <div className="row" style={{ width: "100%", fontWeight: "bold" }}>
                                <div className="col-12">
                                    Download Server
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    http://speedtest.tele2/
                                </div>
                            </div>
                        </div>
                        <div className={`row m-t-10 form-group ${this.state.libreSpeedTestEnable ? 'color-disabled' : ''}`} style={{ padding: "0px 20px" }}>
                            <div className="col-10" style={{ fontWeight: "bold" }}>
                                Use Preset Download
                            </div>
                            <div className="col-2">
                                <input type="checkbox" name="presetDownload" id="presetDownload" ref="presetDownload" checked={this.state.presetDownloadEnable} disabled={this.state.libreSpeedTestEnable} onChange={(e: any) => { this.changePresetDownload(this) }} />
                            </div>
                        </div>
                        <div className={`row m-t-10 form-group   ${this.state.libreSpeedTestEnable || !this.state.presetDownloadEnable ? 'color-disabled' : ''}`} style={{ padding: "0px 35px" }} onClick={(e: any) => { e.preventDefault(); this.showModal('preset-download') }}>
                            <div className="row" style={{ width: "100%", fontWeight: "bold" }}>
                                <div className="col-12">
                                    Preset Download Server
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    http://speedtest.tele2/
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12' style={{ marginLeft: "20px" }}>
                                <span className={`app-settings-header ${this.state.libreSpeedTestEnable ? 'color-disabled' : 'color-theme-blue'}`}>Upload Endpoint</span>
                            </div>
                        </div>
                        <div className={`row m-t-10 form-group   ${this.state.libreSpeedTestEnable || this.state.presetUploadEnable ? 'color-disabled' : ''}`} style={{ padding: "0px 35px" }} onClick={(e: any) => { e.preventDefault(); this.showModal('upload-server') }}>
                            <div className="row" style={{ width: "100%", fontWeight: "bold" }}>
                                <div className="col-12">
                                    Upload Server
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    http://speedtest.tele2/
                                </div>
                            </div>
                        </div>
                        <div className={`row m-t-10 form-group   ${this.state.libreSpeedTestEnable ? 'color-disabled' : ''}`} style={{ padding: "0px 20px" }}>
                            <div className="col-10" style={{ fontWeight: "bold" }}>
                                Use Preset Upload
                            </div>
                            <div className="col-2">
                                <input type="checkbox" name="presetUpload" id="presetUpload" ref="presetUpload" checked={this.state.presetUploadEnable} disabled={this.state.libreSpeedTestEnable} onChange={(e: any) => { this.changePresetUpload(this) }} />
                            </div>
                        </div>
                        <div className={`row m-t-10 form-group   ${this.state.libreSpeedTestEnable || !this.state.presetUploadEnable ? 'color-disabled' : ''}`} style={{ padding: "0px 35px" }} onClick={(e: any) => { e.preventDefault(); this.showModal('preset-upload') }}>
                            <div className="row" style={{ width: "100%", fontWeight: "bold" }}>
                                <div className="col-12">
                                    Preset Upload Server
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    http://speedtest.tele2/
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                <Modal show={this.state.modalName === 'website'} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Website</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div style={{ margin: "20px 0px" }}>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> Octaware
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> Internal Debugging
                                </div>
                                </div>

                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn' style={{ width: '268px' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.modalName === 'preset-upload'} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Preset Upload Server</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div style={{ margin: "20px 0px" }}>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> https://c3spectra.octaware.com/speedtest/download/1mb.jpg
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> http://ipv4.bouygues.testdebit.info/5m.iso
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> http://ipv4.ikoula.testdebit.info/1m.iso
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> ftp://speedtest.tele2.net/upload/
                                </div>
                                </div>

                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn' style={{ width: '268px' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.modalName === 'preset-download'} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Preset Download Server</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div style={{ margin: "20px 0px" }}>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> https://c3spectra.octaware.com/speedtest/download/1mb.jpg
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> http://ipv4.bouygues.testdebit.info/5m.iso
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> http://ipv4.ikoula.testdebit.info/1m.iso
                                </div>
                                    <div style={{ width: "100%" }}>
                                        <input type="radio" name="website" /> ftp://speedtest.tele2.net/upload/
                                </div>
                                </div>

                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn' style={{ width: '268px' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.modalName === 'download-server'} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Download Server</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div style={{ margin: "20px 0px" }}>
                                    <input type="text" className="text-box" placeholder="Download Server url" />
                                </div>
                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className="col-6">
                                        <button className='btn cardbtn' style={{ width: '100%' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Cancel</button>
                                    </div>
                                    <div className="col-6">
                                        <button className='btn cardbtn' style={{ width: '100%' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.modalName === 'upload-server'} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Upload Server</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div style={{ margin: "20px 0px" }}>
                                    <input type="text" className="text-box" placeholder="Upload Server url" />
                                </div>
                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className="col-6">
                                        <button className='btn cardbtn' style={{ width: '100%' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Cancel</button>
                                    </div>
                                    <div className="col-6">
                                        <button className='btn cardbtn' style={{ width: '100%' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            </IonContent>
            </IonPage>
        );
    }
}