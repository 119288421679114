import React, { Component } from 'react'
import { IonTextarea, IonPage, IonContent, IonAlert, IonLoading, withIonLifeCycle, IonLabel, IonItem } from '@ionic/react';
import Back from '../assets/img/left-arrow.svg'
import Heat from '../assets/img/heaticon.png'
import Dropz from './DropZ';
import BackIcon from '../assets/img/go-back-icon-img.png'
import geo from '../assets/img/target-icon.png'
import qricon from '../assets/img/bar-code-img-icon.png'
import "./dashboard.css";
import { isBrowser, isMobile } from "react-device-detect";
import Dropzone from 'react-dropzone';
import { requestAPICall } from "../service/apiHandler";
import { breakStatement } from '@babel/types';
import store from '../store';
import camicon from '../assets/img/camera-icon-img.png'
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { Redirect } from 'react-router-dom';
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from '../constants/actionTypes';
// import { connect } from 'tls';
import { connect } from 'react-redux';
const { Camera } = Plugins;
var add_cp2_properties: any = []
var add_cp3_properties: any = []
var is_submit_form = false
var CPE_Data: any = []
var unit: any = ""


class AddCPE extends React.Component {
    cpeFocus: any = []
    state: any = {
        name: "",
        asset_id: "",
        description: "",
        image: "",
        serial_id: "",
        cpe_name: "",
        alertbox: false,
        is_send_request: false,
        files: [],
        is_loader: 0,
        disable_button: true,
        field_disable: true,
        properties: [],
        assetAction: 'add',
        asset_cpe_id: "",
        add_cp1_properties: [],
        selected_asset_id: "",
        assetTypeId: "",
        asset_serail_id: ""
        // coordinates : {}
    };
    props: any = {};
    reduxStore: any = store.getState()['general'];
    constructor(props: any) {
        super(props)
        this.cpeFocus = []
        this.submit_parameters = this.submit_parameters.bind(this)
        this.onChange = this.onChange.bind(this)
        this.validate_request = this.validate_request.bind(this)
    }


    ionViewDidEnter() {
        this.initializeComponent()
    }
    componentDidMount() {
        // if (isBrowser) {
        this.initializeComponent()
        // onClick={() => this.takePicture()}
        // }
    }

    initializeComponent = () => {
        // this.takePicture()
        // this.openScanner()
        console.log(this.props, 'eeeeeeeeeeeeeeeeee')
        this.reduxStore = store.getState()['general']
        console.log(this.reduxStore);
        console.log(this.reduxStore['assetAction']);
        this.setState({
            assetAction: this.reduxStore['assetAction'],
            files: [],
            properties: [],
            selectedAssetId: this.props.selectedAssetId,
            assetTypeId: this.props.typeId
        })
        this.getProperties()
    }

    openScanner = async () => {
        const data = await BarcodeScanner.scan();
        this.setState({ serial_id: data.text })
        this.onChange({ "target": { "name": "cpe_serial_id", "value": data.text } })
        this.onChange({ "target": { "name": "cpe_serial_id", "value": data.text } })
    };

    takePicture = async () => {
        console.log('opening camera');
        var image: any = await Camera.getPhoto({
            quality: 50,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl
        });
        // var imageUrl = image.webPath;
        console.log(image);
        image['fileName'] = 'camera_upload'
        image['uploadType'] = 'capture'
        image['name'] = 'IMG.' + image['format']
        this.onDrop([image])
    }

    getProperties = () => {
        let requestBody = {}
        var typeId = this.reduxStore['assetTypeId']
        if (this.reduxStore['assetAction'] === "edit") {
            requestAPICall("get_asset_editable_data", { asset_type: this.props.typeId, asset_id: this.props.selectedAssetId }, requestBody)
                .then(data => {
                    try {
                        var name = data["properties"].filter((property: any) => property.propertyName === "cpe_name")
                        // var requestList = data["properties"].filter((property: any) => property.propertyName !== "cpe_serial_id")
                        if (data.responseCode == 200) {
                            this.setState({
                                properties: data["properties"],
                                asset_id: this.props.selectedAssetId,
                                field_disable: false,
                                cpe_name: name[0].value
                            }, () => {
                                // this.setDefaults()
                            })

                        }
                    } catch (error) {

                    }
                })
        }
        else {
            if (this.reduxStore['tabType'] == "outdoor") {
                CPE_Data = localStorage.getItem('CPE_Outdoor_Data');
            }

            if (this.reduxStore['tabType'] == "indoor") {
                CPE_Data = localStorage.getItem('CPE_Indoor_Data');
            }
            if (CPE_Data == null) {
                requestAPICall("list_asset_properties", { asset_type_id: typeId }, requestBody)
                    .then(data => {
                        console.log(data)
                        if (data.responseCode == 200) {
                            this.setState({
                                properties: data["properties"]
                            })
                            if (this.reduxStore['tabType'] == "outdoor") {
                                localStorage.setItem('CPE_Outdoor_Data', JSON.stringify(data["properties"]));
                            }
                            if (this.reduxStore['tabType'] == "indoor") {
                                localStorage.setItem('CPE_Indoor_Data', JSON.stringify(data["properties"]));
                            }
                            if (isMobile) {
                                var properties = data.properties.map((property: any) => {
                                    if (property.propertyName === 'latitude') {
                                        property.value = this.reduxStore['coordinates']['latitude']
                                    } else if (property.propertyName === 'longitude') {
                                        property.value = this.reduxStore['coordinates']['longitude']
                                    }
                                    return property
                                })
                                this.setState({
                                    properties: data["properties"]
                                })
                            }
                        }
                    })
            } else {
                let parse_data = JSON.parse(CPE_Data)
                this.setState({
                    properties: parse_data
                })
                if (isMobile) {
                    var properties = parse_data.map((property: any) => {
                        if (property.propertyName === 'latitude') {
                            property.value = this.reduxStore['coordinates']['latitude']
                        } else if (property.propertyName === 'longitude') {
                            property.value = this.reduxStore['coordinates']['longitude']
                        }
                        return property
                    })
                    this.setState({
                        properties: parse_data
                    })
                }
            }
        }
    }

    goBack(deviceType: any) {
        if (isBrowser) {
            this.props.back()
        } else {
            this.props.history.push('/map')
        }
    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
        // if (this.state.assetAction == "edit") {
        //     this.goBack("")
        // }
    }

    setShowLoading = (st: number) => {
        this.setState({ is_loader: st })
    }
    validationRequest() {
        let check = false
        this.state.properties.forEach((property: any, key: any) => {
            if (!check) {
                if (property.value === "" && property.isRequired == true && property.isShowable == true) {
                    console.log(property)
                    this.cpeFocus[key].focus()
                    check = true
                    this.setState({
                        warnMessage: property.displayName + " cannot be empty",
                        alertbox: true
                    })

                }
            }

            // if (property.value !== "" && (property.propertyName == "cpe_name" || property.propertyName == "cpe_id" || property.propertyName == "serial_id" || property.propertyName == "customer_id" || property.propertyName == "customer_name" )){
            //     if (!(/^[a-zA-Z0-9.-]+$/.test(property.value))) {
            //                        this.setState({
            //                            warnMessage: "Please enter only Alphanumeric values for " + property.displayName,
            //                            alertbox: true
            //                        })
            //                        this.cpeFocus[key].focus()
            //                        check = true
            //                    }
            //                    }
        });
        if (check == false) {
            is_submit_form = true
        } else {
            is_submit_form = false
        }
    }

    submit_parameters() {
        this.validationRequest()
        let new_request_list: any = []
        new_request_list = this.state.properties.map((property: any) => {
            // if (property.propertyName === 'latitude') {
            //     property.value = this.reduxStore['coordinates']['latitude']
            // } else if (property.propertyName === 'longitude') {
            //     property.value = this.reduxStore['coordinates']['longitude']
            // } else
            if (property.propertyName === "radiation_center") {
                if (property.value === "") {
                    property.value = 0
                }
            }
            if (this.state.files.length > 0) {
                if (property.propertyName == "image") {
                    property.value = this.state.files
                }
            }
            return property
        })
        // let requestList = new_request_list.filter((property: any) => property.propertyName !== 'cpe_id')
        var asset = this.state.properties.filter((property: any) => property.propertyName === 'cpe_id')
        this.setState({
            asset_cpe_id: asset[0].value
        })
        var asset_serail = this.state.properties.filter((property: any) => property.propertyName === 'serial_id')
        this.setState({
            asset_serail_id: asset_serail[0].value
        })
        if (is_submit_form) {
            this.setState({
                is_loader: 1
            })
            if (this.state.files.length > 0) {
                var picReader = new FileReader();
                let filerequestBody = {}
                let fileName = this.state.files[0].name
                let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
                if (this.state.files[0].uploadType === undefined) {
                    picReader.onload = (e) => {
                        filerequestBody = {
                            'fileName': fileName,
                            'fileExtension': fileExt,
                            'fileValue': picReader.result
                        }
                        this.uploadImageAndAddAsset(filerequestBody, new_request_list)
                    }
                    picReader.readAsDataURL(this.state.files[0]);
                } else {
                    filerequestBody = {
                        'fileName': fileName,
                        'fileExtension': this.state.files[0].format,
                        'fileValue': this.state.files[0].dataUrl
                    }
                    requestAPICall("upload_file", {}, filerequestBody).then(data => {
                        if (data['responseCode'] == 200) {
                            this.state.properties.forEach((property: any) => {
                                if (property.propertyName == "image") {
                                    property.value = data.file_id
                                }
                            });
                            let requestBody = {
                                "assetName": this.state.cpe_name,
                                "assetTypeId": this.reduxStore['assetTypeId'],
                                "asset_id_value": asset[0].value,
                                "properties": new_request_list,
                                "serialId": asset_serail[0].value,
                                "building_id": this.reduxStore['buildingId'],
                                "floor_id": this.reduxStore['floorId']
                            }
                            requestAPICall("create_asset", { asset_type_id: this.reduxStore['assetTypeId'] }, requestBody).then(data => {
                                this.setState({
                                    is_loader: 2
                                })
                                if (data.responseCode == "200") {
                                    this.setState({
                                        warnMessage: data.message,
                                        alertbox: true
                                    })
                                    if (isBrowser) {
                                        this.props.listAssets('create-list-angle')
                                        this.goBack('')
                                    }
                                    else {
                                        // this.goBack('mobile')
                                        this.props.listAssets('create-list-angle')
                                        this.props.hideOverlay()
                                        this.props.changeMapCenter(this.reduxStore['coordinates']['latitude'], this.reduxStore['coordinates']['longitude'])
                                    }
                                } else {
                                    this.setState({
                                        warnMessage: data.message,
                                        alertbox: true
                                    })
                                }
                            })
                        }
                    })
                    this.uploadImageAndAddAsset(filerequestBody, new_request_list)
                }
            } else {
                let requestBody = {
                    "assetName": this.state.cpe_name,
                    "assetTypeId": this.reduxStore['assetTypeId'],
                    "serialId": asset_serail[0].value,
                    "asset_id_value": asset[0].value,
                    "properties": new_request_list,
                    "building_id": this.reduxStore['buildingId'],
                    "floor_id": this.reduxStore['floorId']
                }
                console.log("requestbody is", requestBody)
                requestAPICall("create_asset", { asset_type_id: this.reduxStore['assetTypeId'] }, requestBody).then(data => {
                    this.setState({
                        is_loader: 2,
                    })
                    if (data.responseCode == "200") {
                        this.setState({
                            alertbox: true,
                            warnMessage: data.message,
                        })
                        if (isBrowser) {
                            this.goBack('browser')
                            this.props.listAssets('create-list-angle')
                        }
                        else {
                            // this.goBack('mobile')
                            this.props.listAssets('create-list-angle')
                            this.props.hideOverlay()
                            this.props.changeMapCenter(this.reduxStore['coordinates']['latitude'], this.reduxStore['coordinates']['longitude'])
                        }
                    } else {
                        this.setState({
                            alertbox: true,
                            warnMessage: data.message

                        })
                    }
                    console.log(this.state.alertbox)
                    console.log(this.state.warnMessage)


                })
            }
        }
    }

    uploadImageAndAddAsset(imageData: any, properties: any) {

        var asset = this.state.properties.filter((property: any) => property.propertyName === 'cpe_id')
        this.setState({
            asset_cpe_id: asset[0].value
        })
        var serialId_asset = this.state.properties.filter((property: any) => property.propertyName === 'serial_id')
        this.setState({
            asset_serail_id: serialId_asset[0].value
        })

        requestAPICall("upload_file", {}, imageData).then(data => {
            if (data['responseCode'] == 200) {
                this.state.properties.forEach((property: any) => {
                    if (property.propertyName == "image") {
                        property.value = data.file_id
                    }
                });
                let requestBody = {
                    "assetName": this.state.cpe_name,
                    "assetTypeId": this.reduxStore['assetTypeId'],
                    "asset_id_value": this.state.asset_cpe_id,
                    "properties": properties,
                    "serialId": this.state.asset_serail_id,
                    "building_id": this.reduxStore['buildingId'],
                    "floor_id": this.reduxStore['floorId']
                }
                console.log(requestBody)
                requestAPICall("create_asset", { asset_type_id: this.reduxStore['assetTypeId'] }, requestBody).then(data => {
                    this.setState({
                        is_loader: 2
                    })
                    if (data.responseCode == 200) {
                        this.setState({
                            warnMessage: data.message,
                            alertbox: true
                        })
                        if (isBrowser) {
                            this.goBack('')
                            this.props.listAssets('create-list-angle')
                        }
                        else {
                            // this.goBack('mobile')
                            this.props.listAssets('create-list-angle')
                            this.props.hideOverlay()
                            this.props.changeMapCenter(this.reduxStore['coordinates']['latitude'], this.reduxStore['coordinates']['longitude'])
                        }





                    } else {
                        this.setState({
                            warnMessage: data.message,
                            alertbox: true
                        })
                    }
                })
            }
        })
    }

    saveChanges = () => {
        this.validationRequest()
        let request_list: any = []
        request_list = this.state.properties.filter((property: any) => property.propertyName !== 'cpe_serial_id')
        // request_list = request_list.filter((property: any) => property.propertyName !== 'cpe_id')
        let requestBody = {
            "assetName": this.state.cpe_name,
            "serialId": this.state.serial_id,
            "assetTypeId": this.props.typeId,
            "properties": request_list,
            "building_id": this.reduxStore['buildingId'],
            "status": "Offline"
        }

        this.state.properties.forEach((property: any) => {
            if (property.propertyName == "name") {
                this.setState({ cpe_name: property.value })
            }
            else if (property.propertyName === "radiation_center") {
                if (property.value === "") {
                    property.value = 0
                }
            }
            if (this.state.files.length > 0) {
                if (property.propertyName == "image") {
                    property.value = this.state.files
                }
            }
        })
        let image_value = this.state.properties.filter((property: any) => property.propertyName === 'image')

        if (image_value.value === "" || this.state.files.length > 0) {

            if (is_submit_form) {
                var picReader = new FileReader();
                let filerequestBody = {}
                let fileName = this.state.files[0].name
                let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
                if (this.state.files[0].uploadType === 'undefined' || this.state.files[0].uploadType === undefined || typeof (this.state.files[0].uploadType) === undefined) {
                    var picReader = new FileReader();
                    picReader.onload = (e) => {
                        filerequestBody = {
                            'fileName': fileName,
                            'fileExtension': fileExt,
                            'fileValue': picReader.result
                        }
                        console.log(filerequestBody)
                        this.uploadEditAssetImage(filerequestBody, request_list)
                    }
                    picReader.readAsDataURL(this.state.files[0]);

                } else {
                    console.log("========")
                    filerequestBody = {
                        'fileName': fileName,
                        'fileExtension': this.state.files[0].format,
                        'fileValue': this.state.files[0].dataUrl
                    }
                }
                console.log(filerequestBody)
                this.uploadEditAssetImage(filerequestBody, request_list)

            }
        }

        else {
            if (is_submit_form) {
                let requestBody = {
                    "assetName": this.state.cpe_name,
                    "serialId": this.state.serial_id,
                    "assetTypeId": this.props.typeId,
                    "properties": request_list,
                    "building_id": this.reduxStore['buildingId'],
                    "status": "Offline"
                }
                this.editAssetChanges(requestBody)
            }
        }


    }
    uploadEditAssetImage = (filerequestBody: any, request_list: any) => {
        requestAPICall("upload_file", {}, filerequestBody).then(data => {
            console.log(data)
            if (data['responseCode'] == 200) {
                let new_request_list = request_list.map((property: any) => {
                    if (property.propertyName === 'image') {
                        property.value = data.file_id
                    }
                    return property
                })
                let requestBody = {
                    "assetName": this.state.cpe_name,
                    "serialId": this.state.serial_id,
                    "assetTypeId": this.props.typeId,
                    "properties": new_request_list,
                    "building_id": this.reduxStore['buildingId'],
                    "status": "Offline"
                }
                this.editAssetChanges(requestBody)
            }
        })
    }
    editAssetChanges = (requestBody: any) => {
        this.setState({
            is_loader: 1
        })
        requestAPICall("edit_asset_details", { asset_type_id: this.props.typeId, asset_id: this.props.selectedAssetId }, requestBody).then(data => {
            this.setState({
                is_loader: 0
            })
            if (data.responseCode == 200) {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true,
                    disable_button: false
                })
                let latitude: any
                let longitude: any
                requestBody['properties'].forEach((element: any) => {
                    if (element.propertyName === 'latitude') {
                        latitude = element.value
                    }
                    if (element.propertyName === 'longitude') {
                        longitude = element.value
                    }
                });
                if (this.reduxStore['tabType'] == "outdoor") {
                    this.props.changeMapCenter(latitude, longitude)
                }
                this.props.get_asset_details(this.state.asset_id)
                if (this.reduxStore['tabType'] == "indoor") {
                    this.props.listAssets('create-list', this.reduxStore['floorId'])
                } else {
                    this.props.listAssets('create-list-angle')
                }
            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }

        })
    }

    validate_request() {
        let check = false
        this.state.properties.forEach((property: any) => {
            if (property.value === "") {
                check = true
            }
        });
        if (check == false) {
            this.setState({
                disable_button: check
            })

        }
    }

    // disable_button = () => {
    //     let check = true
    //     this.state.properties.forEach((property: any) => {
    //         if(property.isRequired){
    //         if (property.propertyName === "cpe_id"  ) {
    //             if (property.value === "") {
    //                 check = false
    //             }
    //         }
    //         if (property.propertyName === "customer_id") {
    //             if (property.value === "") {
    //                 check = false
    //             }
    //         }
    //         }
    //     });
    //     console.log(check , 'ccccccccccc')
    //     console.log(this.state.properties , 'ppppppppppp')
    //     this.setState({
    //         disable_button : !check
    //     })
    // }

    onDrop = (files: any) => {
        this.setState({ files: files, clearImage: false })
        console.log(this.state.files)
        // this.state.properties.forEach((property: any) => {
        //     if (property.propertyName == "image") {
        //         property.value = this.state.files
        //     }
        // });
        this.validate_request()
    };
    onClear = (files: any) => {
        this.setState({ files: files, clearImage: true })
        console.log(this.state.files)
        // this.state.assetProperties.forEach((property: any) => {
        //     if (property.propertyName == "image") {
        //         property.value = this.state.files
        //     }
        // });
        this.validate_request()
    };
    disableButton = () => {
        this.reduxStore = store.getState()['general']
        if (this.state.assetAction === 'add') {
            if (this.props.latlong.length === 0) {
                this.setState({
                    field_disable: true,
                    disable_button: true
                })
            } else {
                this.setState({
                    field_disable: false,
                    disable_button: false
                })
            }
        }
        var properties = this.state.properties.map((property: any) => {
            if (property.propertyName === 'latitude') {
                property.value = this.reduxStore['coordinates']['latitude']
            } else if (property.propertyName === 'longitude') {
                property.value = this.reduxStore['coordinates']['longitude']
            }
            return property
        })
        this.setState({
            properties: properties
        })
    }

    onChange(e: any) {
        console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        })

        this.state.properties.forEach((property: any) => {
            if ("cpe_" + property.propertyName == e.target.name) {
                property.value = e.target.value
            }
            if ("cpe_" + property.propertyName == "cpe_radiation_center" && e.target.name == "cpe_radiation_center") {
                if (e.target.value == '') {
                    property.value = e.target.value
                }
            }
            if ("cpe_" + property.propertyName == "cpe_cpe_name" && e.target.name == "cpe_cpe_name") {
                this.setState({
                    cpe_name: e.target.value
                })
            }
            if (property.propertyName == "serial_id" && e.target.name == "cpe_serial_id") {
                this.setState({
                    serial_id: e.target.value
                })
            }
            if ("cpe_" + property.propertyName == "cpe_latitude" && e.target.name == "cpe_latitude") {
                property.value = e.target.value
            }
            if ("cpe_" + property.propertyName == "cpe_longitude" && e.target.name == "cpe_longitude") {
                property.value = e.target.value
            }
            return property;
        });

        this.validate_request()

    }
    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }
    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'
        let classImageUpload = files.length ? '-uploaded' : ''
        return (
            <div>
                {isBrowser ?
                    <div className='webonly'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className="card apcard">
                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <img className='backicon' src={BackIcon} onClick={() => { this.goBack('browser') }} />
                                                <button type='button' className='backbutton' ></button>
                                                {/* <img className='backicon' src={Back} onClick={this.props.back} /> */}
                                                {/* <button type='button' className='backbutton material-icons' onClick={this.props.back}>keyboard_backspace </button> */}
                                                {/* <button type='button' className='backbutton'></button> */}
                                            </div>
                                            <div className='col-md-8' style={{ padding: "13px 0", marginLeft: "-5px" }}>
                                                <span className='apheader'>{this.state.assetAction == "edit" ? "EDIT CPE" : "ADDING CPE"}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 210px)' }}>

                                            <div className='col-md-12 p-0'>
                                                <div className='col-md-12 apborder p-0'>
                                                    <span className='apnote'>Click where you'd like to drop the CPE</span>
                                                </div>
                                            </div>
                                            {this.state.properties.map((property: any, key: any) => {
                                                if (property.units !== "") {
                                                    unit = "(" + property.units + ")"
                                                } else {
                                                    unit = ""
                                                }
                                                if (property.isShowable === true) {
                                                    if (property.dataType === "string" || property.dataType == "float" || property.dataType === "int") {
                                                        if (property.isRequired == true && property.propertyName != 'latitude' && property.propertyName != 'longitude') {
                                                            if (property.propertyName === "cpe_id") {
                                                                const element = <div className='col-md-12 p-0 m-t-8'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} type="text" id={"cpe_" + property.propertyName} maxLength={45} name={"cpe_" + property.propertyName} disabled={this.state.assetAction == "add" ? this.state.field_disable : true} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            } else {
                                                                const element = <div className='col-md-12 p-0 m-t-8'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} type="text" id={"cpe_" + property.propertyName} maxLength={45} name={"cpe_" + property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            }
                                                        }
                                                        else if (property.isRequired == true && property.propertyName == 'latitude') {
                                                            if (this.state.assetAction === 'edit') {
                                                                const element = <div className='col-md-12 p-0 m-t-10'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} type="number" id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={this.state.assetAction === 'edit' ? property.value : this.reduxStore['coordinates']['latitude']} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            } else {
                                                                const element = <div className='col-md-12 p-0 m-t-10'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} type="number" id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={property.value === 0 ? this.reduxStore['coordinates']['latitude'] : property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            }


                                                        }
                                                        else if (property.isRequired == true && property.propertyName == 'longitude') {
                                                            if (this.state.assetAction === 'edit') {
                                                                const element = <div className='col-md-12 p-0 m-t-10'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} type="number" id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={this.state.assetAction === 'edit' ? property.value : this.reduxStore['coordinates']['longitude']} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            } else {
                                                                const element = <div className='col-md-12 p-0 m-t-10'>
                                                                    <div className="form-label-group m-0">
                                                                        <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} type="number" id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} disabled={this.state.field_disable} className="accesspointname" value={property.value === 0 ? this.reduxStore['coordinates']['longitude'] : property.value} placeholder={property.displayName + "*"} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + "*"}</label>
                                                                    </div>
                                                                </div>
                                                                return element
                                                            }
                                                        } else if (property.dataType === "int") {
                                                            const element = <div className='col-md-12 p-0 m-t-10'>
                                                                <div className="form-label-group m-0">
                                                                    <input type="number" ref={(ref) => { this.cpeFocus[key] = ref; return true; }} maxLength={45} id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} value={property.value} className="accesspointname" placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                    <label className="pages" style={{ padding: '4px 0px 0px 8px' }} htmlFor={"cpe_" + property.propertyName}>{property.displayName} {unit}</label>
                                                                </div>
                                                            </div>
                                                            return element
                                                        }
                                                        // Included on 10-7-20 due to serial id can not be editable after reg to SAS
                                                        else if (property.propertyName === "serial_id" && this.state.assetAction === 'edit') {
                                                            const element = <div className='col-md-12 p-0 m-t-10'>
                                                                <div className="form-label-group m-0">
                                                                    <input type="text" disabled={!(this.props.editSerial)} ref={(ref) => { this.cpeFocus[key] = ref; return true; }} maxLength={45} id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} value={property.value} className="accesspointname" placeholder={property.displayName} />
                                                                    <label className="pages" style={{ padding: '4px 0px 0px 8px' }} htmlFor={"cpe_" + property.propertyName}>{property.displayName} {unit}</label>
                                                                </div>
                                                            </div>
                                                            return element
                                                        }
                                                        else {
                                                            const element = <div className='col-md-12 p-0 m-t-10'>
                                                                <div className="form-label-group m-0">
                                                                    <input ref={(ref) => { this.cpeFocus[key] = ref; return true; }} value={property.value} type="text" maxLength={45} id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} className="accesspointname" placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                    <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName}</label>
                                                                </div>
                                                            </div>
                                                            return element
                                                        }

                                                    } else if (property.dataType === "image") {
                                                        return <div className='col-md-12 p-0 m-t-7 justify-content-center' style={{ padding: "0px 35px", fontSize: '14px' }}>
                                                            {
                                                                (files.length) ? <i className="material-icons imgclose-file dropzclose" style={{ marginBottom: "10px", marginTop: "20px" }} onClick={(e: any) => { e.preventDefault(); files = []; this.onClear([]) }}>clear</i> : ""
                                                            }
                                                            <Dropzone onDrop={this.onDrop} disabled={false}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='dropzone-set' >
                                                                        <div {...getRootProps({ className: classDName })}>
                                                                            <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                                                            {this.state.clearImage == true && property.value === "" ? <span style={{ fontSize: '14px' }}> + Add Image</span> :
                                                                                property.value !== "" && files.length == 0 ? <span style={{ fontSize: '14px' }}> Image uploaded Already</span> :
                                                                                    (files.length) ? <span style={{ color: "#00D56E", display: 'contents' }}>
                                                                                        {files}
                                                                                    </span>
                                                                                        :
                                                                                        <span style={{ fontSize: '14px' }}>+ Add Image</span>

                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                        {/* <div className="col-2 p-l-0">
                                                                <div className={"v-h-center image-field-action" + classImageUpload}>
                                                                    {files.length ?
                                                                        <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = []; this.onClear([]) }}>clear</i>
                                                                        : <img src={camicon} style={{ width: '18px' }} onClick={this.takePicture}></img>
                                                                    }
                                                                </div>
                                                            </div> */}
                                                    } else if (property.dataType === "textArea") {
                                                        if (this.state.assetAction == "edit") {
                                                            const element = <div className='col-md-12 p-0 m-t-10'>
                                                                <IonItem lines="none" color="none" className="description">
                                                                    <IonLabel position="floating">{property.displayName}</IonLabel>
                                                                    <IonTextarea name={"cpe_" + property.propertyName} rows={4} disabled={this.state.field_disable} value={property.value} onInput={this.onChange} />
                                                                </IonItem>
                                                            </div>
                                                            return element
                                                        } else {
                                                            const element = <div className='col-md-12 p-0 m-t-10'>
                                                                <IonItem lines="none" color="none" className="description">
                                                                    <IonLabel position="floating">{property.displayName}</IonLabel>
                                                                    <IonTextarea maxlength={45} name={"cpe_" + property.propertyName} id={"cpe_" + property.propertyName} disabled={this.state.field_disable} rows={2} defaultValue={property.value} onIonInput={this.onChange} />
                                                                </IonItem>
                                                            </div>
                                                            return element
                                                        }

                                                    } else if (property.dataType === "float") {
                                                        console.log(property.value)
                                                        return <div className='col-md-12 p-0 m-t-10'>
                                                            <div className="form-label-group m-0">
                                                                <input value={property.value} type="text" id={"cpe_" + property.propertyName} name={"cpe_" + property.propertyName} className="accesspointname" placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName}</label>
                                                            </div>
                                                        </div>
                                                    }
                                                }
                                            }
                                            )
                                            }
                                            <div className='row'>
                                                {
                                                    this.state.assetAction == "edit" ? <div className='col-md-12 m-t-10'>
                                                        <button className='btn savebtn btn-secondary-c3' disabled={!this.state.disable_button} style={{ width: '268px' }} onClick={this.saveChanges} >Update Changes</button>
                                                    </div> : <div className='col-md-12 m-t-10'>
                                                            <button className='btn savebtn btn-secondary-c3' style={{ width: '268px' }} disabled={this.state.disable_button} onClick={this.submit_parameters} >Save Changes</button>
                                                        </div>

                                                }
                                            </div>
                                            {/* <div className='row m-t-10' style={{ padding: "0px 35px" }}> */}
                                            {/* <button className='btn savebtn' onClick={this.submit_parameters} disabled={this.state.disable_button}>Save Changes</button> */}
                                            {/* <button className='btn savebtn' onClick={this.submit_parameters} >Save Changes</button>

 </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3' >
                                <div className="col-md-12 justify-content-flex-end">
                                    <div className="heaticon text-center">
                                        <img className='heat' src={Heat} />
                                    </div>
                                </div>

                                <div className="col-md-12 float-right">
                                    <div className="geoicon text-center">
                                        <img style={{ padding: '5px' }} src={geo} onClick={this.setCurrentPosition} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='mobonly'>

                        <div className='param-info-mob ' style={{ height: '100vh' }}>
                            <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                                <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={() => { this.props.hideOverlay() }} src={BackIcon} />
                                <span className="apheader">ADD CPE</span>
                            </div>
                            <div className='container-fluid apdash'>
                                <div className="card apeditcard" >
                                    <div className="card-body" style={{ overflowY: 'auto' }}>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <span className='apheader'>CUSTOMER EQUIPMENT</span>
                                            </div>
                                        </div>
                                        {
                                            this.state.properties.map((property: any, key: any) => {
                                                var isRequired = ''
                                                if (property.isRequired) isRequired = "*"
                                                if (property.units !== "") {
                                                    unit = "(" + property.units + ")"
                                                } else {
                                                    unit = ""
                                                }
                                                if (property.isShowable === true) {
                                                    if (property.dataType === "string" && property.propertyName !== 'serial_id') {
                                                        return <div className='row m-t-10'>
                                                            <div className="col-12">
                                                                <div className="form-label-group m-0">
                                                                    <input type="text" maxLength={45} ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" id={"cpe_" + property.propertyName} style={{ width: "100%" }} placeholder={property.displayName} name={"cpe_" + property.propertyName} onChange={this.onChange.bind(this)} />
                                                                    <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + isRequired}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (property.dataType === "float" || property.dataType === "int") {
                                                        if (property.propertyName === 'latitude') {
                                                            if (this.reduxStore['assetAction'] === "edit") {
                                                                return <div className='row m-t-10'>
                                                                    <div className="col-12">
                                                                        <div className="form-label-group m-0">
                                                                            <input type="number" ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" name={"cpe_" + property.propertyName} id={"cpe_" + property.propertyName}
                                                                                style={{ width: "100%" }} placeholder={property.displayName} value={this.state.assetAction === 'edit' ? property.value : this.reduxStore['coordinates']['latitude']} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + isRequired}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            } else {
                                                                return <div className='row m-t-10'>
                                                                    <div className="col-12">
                                                                        <div className="form-label-group m-0">
                                                                            <input type="number" ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" name={"cpe_" + property.propertyName} id={"cpe_" + property.propertyName}
                                                                                style={{ width: "100%" }} placeholder={property.displayName} value={property.value === 0 ? this.reduxStore['coordinates']['latitude'] : property.value} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + isRequired}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        }
                                                        else if (property.propertyName === 'longitude') {
                                                            if (this.reduxStore['assetAction'] === "edit") {
                                                                return <div className='row m-t-10'>
                                                                    <div className="col-12">
                                                                        <div className="form-label-group m-0">
                                                                            <input type="number" ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" name={"cpe_" + property.propertyName} id={"cpe_" + property.propertyName}
                                                                                style={{ width: "100%" }} placeholder={property.displayName} value={this.state.assetAction === 'edit' ? property.value : this.reduxStore['coordinates']['longitude']} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + isRequired}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            } else {
                                                                return <div className='row m-t-10'>
                                                                    <div className="col-12">
                                                                        <div className="form-label-group m-0">
                                                                            <input type="number" ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" name={"cpe_" + property.propertyName} id={"cpe_" + property.propertyName}
                                                                                style={{ width: "100%" }} placeholder={property.displayName} value={property.value === 0 ? this.reduxStore['coordinates']['longitude'] : property.value} onChange={this.onChange.bind(this)} />
                                                                            <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + isRequired}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        } else {
                                                            return <div className='row m-t-5'>
                                                                <div className="col-12">
                                                                    <div className="form-label-group m-0">
                                                                        <input type="number" ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" value={property.value} name={"cpe_" + property.propertyName} id={"cpe_" + property.propertyName}
                                                                            style={{ width: "100%" }} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                        <label className="pages" htmlFor={"cpe_" + property.propertyName}>{property.displayName + isRequired}{unit}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    }
                                                    else if (property.dataType === "textArea") {
                                                        return <div className='row m-t-10' >
                                                            <div className='col-12'>
                                                                <IonItem lines="none" color="none" className="description">
                                                                    <IonLabel position="floating">{property.displayName}</IonLabel>
                                                                    <IonTextarea style={{ width: "100%", margin: '0px' }} rows={3} maxlength={45} name={property.propertyName} onIonInput={this.onChange} />
                                                                </IonItem>
                                                                {/* <textarea className="description" maxLength={45} style={{ padding: '7px' }} rows={5} placeholder="Description" name={"cpe_" + property.propertyName} onChange={this.onChange.bind(this)} /> */}
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (property.dataType === "image") {
                                                        return <div className="row m-t-10">
                                                            <div className='col-10 p-r-0' style={{ fontSize: '14px' }}>
                                                                <Dropzone onDrop={this.onDrop} disabled={false}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section className='dropzone-set' >
                                                                            <div {...getRootProps({ className: classDName + "-mob" })}>
                                                                                <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                                                                {(files.length) ? <span style={{ color: "#00D56E", display: 'contents' }}>
                                                                                    {files}
                                                                                </span>
                                                                                    : <div>
                                                                                        <span style={{ fontSize: '14px' }}>+ Add Image</span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                            <div className="col-2 p-l-0">
                                                                <div className={"v-h-center image-field-action" + classImageUpload}>
                                                                    {files.length ?
                                                                        <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = []; this.onDrop([]) }}>clear</i>
                                                                        : <img src={camicon} style={{ width: '21px' }} onClick={this.takePicture}></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (property.propertyName === 'serial_id') {
                                                        return <div className='row m-t-10'>
                                                            <div className='col-10 p-r-0'>
                                                                <div className="form-label-group m-0">

                                                                    {/* <img src={qricon} style={{ position: 'absolute', right: '15px', marginTop: '7px', width: '25px' }}></img> */}
                                                                    <input type="text" maxLength={45} ref={(ref) => { this.cpeFocus[key] = ref; return true; }} className="accesspointname font14" name="cpe_serial_id" value={property.value} id="cpe_serial_id" onChange={this.onChange.bind(this)} style={{ width: "100%", borderRadius: "6px 0px 0px 6px", borderRight: "none" }} placeholder="Serial ID" />
                                                                    <label className="pages" htmlFor="cpe_serial_id">Serial # </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 p-l-0">
                                                                <div className="v-h-center" style={{ height: "100%", borderRadius: "0px 6px 6px 0px", border: "0.5px solid #CED7DF", borderLeft: "none" }} onClick={() => { this.openScanner() }}>
                                                                    <img src={qricon} style={{ width: '25px' }} onClick={() => { this.openScanner() }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                }
                                            })
                                        }


                                        <div className='row m-t-10 ' >
                                            <div className='col-12 '>
                                                <button className='lightbtnmob ' onClick={() => { this.submit_parameters(); }} disabled={false}> Save Changes </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />

                <IonLoading
                    isOpen={this.state.is_loader === 1}
                    onDidDismiss={() => this.setShowLoading(2)}
                    message={'Loading...'}
                />
            </div>
        );
    }
}

// export default connect<{}, {}, Iprops>(mapStateToProps ,mapDispatchToProps, )(withIonLifeCycle(AddCPE));
export default withIonLifeCycle(AddCPE)
