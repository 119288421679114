import React from 'react';
import { IonContent, IonSpinner, IonAlert, IonLoading, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import sort from '../assets/img/checked.png'
import { requestAPICall } from "../service/apiHandler";
import Dropzone from 'react-dropzone';
import Modal from './modal'
import { saveAs } from 'file-saver'

const INITIAL_STATE = {
    card: '',
    show: false,
    status_show: false,
};
var status_list: any = []
var selected_polygon_list: any = []
export default class Polygon extends React.Component {

    props: any = {};
    state: any = {};
    constructor(props: any) {
        super(props)
        this.state = { ...INITIAL_STATE }
        this.state = {
            files: [],
            country_name: "",
            boundary_type: "",
            boundary_value: "",
            region_name: "",
            geometry_type: "",
            polygon_list: [],
            showButton: false,
            select_polygonId: 0
        }
        this.onChange = this.onChange.bind(this)
        this.list_polygon = this.list_polygon.bind(this)
        this.import_files = this.import_files.bind(this)
        this.showButton = this.showButton.bind(this)
    }
    componentWillMount() {
        this.list_polygon()
    }
    showModal = (e: any) => {
        e.preventDefault();
        this.setState({
            card: e.target.id,
            show: true
        })
    }
    setShowLoading = (st: Boolean) => {
        this.setState({ showLoading: st })
    }
    hideModal = () => {
        this.setState({
            card: '',
            files: [],
            show: false
        })
    }
    status_hideModal = () => {
        this.setState({
            status_show: false
        })
    }
    onDrop = (files: any) => {
        this.setState({ files: files })
    };
    import_files() {
        status_list = []
        var picReader = new FileReader();
        let filerequestBody = {}
        let fileName = this.state.files[0].name
        let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
        picReader.onload = (e) => {
            filerequestBody = {
                'fileName': fileName,
                'fileExtension': fileExt,
                'fileValue': picReader.result,
                'type': 'polygon'
            }
            console.log(filerequestBody)
            requestAPICall("upload_file", {}, filerequestBody).then(data => {
                var requestBody = {
                    "file_path_id": data.file_id,
                }
                requestAPICall("import_polygan_boundaries", {}, requestBody).then(data => {
                    if (data.responseCode == 200) {
                        status_list = data.status
                        this.setState({
                            show: false,
                            status_show: true
                        })
                        this.list_polygon()
                    } else {
                        this.setState({
                            show: false,
                            status_show: false
                        })
                    }
                })
            })
        }
        picReader.readAsDataURL(this.state.files[0]);
        this.hideModal()
    }
    update_polygon = (polygon: any) => {
        let requestBody = {
            "country_name": this.state.country_name,
            "boundary_type": this.state.boundary_type,
            "boundary_value": this.state.boundary_value,
            "region_name": this.state.region_name,
            "geometry_type": this.state.geometry_type

        }
        console.log(requestBody)
        requestAPICall("update_polygon_boundaries", { polygon_id: polygon.polygonId }, requestBody).then(data => {
            this.list_polygon()

        })
    }
    list_polygon() {
        requestAPICall("list_polygon_boundaries", {}, {}).then(data => {
            this.setState({
                polygon_list: data.polygonList,
                showButton: false
            })
        })
    }
    download_polygon = () => {
        let requestBody = {
            'json_file': 'polygon_boundaries.kml'
        }

        requestAPICall("download_files", {}, requestBody).then(data => {
            var exportedFilenmae = 'polygon_boundaries.kml'
            var blob = new Blob([data['file_content']]);
            saveAs(blob, exportedFilenmae);
        })
    }
    showButton(polygonId: number) {
        this.setState({
            showButton: true,
            select_polygonId: polygonId
        })
    }
    onChange(e: any, type: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.state.polygon_list.map((polygon: any) => {
            if (polygon.polygonId === parseInt(e.target.name)) {
                if (type === "geometryType") {
                    polygon.geometryType = e.target.value
                    this.setState({
                        geometry_type: e.target.value
                    })
                    return polygon
                } else if (type === "regionName") {
                    this.setState({
                        region_name: e.target.value
                    })
                    polygon.regionName = e.target.value
                    return polygon
                } else if (type === "boundaryType") {
                    this.setState({
                        boundary_type: e.target.value
                    })
                    polygon.boundaryType = e.target.value
                    return polygon
                } else if (type === "boundaryValue") {
                    polygon.boundaryValue = e.target.value
                    this.setState({
                        boundary_value: e.target.value
                    })
                    return polygon
                } else if (type === "countryName") {
                    polygon.countryName = e.target.value
                    this.setState({
                        country_name: e.target.value
                    })
                    return polygon
                }
            }
            return polygon
        })
    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    coverage_layers_checked = (e: any) => {
        let floor_index = selected_polygon_list.indexOf(e.target.id)
        if (e.target.checked == true) {
            selected_polygon_list.push(parseInt(e.target.id))
        } else {
            if (floor_index <= 0) {
                selected_polygon_list.splice(floor_index, 1)
            }
        }
        console.log(selected_polygon_list, 'ssssssssssssssssssssss')
    }
    selectAllCheckbox = (e: any) => {
        if (e.target.checked == true) {
            this.state.heatmap_list.forEach((heatmap: any) => {
                selected_polygon_list.push(heatmap.heatmapId)

            })
            this.setState({
                selectAll: true
            })


        } else {
            this.setState({
                selectAll: false
            })
            selected_polygon_list = []
        }
        console.log(selected_polygon_list, 'hhhhhhhhhhhhhhhh')
    }
    delete_polygon = () => {
        if (selected_polygon_list.length === 0) {
            this.setState({
                alertbox: true,
                warnMessage: "Please select one row"
            })
        }
        let requestBody = {
            "polygon_id_list": selected_polygon_list
        }
        requestAPICall("delete_polygon_boundary", {}, requestBody).then(data => {
            console.log(data)
            this.setState({
                showButton: false
            })
            this.setState({
                alertbox: true,
                warnMessage: data.message
            })
            this.list_polygon()

        })
        this.list_polygon()
    }

    sortByValueAsc = (key: any) => {
        this.setState({
            show_sector_details: false
        })
        let sortedProductsAsc;
        sortedProductsAsc = this.state.polygon_list.sort((a: any, b: any) => {
            return parseInt(a.key) - parseInt(b.key);
        })
        let asset_list = this.asecnding(key)
        this.setState({
            polygon_list: sortedProductsAsc
        })
    }
    sortByValueDesc = (key: any) => {
        this.setState({
            show_sector_details: false
        })
        let sortedProductsDesc;
        sortedProductsDesc = this.state.polygon_list.sort((a: any, b: any) => {
            return parseInt(a.key) - parseInt(b.key);
        })
        this.setState({
            polygon_list: sortedProductsDesc
        })
        let list = this.decending(key)
    }

    asecnding = (key: any) => {
        return this.state.polygon_list.sort(function (a: any, b: any) {
            if (a[key] > b[key]) {
                return 1;
            }
            if (b[key] > a[key]) {
                return -1;
            }
            return 0;
        })
    }
    decending = (key: any) => {
        return this.state.polygon_list.sort(function (a: any, b: any) {
            if (b[key] > a[key]) {
                return 1;
            }
            if (a[key] > b[key]) {
                return -1;
            }
            return 0;
        })
    }

    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'importdropzuploaded' : 'importdropz'
        return (

            <div className="card importcard" >
                <div className="card-body">
                    <div className="row" style={{ padding: '0px 10px' }}>
                        <div className="col-md-8">
                            <span style={{ fontSize: '13px' }}>LISTING</span>
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span className="p-l-5 m-r-30 font13" style={{ color: '#0073FF', paddingTop: '2px', cursor: 'pointer' }} onClick={this.delete_polygon}>Delete File</span>
                            <i className="material-icons" style={{ color: '#0073FF', cursor: 'pointer' }}>get_app</i>
                            <span className="p-l-5 m-r-30 font13" style={{ color: '#0073FF', cursor: 'pointer', paddingTop: '2px' }} onClick={this.download_polygon}>Download Sample File</span>
                            <i className="material-icons p-l-5" style={{ color: '#0073FF', transform: 'rotate(180deg)', cursor: 'pointer' }}>get_app</i>
                            <span className="font13" style={{ color: '#0073FF', paddingTop: '2px', cursor: 'pointer' }} id='heat' onClick={this.showModal}>Import File</span>
                        </div>
                    </div>
                    <div className="row m-t-10" style={{ padding: '0px 10px' }}>
                        <div className="col-md-12" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 244px)' }}>
                            <table className="table sitetable">
                                <thead className="sitehead" style={{ background: "none" }}>
                                    <tr >
                                        <th className="w5 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }} ></th>
                                        <th className="w15 table-bg-hdr-color" >File Name
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e)=>this.sortByValueDesc("polyganFilename")} />
                                            <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e)=>this.sortByValueAsc("polyganFilename")} />
                                        </th>
                                        <th className="w15 table-bg-hdr-color" >Country Name
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e)=>this.sortByValueDesc("countryName")} />
                                            <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e)=>this.sortByValueAsc("countryName")} />
                                        </th>
                                        <th className="w15 table-bg-hdr-color" >Boundary Type
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e)=>this.sortByValueDesc("boundaryType")} />
                                            <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e)=>this.sortByValueAsc("boundaryType")} />
                                        </th>
                                        <th className="w15 table-bg-hdr-color" >Boundary Value
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e)=>this.sortByValueDesc("boundaryValue")} />
                                            <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e)=>this.sortByValueAsc("boundaryValue")} />
                                        </th>
                                        <th className="w10 table-bg-hdr-color" >Region Name
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e)=>this.sortByValueDesc("regionName")} />
                                            <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e)=>this.sortByValueAsc("regionName")} />
                                        </th>
                                        <th className="w15 table-bg-hdr-color" >Geometry Type
                                        <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e)=>this.sortByValueDesc("geometryType")} />
                                            <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e)=>this.sortByValueAsc("geometryType")} />
                                        </th>

                                        <th className="w5 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {this.state.polygon_list.map((polygon: any, keys: any) => {
                                        {
                                            {
                                                if (this.state.showButton && this.state.select_polygonId === polygon.polygonId) {
                                                    return <tr id={polygon.polygonId} >
                                                        <td className="w5"></td>
                                                        <td className="w20">{polygon.polyganFilename} </td>
                                                        <td className="w20"><input name={polygon.polygonId} value={polygon.countryName} onChange={(e) => { this.onChange(e, "countryName") }} /> </td>
                                                        <td className="w20"><input name={polygon.polygonId} value={polygon.boundaryType} onChange={(e) => { this.onChange(e, "boundaryType") }} /> </td>
                                                        <td className="w20"><input name={polygon.polygonId} value={polygon.boundaryValue} onChange={(e) => { this.onChange(e, "boundaryValue") }} /></td>
                                                        <td className="w15">{polygon.regionName}</td>
                                                        <td className="w15"><input name={polygon.polygonId} value={polygon.geometryType} onChange={(e) => { this.onChange(e, "geometryType") }} /></td>

                                                        <td className="w5">
                                                            <button className='btn cardbtn' style={{ width: '100%' }} onClick={() => this.update_polygon(polygon)}>Save</button>
                                                        </td>

                                                    </tr>
                                                }
                                                else {
                                                    return < tr >
                                                        <td className="w5"><input type="checkbox" id={polygon.polygonId} onChange={this.coverage_layers_checked}></input></td>
                                                        <td className="w20">{polygon.polyganFilename} </td>
                                                        <td className="w20">{polygon.countryName} </td>
                                                        <td className="w20">{polygon.boundaryType} </td>
                                                        <td className="w20">{polygon.boundaryValue}</td>
                                                        <td className="w15">{polygon.regionName}</td>
                                                        <td className="w15">{polygon.geometryType}</td>

                                                        <td className="w5">
                                                            <i className="fas fa-ellipsis-v float-right" style={{ color: '#B4B4B4' }} onClick={() => this.showButton(polygon.polygonId)}></i>
                                                        </td>
                                                    </tr>
                                                }

                                            }

                                        }
                                    })
                                    }



                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>IMPORT FILE</h6>
                                    <i className="material-icons modalclose imgclose-file m-l-5" onClick={this.hideModal} data-dismiss="modal" aria-label="Close">clear</i>
                                </div>
                                <div className='row m-t-20 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className='col-md-12 p-0'>
                                        <Dropzone onDrop={this.onDrop}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className='dropzone-set'>
                                                    <div style={{ height: '125px' , width:"260px" , marginLeft:"8px" }}{...getRootProps({ className: classDName })}>
                                                        <input {...getInputProps()} accept='.json , .kml' />
                                                        {files.length ?
                                                            <div>
                                                                <div className="m-t-30">
                                                                    <span className="justify-content-center" style={{ color: "#00D56E", display: 'flex' }}>
                                                                        {files}
                                                                        {/* <i className="material-icons imgclose-file m-l-5" onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}
                                                                    </span>
                                                                </div>
                                                                <div className="m-t-10">
                                                                    <span><a style={{ color: "#8F9FAC", fontSize: "14px" }}>Change File</a></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div  className="m-t-5" style={{ textAlign: "center" }}>
                                                                    <i className="fas fa-plus-circle text-center" style={{ fontSize: '40px', color: '#ccc' }}></i>
                                                                </div>
                                                                <div style={{ textAlign: "center" }}>
                                                                    <span className='text-center' style={{ fontSize: '14px', color: '#9b9b9b' }}>Drag and drop files here</span>
                                                                </div>
                                                                <div className="m-t-5" style={{ textAlign: "center" , paddingBottom:"10px"}}>
                                                                    <span className="font14 cursor" style={{ color: '#0059b2' }}>Browse</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </div>
                                {files.length ?
                                    <div className='row m-t-10 justify-content-center' style={{ marginLeft: "1px", width:"265px"}}>
                                        <div className='col-md-12 p-0'>
                                            <button className='btn cardbtn' style={{ width: '100%' }} onClick={() => { this.import_files(); this.hideModal() }}>IMPORT</button>
                                        </div>
                                    </div> :
                                    <div className='row m-t-10 justify-content-center' style={{ marginLeft: "1px", width:"265px"}}>
                                        <div className='col-md-12 p-0'>
                                            <button className='btn cardbtn' style={{ width: '100%' }} disabled={true} >IMPORT</button>
                                        </div>
                                    </div>
                                }
                                {/* <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn' style={{ width: '268px' }} onClick={this.import_files}>IMPORT</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.status_show}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>POLYGON BOUNDARY STATUS</h6>
                                    <i className="material-icons modalclose imgclose-file m-l-5" onClick={this.status_hideModal} data-dismiss="modal" aria-label="Close">clear</i>
                                </div>
                                <table className="table sitecollapse m-t-20">
                                    <thead>
                                        <tr >
                                            <th className="w15" >Polygon Name</th>
                                            <th className="w20" >Status</th>
                                        </tr>
                                        {
                                            status_list.map((status: any) => {
                                                console.log(status)
                                                return <tr>
                                                    <td>{status.name}</td>
                                                    <td>{status.status}</td>
                                                </tr>
                                            })
                                        }
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div >



        );
    }

}
