import React, { Component } from 'react'
import Dropz from './DropZ';
import "./ap-parameters.css";
import BackIcon from '../assets/img/go-back-icon-img.png'
import Back from '../assets/img/left-arrow.svg'
import { IonTextarea, IonLoading, IonAlert, withIonLifeCycle, IonItem, IonLabel } from '@ionic/react';
import Lake from '../assets/img/lake.png'
import { IonPage, IonContent } from '@ionic/react';
import Modal from './modal'
import qricon from '../assets/img/bar-code-img-icon.png'
import store from '../store';
import { isBrowser } from 'react-device-detect';
import { requestAPICall } from "../service/apiHandler";
import { connect } from 'react-redux';
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from '../constants/actionTypes';
import Dropzone from 'react-dropzone';
import camicon from '../assets/img/camera-icon-img.png'
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { withRouter } from 'react-router-dom';

const { Camera } = Plugins;
var sector_list_ids: any = []
var is_submit_form = false
var secval = false

const INITIAL_STATE = {
    card1: false,
    card2: false,
    show: true,
    isChangesSaved: false,
    assetProperties: [],
    serialId: '',
    sectorList: [],
    image: '',
    imageId: 0,
    assetName: '',
    alertbox: false,
    isLoading: false,
    files: [],
    isImageChanged: false,
    isImageRemoved: false,
    imageUploadReq: {},
    operationalStatus: "",
    isShow: false,
    edit_serial: true,
    sectorsCount: [1, 2, 3, 4, 5, 6],
    selectedSectorCount: 0,
    vendor_type: localStorage.getItem('vendor_type'),
    isShowSectorIcons: localStorage.getItem('vendor_type') == 'Directional'?true:false

};
interface Iprops {
    hideOverlay: Function,
    getParticularAsset: Function,
    deleteAssetConfrm: any

}
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
    upadateGMapClickEvent: (value: any) =>
        dispatch({ type: CURRENT_MAP_CLICK_INSTANCE, key: 'gMapClickInstance', value }),
    upadateMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'coordinates', value }),
    upadateAssetTypeId: (value: any) =>
        dispatch({ type: ASSET, key: 'assetTypeId', value }),
    upadateAssetAction: (value: any) =>
        dispatch({ type: ASSET, key: 'assetAction', value }),
    upadateAssetId: (value: any) =>
        dispatch({ type: ASSET, key: 'assetId', value }),
    updateOverlay: (value: any) =>
        dispatch({ type: ASSET, key: 'show_overlay', value })
});

class Acpmob extends Component<Iprops> {
    state: any = {};
    props: any = {};
    apsecFocus: any = []
    reduxStore: any = store.getState()['general']
    constructor(props: any) {
        super(props);
        this.apsecFocus = []
        this.state = { ...INITIAL_STATE };
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        console.log('==component did mount==')
        this.initializeComponent()
    }

    initializeComponent() {
        this.reduxStore = store.getState()['general']
        console.log(this.reduxStore);
        this.parameterStatus()
        this.setState({ ...INITIAL_STATE }, () => {
            this.get_asset_details()
        })
    }
    componentDidUpdate() {
        this.reduxStore = store.getState()['general']
    }

    openScanner = async () => {
        const data = await BarcodeScanner.scan();
        this.setState({ serial_id: data.text })
        this.onChange({ "target": { "name": "serial_mob_edit", "value": data.text } })
        this.onChange({ "target": { "name": "serial_mob_edit", "value": data.text } })
    };

    takePicture = async () => {
        console.log('opening camera');
        var image: any = await Camera.getPhoto({
            quality: 50,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl
        });
        // var imageUrl = image.webPath;
        console.log(image);
        image['fileName'] = 'camera_upload'
        image['uploadType'] = 'capture'
        image['name'] = 'IMG.' + image['format']
        this.onDrop([image])
    }
    isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    get_asset_details = () => {
        let requestBody = {}
        this.setShowLoading(true)
        requestAPICall("get_asset_editable_data", { asset_id: this.reduxStore['assetId'], asset_type: this.reduxStore['assetTypeId'] }, requestBody)
            .then(data => {
                console.log(data)
                if (data.responseCode === 200) {
                    var number_of_sector = data["properties"].filter((property: any) => property.propertyName === "no_of_sectors")
                    console.log(number_of_sector)
                    data.properties.map((property: any) => {
                        if (property.propertyName == 'image') {
                            this.setState({
                                imageId: property.value,
                                assetProperties: data.properties
                            })
                        }
                        if (this.reduxStore['assetTypeId'] == 1) {
                            this.setState({
                                selectedSectorCount: number_of_sector[0].value,
                                sectorlist: this.reduxStore['sector_id']
                            }, () => {
                                this.load_sector();
                            })
                            this.setState({
                                vendor_type: data["vendor_type"]
                            });
                            if (data["vendor_type"] == 'Directional'){
                                this.setState({ isShowSectorIcons: true })
                            }else {
                                this.setState({ isShowSectorIcons: false })
                            }
                        }

                        return property;
                    })
                }
            })
        requestAPICall("get_asset_details", { asset_id: this.reduxStore['assetId'] }, requestBody)
            .then(data => {
                this.setShowLoading(false)
                console.log(data)
                if (data.responseCode === 200) {
                    if (!this.isEmpty(data['sasStatus'])) {
                        console.log("====== SAS Registration found====")
                        this.setState({
                            edit_serial: false
                        })
                    }
                    this.props.upadateAssetTypeId(data.assetTypeId);
                    this.props.upadateAssetId(data.assetId);
                    this.setState({
                        // assetProperties: data.properties,
                        isShow: true,
                        sectorList: data.sector_id,
                        assetName: data.assetName,
                        operationalStatus: data.operationalStatus
                    })
                    data.properties.map((property: any, index: any) => {
                        if (property.propertyName == 'image') {
                            this.setState({ image: property.value })
                        }
                        if (property.propertyName == 'ap_name' || property.propertyName == 'cpe_name') {
                            this.setState({ assetName: property.value })
                        }
                        if (property.propertyName == 'serial_id') {
                            this.setState({ serialId: property.value })
                        }
                        if (property.propertyName == 'vendor_type') {
                            this.setState({ vendor_type: property.value })
                        }
                        return property
                    })
                    this.setState({
                        vendor_type: data["vendor_type"]
                    });
                    if (data["vendor_type"] == 'Directional'){
                        this.setState({ isShowSectorIcons: true })
                    }else {
                        this.setState({ isShowSectorIcons: false })
                    }
                } else {
                    this.setState({
                        warnMessage: data.message,
                        alertbox: true
                    })
                }

            }, err => {
                this.setState({ assetProperties: [] })
            })
    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    setShowLoading = (st: any) => {
        this.setState({ isLoading: st })
    }

    handleChange = (evt: any) => {
        console.log(evt.target.value)
        if (evt.target.value == "Omni"){
            this.setState({ isShowSectorIcons: false })
        } else {
            this.setState({ isShowSectorIcons: true })
        }
        this.setState({ [evt.target.name]: evt.target.value });
        this.state.assetProperties.map((property: any) => {
            if (property.propertyName === 'vendor_type') {
                property.value = evt.target.value
            }
            return property
        })

    }


    parameterStatus = () => {
        console.log("GET STATUS============")
        let requestbody = {}
        requestAPICall("param_status", { asset_id: this.reduxStore['assetId'] }, requestbody).then(data => {
            if (data.responseCode == 200) {
                this.setState({
                    paramStat: data.ParamstatusCode
                })

            }
            console.log(this.state.paramStat)

        })
    }
    delete_asset() {
        this.setShowLoading(true)
        let requestbody = {
            parameterStatus: "Deregistered",
            reason: "delete from SAS"
        }
        // if ([3, 4, 5, 6, 11].includes(this.state.paramStat)) {
        requestAPICall("delete_device", { asset_id: this.reduxStore['assetId'] }, requestbody).then(data => {
            console.log(data.responseCode, "asset deleted=========")
        })
        // }

        requestAPICall("delete_asset", { asset_id: this.reduxStore['assetId'] }, {}).then(data => {
            this.setShowLoading(false)
            if (data.responseCode == 200) {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
                this.hideModal2()
                this.hideModal1()
                this.props.deleteAssetConfrm()
                this.props.hideOverlay()
            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }
        })
    }
    showModal1 = () => {
        this.setState({
            card1: true
        })
        console.log(this.state.show)
    }
    hideModal1 = () => {
        this.setState({
            card1: false
        })
    }
    showModal2 = () => {
        this.setState({
            card2: true
        })
        console.log(this.state.show)
    }
    hideModal2 = () => {
        this.setState({
            card2: false
        })
    }
    removePhoto() {
        this.setState({ isImageRemoved: true })
        this.hideModal2();
    }
    onDrop = (files: any) => {
        this.setState({ files: files, isImageChanged: true, isImageRemoved: false }, () => {
            console.log(this.state.files)
            this.state.assetProperties.forEach((property: any) => {
                if (property.propertyName == "image") {
                    if (this.state.files.length > 0) {
                        var picReader = new FileReader();
                        let fileName = this.state.files[0].name
                        let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
                        if (this.state.files[0].uploadType === undefined) {
                            picReader.onload = (e) => {
                                this.state.filerequestBody = {
                                    'fileName': fileName,
                                    'fileExtension': fileExt,
                                    'fileValue': picReader.result
                                }

                            }
                            picReader.readAsDataURL(this.state.files[0]);
                        } else {
                            this.state.filerequestBody = {
                                'fileName': fileName,
                                'fileExtension': this.state.files[0].format,
                                'fileValue': this.state.files[0].dataUrl
                            }
                        }

                    }
                }
            })
        })

    };
    onChange(e: any) {
        console.log(e.target.name)
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
        this.state.assetProperties.forEach((property: any) => {


            if (property.propertyName == e.target.name) {
                property.value = e.target.value
            }
            if (property.propertyName === "ap_name" && e.target.name === "ap_name") {
                console.log(property.propertyName)
                this.setState({
                    assetName: e.target.value
                })
            }
            if (property.propertyName === "cpe_name" && e.target.name === "cpe_name") {
                console.log(property.propertyName)
                this.setState({
                    assetName: e.target.value
                })
            }
            if (property.propertyName === "serial_id" && e.target.name === "serial_mob_edit") {
                property.value = e.target.value
                this.setState({
                    serialId: e.target.value
                }, () => {
                    console.log(this.state.serialId);

                })
            }
            return property;
        });

    }
    onChangeSector = (e: any) => {
        // console.log(e.target.value);
        // console.log(e.target.name);
        var secList = this.state.sectorList
        try {
            var index = e.target.name.split('_')[1]
            secList[index]['sector_value'] = e.target.value;
            this.setState({ sectorList: secList })
        } catch (error) { }
    }
    setSelectedSector = (count: number) => {
        sector_list_ids = []
        this.setState({ selectedSectorCount: count })

        this.load_sector()
        this.state.assetProperties.forEach((property: any) => {
            if (property.propertyName == "no_of_sectors") {
                property.value = count
            }
        });
        let diff = count - sector_list_ids.length
        if (diff > 0) {
            for (let index = sector_list_ids.length; index < count; index++) {
                var json: any = {}
                json["sector_id"] = 0
                json["sector_value"] = 0
                sector_list_ids.push(json)
            }
        } else {
            for (let index = 0; index < Math.abs(diff); index++) {
                sector_list_ids.pop()
            }
        }


    }
    load_sector() {
        const element_list = []
        var val = ''
        for (let index = 1; index <= this.state.selectedSectorCount; index++) {
            val = ''
            var element;
            sector_list_ids = this.reduxStore['sectorId']
            try {
                val = sector_list_ids[index - 1]["sector_value"]
            } catch (error) {
                val = ""
            }

            element =
                <div className={`col-md-6 col-6 d-inline-block m-t-7  ${index % 2 !== 0 ? ' p-r-5' : 'p-l-5'} `} >
                    <div className="form-label-group m-0">
                        <input ref={(ref) => { this.apsecFocus[index - 1] = ref; return true; }} type="text" style={{ height: '45px' }} value={val} name={"Sector_" + index} className="serial" id={"Sector_" + index} placeholder={"Sector " + index + " ID #"} onChange={(e: any) => this.sector_id_list(e, index)} />
                        <label className="pages" htmlFor={"Sector_" + index}>{"Sector " + index + " ID #"}</label>
                    </div>
                </div>
            element_list.push(element)
        }
        return <div className="row">{element_list}</div>
    }
    sector_id_list(e: any, index: any) {
        sector_list_ids[index - 1]["sector_value"] = e.target.value
        this.setState({
            [e.target.name]: e.target.value
        })
        this.state.assetProperties.forEach((property: any) => {
            if (property.propertyName == "sector_id") {
                property.value = sector_list_ids
            }
        });
        this.validate_request()
    }
    validate_request() {
        console.log("validation")
        let check = false
        this.state.assetProperties.forEach((property: any) => {
            if (property.value === "") {
                check = true

            }
        });


    }
    validationRequest() {
        let check = false
        this.state.assetProperties.forEach((property: any, key: any) => {
            if (!check) {
                if (property.value === "" && property.isRequired == true && property.isShowable == true && property.propertyName !== "no_of_sectors") {
                    console.log(property)
                    this.apsecFocus[key].focus()
                    check = true
                    this.setState({
                        warnMessage: property.displayName + " can not be empty",
                        alertbox: true
                    })
                }
                console.log(this.reduxStore['assetTypeId'])
                if (property.propertyName == "no_of_sectors" && property.value == "" && property.isRequired == true && this.reduxStore['assetTypeId'] == 1) {
                    console.log(property)
                    check = true
                    this.setState({
                        warnMessage: "Please enter " + property.displayName,
                        alertbox: true
                    })

                }

            }
        });
        console.log(check)

        if (!check) {


            if (check == false) {
                is_submit_form = true
            } else {
                is_submit_form = false
            }

            if (is_submit_form && this.reduxStore['assetTypeId'] === 1) {
                this.validateSectors()
            }

            else if (is_submit_form && this.reduxStore['assetTypeId'] === 2) {
                secval = true
            }
        }
    }
    validateSectors() {
        let check = false
        console.log("SSSSSSSSSSSSS", sector_list_ids)
        sector_list_ids.forEach((sector: any, key: any) => {
            if (!check) {
                if (sector.sector_value === 0 || sector.sector_value === "") {
                    this.setState({
                        warnMessage: "Sector " + sector.sector_id + " Id can not be empty",
                        alertbox: true
                    })
                    this.apsecFocus[key].focus()
                    check = true

                }
                if (sector.sector_value !== "") {
                    if (!(/^[a-zA-Z0-9.-]+$/.test(sector.sector_value))) {
                        this.setState({
                            warnMessage: "Please enter only Alphanumeric values for Sector Id ",
                            alertbox: true
                        })
                        this.apsecFocus[key].focus()
                        check = true
                    }
                }

            }

        });

        if (check === false) {
            secval = true

        }
        else if (check === true) {
            secval = false

        }
    }


    saveChanges = () => {
        this.validationRequest()
        let request_list: any = []
        // request_list = this.state.assetProperties.filter((property: any) => property.propertyName !== 'serial_id')
        request_list = this.state.assetProperties.map((property: any) => {
            if (property.propertyName === 'serial_id') { property.value = this.state.serialId }
            if (property.propertyName === "radiation_center") {
                if (property.value === "") {
                    property.value = 0
                }
            }
            return property;
        })

        console.log(this.state.isImageChanged);
        console.log(this.state.isImageRemoved);

        if (this.state.isImageChanged || this.state.isImageRemoved) {
            if (secval) {
                this.setShowLoading(true)
                if (this.state.isImageRemoved) {
                    request_list.forEach((property: any) => {
                        if (property.propertyName == "ap_name" || property.propertyName == "cpe_name") {
                            this.setState({ assetName: property.value })
                        }
                        if (property.propertyName == "image") {
                            property.value = ""
                        }
                    })
                    // property.value = 1
                    console.log(request_list)
                    let requestBody = {
                        "assetName": this.state.assetName,
                        "serialId": this.state.serialId,
                        "assetTypeId": this.reduxStore['assetTypeId'],
                        // "latitude": this.props.latlong[0],
                        // "longitude": this.props.latlong[1],
                        "properties": request_list,
                        "building_id": this.reduxStore['buildingId'],
                        "sector_id": sector_list_ids,
                        "status": this.state.operationalStatus
                    }
                    this.editAssetChanges(requestBody)
                } else {
                    requestAPICall("upload_file", {}, this.state.filerequestBody).then(data => {
                        console.log(data)
                        if (data['responseCode'] == 200) {
                            request_list.forEach((property: any) => {
                                if (property.propertyName == "ap_name" || property.propertyName == "cpe_name") {
                                    this.setState({ assetName: property.value })
                                }
                                if (property.propertyName == "image") {
                                    property.value = data.file_id
                                }
                            })
                            // property.value = 1
                            console.log(request_list)
                            let requestBody = {
                                "assetName": this.state.assetName,
                                "serialId": this.state.serialId,
                                "assetTypeId": this.reduxStore['assetTypeId'],
                                // "latitude": this.props.latlong[0],
                                // "longitude": this.props.latlong[1],
                                "properties": request_list,
                                "building_id": this.reduxStore['buildingId'],
                                "sector_id": sector_list_ids,
                                "status": this.state.operationalStatus
                            }
                            this.editAssetChanges(requestBody)
                        } else {
                            this.setState({
                                warnMessage: data.message,
                                alertbox: true
                            })
                        }
                    }, err => {
                        this.setShowLoading(false)
                        this.setState({
                            warnMessage: "An error occured.",
                            alertbox: true
                        })
                    })
                }
            }
        } else {
            console.log(secval)
            if (secval) {
                this.setShowLoading(true)
                request_list.forEach((property: any) => {
                    if (property.propertyName == "ap_name" || property.propertyName == "cpe_name") {
                        this.setState({ assetName: property.value })
                    }
                    if (property.propertyName == "image") {
                        property.value = this.state.imageId
                    }
                })
                // property.value = 1
                console.log(request_list)
                let requestBody = {
                    "assetName": this.state.assetName,
                    "serialId": this.state.serialId,
                    "assetTypeId": this.reduxStore['assetTypeId'],
                    // "latitude": this.props.latlong[0],
                    // "longitude": this.props.latlong[1],
                    "properties": request_list,
                    "building_id": this.reduxStore['buildingId'],
                    "sector_id": sector_list_ids,
                    "status": this.state.operationalStatus
                }
                this.editAssetChanges(requestBody)
            }
        }
    }

    editAssetChanges = (requestBody: any) => {
        this.setShowLoading(true)
        console.log(requestBody)
        requestAPICall("edit_asset_details", { asset_type_id: this.reduxStore['assetTypeId'], asset_id: this.reduxStore['assetId'] }, requestBody).then(data => {
            this.setShowLoading(false)
            if (data.responseCode == 200) {
                this.props.hideOverlay()
                this.props.getParticularAsset()
                if (this.reduxStore['tabType'] == "indoor") {
                    this.props.listAssets('create-list', this.reduxStore['floorId'])
                } else {
                    this.props.listAssets('create-list-angle')
                }
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })

            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }
        })
    }

    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'
        let classImageUpload = files.length ? '-uploaded' : ''
        return (
            <div>
                <div className="mobonly" >

                    <div className='param-info-mob' style={{ height: '100vh' }}>
                        <div className='col-12 text-center header' style={{ padding: '10px' }}>
                            <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={this.props.hideOverlay} src={BackIcon} />
                            {this.reduxStore['assetTypeId'] == 1 || this.reduxStore['assetTypeId'] == 2 || this.reduxStore['assetTypeId'] == 6?
                                <span className="apheader">CBSD DETAILS</span> : <span className="apheader">CPE DETAILS</span>}
                        </div>
                        <div className='container-fluid apdash' style={{ background: '#F6F6F8' }}>
                            <div className="card apeditcard">
                                <div className="card-body" style={{ overflowY: 'auto' }}>
                                    {
                                        this.state.assetProperties.map((property: any, index: any) => {
                                            if (property.isShowable) {
                                                if (property.dataType === 'string') {
                                                    if (property.propertyName === "serial_id") {
                                                        return <div className='row'>
                                                            <div className='col-10 p-r-0'>
                                                                <div className="form-label-group m-0">
                                                                    <input type="text" className="text-user b-t-0" style={{ borderRadius: '0px', borderRight: "none" }} maxLength={45} disabled={!(this.state.edit_serial)} value={property.value} id="serialmob_edit" name="serial_mob_edit" placeholder="Serial #" onChange={this.onChange.bind(this)} />
                                                                    <label className="acpdetails" htmlFor="serialmob_edit">Serial #</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 p-l-0">
                                                                <div className="v-h-center b-t-0" style={{ height: "100%", border: "0.5px solid #CED7DF", borderLeft: "none" }} >
                                                                    <img src={qricon} style={{ width: '25px' }} onClick={() => { this.openScanner() }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    if (property.propertyName === "access_point_id") {

                                                        return <div className="col-12 p-0">
                                                            <div className="form-label-group m-0">
                                                                <input type="text" className="text-user" style={{ borderRadius: "0px" }} value={property.value} id={property.propertyName} disabled={this.state.assetAction == "add" ? this.state.field_disable : true} name={property.propertyName} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                <label className="acpdetails" htmlFor={property.propertyName}>{property.displayName}</label>
                                                            </div>
                                                        </div>

                                                    }
                                                    if (property.propertyName === "cpe_id") {

                                                        return <div className="col-12 p-0">
                                                            <div className="form-label-group m-0">
                                                                <input type="text" className="text-user" style={{ borderRadius: "0px" }} value={property.value} id={property.propertyName} disabled={this.state.assetAction == "add" ? this.state.field_disable : true} name={property.propertyName} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                <label className="acpdetails" htmlFor={property.propertyName}>{property.displayName}</label>
                                                            </div>
                                                        </div>

                                                    }
                                                    else {
                                                        return <div className="col-12 p-0">
                                                            <div className="form-label-group m-0">
                                                                <input type="text" className={`text-user  ${index === 0 ? 'borderRadtop' : 'borderRadnone'}`} maxLength={45} value={property.value} id={property.propertyName} name={property.propertyName} placeholder={property.displayName} onChange={this.onChange.bind(this)} />
                                                                <label className="acpdetails" htmlFor={property.propertyName}>{property.displayName}</label>
                                                            </div>
                                                        </div>
                                                    }


                                                }
                                                else if (property.propertyName === 'no_of_sectors' && this.reduxStore['assetTypeId'] == 1) {
                                                    return <div className="p-t-10 p-b-10 b-t-0" style={{ border: '1px solid #CED7DF' }}>
                                                        <div className='col-12'>
                                                            <div className='col-12 p-0'>
                                                                <span className='cardtext'>Number of Sectors</span>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 p-0 m-t-10'>
                                                            {this.state.sectorsCount.map((index: any) => (
                                                                <div className='col-2 d-inline-block'>
                                                                    <i onClick={() => this.setSelectedSector(index)} className={`fa fa-map-marker mapmarker m-r-20 ${index <= this.state.selectedSectorCount ? 'mapmarker-selected' : ''}`}>

                                                                    </i>  </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                                else if ((property.dataType === 'float' || property.dataType === 'int') && (property.propertyName === 'latitude' || property.propertyName === 'longitude')) {
                                                    return <div className="col-12 p-0">
                                                        <div className="form-label-group m-0">
                                                            <input type="number" className="text-user b-t-0"
                                                                style={property.propertyName === 'longitude' ? { borderRadius: '0px 0px 6px 6px', borderBottom: '1px solid #CED7DF' } : { borderRadius: '0px' }}
                                                                value={property.value} id={property.propertyName} placeholder={property.displayName} name={property.propertyName} onChange={this.onChange.bind(this)} />
                                                            <label className="acpdetails" htmlFor={property.propertyName}>{property.displayName}</label>
                                                        </div>
                                                    </div>
                                                }

                                                else if ((property.dataType === 'float' || property.dataType === 'int') && (property.propertyName !== 'no_of_sectors' && property.propertyName !== 'latitude' && property.propertyName !== 'longitude')) {
                                                    return <div className="col-12 p-0">
                                                        <div className="form-label-group m-0">
                                                            <input type="number" className="text-user borderRadnone b-t-0" value={property.value} id={property.propertyName} placeholder={property.displayName} name={property.propertyName} onChange={this.onChange.bind(this)} />
                                                            <label className="acpdetails" htmlFor={property.propertyName}>{property.displayName}</label>
                                                        </div>
                                                    </div>
                                                }


                                                else if (property.dataType === 'textArea') {
                                                    return <IonItem lines="none" color="none" className="notes">
                                                    <IonLabel position="floating">{property.displayName}</IonLabel>
                                               <IonTextarea rows={3} maxlength={45} name={property.propertyName} value={property.value} onInput={this.onChange.bind(this)} />
                                               </IonItem>
                                                }


                                                else if (property.propertyName === 'vendor_type' && (this.reduxStore['assetTypeId'] == 1 || this.reduxStore['assetTypeId'] == 6)) {
                                                    return <div className='row m-t-10'>
                                                        <div className='col-12'>
                                                            <div className="form-label-group m-0">
                                                            <select className="form-control settingdropdown" id={property.propertyName}
                                                                style={{ border: '1px solid #CED7DF', borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }} 
                                                                name={property.propertyName} value={this.state.vendor_type} onChange={this.handleChange} disabled={true}>
                                                                {property.validValues.map((value: any) => this.state.vendor_type == value?<option value={value} selected >{value}</option>:<option value={value} >{value}</option>)}
                                                            </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                            }
                                        })
                                    }{
                                        this.load_sector()
                                    }

                                    {/* <div className="row">
                                        {this.state.sectorList.map((sector: any, index: any) => {
                                            return <div className={`col-6 d-inline-block  ${index % 2 === 0 ? 'p-r-0' : 'p-l-0'}`}>
                                                <div className="form-label-group m-0">
                                                    <input type="text" className={`text-user serial buildinfo b-t-0  ${index % 2 === 0 ? '' : 'b-l-0'}`} id={"sector_" + sector.sector_id} value={sector.sector_value} style={{ borderRadius: '0px', borderBottom: '1px solid #CED7DF' }}
                                                        name={"sector_" + index} placeholder="Sector 1 ID #" onChange={this.onChangeSector} />
                                                    <label className="acpdetails" htmlFor={"sector_" + sector.sector_id}>Sector {index + 1} ID #</label>
                                                </div>
                                            </div>
                                        })}

                                    </div> */}

                                    {this.state.isImageRemoved || this.state.isImageChanged || this.state.imageId === '' ? <div className="row m-t-10">
                                        <div className='col-10 p-r-0' style={{ fontSize: '14px' }}>
                                            <Dropzone onDrop={this.onDrop} disabled={false}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className='dropzone-set' >
                                                        <div {...getRootProps({ className: classDName + "-mob" })}>
                                                            <input {...getInputProps()} accept='image/png,image/jpeg,image/png' />
                                                            {(files.length) ? <span style={{ color: "#00D56E", display: 'contents' }}>
                                                                {files}
                                                            </span>
                                                                : <div>
                                                                    <span style={{ fontSize: '14px' }}>+ Add Image</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <div className={"v-h-center image-field-action" + classImageUpload}>
                                                {files.length ?
                                                    <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = []; this.onDrop([]) }}>clear</i>
                                                    : <img src={camicon} style={{ width: '21px' }} onClick={this.takePicture}></img>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                        :
                                        <div className="col-12 p-0 m-t-15">
                                            <div className="col-6 p-l-0 p-r-5 d-inline-block">
                                                {
                                                    this.state.image !== "" && this.state.image !== undefined ? <img className='apimage' style={{ height: '88px', width: '135px' }} src={this.state.image} />: ""
                                                }
                                                {/* <img className='apimage' style={{ height: '80px' }} src={this.state.image} /> */}
                                            </div>
                                            {this.state.isShow ?
                                                <div className="col-6 p-r-0 p-l-5 d-inline-block">
                                                    <i className="material-icons removeclose">close</i>
                                                    <span className="remove" data-toggle="modal" data-target="#deleteimageModal" onClick={(e: any) => { e.preventDefault(); this.showModal2() }}>Remove Photo</span>
                                                </div> : ""}
                                        </div>
                                    }
                                    {this.state.isShow ?
                                        <div className="col-12 p-0 m-t-15">
                                            <i className="fas fa-trash deleteicon"></i>
                                            <button className='deletebtn distance' style={{ color: '#E22B2B' }} data-toggle="modal" data-target="#deleteModal" onClick={(e: any) => { e.preventDefault(); this.showModal1() }} >Delete CBSD</button>
                                        </div> : ""}
                                    {this.state.isShow ?
                                        <div className="col-12 p-0 m-t-10">
                                            <button className='btn cardbtn' onClick={this.saveChanges}>Save Changes</button>
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.show && this.state.card1} handleClose={this.hideModal1}>
                        {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row' style={{ padding: '0 10px' }}>
                                        <h6 className="modal-title" style={{ marginTop: '0px' }}>CONFIRMATION</h6>
                                        <i className="material-icons modalclose imgclose-file" onClick={(e: any) => { e.preventDefault(); this.hideModal1() }} data-dismiss="modal" aria-label="Close">clear</i>
                                    </div>
                                    <div className='row m-t-20 text-center justify-content-center' style={{ padding: '0 10px' }}>
                                        <span className='modaltext'>Are you sure you want to delete this CBSD?</span>
                                    </div>
                                    <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                        <div className='col-6 p-r-5'>
                                            <button className='lightbtnmob modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal1() }}  >No</button>
                                        </div>
                                        <div className='col-6 p-l-5'>
                                            <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.delete_asset() }}  >Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </Modal>
                    <Modal show={this.state.show && this.state.card2} handleClose={this.hideModal2}>
                        {/* <div className="modal fade" id="deleteimageModal" role="dialog" aria-hidden="true"> */}
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row' style={{ padding: '0 10px' }}>
                                        <h6 className="modal-title" style={{ marginTop: '0px' }}>CONFIRMATION</h6>
                                        <i className="material-icons modalclose imgclose-file" onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} data-dismiss="modal" aria-label="Close">clear</i>
                                    </div>
                                    <div className='row m-t-20 text-center justify-content-center' style={{ padding: '0 10px' }}>
                                        <span className='modaltext'>Are you sure you want to remove this photo?</span>
                                    </div>
                                    <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                        <div className='col-md-6 col-6 p-r-5'>
                                            <button className='lightbtnmob modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} >No</button>
                                        </div>
                                        <div className='col-md-6 col-6 p-l-5'>
                                            <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.removePhoto() }}>Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </Modal>
                    <IonAlert
                        isOpen={this.state.alertbox}
                        onDidDismiss={() => this.setShowAlert(false)}
                        message={this.state.warnMessage}
                        buttons={['OK',]}
                    />

                    <IonLoading
                        isOpen={this.state.isLoading}
                        onDidDismiss={() => this.setShowLoading(false)}
                        message={'Loading...'}
                    // duration={5000}
                    />
                </div>
            </div>
        );
    }
}



// export default withIonLifeCycle(Acpmob);
export default connect<{}, {}, Iprops>(mapStateToProps, mapDispatchToProps)(withRouter(withIonLifeCycle(Acpmob)));
