import React, { Component } from 'react'
import "./ap-parameters.css";
import { isBrowser, isMobile } from "react-device-detect";
import Back from '../assets/img/left-arrow.svg'
import { IonPage, IonContent, withIonLifeCycle, IonAlert, IonLoading } from '@ionic/react';
import BackIcon from '../assets/img/go-back-icon-img.png'
import ReactSpeedometer from "react-d3-speedometer"
import { Plugins, AppState } from "@capacitor/core";
import { requestAPICall } from "../service/apiHandler";
import { getSectorImage, getSectorImageMeasure } from '../service/rotateIcon';
import store from '../store';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Screenshot } from '@ionic-native/screenshot'
import { GoogleMap, Marker, Polyline, InfoWindow } from '@react-google-maps/api';

const { CustomNativePlugin } = Plugins;
const { NetworkStatus } = Plugins;
const { Geolocation } = Plugins;
const { Device } = Plugins;
const { App } = Plugins;
const { BackgroundTask } = Plugins
var speed: any = '0';
var gauge: any = 0;
var dspeed: any = 0;
var uspeed: any = 0;
var dprogress: any = 0;
var uprogress: any = 0;
var server_name: any = null;
var latency: any = null;
var speedColor: any = '#0059B2';
var rsrpColor: any = '#2BFF5D'
// let center: {
//     lat: 16, lng: 80
// }
var gps_icon = {
    url: require('../assets/img/add-cpe-icon-img-blue.png'),
    scaledSize: new window.google.maps.Size(16, 16),
    anchor: new window.google.maps.Point(8, 8)
}

var lineSymbol = {
    path: 'M 0,-1 0,1',
    scale: 4,
    strokeColor: rsrpColor,
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: rsrpColor,
    fillOpacity: 0.35,
    radius: 30000,
    zIndex: 1
};
class network extends Component {
    state: any = {};
    props: any = {};
    backButton_event: any
    backButton_timer: any = null
    appState_event: any
    timer: any;
    timer2: any;
    checkIconsLoaded: any;
    reduxStore: any = store.getState()['general'];
    pol: any;
    watchId: any;
    constructor(props: any) {
        super(props);
        this.watchId = ''
        this.state = {
            rsrpValue: -140,
            networkStatusArray: [],
            files: [],
            sectorIdList: [],
            sectorId: 0,
            alertbox: false,
            warnMessage: "",
            assetList: [],
            isLoading: false,
            rsrpPrimaryColor: "#fe8f8f",
            rsrpSecondaryColor: "#fcefef",
            enB: "",
            pci: "",
            tech: "",
            ss: null,
            did: "",
            rawAssetList: [],
            locationService: 0,
            networkScreenType: 'overview',
            assetId: 0,
            assetFound: false,
            pciCardShow: false,
            transparentIcon: {
                url: require('../assets/img/status-img/transparent-circle-marker.png'),
                scaledSize: new window.google.maps.Size(0, 0),
                anchor: new window.google.maps.Point(5, 5),
                labelAnchor: new window.google.maps.Size(0, 0),
                labelOrigin: new window.google.maps.Point(5, 45)
            }
        }
    }

    componentDidMount() {
        // this.reduxStore = store.getState()['general'];

        localStorage.setItem("urlPath", this.props.location.pathname)
        console.log('==component did mount==')
        console.log(this.reduxStore);
        console.log(this.reduxStore['sectorId']);
        this.reduxStore = store.getState()['general'];
        this.get_location()
        this.getAllAssets('create-list-angle')
        console.log(localStorage.getItem('networkScreenType'));
        this.setState({ networkScreenType: localStorage.getItem('networkScreenType') })
        if (this.reduxStore['sectorId'] !== undefined) {
            this.setState({ sectorIdList: this.reduxStore['sectorId'] }, () => {
                if (this.reduxStore['assetTypeId'] === 1) this.setSectorId(this.state.sectorIdList[0])
            })
        }
        if (isMobile) {
            this.timer = setInterval(() => {
                this.getCapacitorConnection();
            }, 3000)
            this.timer2 = setTimeout(() => {
                this.getpciId()
            }, 6000)
        }
        this.set_backButton_listener()
    }

    set_backButton_listener = () => {

        var backButtonListenerExists = localStorage.getItem('backButton')
        console.log('checking for back button listener..')
        if (backButtonListenerExists == null) {
            localStorage.setItem('backButton', "true")
            console.log('creating back button listener')
            this.backButton_event = App.addListener('backButton', (state: any) => {
                // state.isActive contains the active state
                console.log('back button pressed..............');
                this.props.history.push('/map/outdoor/main')
            });
        }
        else {
            console.log('backButton listener already exists')
        }
        if (this.backButton_timer == null) {
            console.log('creating timer.............')
            this.backButton_timer = setInterval(() => {
                this.set_backButton_listener()
            }, 2000)
        }
        else {
            console.log('timer already exists.............')
        }
        var appStateListenerExists = localStorage.getItem('appState')
        console.log('checking for app state listener..')
        if (appStateListenerExists == null) {
            console.log('creating app state event listener....................')
            localStorage.setItem('appState', "true")
            this.appState_event = App.addListener('appStateChange', (state: AppState) => {
                if (state.isActive) {

                    this.set_backButton_listener()

                }
                if (!state.isActive) {
                    let taskId = BackgroundTask.beforeExit(async () => {
                        // In this function We might finish an upload, let a network request
                        // finish, persist some data, or perform some other task
                        console.log('inactive state.............')
                        // Example of long task

                        if (this.backButton_timer != null) {
                            console.log('clearing timer.......')
                            clearInterval(this.backButton_timer)
                            this.backButton_timer = null
                        }
                        if (this.backButton_event != null) {
                            console.log('clearing back button event..........')
                            this.backButton_event.remove()
                        }
                        localStorage.removeItem('backButton')
                        // Must call in order to end our task otherwise
                        // we risk our app being terminated, and possibly
                        // being labeled as impacting battery life
                        BackgroundTask.finish({
                            taskId
                        });
                    });
                }
            }
            )
        }
        else {
            console.log('app state listener already exists............')
        }

    }

    // ionViewDidEnter() {
    //     console.log('==ion view did enter==')
    //     console.log(this.reduxStore);
    //     console.log(this.reduxStore['sectorId']);
    //     this.reduxStore = store.getState()['general'];
    //     this.get_location()
    //     console.log(localStorage.getItem('networkScreenType'));
    //     this.setState({ networkScreenType: localStorage.getItem('networkScreenType') })
    //     if (this.reduxStore['sectorId'] !== undefined) {
    //         this.setState({ sectorIdList: this.reduxStore['sectorId'] },()=>{
    //             if (this.reduxStore['assetTypeId'] === 1) this.setSectorId(this.state.sectorIdList[0])
    //         })
    //     }
    //     if (isMobile) {
    //         this.timer = setInterval(() => {
    //             this.getCapacitorConnection();
    //         }, 500)
    //     }
    // }
    componentDidUpdate() {
        this.reduxStore = store.getState()['general'];
        // fetch('http://ip-api.com/json/').then(results => {
        //     return results.json()
        // }).then(data => {
        //     console.log(data.lat,data.lon,"location net")
        //     this.setState({
        //         lat: data.lat.toFixed(6),
        //         lng: data.lon.toFixed(6)
        //     })
        // })
    }
    setMap = (map: any) => {
        this.pol = map

    }
    setPolyline = (asset: any) => {
        console.log(asset)
        var assetLocation = { lat: parseFloat(asset['latitude']), lng: parseFloat(asset['longitude']) }
        var assetLocationObject = new window.google.maps.LatLng(parseFloat(asset['latitude']), parseFloat(asset['longitude']))
        var centerLocationObject = new window.google.maps.LatLng(this.state.center['lat'], this.state.center['lng'])
        if (this.pol != null && this.pol != undefined) {
            var poliline = new window.google.maps.Polyline({
                path: [
                    assetLocation,
                    this.state.center
                ],
                geodesic: true,
                strokeColor: '#FF8C00',
                strokeOpacity: 0,
                strokeWeight: 2,
                icons: [{
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '20px'
                }],
            });
            poliline.setMap(this.pol);
            this.setState({
                midPoint: google.maps.geometry.spherical.interpolate(assetLocationObject, centerLocationObject, 0.5)
            })
        }
    }
    clearWatch() {
        if (this.watchId != null) {
            Geolocation.clearWatch({ id: this.watchId });
        }

    }
    async getLocationUsingWatchPosition(): Promise<any> {
        return new Promise<any>((resolve) => {
            this.watchId = Geolocation.watchPosition({}, (position: any, err: any) => {
                if (err) {
                    console.log(err)
                }
                else {
                    console.log(position)
                    this.clearWatch()
                    resolve(position)
                }
            })
        })
    }

    get_location = async () => {
        const coordinates = await this.getLocationUsingWatchPosition()
        // const coordinates = await Geolocation.getCurrentPosition();
        console.log('Current', coordinates);
        this.setState({
            lat: coordinates.coords.latitude.toFixed(6),
            lng: coordinates.coords.longitude.toFixed(6),
            center: {
                lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
                lng: parseFloat(coordinates.coords.longitude.toFixed(6))
            }

        })

        console.log(this.state.center)
        // fetch('http://ip-api.com/json/').then(results => {
        //     return results.json()
        // }).then(data => {
        //     console.log(data.lat,data.lon,"location net")
        //     this.setState({
        //         lat: data.lat.toFixed(6),
        //         lng: data.lon.toFixed(6)
        //     })

        // })
    }

    uploadFile() {
        this.getNetworkType()
        this.getDeviceId()
        let filerequestBody = {}
        console.log(this.state.ss, "inside update")

        var initial = this.state.ss["URI"].split(';')[0]
        var fileExt = initial.split('/')[1]

        let tempDate = new Date()
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + '_' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        var fileName = "img_" + date + "." + fileExt

        var fileValue = this.state.ss["URI"]

        filerequestBody = {
            'fileName': fileName,
            'fileExtension': fileExt,
            'fileValue': fileValue
        }
        console.log(filerequestBody)
        this.setState({ isLoading: true })
        requestAPICall("upload_file", {}, filerequestBody).then(data => {
            console.log(data)
            if (data['responseCode'] == 200) {
                console.log(data.file_id);
                this.updateScreenshot(data.file_id)
            } else {
                this.setState({ isLoading: false })
                this.setState({ alertbox: true, warnMessage: data.message })
            }
        })
    }
    async getNetworkType() {
        let res = await NetworkStatus.getNetworkType({ message: "signal" });
        console.log(res);
        this.setState({
            tech: res['networkType']
        })
        // this.setState({ connectionNetworkType: res['networkType'] })
    }
    async getDeviceId() {
        const info = await Device.getInfo();
        console.log(info);
        this.setState({
            did: info['uuid']
        })
    }
    async getpciId() {
        var reqBody = {
            "pci": this.state.pci,
            "enB": this.state.enB,
            "latitude": this.state.lat,
            "longitude": this.state.lng
        }
        console.log("search call")
        console.log(reqBody)
        requestAPICall("search_tower", {}, reqBody).then(data => {
            console.log(data)
            // this.setState({ isLoading: false }).
            // this.setState({ alertbox: true, warnMessage: data.message })
            if (data['asset_found'] == true) {
                this.setState({
                    assetFound: data['asset_found'],
                    distance: data['distance']
                }, () => {
                    this.setPolyline(data['data'])
                    if (this.state.rsrpValue >= -110 && this.state.rsrpValue < -100) {
                        rsrpColor = '#FFFF00'
                        lineSymbol['fillColor'] = '#FFFF00'
                        lineSymbol['strokeColor'] = '#FFFF00'
                    } else if (this.state.rsrpValue >= -120 && this.state.rsrpValue < -110) {
                        rsrpColor = '#FF892E'
                        lineSymbol['fillColor'] = '#FF892E'
                        lineSymbol['strokeColor'] = '#FF892E'
                    } else {
                        rsrpColor = '#2BFF5D'
                        lineSymbol['fillColor'] = '#2BFF5D'
                        lineSymbol['strokeColor'] = '#2BFF5D'
                    }

                })
                // this.setState({ "rawAssetList": data['data'] }, () => this.listAssets('create-list-angle'))
            }
        })

    }
    getAllAssets = (status: any) => {
        var assetsList: any = [];
        this.setState({ rawAssetList: [] })
        requestAPICall("list_asset", {}, {})
            .then(data => {
                console.log(data)
                assetsList = [];
                if (data.responseCode == 200) {

                    data.assets.map((asset: any, index: any) => {
                        if (asset['assetType'] === 'AP-outdoor' || asset['assetType'] === 'CPE-outdoor') {
                            assetsList.push(asset)

                        }
                    })
                    if (assetsList.length > 0) {
                        this.setState({ rawAssetList: assetsList }, () => {
                            this.listAssets(status)
                        })
                    }

                }
            })
    }
    listAssets(status: any) {
        console.log("===================")
        console.log(this.state.rawAssetList)
        var assetsList: any = [];
        var label_Text: any = "";
        var pcilabel_Text: any = "";
        this.setState({ assetList: [] })
        this.setState({ disabledAssetList: [] })
        try {
            clearInterval(this.checkIconsLoaded);
        } catch (error) { }
        // flag = flag + 1 
        if (this.state.pciCardShow == true) {
            this.setState({
                transparentIcon: {
                    url: require('../assets/img/status-img/transparent-circle-marker.png'),
                    scaledSize: new window.google.maps.Size(0, 0),
                    anchor: new window.google.maps.Point(5, 5),
                    labelAnchor: new window.google.maps.Size(0, 0),
                    labelOrigin: new window.google.maps.Point(5, 65)
                }
            })
        }
        else {
            this.setState({
                transparentIcon: {
                    url: require('../assets/img/status-img/transparent-circle-marker.png'),
                    scaledSize: new window.google.maps.Size(0, 0),
                    anchor: new window.google.maps.Point(5, 5),
                    labelAnchor: new window.google.maps.Size(0, 0),
                    labelOrigin: new window.google.maps.Point(5, 45)
                }
            })
        }
        this.state.rawAssetList.map((asset: any) => {
            var asset_idList: any = []
            var flag = 0
            asset['azimuth'].forEach((sector: any) => {
                var pci: any = " ";
                if (asset['assetTypeId'] === 1 && 'pci' in asset && asset['pci'].length > 0) {
                    if (asset['pci'][flag] !== 0) {
                        pci = asset['pci'][flag].toString()
                    }
                }
                if (!(asset_idList.indexOf(asset['assetIdentifierId']) >= 0)) {
                    asset_idList.push(asset['assetIdentifierId'])
                    label_Text = { text: asset['assetIdentifierId'] }
                }
                else {
                    var text_1: any = " "
                    label_Text = { text: text_1 }
                }
                if (this.state.pciCardShow && asset['assetTypeId'] == 1) {
                    pcilabel_Text = { text: pci }
                }
                assetsList.push({
                    "assetType": asset['assetType'],
                    "assetId": asset['assetId'],
                    "assetOperationalStatus": asset['operationalStatus'],
                    "assetName": asset['assetName'],
                    "assetIdentifierId": asset['assetIdentifierId'],
                    "coOrdinates": { lat: parseFloat(asset['latitude']), lng: parseFloat(asset['longitude']) },
                    "labelText": label_Text,
                    "pciLabel": pcilabel_Text,
                    "markerIcon": getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "create", "pciCardShow": this.state.pciCardShow, "sasStatus": asset['sasStatus'] }, () => { }),
                    "azimuth": asset['azimuth'],
                    "sector_azimuth": sector[1]
                })
                flag++;

            })
        });
        this.setState({ assetList: assetsList }, () => {
            console.log(this.state.assetList)
            this.state.assetList.map((asset: any) => {
                asset['azimuth'].forEach((sector: any) => {
                    getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate", "pciCardShow": this.state.pciCardShow, "sasStatus": asset['sasStatus'] }, () => { })
                });
            })
        })
        // this.checkIconsLoaded = setInterval(() => {
        //     this.state.assetList.map((asset: any) => {
        //         asset['azimuth'].forEach((sector: any) => {
        //             getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate", "pciCardShow": this.state.pciCardShow, "sasStatus": asset['sasStatus'] }, () => { })
        //         });
        //     })

        // }, 500);
    }
    updateScreenshot(fileId: any) {
        this.getNetworkType()
        this.getDeviceId()
        var reqBody = {
            "assetId": this.reduxStore['assetId'],
            "assetTypeId": this.reduxStore['assetTypeId'],
            "description": "",
            "sectorId": this.reduxStore['assetTypeId'] === 1 ? this.state.sectorId : 0,
            "fileId": fileId,
            "network_status_array": this.state.networkStatusArray,
            "technology": this.state.tech,
            "did": this.state.did,
            "latitude": this.state.lat,
            "longitude": this.state.lng,
            "server": server_name,
            "dspeed": dspeed,
            "uspeed": uspeed,
            "latency": latency
        }
        console.log(reqBody)
        requestAPICall("update_network_status_screenshot", {}, reqBody).then(data => {
            console.log(data)
            this.setState({ isLoading: false })
            this.setState({ alertbox: true, warnMessage: data.message })
            if (data['responseCode'] == 200) {
                console.log(data);
            }
        })
    }

    setShowLoading = (st: boolean) => {
        this.setState({ isLoading: st })
    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    async getCapacitorConnection() {
        let res = await NetworkStatus.getSignals({ message: "lte" });
        // console.log(res);
        // console.log(JSON.parse(res['networkStatusArray']));
        // res = {
        //     "Status": 0,
        //     "mcc": "405",
        //     "mnc": "869",
        //     "networkStatusArray": [
        //         {
        //             "mcc": "405",
        //             "mnc": "869",
        //             "ci": 3473938,
        //             "pci": 80,
        //             "tac": 83,
        //             "networkOperator": "405869",
        //             "earfcn": 38750,
        //             "bandwidth": 2147483647,
        //             "asuLevel": 44,
        //             "cqi": 2147483647,
        //             "dbm": -96,
        //             "level": 3,
        //             "rsrp": -96,
        //             "rsrq": -11,
        //             "rssnr": 2147483647,
        //             "timingAdvance": 23
        //         },
        //         {
        //             "ci": 2147483647,
        //             "pci": 261,
        //             "tac": 2147483647,
        //             "earfcn": 38750,
        //             "bandwidth": 2147483647,
        //             "asuLevel": 32,
        //             "cqi": 2147483647,
        //             "dbm": -108,
        //             "level": 2,
        //             "rsrp": -108,
        //             "rsrq": -2,
        //             "rssnr": 2147483647,
        //             "timingAdvance": 2147483647
        //         },
        //         {
        //             "ci": 2147483647,
        //             "pci": 45,
        //             "tac": 2147483647,
        //             "earfcn": 38750,
        //             "bandwidth": 2147483647,
        //             "asuLevel": 30,
        //             "cqi": 2147483647,
        //             "dbm": -110,
        //             "level": 2,
        //             "rsrp": -110,
        //             "rsrq": -20,
        //             "rssnr": 2147483647,
        //             "timingAdvance": 2147483647
        //         }
        //     ]
        // }
        var networkStatus = JSON.parse(res['networkStatusArray'])
        this.setState({ locationService: res['locationService'] })
        if (res['locationService'] !== 0) {
            this.setState({
                rsrpValue: -140,
                enB: "N/A",
                pci: "N/A"
            })
        }
        this.setState({ networkStatusArray: JSON.parse(res['networkStatusArray']) })
        if (networkStatus.length) {
            this.setState({
                rsrpValue: networkStatus[0]['rsrp'],
                enB: networkStatus[0]['eNBDecimal'],
                pci: networkStatus[0]['pci']
            }, () => {
                if (this.state.rsrpValue <= -100) {
                    this.setState({ rsrpPrimaryColor: "#fe8f8f", rsrpSecondaryColor: "#fcefef" })
                } else if (this.state.rsrpValue > -100 && this.state.rsrpValue < -70) {
                    this.setState({ rsrpPrimaryColor: "#ffe991", rsrpSecondaryColor: "#fff6d3" })
                } else {
                    this.setState({ rsrpPrimaryColor: "#80eab7", rsrpSecondaryColor: "#e5fbf0" })
                }
            })
        }
    }
    componentWillUnmount() {
        console.log('component will leave fired')
        speed = '0';
        gauge = 0;
        dspeed = 0;
        uspeed = 0;
        server_name = null;
        latency = null
        console.log('clearing interval');
        clearInterval(this.timer);
        clearTimeout(this.timer2);
        if (this.backButton_timer != null) {
            console.log('clearing timer.......')
            clearInterval(this.backButton_timer)
            this.backButton_timer = null
        }
        if (this.backButton_event != null) {
            console.log('clearing back button event..........')
            this.backButton_event.remove()
        }
        if (this.appState_event != null) {
            console.log('clearing app state event..........')
            this.appState_event.remove()
        }
        localStorage.removeItem('backButton')
        localStorage.removeItem('appState')
    }
    // ionViewWillLeave() {
    //     console.log('ionViewWillLeave event fired')
    //     speed = '0';
    //     gauge = 0;
    //     dspeed = 0;
    //     uspeed = 0;
    //     server_name = '';
    //     latency = ''
    //     console.log('clearing interval');
    //     clearInterval(this.timer);
    // }
    setSectorId(sector: any) {
        this.setState({ sectorId: sector['sector_id'] })
    }

    async takeScreenshot() {
        await Screenshot.URI(50).then(data => {
            // console.log(data);
            this.setState({
                ss: data
            }, () => this.uploadFile())
        }, err => {
            console.log(err);
        });
    }
    goBack() {
        speed = '0';
        gauge = 0;
        dspeed = 0;
        uspeed = 0;
        server_name = null;
        latency = null
        clearInterval(this.timer);
        clearTimeout(this.timer2);
        // this.props.history.push("/map")
    }

    speed_test = async () => {
        this.get_location()
        this.setShowLoading(true)
        const event_download = Plugins.CustomNativePlugin.addListener("download_test", (info: any) => {
            this.setShowLoading(false)
            // console.log(info);
            speedColor = '#0059B2';
            gauge = info.gauge;
            speed = info.speed;
            dspeed = info.speed;

        });
        const event_upload = Plugins.CustomNativePlugin.addListener("upload_test", (info: any) => {
            // console.log(info);
            this.setShowLoading(false)
            speedColor = '#00d56e'
            gauge = info.gauge;
            speed = info.speed;
            uspeed = info.speed;
        });
        let result = await CustomNativePlugin.customFunction();
        console.log(result)
        server_name = result.server_name
        latency = result.ping + " ms"
        gauge = 0
        speed = "0" // retest
        event_download.remove()
        event_upload.remove()
    }
    options = {
        icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px'
        }],
        strokeColor: '#FF9445',
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: '#FF9445',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: true,
        visible: true,
        radius: 30000,
        zIndex: 1
    };
    pci_visibility = () => {
        if (this.pol != undefined && this.pol != null) {
            var zoom = this.pol.zoom
            if (zoom > 15) {
                this.setState({
                    pciCardShow: true,
                }, () => this.listAssets('create-list-angle'))
            } else {
                this.setState({
                    pciCardShow: false,
                }, () => this.listAssets('create-list-angle'))
            }
        }
    }
    render() {
        return (
            <div>
                <div className="mobonly">
                    <IonPage>
                        <IonContent>
                            <div className='param-info-mob '>
                                <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                                    {/* {window.location.pathname.split('/')[1] == 'map' ? <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={() => { this.props.hideNetworkOverlay() }} src={BackIcon} />
                                        : <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={() => { this.props.history.push('/map') }} src={BackIcon} />
                                    } */}
                                    <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={() => { this.props.history.push('/map') }} src={BackIcon} />
                                    <span className="apheader">NETWORK STATUS</span>
                                </div>

                                <div className='col-12 p-0'>
                                    <ul className="nav nav-tabs sectab" id="secTabmob" role="tablist">
                                        <li className="nav-item col-6 p-0">
                                            <a className="nav-link active p-l-0" id="speed-tab-mob" data-toggle="tab" href="#speedmob" role="tab" aria-controls="speedmob"
                                                aria-selected="true">SPEED TEST</a>
                                        </li>
                                        <li className="nav-item col-6 p-0">
                                            <a className="nav-link" id="map-tab-mob" data-toggle="tab" href="#mapmob" role="tab" aria-controls="mapmob"
                                                aria-selected="false">NETWORK MAP</a>
                                        </li>
                                    </ul>
                                    {this.state.networkScreenType === 'detailed' ? <ul className="nav nav-tabs sectab font12" id="secTabmob" role="tablist">
                                        {this.state.sectorIdList.length !== 0 ?
                                            this.state.sectorIdList.map((sector: any, index: any) => {
                                                var wid = (100 / this.state.sectorIdList.length).toString() + "%"
                                                return <div style={{ width: wid }}>
                                                    <li className="nav-item" onClick={() => this.setSectorId(sector)}>
                                                        <a className={`nav-link p-l-0 ${this.state.sectorId === sector["sector_id"] ? 'active' : ''} p-l-0`} style={{ padding: '0.5rem 0rem' }} id="sec1-tab-mob" data-toggle="tab" href="#sec1mob" role="tab" aria-controls="sec1mob"
                                                            aria-selected="true">SEC{index + 1}</a>
                                                    </li>
                                                    <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '-30px' }}></div>
                                                    <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '5px' }}></div>
                                                </div>
                                            })
                                            : null}

                                    </ul>
                                        : null}
                                </div>
                            </div>
                            <div className="tab-content" id="secTabContentmob">
                                <div className="tab-pane fade show active" id="speedmob" role="tabpanel" aria-labelledby="speed-tab-mob">
                                    <div className='container-fluid apdash' style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 89px)' }}>
                                        <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                            <div className="card-body" style={{ padding: '10px' }}>
                                                {
                                                    this.state.locationService !== 0 ? <div className="col-12">
                                                        <span style={{ textAlign: "center", color: "red" }}>  Please turn on device location to continue.</span>
                                                    </div> : null
                                                }

                                                <div className="col-12 p-0" style={{ display: "flex", justifyContent: "center" }}>
                                                    <ReactSpeedometer
                                                        maxValue={0}
                                                        minValue={-140}
                                                        needleHeightRatio={0.5}
                                                        maxSegmentLabels={5}
                                                        height={200}
                                                        currentValueText={"RSRP: ${value}dBm"}
                                                        segments={140}
                                                        value={this.state.rsrpValue}
                                                        textColor={"grey"}
                                                        needleTransition={"easeLinear"}
                                                        needleColor={this.state.rsrpPrimaryColor}
                                                        ringWidth={20}
                                                        startColor={"#dd4242"}
                                                        endColor={"#76e8b1"}
                                                    // forceRender={true}
                                                    >
                                                    </ReactSpeedometer>
                                                </div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <span>enB ID: {this.state.enB}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span>PCI: {this.state.pci}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card speedtestcard" style={{ height: "max-content" }}>
                                            <div className="card-body" style={{ padding: '10px' }}>
                                                <div className="col-12 p-0">
                                                    <span className="apheader" style={{ textTransform: 'none' }}>Speed test</span>
                                                </div>

                                                <div className="row searchborder"> </div>   {/* border line */}
                                                <div className='row m-t-15'>
                                                    <div className="col-6">
                                                        <CircularProgressbarWithChildren
                                                            value={gauge}
                                                            maxValue={1000}
                                                            circleRatio={0.75}
                                                            strokeWidth={7}
                                                            styles={buildStyles({
                                                                rotation: 1 / 2 + 1 / 8,
                                                                pathColor: speedColor,
                                                                trailColor: "#0059B21A",
                                                            })}
                                                        >
                                                            <div style={{ borderRadius: '90%', width: '75%', height: '75%', backgroundColor: '#0059B21A', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                <div>
                                                                    <span style={{ fontSize: "30px" }}>{speed}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ color: "grey" }}>Mbps</span>
                                                                </div>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                    <div className='col-6 p-0'>
                                                        <div className='col-12'>
                                                            <div><span style={{ fontSize: "30px" }}>{dspeed}</span></div>
                                                            <span style={{ color: "grey" }}>Mbps Download</span>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div><span style={{ fontSize: "30px" }}>{uspeed}</span></div>
                                                            <span style={{ color: "grey" }}>Mbps Upload</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <span>Latency: {latency != null && latency != undefined ? latency : null}</span>
                                                    </div>
                                                    <div className='col-12'>
                                                        <span>Server: {server_name != null && server_name != undefined ? server_name : null}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 m-t-10">
                                                    {/* <button className='btn cardbtn speedtest'><a href="https://speedtest.fdossena.com/#">Run Test </a></button> */}
                                                    <button className='btn cardbtn speedtest' onClick={this.speed_test}>Run Test</button>
                                                </div>
                                                {this.state.networkScreenType === 'detailed' ? <div className="col-12 p-0 m-t-10">
                                                    <button className='btn cardbtn speedtest' onClick={() => { this.takeScreenshot() }}>Take Screenshot</button>
                                                </div>
                                                    : null}
                                            </div>
                                        </div>
                                        {this.state.networkStatusArray.length === 0 ?
                                            <div className="card speedtestcard" style={{ height: "min-content", marginTop: "10px" }}>
                                                <div className="card-body" style={{ padding: '10px' }}>
                                                    <div className="col-12 p-0">
                                                        <span className="apheader" style={{ textTransform: 'none' }}>NO LTE BANDS FOUND</span>
                                                    </div>
                                                </div>
                                            </div> : null
                                        }
                                        {this.state.networkStatusArray.map((network: any) => {
                                            return <div className="col-12 p-0 m-t-10">
                                                <div className="card speedtestcard" style={{ height: 'inherit' }}>
                                                    <div className="card-body" style={{ padding: '10px' }}>
                                                        <div className="col-md-12 p-0">
                                                            <div className="apheader" style={{ textTransform: 'none' }}>enB {network.eNBDecimal} Cell {network.cellID}</div>
                                                            <div className="apheader" style={{ textTransform: 'none' }}>RSRP: {network.rsrp} PCI:{network.pci} </div>
                                                            <span className="apheader p-r-10" style={{ textTransform: 'none' }}>MCC: {network.mcc}</span>
                                                            <span className="apheader p-r-10" style={{ textTransform: 'none' }}>MNC: {network.mnc}</span>
                                                            <span className="apheader" style={{ textTransform: 'none' }}>TAC: {(network.tac).toString().length > 4 ? "N/A" : network.tac}</span>
                                                        </div>
                                                        <div className="row searchborder" ></div>
                                                        <div className="col-12 p-0 m-t-10"  >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Reference Signal Received Power</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}> {network.rsrp}dBm</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Reference Signal Received Quality</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>{network.rsrq}dB</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Receive Signal Strength</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}> {network.rssi} dBm</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>Band Name</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>AWS 1-3</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>RX Frequency</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>2145 MHz</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>Band Number</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>66</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>TX Frequency</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>66</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Cell Identity</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>{network.ci}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>(EA/UA/A)RFCN</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>{network.earfcn}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Signal to Noise Ratio</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>{(network.rssnr).toString().length > 4 ? "N/A" : network.rssnr}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Physical cell Identity</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>{network.pci}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0" >
                                                            <div>
                                                                <span style={{ fontSize: '12px', color: '#5B5E62' }}>LTE Timing Advance</span>
                                                                <span className="p-l-5" style={{ fontSize: '12px', color: '#5B5E62', fontWeight: 'bold' }}>{(network.timingAdvance).toString().length > 4 ? "N/A" : network.timingAdvance}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                        {/* <div className="card speedtestcard" style={{ height: '290px' }}>
                                            <div className="card-body" style={{ padding: '10px' }}>
                                                <div className="col-12 p-0">
                                                    <span className="apheader" style={{ textTransform: 'none' }}>Speed test</span>
                                                </div>

                                                <div className="row searchborder"> </div>  

                                                <div className="col-12 p-0 m-t-10">
                                                    <div className="col-6 d-inline-block">
                                                        <div className="retest text-center  ">
                                                            <i className="material-icons retesticon">refresh</i>
                                                            <span style={{ color: '#0073FF' }}>Retest</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-6  d-inline-block">
                                                        <div className="row">
                                                            <i className="fas fa-arrow-circle-down p-r-10" style={{ color: '#0372fb' }}></i>
                                                            <span className="apheader">64.93</span> <br />
                                                            <span className="distance" style={{ fontSize: '12px' }}>Mbps Download</span>
                                                        </div>
                                                        <div className="row m-t-20">
                                                            <i className="fas fa-arrow-circle-up p-r-10" style={{ color: '#61d76f' }}></i>
                                                            <span className="apheader" style={{ paddingRight: '20px' }}>5.54</span> <br />
                                                            <span className="distance" style={{ fontSize: '12px' }}>Mbps Upload</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 p-0 m-t-10">
                                                    <span className="apheader" style={{ textTransform: 'none' }}>Latency:&nbsp;</span>
                                                    <span className="distance" style={{ fontSize: '12px' }}>7 ms</span>
                                                </div>
                                                <div className="col-12 p-0">
                                                    <span className="apheader" style={{ textTransform: 'none' }}>Server:&nbsp;</span>
                                                    <span className="distance" style={{ fontSize: '12px' }}>San Francisco</span>
                                                </div>
                                                <div className="col-12 p-0 m-t-10">
                                                    <button className='btn cardbtn speedtest'>Take a Screenshot</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="mapmob" role="tabpanel" aria-labelledby="map-tab-mob">
                                    <div className='container-fluid apdash' style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 89px)' }}>
                                        <div className="card speedtestcard" style={{ height: "130px" }}>
                                            <div className="card-body" style={{ padding: '15px' }}>
                                                <div className="col-md-12 m-t-5 p-0">
                                                    Network Details
                                                </div>
                                                {this.state.networkStatusArray.map((network: any, iter: any) => {
                                                    if (iter == 0) {
                                                        return <div className="col-12 m-t-10 p-0">
                                                            <div className="row">
                                                                <div className="col-6 p-r-0">
                                                                    <span className="apheader" style={{ textTransform: 'none' }}>RSRP: {network.rsrp}dBm</span>
                                                                </div>
                                                                <div className="col-6 p-r-0">
                                                                    <span className="apheader" style={{ textTransform: 'none' }}>RSRQ: {network.rsrq}dB</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6 p-r-0">
                                                                    <span className="apheader" style={{ textTransform: 'none' }}>Cell: {network.cellID}</span>
                                                                </div>
                                                                <div className="col-6 p-r-0">
                                                                    <span className="apheader" style={{ textTransform: 'none' }}>enB: {network.eNBDecimal}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6 p-r-0">
                                                                    <span className="apheader" style={{ textTransform: 'none' }}>PCI: {network.pci}</span>
                                                                </div>
                                                                <div className="col-6 p-r-0">
                                                                    <span className="apheader" style={{ textTransform: 'none' }}>TAC: {network.tac}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        {/* <div className='card apcard' style={{ height: 'calc(100vh - 270px)', width: '100%' }}> */}
                                        <div className="m-t-10">
                                            <GoogleMap
                                                id='searchbox'
                                                mapContainerStyle={{ height: "calc(100vh - 270px)", width: "100%", borderRadius: "8px" }}
                                                options={
                                                    { zoomControl: true, disableDefaultUI: true }
                                                }
                                                onLoad={this.setMap}
                                                onZoomChanged={this.pci_visibility}
                                                zoom={this.state.assetFound === true ?
                                                    14
                                                    :
                                                    10}

                                                center={this.state.center}>
                                                {this.state.assetList ? this.state.assetList.map((asset: any, index: any) => {
                                                    if (asset.assetType == 'AP-outdoor') {
                                                        return <Marker position={asset.coOrdinates} key={index} icon={asset.markerIcon}
                                                            label={asset.pciLabel} draggable={false} />
                                                    }
                                                    if (asset.assetType == 'CPE-outdoor') {
                                                        return <Marker position={asset.coOrdinates} key={index} icon={asset.markerIcon}
                                                            label={asset.labelText} title={asset.pci} draggable={false} />
                                                    }
                                                }) : null}
                                                {this.state.assetList && this.state.transparentIcon ? this.state.assetList.map((asset: any, index: any) => {
                                                    if (asset.assetType == 'AP-outdoor') {
                                                        return <Marker position={asset.coOrdinates} key={index} icon={this.state.transparentIcon} label={asset.labelText} draggable={false} />
                                                    }
                                                }) : null}
                                                <Marker position={this.state.center} icon={gps_icon} />
                                                {
                                                    this.state.midPoint ?
                                                        <InfoWindow position={this.state.midPoint} >
                                                            <div style={{}}>
                                                                <div className="font14 fontw400">
                                                                    <span>Distance:
                                                            <span style={{ color: "#FF9445" }}>{this.state.distance.toFixed(2)} m</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </InfoWindow> : null
                                                }
                                                {/* {this.state.assetList.map((asset: any, index: any) => (
                                                    <Polyline
                                                        options={this.options}
                                                        draggable={false}
                                                        editable={false}
                                                        path={[
                                                            asset.coOrdinates,
                                                            this.state.center
                                                        ]}
                                                    />
                                                ))} */}


                                            </GoogleMap>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IonContent>
                        <IonAlert
                            isOpen={this.state.alertbox}
                            onDidDismiss={() => this.setShowAlert(false)}
                            message={this.state.warnMessage}
                            buttons={['OK',]}
                        />

                        <IonLoading
                            isOpen={this.state.isLoading}
                            onDidDismiss={() => this.setShowLoading(false)}
                            message={'Loading...'}
                        />
                    </IonPage>
                </div>
            </div>
        );
    }
}



export default withIonLifeCycle(network);