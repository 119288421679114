import React, { Component } from 'react'
import Back from '../assets/img/left-arrow.svg'
import Heat from '../assets/img/heaticon.png'
import close from '../assets/img/close.png'
//import Demo from './demo.js'
import './calculate.css'
import markerIcon from '../assets/img/gps-icon-img2.png'
import BackIcon from '../assets/img/go-back-icon-img.png'
import geo from '../assets/img/target-icon.png'
import SearchIcon from '../assets/img/search_icon.png'
import checkSuccess from '../assets/img/check-success.png'
import measureIcon from '../assets/img/Measure-icon-img.png'
import addCPEIcon from '../assets/img/add-cpe-icon-img.png'
import addAPIcon from '../assets/img/add-ap-icon-img.png'
import heat from '../assets/img/heaticon.png'
import whitecalcmob from '../assets/img/white-align-icon.png'
import color from '@material-ui/core/colors/amber'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFabButton, IonFabList, IonIcon, IonFab, IonGrid, IonRow, IonCol, IonLoading, withIonLifeCycle } from '@ionic/react';

import { requestAPICall } from "../service/apiHandler";
import { Button } from '@material-ui/core'
import store from '../store';
import { isMobile, isBrowser } from 'react-device-detect'

const INITIAL_STATE = {
    rcamsl: 0,
    haat: 0,
    radials: '8',
    declination: 0,
    is_loader: 0,
    source: 'ned_1',
    unit: 'm',
    rc: '',
    altitude: 0,
    bar: true,
    showLoading: false,
    dummy: false,
    disable_button: true

};

class Calculate extends Component {
    state: any = {};
    props: any = {};
    reduxStore: any = store.getState()['general'];
    switch = () => {
        this.setState({
            bar: !this.state.bar
        })
    }
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.handleChange = this.handleChange.bind(this);
        this.callCalculateAPI = this.callCalculateAPI.bind(this)
    }
    componentDidMount() {
        console.log(this.props.radiation_center)
        // this.props.latitude = ""
        // this.props.longitude = ""
        if (this.props.radiation_center != null && this.props.radiation_center != undefined) {
            this.setState({
                rc: this.props.radiation_center
            })
        }
        if (this.props.latitude !== 0 && this.props.longitude !== 0) {
            this.setState({
                disable_button: false
            })
        }
        this.disablebutton()
        if (isBrowser) {
            $("#" + "calculatesource").css(
                {
                    "top": "5px",
                    "fontSize": "12px"
                }
            )
            $("#" + "calculatesource").show()
    
            $("#" + "calculateunit").css(
                {
                    "top": "5px",
                    "fontSize": "12px"
                }
            )
            $("#" + "calculateunit").show()
        }else{
            $("#" + "calculatesource1").css(
                {
                    "top": "5px",
                    "fontSize": "12px"
                }
            )
            $("#" + "calculatesource1").show()
    
            $("#" + "calculateunit1").css(
                {
                    "top": "5px",
                    "fontSize": "12px"
                }
            )
            $("#" + "calculateunit1").show()
        }
       
    }
    disablebutton = () => {
        if (this.props.latitude !== 0 && this.props.longitude !== 0) {
            this.setState({
                disable_button: false
            })
        }
    }
    // ionViewDidEnter(){
    //     if(isMobile){
    //         if(this.props.radiation_center != null && this.props.radiation_center != undefined){
    //             this.setState({
    //                 rc: this.props.radiation_center
    //             })
    //         }            
    //     }
    // }
    handleChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    selectchange = (e: any, id: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value === "") {
            $("#" + id).css(
                {
                    "top": "14px",
                    "fontSize": "14px"
                }
            )
            $("#" + id).show()
        } else {
            $("#" + id).css(
                {
                    "top": "5px",
                    "fontSize": "12px"
                }
            )
            $("#" + id).show()
        }
    }
    setShowLoading = (st: Boolean) => {
        this.setState({ is_loader: st })
    }

    callCalculateAPI() {
        this.setState({
            showLoading: true,

        })
        console.log(this.props)
        let requestBody = {
            // lat: this.reduxStore['coordinates']['latitude'],
            // lon: this.reduxStore['coordinates']['longitude'],
            lat: this.props.latitude,
            lon: this.props.longitude,
            nradial: parseInt(this.state.radials),
            src: this.state.source,
            rad_center: parseFloat(this.state.rc),
            unit: this.state.unit
        }
        console.log(requestBody)
        requestAPICall("measure_haat", {}, requestBody).then(data => {
            this.setState({
                showLoading: false
            })
            this.setState({
                is_loader: 2,
                rcamsl: parseFloat(data.RCAMSL.toFixed(1)),
                haat: data.HAAT,
                declination: data.declination,
                altitude: data.elevation
            })
            this.dummy()
        })
    }

    dummy = () => {
        this.setState({
            dummy: true
        })
    }
    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }

    propChange = (e: any) => {
        this.props.changeProps(e.target.name, e.target.value)

    }

    render() {

        return (
            <div>
                <div className='webonly'>
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="card apcard">
                                <div className="card-body" style={{ padding: '0px' }}>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <img className='backicon' src={BackIcon} onClick={this.props.back} />
                                            <button type='button' className='backbutton' ></button>
                                            {/* <button type='button' className='backbutton material-icons' onClick={this.props.back}>keyboard_backspace </button> */}
                                            {/* <img className='backicon' src={Back} onClick={this.props.back} />
                                            <button type='button' className='backbutton'></button> */}
                                        </div>
                                        <div className='col-md-6' style={{ padding: "13px 0" }}>
                                            <span className='apheader'>CALCULATIONS</span>
                                        </div>
                                    </div>
                                    <div className='row' style={{ padding: "0px 35px" }}>
                                        <div className='col-md-12 apborder' style={{ height: '35px', paddingTop: '0px' }}>
                                            <span className='apnote'>Calculate Declination and HAAT</span>
                                            {this.props.markers.length !== 1 ?
                                                <span className='calculations' onClick={this.props.place} style={{ padding: '3px' }}>
                                                    <img src={markerIcon} style={{ width: '11px' }} /></span>
                                                :
                                                <span className='calculations-disabled' style={{ padding: '2px', background: "#FF944533", border: "1px dashed #FF9445" }}>
                                                    <img src={markerIcon} style={{ width: '11px' }} /></span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-0 p-0" >
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input type="text" style={{ height: '38px' }} className="accesspointname" id="Latitude" name="lat" placeholder="Latitude" value={this.props.latitude} onChange={this.propChange} />
                                                    <label className="pages" htmlFor="Latitude">Latitude</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input type="text" style={{ height: '38px' }} className="accesspointname" id="Longitude" name="lng" placeholder="Longitude" value={this.props.longitude} onChange={this.propChange} />
                                                    <label className="pages" htmlFor="Longitude">Longitude</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="floating-label">
                                                    <select name="source" style={{ height: '38px' }} onChange={(e: any) => this.selectchange(e, "calculatesource")} className="floating-select selectarrow" >
                                                        <option value="" hidden>ned_1</option>
                                                        <option value="ned_1" >ned_1</option>
                                                        <option value="ned_2" >ned_2</option>
                                                        <option value="globe30" >globe30</option>
                                                    </select>
                                                    <label id="calculatesource">Source</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="floating-label">
                                                    <select name="unit" style={{ height: '38px' }} onChange={(e: any) => this.selectchange(e, "calculateunit")} className="floating-select selectarrow" >
                                                        <option value="" hidden>meters</option>
                                                        <option value="m" >meters</option>
                                                        <option value="mi" >miles</option>
                                                        <option value="ft" >feet</option>
                                                    </select>
                                                    <label id="calculateunit" >Unit</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input type="number" style={{ height: '38px' }} className="accesspointname" id="Radiation" name="rc" value={this.state.rc} placeholder="Radiation Center" onChange={this.handleChange} />
                                                    <label className="pages" htmlFor="Radiation">Radiation Center (ft)</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input type="text" style={{ height: '38px' }} className="accesspointname" id="RCAMSL" name="rcamsl" placeholder="RCAMSL" value={this.state.rcamsl} />
                                                    <label className="pages" htmlFor="RCAMSL">RCAMSL ({this.state.unit})</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input type="text" style={{ height: '38px' }} className="accesspointname" id="Radials" name="radials" placeholder="No. Radials" value={this.state.radials} onChange={this.handleChange} />
                                                    <label className="pages" htmlFor="Radials">No. Radials</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-t-9' style={{ padding: "0px 35px" }}>
                                            <div className="col-md-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input type="text" style={{ height: '38px' }} className="accesspointname" value={this.state.altitude} id="Altitude" placeholder="Altitude (ft)" />
                                                    <label className="pages" htmlFor="Altitude">Altitude ({this.state.unit})</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row m-t-8' style={{ padding: "0px 35px", height: '63px' }}>
                                        <div className='col-md-6 p-l-0 p-r-5'>
                                            <div className="card calccard">
                                                {this.state.declination === 0 ? <div className="card-body text-center" style={{ padding: "5px" }} >
                                                    <span className='calctext'>Declination</span>
                                                    <span className='calc' style={{ fontWeight: 'bold' }}>-</span>
                                                </div>
                                                    :
                                                    <div className="card-body text-center" style={{ padding: "5px", borderRadius: '6px', backgroundColor: '#e5fbf0' }} >
                                                        <span className='calctext'>Declination</span>
                                                        <span className='calc' style={{ fontWeight: 'bold', color: '#008947' }}>{this.state.declination}'</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-6 p-r-0 p-l-5'>
                                            <div className="card calccard">
                                                {this.state.haat === 0 ?
                                                    <div className="card-body text-center" style={{ padding: "5px" }} >
                                                        <span className='calctext'>HAAT</span>
                                                        <span className='calc' style={{ fontWeight: 'bold' }}>-</span>
                                                    </div>
                                                    :
                                                    <div className="card-body text-center" style={{ padding: "5px", borderRadius: '6px', backgroundColor: '#e5fbf0' }} >
                                                        <span className='calctext'>HAAT</span>
                                                        <span className='calc' style={{ fontWeight: 'bold', color: '#008947' }}>{this.state.haat} {this.state.unit}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-t-10' style={{ padding: "0px 35px" }}>

                                        <button className='btn savebtn' disabled={this.props.latitude === 0 && this.props.longitude === 0} onClick={this.callCalculateAPI}>Calculate</button>
                                    </div>

                                </div>
                            </div>



                        </div>
                        <div className='col-md-3' >
                            <div className="col-md-12 justify-content-flex-end">
                                <div className="heaticon text-center" style={{ display: 'none' }}>
                                    <img className='heat' src={Heat} />
                                </div>
                            </div>

                            <div className="col-md-12 float-right">
                                <div className="geoicon text-center">
                                    <img style={{ padding: '5px' }} src={geo} onClick={this.setCurrentPosition} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mobonly'>
                    <div style={{ position: 'fixed', bottom: '320px', right: '10px' }}>
                        <IonFab horizontal="end" style={{ position: 'relative', left: '40px' }} >
                            <IonFabButton className="m-b-10 backg" color="light" size="small" onClick={() => this.setCurrentPosition()}>
                                <img style={{ padding: '5px' }} src={geo} />
                            </IonFabButton>
                        </IonFab>
                        <Button type='button' className='cardbutton m-b-10' style={{ backgroundColor: '#f59343', marginBottom: '10px', minWidth: '80px', position: 'relative', borderRadius: '18px' }} onClick={() => this.props.back()} >
                            <img className='calcicon' src={whitecalcmob} style={{ marginLeft: '-19px' }} />
                            <span className="floatcalc"><span className="material-icons" style={{ padding: '8px', color: '#fff' }}>clear</span></span>
                        </Button>
                        <IonFab horizontal="end" style={{ position: 'relative', left: '40px' }}  >
                            <IonFabButton className="m-b-10 backg" color="light" size="small">
                                <img className='search-mob' onClick={this.switch} src={SearchIcon} />
                            </IonFabButton>
                        </IonFab>
                    </div>

                    <div style={{ position: 'fixed', bottom: '0px' }}>

                        <div className="card apcard" style={{ height: 'inherit' }}>
                            <div className="card-body" style={{ overflow: 'hidden auto' }} >
                                <div className='row'>
                                    <div className='col-8'>
                                        <span className='apheader'>DECLINATION AND HAAT</span>
                                    </div>
                                    <div className='col-4 p-l-0'>
                                        {this.props.markers.length !== 1 ?
                                            <span className="m-l-25 font13">GPS</span>
                                            :
                                            <span className="m-l-5 font13">Custom</span>
                                        }
                                        {this.props.markers.length !== 1 ?
                                            <span className='calculations' draggable onClick={this.props.place} style={{ padding: '3px' }}>
                                                <img src={markerIcon} style={{ width: '11px' }} /></span>
                                            :
                                            <span className='calculations-disabled' style={{ padding: '2px', background: "#FF944533", border: "1px dashed #FF9445" }}>
                                                <img src={markerIcon} style={{ width: '11px' }} /></span>
                                        }
                                        {/* <span className='calculations' onClick={this.props.place} style={{ fontSize: '17px' }}> <i className="fas fa-map-pin calculateicon"></i></span> */}
                                    </div>
                                </div>
                                <div className='row m-t-10'>
                                    <div className='col-6 p-r-0'>
                                        <div className="form-label-group m-0">
                                            <input type="text" className="text-user font12" id="latmob" style={{ width: "100%", borderRadius: '6px 0px 0px 0px' }} name="lat" placeholder="Latitude" value={this.props.latitude} onChange={this.propChange} />
                                            <label htmlFor="latmob">Latitude</label>
                                        </div>
                                        {/* <input type='text' className='text-user ' placeholder='Latitude' style={{ borderBottom: 'none', borderRadius: '6px 0px 0px 0px' }} /> */}
                                    </div>
                                    <div className='col-6 p-l-0'>
                                        <div className="form-label-group m-0">
                                            <input type="text" className="text-user font12 b-l-0" id="lngmob" style={{ width: "100%", borderRadius: '0px 6px 0px 0px' }} name="lng" placeholder="Longitude" value={this.props.longitude} onChange={this.propChange} />
                                            <label htmlFor="lngmob">Longitude</label>
                                        </div>
                                        {/* <input type='text' className='text-user ' placeholder='Longitude' style={{ borderLeft: 'none', borderBottom: 'none', borderRadius: '0px 6px 0px 0px' }} /> */}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 p-r-0'>
                                        <div className="floating-label">
                                            <select name="source" className="floating-select selectarrow" style={{ height: '48px', borderRadius: '0px', border: '1px solid #CED7DF' }} onChange={(e: any) => this.selectchange(e, "calculatesource1")} >
                                                <option value="" hidden>ned_1</option>
                                                <option value="ned_1" >ned_1</option>
                                                <option value="ned_2" >ned_2</option>
                                                <option value="globe30" >globe30</option>
                                            </select>
                                            <label id="calculatesource1">Source</label>
                                        </div>
                                    </div>
                                    <div className='col-4 p-0'>
                                        <div className="floating-label">
                                            <select name="unit" className="floating-select selectarrow" style={{ height: '48px', border: '1px solid #CED7DF', borderRadius: '0px', borderLeft: 'none', borderRight: 'none' }} onChange={(e: any) => this.selectchange(e, "calculateunit1")}>
                                                <option value="" hidden>meters</option>
                                                <option value="m" >meters</option>
                                                <option value="mi" >miles</option>
                                                <option value="ft" >feet</option>
                                            </select>
                                            <label id="calculateunit1">Unit</label>
                                        </div>
                                    </div>
                                    <div className="col-4 p-l-0">
                                        <div className="form-label-group m-0">
                                            <input type="number" style={{ width: "100%", borderRadius: '0px', border: '1px solid #CED7DF' }} className="text-user font12 " id="Radiationmob" name="rc" value={this.state.rc} placeholder="Radiation Center" onChange={this.handleChange} />
                                            <label htmlFor="Radiationmob">Ant.ht(ft)</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 p-r-0'>
                                        <div className="form-label-group m-0">
                                            <input type="text" className="text-user font12 b-t-0" id="rcamslob" style={{ width: "100%", border: '1px solid #CED7DF', borderRadius: '0px 0px 0px 6px' }} placeholder="Sector 6 ID #" value={this.state.rcamsl} />
                                            <label htmlFor="rcamslob">RCAMSL ({this.state.unit})</label>
                                        </div>
                                        {/* <input type='text' className='text-user b-t-0 ' placeholder='RCAMSL' style={{ borderRadius: '0px 0px 0px 0px', color: '#afafaf' }} /> */}
                                    </div>
                                    <div className='col-4 p-0'>
                                        <div className="form-label-group m-0">
                                            <input type="text" className="text-user font12 b-t-0 b-l-0 b-r-0" id="radialsmob" name="radials" style={{ width: "100%", border: '1px solid #CED7DF', borderRadius: '0px' }} placeholder="Sector 6 ID #" value={this.state.radials} onChange={this.handleChange} />
                                            <label htmlFor="radialsmob">No. Radials</label>
                                        </div>
                                        {/* <input type='text' className='text-user b-t-0 ' placeholder='No.Radials' style={{ borderLeft: 'none', borderRadius: '0px 0px 0px 0px', color: '#afafaf' }} /> */}
                                    </div>
                                    <div className='col-4 p-l-0'>
                                        <div className="form-label-group m-0">
                                            <input type="text" className="text-user font12 b-t-0" id="altitudemob" style={{ width: "100%", border: '1px solid #CED7DF', borderRadius: '0px 0px 6px 0px' }} placeholder="Sector 6 ID #" value={this.state.altitude} onChange={this.handleChange} />
                                            <label htmlFor="altitudemob">Altitude ({this.state.unit})</label>
                                        </div>
                                        {/* <input type='text' className='text-user b-t-0' placeholder='Altitude (ft)' style={{ borderLeft: 'none', borderRadius: '0px 0px 0px 0px', color: '#afafaf' }} /> */}
                                    </div>
                                </div>

                                <div className=' m-t-10' >
                                    <div className='col-12 p-0'>
                                        <button className='btn' disabled={this.props.latitude === 0 && this.props.longitude === 0} onClick={this.callCalculateAPI} >Calculate</button>
                                    </div>
                                </div>
                                <div className='row m-t-10'>
                                    <div className='col-6 p-r-5'>
                                        {this.state.dummy ?
                                            <div className="card calccardact">
                                                <div className="card-body " style={{ padding: "10px 0px" }} >
                                                    <span className='calctext' style={{ color: '#008947' }}>Declination:{this.state.declination}</span>
                                                </div>
                                            </div> :
                                            <div className="card calccard">
                                                <div className="card-body " style={{ padding: "10px 0px" }} >
                                                    <span className='calctext'>Declination:</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-6 p-l-5'>
                                        {this.state.dummy ?
                                            <div className="card calccardact">
                                                <div className="card-body " style={{ padding: "10px" }} >
                                                    <span className='calctext' style={{ color: '#008947' }}>HAAT:{this.state.haat} {this.state.unit}</span>
                                                </div>
                                            </div> :
                                            <div className="card calccard">
                                                <div className="card-body " style={{ padding: "10px" }} >
                                                    <span className='calctext'>HAAT:</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <IonLoading
                    isOpen={this.state.showLoading}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                />
            </div >
        );
    }
}

export default withIonLifeCycle(Calculate)