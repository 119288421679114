import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import logo from '../assets/img/Spectra_logo.png'
import moblogo from '../assets/img/Spectra_logo_mob.png'
import logoname from '../assets/img/Spectra_logo_white.png'
import Cookies from 'js-cookie'
import { isBrowser } from 'react-device-detect';
import cogs from "../assets/img/settings-white.png"

const INITIAL_STATE = {
  login: false,
  register: false
};

export default class Home extends Component {
  state: any = {};
  props: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.getCookies = this.getCookies.bind(this)
    this.navigateToQR = this.navigateToQR.bind(this)
  }
  componentDidMount() {
    window.addEventListener('load', this.getCookies);
    this.getCookies();
  }
  getCookies() {
    if ([undefined, null, 'undefined', 'null'].includes(Cookies.get('idToken')) || [undefined, null, 'undefined', 'null'].includes(Cookies.get('accessToken'))) {
      this.props.history.push('/home');
    } else {
      if (isBrowser) {
        this.props.history.push('/map/outdoor');
      } else {
        localStorage.removeItem('backButton')
        localStorage.removeItem('appState')
        this.props.history.push('/map/outdoor/main');
      }
    }

  }
  navigateToQR() {
    this.props.history.push('/configure');
  }
  render() {

    return (
      <IonPage>
        <IonContent>
          <div className="webonly">
            <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
              {/* <div id="home-reload" style={{ display: 'none' }} className='row m-r-0 m-l-0'> */}
              <div id="home-reload"  className='row m-r-0 m-l-0'>

                <div className='col-md-7 col-sm-12 login-bghome'>
                  <div className='row bg-text bg-slogan'>
                    <div className="col-md-12">
                      <span>Your Signal.</span><br />
                      <span style={{ lineHeight: '1.8' }}>Your Company.</span><br />
                      <span style={{ lineHeight: '1.8' }}>Your Promise to Customers.</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-sm-12 login-logo'>
                  <div className='row m-r-0 m-l-0'>
                    <div className='col-md-12 homelogo logoweb'>
                      <img className='img-center img' src={logo} />
                    </div>
                    <div className='col-md-12 homelogo logomob'>
                      <img className='img-center imgmob' src={moblogo} />
                      <img className='img-center m-t-5 namemob' src={logoname} />
                    </div>
                  </div>
                  <div className='row m-r-0 m-l-0'>
                    <div className='col-md-12 homecontent' style={{ paddingTop: "30%" }}>
                      <span className='login-texthome img-center'>Get Started Now</span>
                      <button className='btn img-center m-t-30' onClick={() => { this.props.history.push('/login') }}>Log In</button>
                      <button className='lightbtn img-center m-t-10' onClick={() => { this.props.history.push('/register') }}>Sign Up</button>
                      <div className='login-info m-t-30' style={{ fontSize: '11px' }}>
                        <span>By continuing, you agree to our</span><br />
                        <span className='cond' style={{ paddingLeft: '7px' }}>Terms & Conditions</span>
                        <span>&nbsp;and&nbsp;</span>
                        <span className='cond'>Privacy Policy</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className="mobonly">
            <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
              <div className='row m-r-0 m-l-0'>

                <div className='col-md-7 col-sm-12 login-bghome'>
                  <div className='row bg-text bg-slogan'>
                    <div className="col-md-12">
                      <span>Your Signal.</span><br />
                      <span style={{ lineHeight: '1.8' }}>Your Company.</span><br />
                      <span style={{ lineHeight: '1.8' }}>Your Promise to Customers.</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-sm-12 login-logo'>
                  <div className="col-12" onClick={this.navigateToQR}>
                    <img className="homesetting" src={cogs} />
                  </div>
                  <div className='row m-r-0 m-l-0'>
                    <div className='col-md-12 homelogo logoweb'>
                      <img className='img-center img' src={logo} />
                    </div>
                    <div className='col-md-12 homelogo logomob'>
                      <img className='img-center imgmob' src={moblogo} />
                      <img className='img-center m-t-5 namemob' src={logoname} />
                    </div>
                  </div>
                  <div className='row m-r-0 m-l-0'>
                    <div className='col-md-12 homecontent p-r-16 p-l-16' style={{ paddingTop: "30%" }}>
                      <span className='login-texthome img-center'>Get Started Now</span>
                      <button className='btn img-center m-t-24' onClick={() => { this.props.history.push('/login') }}>Log In</button>
                      <button className='lightbtn img-center m-t-10' onClick={() => { this.props.history.push('/register') }}>Sign Up</button>
                      <div className='login-info m-t-30' style={{ fontSize: '11px' }}>
                        <span>By continuing, you agree to our</span><br />
                        <span className='cond' style={{ paddingLeft: '7px' }}>Terms & Conditions</span>
                        <span>&nbsp;and&nbsp;</span>
                        <span className='cond'>Privacy Policy</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </IonContent>
      </IonPage>

    );
  }
}

$(document).ready(function () {

  setTimeout(() => {
    $("#home-reload").css('display', '');
  }, 500)
})
