import React, { Component } from 'react'
import { IonPage, IonLoading, IonAlert, IonContent } from '@ionic/react'
import { requestAPICall } from "../service/apiHandler";
import logo from '../assets/img/Spectra_logo.png'
import { Link, useRouteMatch, Switch, Route, useParams, Redirect } from 'react-router-dom';
const INITIAL_STATE = {
    text: '',
    userId: '',
    is_loader: false,
    token: "",
    alertbox: false,
    warnMessage: ""
};

export default class Blank extends Component {

    state: any = {};
    props: any = {};
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.change = this.change.bind(this)
        this.onChange = this.onChange.bind(this)
        this.callTokenValidationAPI = this.callTokenValidationAPI.bind(this)
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        console.log(params)
        this.setState({
            token: params.token
        })
        this.callTokenValidationAPI(params.token)
    }
    onChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    setShowLoading = (st: any) => {
        this.setState({ is_loader: st })
    }



    callTokenValidationAPI = (token: any) => {
        this.setState({
            token: token
        })
        let requestBody = {
            token: token
        }
        this.setState({
            is_loader: true
        })

        requestAPICall("password_token_validation", {}, requestBody)
            .then(data => {
                this.setState({
                    is_loader: false
                })
                if (data.responseCode == 200) {
                    this.props.history.push({
                        pathname: '/forgot-password',
                        state: { token: this.state.token }
                    })


                } else {
                    this.setState({
                        warnMessage: data.message
                    })
                    this.setState({
                        alertbox: true
                    })

                    this.props.history.push('/login');
                }
            })

    }

    change = (e: any) => {
        this.setState({
            text: e.target.value
        })
    }
    render() {
        return (
            <IonPage>
                <IonContent>
                    {!this.state.is_loader ?

                        <div>
                        </div>
                        : null
                    }


                    <IonAlert
                        isOpen={this.state.alertbox}
                        onDidDismiss={() => this.setShowAlert(false)}

                        message={this.state.warnMessage}
                        buttons={['OK',]}
                    />


                    <IonLoading
                        isOpen={this.state.is_loader === true}
                        onDidDismiss={() => this.setShowLoading(false)}
                        message={'Loading...'}
                        duration={5000}
                    />
                </IonContent>
            </IonPage>
        );
    }
}