import {
    CURRENT_MAP_CLICK_INSTANCE,
    COORDINATES,
    PROPERTIES,
    ASSET
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case CURRENT_MAP_CLICK_INSTANCE:
        case COORDINATES:
            return { ...state, [action.key]: action.value };
        case PROPERTIES:
            return { ...state, [action.key]: action.value };
        case ASSET:
            return { ...state, [action.key]: action.value };
        default:
            return state;
    }
    return state;
};
