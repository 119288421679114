import React, { Component, useState } from 'react';
import { IonContent, IonAlert, IonPage, withIonLifeCycle, IonBadge, IonIcon } from '@ionic/react';
import logo from '../assets/img/spectralogo.png';
// import logoname from '../assets/img/spectra_logoname.png';
import logoname from '../assets/img/spectra_hash.png';
import White from '../assets/img/SpectraWhite.png';
import avatar from '../assets/img/cross-icon-img.png'
import user from '../assets/img/userpic.png';
import Outdoor from './outdoor'
import Indoor from './indoor'
import Import from './import'
import { useParams, Switch, Route, Redirect } from 'react-router-dom';
import Analytics from './analytics';
import Settings from './settings';
import { Link } from 'react-router-dom';
import './header.css'
import AppSettings from "./app-settings";
import Cookies from 'js-cookie'
import Logout from '../assets/img/logout.svg'
import ComAnalytics from './companyanalytics';
import CSettings from './CompanySettings';
import Modal from './modal'
import { connect } from 'react-redux';
import store from '../store';
import { UPDATE_FIELD_AUTH, CURRENT_TAB } from '../constants/actionTypes';
import { requestAPICall } from "../service/apiHandler";
import { isBrowser, isMobile } from "react-device-detect";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import NetworkActivity from './network-activity';
import { Plugins } from '@capacitor/core';
import { PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications } = Plugins;
let INITIAL_STATE = {
    tabState: 'outdoor-tab',
    company: false,
    show: false,
    modalName: '',
    permission_list: [],
    alertbox: false,
    warnMessage: 'Error occured.',
    accessToken: "",
    remember_me: "",
    notificationList: [],
    rejectedNotificationCount: 0,
    pendingNotificationCount: 0,
    notificationalert: false

};

const mapStateToProps = (state: any) => ({ ...state.auth });

const mapDispatchToProps = (dispatch: any) => ({
    upadateUserPermissions: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'permissions', value }),
    upadateUserRole: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'role', value }),
    upadateTabState: (value: any) =>
        dispatch({ type: CURRENT_TAB, key: 'tab', value }),
    clickNotify: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'notification', value }),
    upadateUserCpiId: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'cpiId', value }),
    upadateUserCpiName: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'cpiName', value }),
    upadatePendingNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'pendingNotificationCount', value }),
    upadateRejectedNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'rejectedNotificationCount', value })
});

var permission_list: any = []
var role: any = ''
var urltab: any = ''
var rejectedNotificationCount: number = 0;
var pendingNotificationCount: number = 0;

class Header extends Component {
    state: any = {};
    props: any = {};
    timer: any;
    notificationCountInterval: any = {};
    clearCountInterval: Boolean = true;
    // showPopover: any = useState(false);
    // setShowPopover: any = useState(false);

    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.changeTab = this.changeTab.bind(this)
        // this.getCookies = this.getCookies.bind(this)
        this.deleteCookies = this.deleteCookies.bind(this)
        this.clickNotification = this.clickNotification.bind(this)
        this.getNotificationCount = this.getNotificationCount.bind(this)
        this.timer = null;
        defineCustomElements(window);

    }

    // ionViewWillLeave() {
    //     this.clearCountInterval = false;
    //     console.log("CAAAAAAAAAAAAALiIIIIIIIINNNNNNNNGGGGGG", this.timer);
    //     console.log("CAAAAAAAAAAAAALiIIIIIIIINNNNNNNNGGGGGG", this.notificationCountInterval);
    //     clearInterval(this.timer);
    //     clearInterval(this.notificationCountInterval)
    //     clearInterval(this.state.notitimer)

    //     this.notificationCountInterval = {};

    // }

    // ionViewDidEnter() {
    //     this.clearCountInterval = true;

    //     console.log("==================")

    //     window.addEventListener('load', this.refreshController);

    //     urltab = this.props.location.pathname.split("/")[2] + '-tab'
    //     this.props.upadatePendingNotificationCount(0)
    //     this.props.upadateRejectedNotificationCount(0)
    //     this.getNotificationCount()
    //     this.checkNetworkStatus()
    //     console.log(store.getState());
    //     permission_list = store.getState()['auth']['permissions']
    //     role = store.getState()['auth']['role']


    //     console.log(Cookies.get("userRole"))
    //     // if (permission_list !== undefined && permission_list.includes(18)) {
    //     this.notificationCountInterval = setInterval(this.getNotificationCount
    //         , 60000)
    //     // }

    //     setInterval(() =>
    //         this.startRefreshingToken()
    //         , 3000000)
    //     this.setState({
    //         notitimer: this.notificationCountInterval
    //     })


    // }
    componentWillMount() {
        console.log("========componentWillMount==========")
        this.startRefreshingToken()
        this.getCookies()

    }
    componentDidMount() {
        if (isBrowser) {
            navigator.serviceWorker.addEventListener("message", (message: any) => {
                console.log(message)
                this.getNotificationCount()
            }
            );
        }
        if (isMobile) {
            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotification) => {
                    console.log(notification)
                    this.setState({
                        notificationAlert: true
                    })
                    this.getNotificationCount()
                }
            );
        }
        this.clearCountInterval = true;
        console.log("==================")
        this.refreshController();
        urltab = this.props.location.pathname.split("/")[2] + '-tab'
        this.props.upadatePendingNotificationCount(0)
        this.props.upadateRejectedNotificationCount(0)
        this.getNotificationCount()
        this.checkNetworkStatus()
        console.log(store.getState());
        permission_list = store.getState()['auth']['permissions']
        role = store.getState()['auth']['role']
        console.log(Cookies.get("userRole"))
        if (role != "Installer" && isBrowser) {
            this.notificationCountInterval = setInterval(this.getNotificationCount
                , 60000)
        }

        setInterval(() =>
            this.startRefreshingToken()
            , 3000000)
        this.setState({
            notitimer: this.notificationCountInterval
        })

    }
    componentDidUpdate() {
        permission_list = store.getState()['auth']['permissions']
        console.log(permission_list)
        role = store.getState()['auth']['role']
    }
    componentWillUnmount() {

        this.clearCountInterval = false;
        console.log("CAAAAAAAAAAAAALiIIIIIIIINNNNNNNNGGGGGG", this.timer);
        console.log("CAAAAAAAAAAAAALiIIIIIIIINNNNNNNNGGGGGG", this.notificationCountInterval);
        clearInterval(this.timer);
        clearInterval(this.notificationCountInterval)
        clearInterval(this.state.notitimer)
        this.notificationCountInterval = {};
    }


    company_mode = () => {
        this.setState({
            company: !this.state.company,
            tabState: 'analytics-tab'
        })
    }

    checkNetworkStatus = async () => {
        await console.log(navigator.onLine);
    }
    getNotificationCount() {

        console.log("==========OUTSIDE========", this.clearCountInterval)
        if (!(this.clearCountInterval)) {
            clearInterval(this.notificationCountInterval);
            console.log("==========INSIDE========", this.clearCountInterval)
            return false;
        }
        let requestBody = {}
        requestAPICall("get_notification_count", requestBody).then(data => {
            console.log(data)
            if (data.responseCode == 200) {
                this.props.upadatePendingNotificationCount(data['pendingNotificationCount'])
                this.props.upadateRejectedNotificationCount(data['rejectedNotificationCount'])
                this.setState({
                    pendingNotificationCount: data['pendingNotificationCount'],
                    rejectedNotificationCount: data['rejectedNotificationCount']

                }, () => console.log(this.state.pendingNotificationCount))
            }


        })

    }

    clickNotification() {
        if (Cookies.get("userRole") === "RegionalAdmin" || Cookies.get("userRole") === "MasterAdmin" || Cookies.get("userRole") === "Engineer" || Cookies.get("userRole") === "CPI") {
            this.props.clickNotify(true)
            var url = this.props.match.url + "/network-activity"
            this.props.history.push(url)
        }


    }

    refreshController = () => {

        console.log('checked refreshing token');

        this.timer = setInterval(() =>
            this.startRefreshingToken()
            , 3000000)

        // var currentTime = new Date();
        // const current_time = currentTime.valueOf()
        // var lastRefreshedtime = Cookies.get('logintime')
        // const lastRefreshed_time = Number(lastRefreshedtime)


        // const dif = current_time - lastRefreshed_time;
        // if (dif < 3000000) {

        //   console.log('refreshing the token');
        //   this.startRefreshingToken();
        // }
    }
    startRefreshingToken = () => {
        this.setState({
            remember_me: Cookies.get('rememberMe'),
            accessToken: Cookies.get('accessToken')
        }, () => {
            console.log('refresh');
            let requestBody = {
                username: Cookies.get('username'),
                access_token: Cookies.get('accessToken')

            }

            requestAPICall("refresh_token", {}, requestBody).then(data => {
                console.log("refresh-token API")
                var date = new Date();
                var currenttime = new Date()
                //   date.setTime(date.getTime() + (3 * 1000));

                if (this.state.remember_me == "true") {
                    const currenttime_value = "" + currenttime.valueOf()
                    Cookies.set('idToken', data.idToken, { expires: 365 })
                    Cookies.set('accessToken', this.state.accessToken, { expires: 365 })
                    Cookies.set('refreshtime', currenttime_value, { expires: 365 })
                    Cookies.set('username', data.username, { expires: 365 })
                    Cookies.set('rememberMe', this.state.remember_me, { expires: 365 })
                    Cookies.set('userRole', data.userRole, { expires: 365 })
                    Cookies.set('permissionId', data.permissionId, { expires: 365 })

                }
                else {
                    var date = new Date();
                    const currenttime_value = "" + currenttime.valueOf()
                    date.setTime(date.getTime() + (3600000));
                    Cookies.set('idToken', data.idToken, { expires: date })
                    Cookies.set('accessToken', this.state.accessToken, { expires: date })
                    Cookies.set('refreshtime', currenttime_value, { expires: date })
                    Cookies.set('username', data.username, { expires: date })
                    Cookies.set('rememberMe', this.state.remember_me, { expires: date })
                    Cookies.set('userRole', data.userRole, { expires: date })
                    Cookies.set('permissionId', data.permissionId, { expires: date })
                }
            })

        })
        // console.log(Cookies.get('idToken'));
        // console.log(Cookies.get('accessToken'));


    }

    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
        if (st === false) {
            this.deleteCookies()
        }
    }



    getCookies = () => {
        if ([undefined, null, 'undefined', 'null'].includes(Cookies.get('idToken')) || [undefined, null, 'undefined', 'null'].includes(Cookies.get('accessToken'))) {
            this.props.history.push('/home');
        } else {
            this.setState({
                permission_list: Cookies.get('permissionId'),
                role: Cookies.get('userRole')
            }, () => {
                console.log(Cookies.get('permissionId'));
                var plist = this.state.permission_list.replace("[", '').replace("]", '').split(",").map((n: any) => parseInt(n))
                this.props.upadateUserPermissions(plist)
                this.props.upadateUserRole(this.state.role)
                permission_list = store.getState()['auth']['permissions']
                role = store.getState()['auth']['role']
                console.log(permission_list);
                console.log(role);
            })
            // permission_list = Cookies.get('permissionId');
            // role = Cookies.get('userRole');
        }
    }
    notificationalert = (st: any) => {
        this.setState({ notificationAlert: st })
    }
    moveToNotification() {
        this.props.history.push('/notification-mob');
    }

    deleteCookies() {

        clearInterval(this.notificationCountInterval)
        this.setState({
            permission_list: [],
            role: ""
        })
        localStorage.removeItem('CPE_Outdoor_Data')
        localStorage.removeItem('CPE_Indoor_Data')
        localStorage.removeItem('AP_Outdoor_Data')
        localStorage.removeItem('AP_Indoor_Data')
        localStorage.removeItem('DAS_Data')
        localStorage.removeItem('floorAddProperty')
        localStorage.removeItem('buildingData1AddProperty')
        localStorage.removeItem('outdoor_map_center_lat')
        localStorage.removeItem('outdoor_map_center_lng')
        localStorage.removeItem('indoor_map_center_lat')
        localStorage.removeItem('indoor_map_center_lng')
        localStorage.removeItem('browser')
        Cookies.remove('idToken')
        Cookies.remove('accessToken')
        Cookies.remove('logintime')
        Cookies.remove('username')
        Cookies.remove('rememberMe')
        Cookies.remove('userRole')
        Cookies.remove('permissionId')
        this.props.upadateUserPermissions([])
        this.props.upadateUserRole([])
        this.props.history.push('/home');
        // window.location.reload(false)
        // urltab = 'outdoor-tab'

    }

    urlState = (state: any) => {

        urltab = state;
        this.props.upadateTabState(urltab)
        this.setState({
            tabState: state
        })
        if ([undefined, null, 'undefined', 'null'].includes(Cookies.get('idToken')) || [undefined, null, 'undefined', 'null'].includes(Cookies.get('accessToken'))) {
            console.log('SessionExp');
            this.setState({ alertbox: true, warnMessage: 'Your session has been expired! Please login again.' })
        }
    }

    changeTab = (e: any) => {
        e.preventDefault();
        this.setState({
            tabState: e.target.id
        })
        this.props.upadateTabState((e.target.id))
        console.log(this.state.tabState)
        if (isMobile) {
            this.props.history.push('/map/' + (e.target.id).split("-")[0] + '/main')
        }
    }
    changeSettingsTab = (e: any) => {
        if (this.state.role == 'MasterAdmin' || this.state.role == 'CPI') {
            this.setState({
                company: true,
                tabState: e.target.id
            })
        } else {
            this.setState({
                company: false,
                tabState: e.target.id
            })
        }

    }
    showModal = (param: any) => {
        this.setState({ show: true, modalName: param })

        console.log(this.state.show)
    }

    hideModal = () => {
        this.setState({
            show: false,
            modalName: ''
        })
    }
    Tabs = () => {
        // {(() => {
        // console.log(this.state.tabState)
        // if (this.state.tabState === 'outdoor-tab')
        //     return <Outdoor />
        // if (this.state.tabState === 'indoor-tab')
        //     return <Indoor />
        // if (this.state.tabState === 'analytics-tab')
        //     return <Analytics />
        // if (this.state.tabState === 'analytics-tab')
        //     return <ComAnalytics />
        // if (this.state.tabState === 'import-tab')
        //     return <Import />
        // if (this.state.tabState === 'settings-tab' && !this.state.company)
        //     return <Settings />
        // if (this.state.tabState === 'settings-tab' && this.state.company)
        //     return <CSettings />
        // })()}
    }

    mobileRender() {
        var param = this.state.tabState;
        let { topicId } = useParams()
        switch (topicId) {
            case 'outdoor': return (<Outdoor />)
            case 'indoor': return (<Indoor />)
            case 'settings': return (<Settings />)
        }
    }
    render() {
        setTimeout(() => {
            console.log("=======***********************===============")
            $("#fade-reload").css('display', '');
        }, 1000)

        return (
            <IonPage>
                {isBrowser ?
                    <IonContent className="webonly">
                        <div>
                            <div className='header fixed-top'>
                                <div className='row'>
                                    <div className='col-md-2 col-sm-2'>
                                        {/* <img className='headerlg' src={logo} /> */}
                                        <img className='header-logo' src={logoname} />
                                    </div>
                                    <div className='col-md-8 col-sm-8'>
                                        <ul className="nav nav-tabs tabhash" id="myTab" role="tablist">
                                            <li className="nav-item w-120 rm-m-b" onClick={() => { this.urlState('outdoor-tab') }}>
                                                <Link to={`${this.props.match.url}/outdoor`} style={{ textDecoration: 'none' }} className={`nav-link ${urltab === 'outdoor-tab' ? 'active' : ''}`} href="#outdoor" id="outdoor-tab" role="tab" aria-controls="outdoor"
                                                    aria-selected="true">
                                                    OUTDOOR
                                                </Link>
                                            </li>
                                            <li className="nav-item w-120 rm-m-b" onClick={() => { this.urlState('indoor-tab') }}>
                                                <Link to={`${this.props.match.url}/indoor`} style={{ textDecoration: 'none' }} className={`nav-link ${urltab === 'indoor-tab' ? 'active' : ''}`} id="indoor-tab" href="#indoor" role="tab" aria-controls="indoor"
                                                    aria-selected="false">
                                                    INDOOR
                                                </Link>
                                            </li>
                                            {permission_list !== undefined && (permission_list.includes(19)) ?
                                                <li className="nav-item rm-m-b" onClick={() => { this.urlState('network-activity-tab') }}>
                                                    <Link to={`${this.props.match.url}/network-activity`} style={{ textDecoration: 'none' }} className={`nav-link ${urltab === 'network-activity-tab' ? 'active' : ''}`} href="#network-activity" id="network-activity-tab" role="tab" aria-controls="network-activity"
                                                        aria-selected="true">
                                                        NETWORK ACTIVITY
                                                    </Link>
                                                </li> : null}
                                            {permission_list !== undefined && (permission_list.includes(11) || permission_list.includes(13) || permission_list.includes(14)) ?
                                                <li className="nav-item w-120 rm-m-b" onClick={() => { this.urlState('analytics-tab') }}>
                                                    {role == 'RegionalAdmin' || role == 'MasterAdmin' || role == 'AdminManager' || role == 'Engineer' ?
                                                        <Link to={`${this.props.match.url}/company-analytics`} style={{ textDecoration: 'none' }} className={`nav-link ${urltab === 'company-analytics-tab' ? 'active' : ''}`} id="analytics-tab" href="#analytics" role="tab" aria-controls="analytics-tab"
                                                            aria-selected="false">
                                                            ANALYTICS
                                                        </Link> :
                                                        role == 'CPI' ?
                                                            <Link to={`${this.props.match.url}/analytics`} style={{ textDecoration: 'none' }} className={`nav-link ${urltab === 'analytics-tab' ? 'active' : ''}`} id="analytics-tab" href="#analytics" role="tab" aria-controls="analytics-tab"
                                                                aria-selected="false">
                                                                ANALYTICS
                                                            </Link> : null}



                                                </li> : null}

                                            {permission_list !== undefined && permission_list.includes(15) ?
                                                <li className="nav-item rm-m-b" onClick={() => { this.urlState('import-tab') }}>
                                                    <Link to={`${this.props.match.url}/import`} style={{ textDecoration: 'none' }} className={`nav-link ${urltab === 'import-tab' ? 'active' : ''}`} href="#import" role="tab" aria-controls="indoor" id="import-tab"
                                                        aria-selected="false">
                                                        Data / Import
                                                    </Link>
                                                </li> : null}

                                        </ul>
                                    </div>
                                    <div className='col-md-2 col-sm-2 tabhash' style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", justifyContent: "end", paddingRight: "25px" }}>
                                        <div className="settings text-center m-r-10" style={{ padding: '8px' }} onClick={this.deleteCookies}>

                                            <img className="float-right" style={{ width: '13px', transform: "rotate(180deg)", marginLeft: "2px" }} src={Logout} />
                                        </div>
                                        {role == 'MasterAdmin' || role == 'RegionalAdmin' ?
                                            <Link to={`${this.props.match.url}/settings`} style={{ textDecoration: 'none' }}>
                                                <img src={user} alt="Avatar" style={{ cursor: "pointer", margin: '0px 15px 0px 5px' }} className="avatar" /> </Link> : null}


                                        {permission_list !== undefined && permission_list.includes(18) && role !== 'Installer' ?
                                            <button ion-button icon-only className="settings text-center m-r-10" onClick={this.clickNotification}>
                                                <IonBadge color="danger" class="custom-badge" style={{ position: 'absolute', marginLeft: '7px', marginTop: '-2px' }} item-end>{this.state.pendingNotificationCount}</IonBadge>
                                                <i className="fas fa-bell cogs"></i>
                                            </button>
                                            : null}


                                        {permission_list !== undefined && (permission_list.includes(12) || permission_list.includes(16)) ?
                                            <div className="settings text-center m-r-10" onClick={() => { this.urlState('settings-tab') }}>
                                                {role == 'MasterAdmin' || role == 'AdminManager' || role == 'RegionalAdmin' || role == 'Engineer' ?
                                                    <Link to={`${this.props.match.url}/company-settings`} style={{ textDecoration: 'none' }}>
                                                        <i className={`fa fa-cog cogs ${urltab === 'company-settings-tab' ? 'active' : ''}`} id='settings-tab' onClick={this.changeSettingsTab} style={{ display: 'grid' }}></i>
                                                    </Link> :
                                                    <Link to={`${this.props.match.url}/settings`} style={{ textDecoration: 'none' }}>
                                                        <i className={`fa fa-cog cogs ${urltab === 'settings-tab' ? 'active' : ''}`} id='settings-tab' onClick={this.changeSettingsTab} style={{ display: 'grid' }}></i>
                                                    </Link>
                                                }
                                            </div> : null}
                                    </div>
                                </div>
                            </div >
                            {/* <div className="tab-content p-t-58" id="myTabContent">
                        <div className="tab-pane fade show active" id="outdoor" role="tabpanel" aria-labelledby="outdoor-tab">
                            <Outdoor />
                        </div>
                        <div className="tab-pane fade" id="indoor" role="tabpanel" aria-labelledby="indoor-tab">
                            <Indoor />
                        </div>
                        <div className="tab-pane fade" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                            <Analytics />
                        </div>
                        <div className="tab-pane fade" id="import" role="tabpanel" aria-labelledby="import-tab">
                            <Import />
                        </div>
                        <div className="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <Settings />
                        </div>
                       
                    </div> */}

                            <div className='tab-content p-t-58'>
                                <Switch>
                                    <Route exact path={this.props.match.path}>
                                        <Redirect to={`${this.props.match.url}/outdoor`} />
                                    </Route>
                                    <Route path={`${this.props.match.path}/:topicId`}>

                                        <Tabs />

                                    </Route>
                                </Switch>

                            </div>
                        </div>
                    </IonContent >
                    :
                    <IonContent className="mobonly">
                        {/* <div id='fade-reload' style={{ display: 'none' }}> */}
                        <div id='fade-reload' >

                            <div className='header fixed-top'>
                                <div className='row' style={{ background: '#0159b2' }}>
                                    <div className='col-6'>
                                        {/* <img className='headerlg' src={logo} /> */}
                                        <img className='header-newlg' src={White} />
                                    </div>
                                    <div className='col-6' style={{ padding: "15px" }}>
                                        <div className="row" style={{ float: "right" }}>
                                            {/* <div className="col-4 p-0 text-right" onClick={() => this.showModal('url')}>
                                                URL
                                             </div> */}
                                            <div style={{ cursor: 'pointer' }} className="col-2" onClick={() => { this.props.history.push('/notification-mob') }}>
                                                <IonBadge color="danger" class="custom-badge" style={{ position: 'absolute', marginLeft: '7px', marginTop: '-2px' }} item-end>{this.state.rejectedNotificationCount}</IonBadge>
                                                <i className="fas fa-bell" style={{ color: '#fff' }}></i>
                                            </div>

                                            <div style={{ cursor: 'pointer' }} className="col-2" onClick={() => { this.props.history.push('/calculator') }}>
                                                <i className="fa fa-calculator" style={{ color: '#fff' }} ></i>

                                            </div>

                                            {/* <div className="col-2">
                                                <i className="fa fa-cog" id="settings-app-tab" onClick={() => { this.props.history.push('/app-settings') }}></i>
                                            </div> */}
                                            <div className="col-2" onClick={() => { localStorage.removeItem('backButton'); localStorage.removeItem('appState'); window.location.reload(false) }}>
                                                <i className="fa fa-refresh" style={{ cursor: 'pointer', color: '#fff' }}></i>
                                            </div>
                                        </div>


                                        {/* <IonMenu side="start" menuId="first">
                                        <IonHeader>
                                            <IonToolbar color="primary">
                                                <IonTitle>Start Menu</IonTitle>
                                            </IonToolbar>
                                        </IonHeader>
                                        <IonContent>
                                            <IonList>
                                                <IonItem>Menu Item</IonItem>
                                                <IonItem>Menu Item</IonItem>
                                                <IonItem>Menu Item</IonItem>
                                                <IonItem>Menu Item</IonItem>
                                                <IonItem>Menu Item</IonItem>
                                            </IonList>
                                        </IonContent>
                                    </IonMenu> */}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <ul className="nav nav-tabs tabhash" style={{ padding: '0px', fontWeight: 'bold' }} id="myTab" role="tablist">
                                            <li className="nav-item p-0 w33" id="outdoor" onClick={(e: any) => { this.changeTab(e); this.urlState('outdoor-tab') }}>
                                                <Link to={`map/outdoor/main`} className={`nav-link ${urltab === 'outdoor-tab' ? 'active' : ''}`} style={{ padding: '0rem' }} id="outdoor-tab" data-toggle="tab" href="#outdoor" role="tab" aria-controls="outdoor"
                                                    aria-selected="true">OUTDOOR</Link>
                                            </li>
                                            <div>
                                                <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '11px' }}></div>
                                                <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '5px' }}></div>
                                            </div>
                                            <li className="nav-item p-0 w33" onClick={(e: any) => { this.changeTab(e); this.urlState('indoor-tab') }}>
                                                <Link to={`map/indoor/main`} className={`nav-link ${urltab === 'indoor-tab' ? 'active' : ''}`} style={{ padding: '0rem' }} id="indoor-tab" data-toggle="tab" href="#indoor" role="tab" aria-controls="indoor"
                                                    aria-selected="false">INDOOR</Link>
                                            </li>
                                            <div>
                                                <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '11px' }}></div>
                                                <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '5px' }}></div>
                                            </div>
                                            <li className="nav-item p-0 w33" id="settings" onClick={(e: any) => { this.changeTab(e); this.urlState('settings-tab') }}>
                                                <Link to={`${this.props.match.url}/settings/main`} className={`nav-link ${urltab === 'settings-tab' ? 'active' : ''}`} style={{ padding: '0rem' }} id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings"
                                                    aria-selected="false">SETTINGS</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div >
                            <div className='tab-content p-t-58'>
                                <Switch>
                                    <Route exact path={this.props.match.path}>
                                        <Redirect to={`${this.props.match.url}/outdoor/main`} />
                                    </Route>
                                    <Route path={`${this.props.match.path}/:topicId/:functionality`}>
                                        <Tabs />
                                    </Route>
                                </Switch>
                                {/* <Tabs /> */}

                                {/* {(() => {
                                    console.log(this.state.tabState)
                                    if (this.state.tabState === 'outdoor-tab')
                                        return <Outdoor />
                                    if (this.state.tabState === 'indoor-tab')
                                        return <Indoor />
                                    // if (this.state.tabState === 'analytics-tab')
                                    //     return <Analytics />
                                    // if (this.state.tabState === 'import-tab')
                                    //     return <Import />
                                    if (this.state.tabState === 'settings-tab')
                                        return <Settings />
                                })()} */}
                            </div>

                        </div>
                    </IonContent>
                }
                <Modal show={this.state.modalName == 'url'} handleClose={this.hideModal}>
                    {/* <div className="modal fade" id="submitModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>URL</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>

                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className="form-group" >
                                        <input type="text" style={{ borderRadius: "6px" }} className="text-user img-center" placeholder="URL" />
                                    </div>
                                </div>

                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className="col-6">
                                        <button className='btn cardbtn' style={{ width: '100%' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>Cancel</button>
                                    </div>
                                    <div className="col-6">
                                        <button className='btn cardbtn' style={{ width: '100%' }} onClick={(e: any) => { e.preventDefault(); this.hideModal() }}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <Modal show={this.state.modalName == 'notification'} handleClose={this.hideModal}>
                    {/* <div className="modal fade" id="submitModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row m-0' style={{}}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '14px' }}>NOTIFICATIONS</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div className=' m-t-20 justify-content-center'>
                                    {/* <span>
                                        This is the description for this access point
                                        which could be short or long. We will have to
                                        implement scrolling measures in the case it does get long.
                                    </span> */}
                                    <table className="table sitetable">
                                        <thead className="sitehead" style={{ background: "none" }}>
                                            <tr >


                                                <th className="w10 table-bg-hdr-color" >Parameter Id
                                                    </th>

                                                <th className="w10 table-bg-hdr-color" >Message
                                                   </th>

                                                <th className="w10 table-bg-hdr-color" >Action
                                                   </th>


                                            </tr>
                                        </thead>

                                        {this.state.notificationList.map((val: any, index: any) => {
                                            return <tbody >
                                                <tr className="panel-group analytics" data-toggle="collapse" data-target={"#_" + val.name + index}>
                                                    <td className="w3" >
                                                        {val.parameterId}

                                                    </td>
                                                    <td className="w12">{val.action}</td>
                                                    <td className="w10">
                                                        <button className='btn btn'  >View</button> :


                                                    </td>
                                                </tr>
                                            </tbody>
                                        })}
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['GOT IT',]}
                />
                <IonAlert
                    isOpen={this.state.notificationAlert}
                    onDidDismiss={() => this.notificationalert(false)}
                    message="Params are rejected by CPI"
                    buttons={[{
                        text: 'Action',
                        handler: () => {
                            this.moveToNotification();
                        }
                    }]
                    }
                />
            </IonPage >

        );

    }

}

function Tabs() {
    let { topicId } = useParams();
    urltab = topicId + '-tab'
    return (

        <div>
            {(() => {
                console.log(topicId)
                if (topicId === 'outdoor')
                    return <Outdoor />
                if (topicId === 'indoor')
                    return <Indoor />
                if (topicId === 'analytics')
                    return <Analytics />
                if (topicId === 'company-analytics')
                    return <ComAnalytics />
                if (topicId === 'import')
                    return <Import />
                if (topicId === 'settings')
                    return <Settings />
                if (topicId === 'company-settings')
                    return <CSettings />
                if (topicId === 'network-activity')
                    return <NetworkActivity />
            })()}
        </div>
    );
}


// export default withIonLifeCycle(Header);
export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Header));

setTimeout(() => {
    console.log("=======***********************===============")
    $("#fade-reload").css('display', '');
}, 1000)
