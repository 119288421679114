import React, { useEffect } from 'react'
import { Chart } from 'react-google-charts'
import { relative } from 'path';
import mark from '../assets/img/screenshot.png'
import { GoogleMap, Marker, InfoWindow, InfoBox } from '@react-google-maps/api'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import AP from '../assets/img/Apicon.png'
import APSec1 from '../assets/img/blue_leaf_1.png'; import APSec2 from '../assets/img/blue_leaf_2.png';
import APSec3 from '../assets/img/blue_leaf_3.png'; import APSec4 from '../assets/img/blue_leaf_4.png';
import APSec5 from '../assets/img/blue_leaf_5.png'; import APSec6 from '../assets/img/blue_leaf_6.png';
import CPEMap from '../assets/img/CPE_ICON.png'
import APMap from '../assets/img/add-cpe-icon-img-blue.png'
import DASMap from '../assets/img/dark-pink_leaf_1.png'
import AP1DGray from '../assets/img/status-img/access-point-1.png'; import AP2DGray from '../assets/img/status-img/access-point-2.png';
import AP3DGray from '../assets/img/status-img/access-point-3.png'; import AP4DGray from '../assets/img/status-img/access-point-4.png';
import AP5DGray from '../assets/img/status-img/access-point-5.png'; import AP6DGray from '../assets/img/status-img/access-point-6.png';
import CPEDGray from '../assets/img/status-img/cpe-dark-gray.png';
import 'bootstrap-daterangepicker/daterangepicker.css';
import filter from '../assets/img/down-arrow.svg'
import wifi from '../assets/img/wifi.png'
import calendar from '../assets/img/calendar.png'
import Calculate from './calculate';
import sort from '../assets/img/checked.png'
import office from '../assets/img/ShopsatColumbus1.png'
import { IonTextarea, IonPage, IonContent, IonLoading, IonProgressBar, IonAlert } from '@ionic/react';
import Modal from './modal'
import { requestAPICall } from "../service/apiHandler";
import { start } from 'repl';
import store from '../store';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { UPDATE_FIELD_AUTH, CURRENT_TAB } from '../constants/actionTypes';
import { getSectorImage, getSectorImageMeasure } from '../service/rotateIcon'
import analytics from './analytics';

var sector_property: any = []
var cbsd_list: any = []
var installation_list: any = []
var optional_list: any = []
var cpe_list: any = []
var vendor_model_list: any = []
var vendor_list: any = []
var select_vendor_id = 0
var vendor_id = 0
let center = {
    lat: 40.245992, lng: -99.478220
}
var width: any = 0;
let dummyCenter = {
    lat: 31.256028, lng: -89.913008
}
const styles = require('../assets/GoogleMapStyle.json')
var locations = [
    new window.google.maps.LatLng(13.08, 79.20),
    new window.google.maps.LatLng(13.1832, 78.30),
    new window.google.maps.LatLng(11.38432, 79.40),
    new window.google.maps.LatLng(11.17768, 78.34),
    new window.google.maps.LatLng(12.4645, 77.70),
    new window.google.maps.LatLng(13.0353, 88.2037),
    new window.google.maps.LatLng(12.178678, 79.0335),
]
var locations2 = [
    new window.google.maps.LatLng(11.065984, 78.30),
    new window.google.maps.LatLng(13.3754, 80.1045),
    new window.google.maps.LatLng(13.2876, 80.2053),
    new window.google.maps.LatLng(12.15867, 77.2041),
    new window.google.maps.LatLng(11.09323, 78.2039),
    new window.google.maps.LatLng(12.586566, 79.2037),
    new window.google.maps.LatLng(11.03527, 77.0035)
]
var speedtestMarker = {
    url: require('../assets/img/icons8-new-moon-32.png'),
    scaledSize: new window.google.maps.Size(10, 10),
    anchor: new window.google.maps.Point(5, 5)
}
var markerIcon1 = {
    url: require('../assets/img/circle.svg'),
    scaledSize: new window.google.maps.Size(10, 10)
}
var markerIcon2 = {
    url: require('../assets/img/Apicon.png'),
    scaledSize: new window.google.maps.Size(25, 25)
}
const mapStateToProps = (state: any) => ({ ...state.auth });
const mapDispatchToProps = (dispatch: any) => ({
    upadatePendingNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'pendingNotificationCount', value }),
    upadateRejectedNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'rejectedNotificationCount', value }),
    clickNotify: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'notification', value }),
});
class ComAnalytics extends React.Component {
    state: any = {};
    props: any = {};
    myRef: any = null;
    checkIconsLoaded: any;
    rf_map: any = null;
    rectInterval: any = null;
    _map: any;
    reduxStore: any = store.getState()['auth'];
    constructor(props: any) {

        super(props)
        this.myRef = React.createRef()

        this.handleChange = this.handleChange.bind(this)
        this.list_asset_type_filter = this.list_asset_type_filter.bind(this)
        this.listVendorAPI = this.listVendorAPI.bind(this)
        this.initMap = this.initMap.bind(this)
        this.state = {
            onHover: false,
            showLoading: false,
            show: false,
            dmpList: [],
            sasList: [],
            sector_id: [],
            cpe_list: [],
            assetList: [],
            image_source: "",
            cbsd_list: [],
            a: 0,
            b: 0,
            approveParameterId: "",
            approve: false,
            files: [],
            aval: 0,
            bval: 0,
            categoryA: 0,
            categoryB: 0,
            categoryCBSD: 0,
            categoryAPercent: 0,
            categoryBPercent: 0,
            categoryCPSDPercent: 0,
            outdoorCount: 0,
            indoorCount: 0,
            assetsList: [],
            assettableList: [],
            assetClickId: '',
            progressData: [],
            deploymentData: [],
            vendorcount: [],
            total_vendor_count: 0,
            vendorList: [],
            all_user_list: [],
            start_date: "",
            end_date: "",
            installer: "",
            asset_type: "",
            status_for: [2, 4, 3, 5, 6, 1],
            selected_vendor_id: 0,
            offset_days: 5,
            vendor_id: 2,
            allinstaller: true,
            installername: "",
            rejectParameterId: "",
            reason: "",
            netfileter: "Technology Filter",
            instalationfilter: "All Types",
            hidingRect: false,
            assetLoaded: false,
            isSafari: false
        }

    }
    componentDidUpdate() {
        this.reduxStore = store.getState()['auth']
        if (this.reduxStore['notification'] === true) {
            this.scrollToRef()
            this.reduxStore['notification'] = ""
        }


    }
    componentDidMount() {
        //DETECT BROWSER
        if (localStorage.getItem("browser") == "Safari") {
            console.log("Found Safari")
            this.setState({
                isSafari: true
            })
        }
        this.listUser()
        $("#googlebox").hide()
        setTimeout(() => {
            console.log("first resize................................")
            $("#googlebox").css('width', '99.9%');
            $("#googlebox").show()
        }, 50);
        setTimeout(() => {
            console.log("second resize............................")
            $("#googlebox").css('width', '100%');
            $("#googlebox").show()
        }, 1000);
        this.reduxStore = store.getState()['auth']
        console.log(this.props)


        // this.getRasters()
    }
    onLoad = (map: any) => {
        this._map = map
        this.initMap()

    }
    initMap() {
        google.maps.event.addListenerOnce(this._map, 'idle', () => {
            console.log("==============")
        });
        google.maps.event.addListenerOnce(this._map, 'tilesloaded', () => {
            this.initFunctions()
        })
    }
    initFunctions() {
        console.log("================");

        this.applyDateFilter('', '')

    }
    handleChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }
    scrollToRef = () => {

        const element = document.getElementById('activitytab');
        console.log(element?.scrollIntoView())

    }


    propretycall = (val: any, assetClickId: any) => {
        console.log(val.assetId)
        this.setState({
            asset_id: val.assetId,
            asset_type_id: val.assetTypeId,
            assetClickId: assetClickId
        }, () => this.get_asset_details(val.assetId, ""))
    }
    assetDetail = (e: any, asset: any, text: any) => {
        console.log(asset);
        this.setState({
            "selectedAssetId": asset.assetId
        })
        this.get_asset_details(asset.assetId, text)
    }

    cbsd_category() {

        requestAPICall("analytics_cbsd_category", { start_date: this.state.start_date, end_date: this.state.end_date, installer: this.state.installer }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {

                    this.setState({
                        categoryA: data['Category_A'],
                        categoryB: data['Category_B'],
                        // categoryCBSD: data['Category_CPSD'],
                    })


                    if (this.state.categoryA > 0) {
                        this.setState({
                            categoryAPercent: ((this.state.categoryA / (this.state.categoryA + this.state.categoryB + this.state.categoryCBSD)) * 100).toFixed(2),
                        })
                    }
                    else {
                        this.setState({
                            categoryAPercent: 0
                        })
                    }
                    if (this.state.categoryB > 0) {
                        this.setState({
                            categoryBPercent: ((this.state.categoryB / (this.state.categoryA + this.state.categoryB + this.state.categoryCBSD)) * 100).toFixed(2),

                        })


                    }
                    else {
                        this.setState({
                            categoryBPercent: 0
                        })
                    }
                    // if (this.state.categoryCBSD > 0) {
                    //     this.setState({
                    //         categoryCPSDPercent: ((this.state.categoryCBSD / (this.state.categoryA + this.state.categoryB + this.state.categoryCBSD)) * 100).toFixed(2)
                    //     })
                    // }
                    // else {
                    //     this.setState({
                    //         categoryCPSDPercent: 0
                    //     })
                    // }



                    console.log(this.state.categoryAPercent, this.state.categoryBPercent)
                }

            })
    }
    listvendorDetails = () => {

        requestAPICall("list_analytics_vendor_details", { start_date: this.state.start_date, end_date: this.state.end_date, installer: this.state.installer }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {

                    this.setState({
                        vendorList: data['vendor_list'],
                    })
                    vendor_list = data['vendor_list']
                }
            })
    }


    get_asset_details = (selectedAssetId: any, text: any) => {

        let requestBody = {}
        this.setState({
            sector_id: [], cpe_list: []
        })
        installation_list = [];
        optional_list = [];
        cbsd_list = [];
        var cpe_list = [];
        requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody)
            .then(data => {
                console.log(installation_list, optional_list, cbsd_list)
                var resp = data['properties']
                resp.forEach((element: any) => {
                    if (element['dataType'] === "Image") {
                        this.setState({
                            image_source: element['value'],
                            asset_type_id: data['assetTypeId']

                        })

                    }

                });

                if (data['assetTypeId'] === 3 || data['assetTypeId'] === 2 || data['assetTypeId'] === 4) {

                    installation_list = data["installation_params"]["installation"]
                    optional_list = data["installation_params"]["optional"]
                    cbsd_list = data["installation_params"]["cbsd"]

                    this.setState({
                        cpe_list: data['properties']

                    })

                    cpe_list = data['properties']

                }

                else {
                    this.setState({
                        sector_id: data['sector_id']

                    })
                    this.get_sector_properties(this.state.sector_id[0]["sector_id"])
                }


            })
    }


    get_sector_properties = (sector_id: any) => {
        this.setState({ selectedSector: sector_id })
        requestAPICall("get_sector_details", { asset_type_id: this.state.asset_type_id, asset_id: this.state.asset_id, sector_id: this.state.selectedSector }, {}).then(data => {
            if (data.responseCode == 200) {
                installation_list = data["properties"]["installation"]
                optional_list = data["properties"]["optional"]
                cbsd_list = data["properties"]["cbsd"]
            }
            this.setState({
                showvalue: true
            })
        })
    }


    listSASParams() {

        requestAPICall("list_anaytics_params", { start_date: this.state.start_date, end_date: this.state.end_date, installer: this.state.installer, provider_type_id: 1 }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({
                        sasList: data.providers,

                    })
                }

            })

    }

    listDMPParams() {
        requestAPICall("list_anaytics_params", { start_date: this.state.start_date, end_date: this.state.end_date, installer: this.state.installer, provider_type_id: 2 }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({
                        dmpList: data.providers,
                    })
                }
            })
    }
    list_asset_type_filter = (type: any) => {
        var assetsList: any = []
        console.log(type)
        var status: any = ""
        try {
            clearInterval(this.checkIconsLoaded);
        } catch (error) { }

        this.setState({ assetList: [], instalationfilter: type })

        this.setState({ assetList: [] }, () => {
            console.log(this.state.assetList)
            console.log(this.state.assettableList)
            this.state.assettableList.forEach((asset: any) => {
                console.log(asset.assetTypeId)
                asset['azimuth'].forEach((sector: any) => {
                    if (asset['assetType'] === 'AP-outdoor' || asset['assetType'] === 'CPE-outdoor') {
                        status = "create-list-angle"
                    }
                    else {
                        status = "create-list"
                    }
                    if (type == 'CBSD') {

                        if (asset.assetTypeId == 1 || asset.assetTypeId == 2) {
                            console.log("AAAA")
                            assetsList.push({
                                "assetTypeId": asset['assetTypeId'],
                                "assetType": asset['assetType'],
                                "assetId": asset['assetId'],
                                "assetName": asset['name'],
                                "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                                "name": asset['name'],
                                "markerIcon": asset['markerIcon'],
                                "latitude": asset['latitude'],
                                "longitude": asset['longitude'],
                                "azimuth": asset['azimuth'],
                                "no_of_sectors": asset['no_of_sectors'],
                                "labelText": asset['labelText'],
                                "sector_azimuth": sector[1]


                            })
                        }
                    }
                    else if (type == 'CPE') {
                        console.log("BBB")
                        if (asset.assetTypeId == 3 || asset.assetTypeId == 4) {
                            console.log("CCC")
                            assetsList.push({
                                "assetTypeId": asset['assetTypeId'],
                                "assetType": asset['assetType'],
                                "assetId": asset['assetId'],
                                "assetName": asset['name'],
                                "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                                "name": asset['name'],
                                "markerIcon": asset['markerIcon'],
                                "latitude": asset['latitude'],
                                "longitude": asset['longitude'],
                                "azimuth": asset['azimuth'],
                                "no_of_sectors": asset['no_of_sectors'],
                                "labelText": asset['labelText']


                            })

                        }
                    }
                    else if (type == 'DAS') {
                        if (asset.assetTypeId == 5) {
                            console.log("CCC")
                            assetsList.push({
                                "assetTypeId": asset['assetTypeId'],
                                "assetType": asset['assetType'],
                                "assetId": asset['assetId'],
                                "assetName": asset['name'],
                                "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                                "name": asset['name'],
                                // "markerIcon": getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "create" }, () => { }),
                                "markerIcon": asset['markerIcon'],
                                "latitude": asset['latitude'],
                                "longitude": asset['longitude'],
                                "azimuth": asset['azimuth'],
                                "no_of_sectors": asset['no_of_sectors'],
                                "labelText": asset['labelText']


                            })

                        }
                    }

                    else if (type == "ALL") {
                        assetsList.push({
                            "assetTypeId": asset['assetTypeId'],
                            "assetType": asset['assetType'],
                            "assetId": asset['assetId'],
                            "assetName": asset['name'],
                            "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                            "name": asset['name'],
                            // "markerIcon": getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "create" }, () => { }),
                            "markerIcon": asset['markerIcon'],
                            "latitude": asset['latitude'],
                            "longitude": asset['longitude'],
                            "azimuth": asset['azimuth'],
                            "no_of_sectors": asset['no_of_sectors'],
                            "labelText": asset['labelText'],
                            "sector_azimuth": sector[1]


                        })

                    }
                });
            })
            console.log(assetsList)
            this.setState({ assetList: assetsList }, () => {
                console.log(this.state.assetList)
                this.state.assetList.map((asset: any) => {
                    asset['azimuth'].forEach((sector: any) => {

                        getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate" }, () => { })
                    });
                })
            })
            this.checkIconsLoaded = setInterval(() => {

                this.state.assetList.map((asset: any) => {
                    asset['azimuth'].forEach((sector: any) => {

                        getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate" }, () => { })
                    });
                })

            }, 500);

        })

    }


    listAssets = () => {
        this.setState({
            outdoorCount: 0,
            indoorCount: 0,
            a: 0,
            b: 0,
            aval: 0,
            bval: 0,
            assetList: [],
            assettableList: []

        })
        var outdoorassetsList: any = [];
        var indoorassetsList: any = [];
        var assetsList: any = [];
        var status: any = ""

        console.log("list_assets")
        try {
            clearInterval(this.checkIconsLoaded);
        } catch (error) { }
        requestAPICall("list_analytics_asset", { start_date: this.state.start_date, end_date: this.state.end_date, asset_type: this.state.asset_type, installer: this.state.installer }, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    console.log(this.state.outdoorCount)
                    this.setState({
                        assetLoaded: true
                    })
                    if (data['asset_list'].length > 0) {

                        data.asset_list.map((asset: any) => {
                            var asset_idList: any = []
                            if (asset['assetType'] === 'AP-outdoor' || asset['assetType'] === 'CPE-outdoor') {
                                status = "create-list-angle"
                                outdoorassetsList.push(asset)
                            }
                            else {
                                status = "create-list"
                                indoorassetsList.push(asset)
                            }
                            asset['azimuth'].forEach((sector: any) => {
                                console.log("ooooooooooooooooooooo", sector)
                                if (!(asset_idList.indexOf(asset['assetIdentifierId']) >= 0)) {
                                    asset_idList.push(asset['assetIdentifierId'])
                                    var label_Text = {
                                        text: asset['assetIdentifierId'],

                                    }
                                }
                                else {
                                    console.log("==========")
                                    var text_1: any = " "
                                    var label_Text = {
                                        text: text_1,

                                    }

                                }

                                assetsList.push({
                                    "assetTypeId": asset['assetTypeId'],
                                    "assetType": asset['assetType'],
                                    "assetOperationalStatus": asset['operationalStatus'],
                                    "assetOperationalStatusId": asset['statusId'],
                                    "assetId": asset['assetId'],
                                    "assetName": asset['name'],
                                    "coOrdinates": { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) },
                                    "name": asset['name'],
                                    "markerIcon": getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "create" }, () => { }),
                                    "latitude": asset['latitude'],
                                    "longitude": asset['longitude'],
                                    "createdAt": asset['createdAt'],
                                    "createdBy": asset['createdBy'],
                                    "labelText": label_Text,
                                    "azimuth": asset['azimuth'],
                                    "no_of_sectors": asset['no_of_sectors'],
                                    "sector_azimuth": sector[1]


                                })

                            });
                        })

                        this.setState({ assetList: assetsList, assettableList: assetsList }, () => {
                            console.log(this.state.assetList)
                            this.state.assetList.map((asset: any) => {
                                asset['azimuth'].forEach((sector: any) => {
                                    getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate" }, () => { })
                                });
                            })
                        })


                        this.checkIconsLoaded = setInterval(() => {
                            this.state.assetList.map((asset: any) => {
                                asset['azimuth'].forEach((sector: any) => {
                                    getSectorImage({ "assetType": asset['assetType'], "noOfSectors": parseInt(asset['no_of_sectors']), "status": status, "azimuth": sector, "assetId": asset['assetId'], "action": "rotate" }, () => { })
                                });
                            })

                        }, 500);
                        console.log(this.state.assetList)
                        console.log(outdoorassetsList.length)
                        console.log(indoorassetsList.length)
                        if (outdoorassetsList.length > 0 || indoorassetsList.length > 0) {
                            this.state.percList = this.calculatePerc(outdoorassetsList.length, indoorassetsList.length)
                            this.setState({
                                outdoorCount: outdoorassetsList.length,
                                indoorCount: indoorassetsList.length,
                                a: this.state.percList[0],
                                b: this.state.percList[1]

                            })
                            this.setState({
                                aval: this.state.a / 100,
                                bval: this.state.b / 100
                            })

                        }

                    }
                }
            })

        console.log(this.state.assetList)
        this.setState({
            showLoading: false,
        })

    }
    calculatePerc = (a: number, b: number) => {
        let list = []
        console.log(a)
        console.log(b)
        let vala = ((a / (a + b)) * 100).toFixed(2)
        let valb = ((b / (a + b)) * 100).toFixed(2)
        console.log(vala, valb)
        list = [vala, valb]
        return list
    }


    listUser() {
        console.log("GET USER PROFILE")

        requestAPICall("list_user", {}, {}).then(data => {
            console.log(data)
            var all_user_list: any = [];
            var userResp = data['userList']
            console.log(userResp)
            userResp.forEach((element: any) => {
                // if (element['userRole'] == "CPI" || element['userRole'] == "Installer" || element['userRole'] == "Engineer" || element['userRole'] == "RegionalAdmin") {
                all_user_list.push(element)

                // }

            });
            this.setState({
                all_user_list: all_user_list
            })
            console.log(this.state.all_user_list)
        })

    }

    setShowLoading = (st: Boolean) => {
        this.setState({ showLoading: st })
    }
    handleEvent = (event: any, picker: any) => {
        console.log("==============")
        console.log(picker.startDate);
    }

    applyDateFilter(e: any, dateObj: any) {
        console.log("===========")
        this.setState({
            showLoading: true,
        })
        function format(num: any) {
            return num > 9 ? num.toString() : '0' + num.toString()
        }
        var startDateObj;
        var endDateObj;
        var startDate;
        var endDate;
        if (dateObj !== '') {
            startDateObj = dateObj.startDate._d
            endDateObj = dateObj.endDate._d
            startDate = startDateObj.getFullYear().toString() + "-" + format(startDateObj.getMonth() + 1) + "-" + format(startDateObj.getDate())
            endDate = endDateObj.getFullYear().toString() + "-" + format(endDateObj.getMonth() + 1) + "-" + format(endDateObj.getDate())
            this.setState({
                sdate: format(startDateObj.getMonth() + 1) + "." + format(startDateObj.getDate()) + "." + format(startDateObj.getFullYear().toString()),
                edate: format(endDateObj.getMonth() + 1) + "." + format(endDateObj.getDate()) + "." + format(endDateObj.getFullYear().toString())

            })
        } else {
            startDate = ''
            endDate = ''
            this.setState({
                sdate: '',
                edate: ''

            })
        }
        
        console.log(startDate);
        console.log(endDate);
        this.setState(
            {
                start_date: startDate,
                end_date: endDate
            }, () => {
                this.listSASParams()
                this.listDMPParams()
                // this.get_deployment_progress()
                // this.get_deployment_models()
                this.listVendorAPI()
                this.listvendorDetails()
                this.listAssets()
                this.cbsd_category()
            }
        )

    }

    get_deployment_progress() {
        console.log(this.state.installer)
        console.log(this.state.status_for)
        this.setState({ progressData: [] })
        requestAPICall("get_depolyment_progress", { start_date: this.state.start_date, end_date: this.state.end_date, offset_days: this.state.offset_days, status_for: this.state.status_for, installer: this.state.installer }, {})

            .then(data => {
                console.log(data)

                if (data.responseCode == 200) {
                    console.log(data)
                    if (data.progress == []) {
                        this.setState({
                            showLoading: false,
                            progressData:
                                [
                                    [
                                        "Month",
                                        "In Design",
                                        "On Air",
                                        "In Construction"
                                    ],
                                    [
                                        "1",
                                        1,
                                        1,
                                        0
                                    ]
                                ]
                        })

                    }
                    else {
                        this.setState({ progressData: data.progress })
                    }

                }
            })
    }


    get_deployment_models() {
        this.setState({ deploymentData: [] })
        requestAPICall("get_depolyment_models", { start_date: this.state.start_date, end_date: this.state.end_date, offset_days: this.state.offset_days, status_for: select_vendor_id, vendor_id: select_vendor_id, installer: this.state.installer }, {})
            .then(data => {
                if (data.responseCode == 200) {
                    console.log(data)
                    if (data.progress == []) {
                        this.setState({
                            progressData:
                                [
                                    [
                                        "Month",
                                        "Model1",
                                        "Model2",
                                        "Model3"
                                    ],
                                    [
                                        "1",
                                        0,
                                        0,
                                        0
                                    ]
                                ]
                        })

                    }
                    else {
                        this.setState({ deploymentData: data.progress })
                    }
                }
            })
    }

    listVendorAPI() {
        requestAPICall("list_vendor", {}, {}).then(data => {
            console.log(data)
            if (data['vendor_list'].length > 0 && data['vendor_list'] != undefined) {
                this.setState({
                    selected_vendor_id: data['vendor_list'][0]['vendorId']
                })
                select_vendor_id = data['vendor_list'][0]['vendorId']
                // this.get_deployment_models()
                vendor_model_list = data['vendor_list']
            }
        })
    }

    selectVendor = (e: any) => {
        let vendor_id = e.target.value
        var select_type = vendor_model_list.filter((vendor: any) => vendor.vendorId == e.target.value)
        select_vendor_id = select_type[0]['vendorId']
    }

    set_vendor_model() {
        var item = ['model', 'model2']


    }
    click_installer = (e: any, user: any) => {

        e.preventDefault();
        if (user == "all") {
            this.setState({ assetLoaded: false, installer: "", installername: "All installers" })
        } else {
            this.setState({ assetLoaded: false, installer: user.userId, installername: user.userName });
        }

        setTimeout(() => {
            this.listAssets();
            this.listVendorAPI()
            this.listvendorDetails();
            this.cbsd_category()
            this.listSASParams()
            this.listDMPParams()
            // this.get_deployment_progress();
            // this.get_deployment_models()
        }, 200)

    }

    onDrop = (files: any) => {
        this.setState({ files: files })
    };

    get_speedtest_data = (rasterId: any) => {
        requestAPICall("speedtest-data", {}, { "raster_id": parseInt(rasterId) }).then(data => {
            console.log(data)
            if (data.responseCode == 200) {
                this.setState({
                    speedtest_points: data.speedtest_data,
                    rf_analysis_chart: data.rf_analysis.chart_data,
                    rf_measurements: data.rf_analysis.no_of_measurements,
                    rf_bins: data.rf_analysis.no_of_bins,
                    rf_bin_size: data.rf_analysis.bin_size,
                    avg_rsrp_actual: data.rf_analysis.avg_rsrp_actual,
                    avg_rsrp_predicted: data.rf_analysis.avg_rsrp_predicted,
                    avg_stdev_actual: data.rf_analysis.avg_stdev_actual,
                    avg_stdev_predicted: data.rf_analysis.avg_stdev_predicted
                })
            }
            if (data.responseCode == 404) {
                this.setState({
                    rf_measurements: "No data",
                    rf_bins: data.rf_analysis.no_of_bins,
                    rf_bin_size: data.rf_analysis.bin_size,
                    avg_rsrp_actual: "No data",
                    avg_rsrp_predicted: "No data",
                    avg_stdev_actual: "No data",
                    avg_stdev_predicted: "No data"
                })
            }
        })
    }
    loadHeatmap = (map: any) => {
        this.rf_map = map
        this.rf_map.overlayMapTypes.clear()
        var imageMapType = new google.maps.ImageMapType({
            getTileUrl: function (tile, zoom) {
                var ymax = 1 << zoom;
                var y = ymax - tile.y - 1;
                return 'https://cpiprodemo1.c3spectra.com/heatmaps/tiles/dummyName' + zoom + '/' + tile.x + '/' + y + '.png'
            },
            tileSize: new google.maps.Size(256, 256),
            opacity: 0.3
        });
        this.rf_map.overlayMapTypes.push(imageMapType);
    }

    show_speedtest_data = (event: any, data: any) => {
        this.setState({
            cellID: data.meta_data.cellID,
            enB: data.meta_data.enB,
            mcc: data.meta_data.mcc,
            mnc: data.meta_data.mnc,
            pci: data.meta_data.pci,
            rsrp: data.meta_data.rsrp,
            tac: data.meta_data.tac
        }, () => {
            this.setState({
                download_speed: data.download_speed,
                upload_speed: data.upload_speed,
                latency: data.latency,
                server: data.server,
                infoWindowPosition: { lat: parseFloat(event.latLng.lat().toFixed(6)), lng: parseFloat(event.latLng.lng().toFixed(6)) }
            }, () => {
                this.setState({
                    onHover: true
                })
            })
        })
    }
    dummySpeedtest = (event: any) => {
        this.setState({
            cellID: 10,
            enB: 47230,
            mcc: 310,
            mnc: 260,
            pci: 116,
            rsrp: -111,
            tac: 2023
        }, () => {
            this.setState({
                download_speed: 64.93,
                upload_speed: 60.23,
                latency: '50 ms',
                server: 'Spectra-Pro',
                infoWindowPosition: { lat: parseFloat(event.latLng.lat().toFixed(6)), lng: parseFloat(event.latLng.lng().toFixed(6)) }
            }, () => {
                this.setState({
                    onHover: !this.state.onHover
                })
            })
        })
    }
    getRasters = () => {
        requestAPICall("raster-list", {}, {}).then(data => {
            console.log(data)
            if (data.responseCode == 200) {
                this.setState({ raster_list: data.raster_list })
            }
        })
    }
    setRaster = (e: any) => {
        const { options, value, selectedIndex } = e.target
        if (value != 0) {
            if (this.rf_map != null && this.rf_map != undefined) {
                this.rf_map.overlayMapTypes.clear()
                var imageMapType = new google.maps.ImageMapType({
                    getTileUrl: function (tile, zoom) {
                        var ymax = 1 << zoom;
                        var y = ymax - tile.y - 1;
                        return 'https://cpiprodemo1.c3spectra.com/heatmaps/tiles/' + options[selectedIndex].innerText + '/' + zoom + '/' + tile.x + '/' + y + '.png'
                    },
                    tileSize: new google.maps.Size(256, 256),
                    opacity: 0.3
                });
                this.rf_map.overlayMapTypes.push(imageMapType);
            }
            this.get_speedtest_data(value)
            this.setState({
                rasterSelected: true
            })
        }
    }
    render() {
        // setTimeout(() => {
        //     console.log("first resize................................")
        //     $("#googlebox").css('width', '99.9%');
        //     $("#googlebox").show()
        // }, 50);
        // setTimeout(() => {
        //     console.log("second resize............................")
        //     $("#googlebox").css('width', '100%');
        //     $("#googlebox").show()
        // }, 1000);
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'

        return (

            <div className="container-fluid" >


                <div className='row' style={{ backgroundColor: '#F9FAFC' }} >
                    <div className="col-md-12" >
                        <div className='row m-t-20' >
                            <div className='col-md-7'>
                                <h4 >Market Snapshot</h4>
                            </div>
                            <div className='col-md-5' style={{ display: "flex", alignItems: "center" }}>

                                <span className="dropdown installer">
                                    {this.state.installer == "" ?
                                        < span className="p-r-10 filtername" style={{ fontSize: '14px' }}>All Installers</span>
                                        :
                                        <span className="p-r-10 filtername" style={{ fontSize: '14px' }}>{this.state.installername}</span>}
                                    <img className="filterarrow" src={filter} style={{ width: '15px' }} data-toggle="dropdown" />
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" href="#" onClick={(e: any) => {
                                            this.click_installer(e, "all")

                                        }}>All Installers</a>
                                        {this.state.all_user_list.map((user: any) => {
                                            return <a className="dropdown-item" href="#" onClick={(e: any) => {
                                                this.click_installer(e, user)

                                            }}>{user.userName}</a>

                                        })
                                        }
                                    </div>
                                </span>
                                <div className="dropdown p-l-30 techdrop" >
                                    <img className=" p-r-10" src={wifi} style={{ width: '30px', filter: 'opacity(0.4)' }} />
                                    <span className="p-r-10 filtername" style={{ fontSize: '14px' }}>{this.state.netfileter}</span>
                                    <img className="filterarrow" src={filter} style={{ width: '15px' }} data-toggle="dropdown" />

                                    <div className="dropdown-menu">

                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "Technology Filter" }) }; }} >Technology Filter</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "LTE" }) }; }}>LTE</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "Wifi" }) }; }}>Wifi</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "NR" }) }; }}>NR</a>
                                        <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ netfileter: "Others" }) }; }}>Others</a>

                                    </div>
                                </div>
                                <div className="p-l-30">
                                    <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj); { this.setState({ assetLoaded: false }) }; }}>
                                        <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                        <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                        <img src={filter} style={{ width: '15px' }} />

                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>

                        <div className='row m-t-30'  >
                            <div className="col-md-12">
                                <div className='card' style={{ position: 'relative', width: '100%', height: '580px', margin: '0px' }}>
                                    <div className='card-body' style={{ padding: '0.95rem' }}>
                                        <div className="col-md-12" style={{ padding: '6px 14px' }}>
                                            <span className="font14">INSTALLATIONS</span>
                                            <div className="float-right dropdown techdrop p-r-20">
                                                {this.state.instalationfilter === "ALL" ?
                                                    <span className="p-r-10 filtername" style={{ fontSize: '14px' }}>{this.state.instalationfilter} Types</span>
                                                    : <span className="p-r-10 filtername" style={{ fontSize: '14px' }}>{this.state.instalationfilter}</span>}
                                                <img className="filterarrow" src={filter} style={{ width: '15px' }} data-toggle="dropdown" />
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ assetList: [] }) }; this.list_asset_type_filter('ALL') }}>ALL Types</a>
                                                    <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ assetList: [] }) }; this.list_asset_type_filter('CBSD') }}>CBSD</a>
                                                    <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ assetList: [] }) }; this.list_asset_type_filter('CPE') }}>CPE</a>
                                                    <a className="dropdown-item" onClick={(e: any) => { e.preventDefault(); { this.setState({ assetList: [] }) }; this.list_asset_type_filter('DAS') }}>DAS</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row m-t-10">
                                            <div className="maploder">
                                                {!(this.state.assetLoaded) ?
                                                    <div className="loader" role="status">
                                                    </div> : ""}


                                            </div>
                                            <GoogleMap
                                                id='googlebox'
                                                mapContainerStyle={{ height: "calc(580px - 63px)", borderRadius: '0px 0px 6px 6px' }}
                                                zoom={4}
                                                center={center}
                                                onLoad={this.onLoad}
                                                options={{
                                                    disableDefaultUI: true, styles: styles, mapTypeControl: true, mapTypeControlOptions: {
                                                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                                        position: google.maps.ControlPosition.TOP_RIGHT
                                                    }
                                                }}
                                            >
                                                {this.state.assetList.map((asset: any, index: any) => (
                                                    <Marker position={asset.coOrdinates} label={asset.labelText} key={index} icon={asset.markerIcon} title={this.state.isSafari ? "asset" + asset['assetId'] + asset['sector_azimuth'] : ""} draggable={false} onClick={(e: any) => { this.assetDetail(e, asset, "Info") }} />
                                                ))}

                                            </GoogleMap>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row m-t-16' >
                            <div className='col-md-6 p-r-8'>
                                <div className='card lf' >
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <span className="font14">LOCATIONS</span>
                                        <div className='row blueprogress'>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>Outdoor</span>
                                                <span className='float-right bardes' >{this.state.outdoorCount}</span>
                                                <IonProgressBar value={this.state.aval} style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.a}%</span>
                                            </div>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: "center" }}>
                                                <span className='float-left bardes'>Indoor</span>
                                                <span className='float-right bardes'>{this.state.indoorCount}</span>
                                                <IonProgressBar value={this.state.bval} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.b}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-l-8' >
                                <div className='card rt' >
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <span className="font14">CBSD CATEGORY</span>
                                        <div className='row orangeprogress'>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>Category A</span>
                                                <span className='float-right bardes' >{this.state.categoryA}</span>
                                                <IonProgressBar value={this.state.categoryAPercent} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.categoryAPercent}%</span>
                                            </div>
                                            <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>Category B</span>
                                                <span className='float-right bardes' >{this.state.categoryB}</span>
                                                <IonProgressBar value={this.state.categoryBPercent} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.categoryBPercent}%</span>
                                            </div>
                                            {/* <div className='col-md-4 m-t-20' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                <span className='float-left bardes'>CPE-CPSD</span>
                                                <span className='float-right bardes' >{this.state.categoryCBSD}</span>
                                                <IonProgressBar value={this.state.categoryCPSDPercent} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                <span className="fontw500">{this.state.categoryCPSDPercent}%</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row m-t-16' >
                            <div className='col-md-6 p-r-8'>
                                <div className='card lf' style={{ height: '220px' }}>
                                    {this.state.sasList.length > 0 ?
                                        <div className='card-body' style={{ padding: '20px 30px' }}>
                                            <span className="font14">SAS</span>
                                            <div className='row sorangeprogress'>
                                                {this.state.sasList.map((sas: any) => (
                                                    <div className='col-md-4' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                        {sas.param.length > 0 ?
                                                            <div>

                                                                <span className='float-left bardes'>{sas.providerName}</span>
                                                                <span className='float-right bardes' >{sas.param.length}</span>
                                                                <IonProgressBar value={sas.value} style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">{sas.percent}</span>
                                                            </div> :
                                                            <div>

                                                                <span className='float-left bardes'>{sas.providerName}</span>
                                                                <span className='float-right bardes' >{sas.param.length}</span>
                                                                <IonProgressBar value={0} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">0%</span>
                                                            </div>
                                                        }


                                                    </div>))}

                                            </div>
                                        </div> :
                                        <div className='card-body' style={{ padding: '20px 30px' }}>
                                            NO SAS FOUND</div>}

                                </div>
                            </div>
                            <div className='col-md-6 p-l-8' >
                                <div className='card rt' style={{ height: '220px' }}>
                                    {this.state.dmpList.length > 0 ?
                                        <div className='card-body' style={{ padding: '20px 30px' }}>
                                            <span className="font14">DOMAIN PROXY</span>
                                            <div className='row greenprogress'>
                                                {this.state.dmpList.map((dmp: any) => (
                                                    <div className='col-md-4' style={{ paddingTop: '15px', textAlign: 'center' }}>

                                                        {dmp.param.length > 0 ?
                                                            <div>

                                                                <span className='float-left bardes'>{dmp.providerName}</span>
                                                                <span className='float-right bardes' >{dmp.param.length}</span>
                                                                <IonProgressBar value={dmp.value} style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">{dmp.percent}</span>
                                                            </div> :
                                                            <div>

                                                                <span className='float-left bardes'>{dmp.providerName}</span>
                                                                <span className='float-right bardes' >{dmp.param.length}</span>
                                                                <IonProgressBar value={0} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">0%</span>
                                                            </div>
                                                        }



                                                    </div>))}
                                            </div>
                                        </div> :
                                        <div className='card-body' style={{ padding: '20px 30px' }}>NO DOMAIN PROXY FROUND</div>}
                                </div>
                            </div>

                        </div>
                        <div className='row' >
                            {this.state.vendorList.map((val: any, index: any) => (
                                <div className={`col-md-6 m-t-16 ${index % 2 === 0 ? 'p-r-8' : 'p-l-8'}`}>
                                    <div className={`card ${index % 2 === 0 ? 'lf' : 'rt'}`}>

                                        <div className='card-body' style={{ padding: '20px 30px' }}>

                                            <span className="font14">{val.vendor_name}</span>
                                            <div className='row greenprogress'>
                                                {val.vendor_model.map((count: any) => (
                                                    <div className='col-md-4' style={{ paddingTop: '15px', textAlign: 'center' }}>
                                                        {count.vendor_asset_count > 0 ?
                                                            <div>

                                                                <span className='float-left bardes'>Model  {count.vendor_model_name.split("-")[1]}</span>
                                                                <span className='float-right bardes' >{count.vendor_asset_count}</span>
                                                                <IonProgressBar value={(count.vendor_asset_count / val.total_asset_vendor_count) * 100} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">{((count.vendor_asset_count / val.total_asset_vendor_count) * 100).toFixed(2)}%</span>
                                                            </div> :
                                                            <div>

                                                                <span className='float-left bardes'>Model  {count.vendor_model_name.split("-")[1]}</span>
                                                                <span className='float-right bardes' >{count.vendor_asset_count}</span>
                                                                <IonProgressBar value={0} color='primary' style={{ height: '7px', borderRadius: '10px', marginBottom: '15px' }}></IonProgressBar>
                                                                <span className="fontw500">0%</span>
                                                            </div>}
                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>))}
                        </div>

                        <div className='row m-t-16' >
                            <div className='col-md-6 p-r-8'>
                                <div className="card" style={{ width: '100%', height: '380px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '20px 30px' }} >
                                        <span className="font14">DEPLOYMENT PROGRESS</span>
                                        <div className="" style={{ float: 'right' }}>
                                            <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                                <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                                <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                                <img src={filter} style={{ width: '15px' }} />

                                            </DateRangePicker>
                                        </div>

                                        {/* <i className="fa fa-circle float-right p-r-5 m-t-5" style={{ color: '#00D56E', fontSize: '12px' }}>&nbsp;&nbsp;
                            <span className="font14" style={{ color: '#00D56E' }}>
                                            </span>
                                        </i> */}
                                        <div className="m-t-20">
                                            {/* {this.state.progressData.length == 0 ? "No data found" : */}
                                            <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA/edit#gid=0"
                                                // data={this.state.progressData}
                                                data={[[
                                                    "Month",
                                                    "In Design",
                                                    "On Air",
                                                    "In Construction"
                                                ],
                                                ['01', 1000, 400, 200],
                                                [, 1170, 460, 250],
                                                ['05', 660, 1120, 300],
                                                [, 1030, 540, 350],
                                                ['10', 1000, 400, 200],
                                                [, 1170, 460, 250],
                                                ['15', 660, 1120, 300],
                                                [, 1030, 540, 350],
                                                ['20', 1000, 400, 200],
                                                [, 1170, 460, 250],
                                                ['25', 660, 1120, 300],
                                                [, 1030, 540, 350],
                                                ['30', 1000, 400, 200],
                                                [, 1170, 460, 250],
                                                ]}
                                                options={{
                                                    isStacked: true,
                                                    colors: ['#00D56E', '#FCD369', '#0059B2', '#0000A0', '#D8D8D8', '#ff0000'],
                                                    legend: { display: true, position: 'top' },
                                                    //bars:'vertical',
                                                    height: 300,
                                                    width: 600,
                                                    bar: { groupWidth: '30%' },


                                                    title: '',
                                                    chartArea: { width: '80%' },

                                                    hAxis: {
                                                        title: 'Month',
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        title: '# of Sites',
                                                    },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-l-8' >
                                <div className="card" style={{ width: '100%', height: '380px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <span className="font14">DEPLOYMENT MODELS</span>
                                        <div className="" style={{ float: 'right' }}>
                                            <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                                <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                                <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                                <img src={filter} style={{ width: '15px' }} />

                                            </DateRangePicker>
                                        </div>
                                        {
                                            <select name="vendor" className="m-t-10 form-control settingdropdown buildinfo" onChange={(e) => this.selectVendor(e)} >
                                                {vendor_model_list.map((vendor: any, index: any) => <option value={vendor.vendorId} >{vendor.name}</option>)}
                                            </select>
                                        }
                                        <div className="m-t-10">
                                            {/* {this.state.deploymentData.length == 0 ? "No data found" : */}
                                            <Chart
                                                width={'400px'}
                                                height={'250px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA/edit#gid=0"
                                                // data={this.state.deploymentData}
                                                data={[

                                                    ['Year', 'Model1', 'model2', 'model3'],
                                                    ['01', 1000, 400, 200],
                                                    [, 1170, 460, 250],
                                                    ["05", 660, 1120, 300],
                                                    [, 1030, 540, 350],
                                                    ["10", 1000, 400, 200],
                                                    [, 1170, 460, 250],
                                                    ["15", 660, 1120, 300],
                                                    [, 1030, 540, 350],
                                                    ['20', 1000, 400, 200],
                                                    [, 1170, 460, 250],
                                                    ['25', 660, 1120, 300],
                                                    [, 1030, 540, 350],
                                                    ['30', 1000, 400, 200],
                                                    [, 1170, 460, 250],



                                                ]}
                                                options={{
                                                    isStacked: true,
                                                    colors: ['#0059B2', '#0073FF', '#6EDAFF'],
                                                    legend: { position: 'top' },
                                                    //bars:'vertical',
                                                    height: 250,
                                                    width: 600,
                                                    bar: { groupWidth: '30%' },


                                                    title: '',
                                                    chartArea: { width: '80%' },

                                                    hAxis: {
                                                        title: 'Month',
                                                        minValue: 2000,
                                                        maxValue: 2050
                                                    },
                                                    vAxis: {
                                                        title: '#of assets',
                                                    },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row m-t-16' >
                            <div className='col-md-6 p-r-8'>
                                <div className="card" style={{ width: '100%', height: '480px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '20px 30px' }} >
                                        <div className="" style={{ float: 'right' }}>
                                            <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                                <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                                <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                                <img src={filter} style={{ width: '15px' }} />

                                            </DateRangePicker>
                                        </div>

                                        <div className="m-t-40">
                                            <div className=" m-t-20 text-center font14">Top Markets by Outdoor CBSDs</div>

                                            <Chart
                                                // onLoad={(e: any) => { this.onEvent(e) }}
                                                width={'600px'}
                                                height={'385px'}
                                                chartType="ColumnChart"
                                                loader={<div onLoad={() => { this.setState({ hidingRect: false }) }}>Loading Chart</div>}

                                                data={[
                                                    ["State",
                                                        "Registered",
                                                        { role: 'annotation' },
                                                        "Granted",
                                                        { role: 'annotation' },
                                                        "Online",
                                                        { role: 'annotation' },
                                                    ],
                                                    ['New York, NY', 4.3, 4.3, 2.4, 2.4, 2, 2],
                                                    ['Dallas, TX', 2.5, 2.5, 4.4, 4.4, 2, 2],
                                                    ['LA, CA', 3.5, 3.5, 1.8, 1.8, 3, 3],
                                                    ['Houston, TX', 4.5, 4.5, 2.8, 2.8, 5, 5],

                                                ]}
                                                options={{
                                                    colors: ['#70ad47', '#5a9ad5', '#fac030'],
                                                    legend: { display: true, position: 'top' },
                                                    height: 385,
                                                    width: 600,
                                                    annotations: {
                                                        alwaysOutside: true,
                                                        textStyle: {
                                                            fontSize: 9,
                                                            color: '#000000'
                                                        },
                                                        stem: {
                                                            color: 'transparent',
                                                            length: '4',
                                                            zIndex: '-1'
                                                        },
                                                    },

                                                    bar: { groupWidth: '60%' },
                                                    chartArea: {
                                                        left: "10%",
                                                        width: "80%"
                                                    },

                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-l-8' >
                                <div className="card" style={{ width: '100%', height: '480px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <div className="" style={{ float: 'right' }}>
                                            <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                                <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                                <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                                <img src={filter} style={{ width: '15px' }} />

                                            </DateRangePicker>
                                        </div>

                                        <div className="m-t-40">
                                            <div className=" m-t-20 text-center font14">Top Venue CBSD</div>

                                            <Chart
                                                width={'600px'}
                                                height={'385px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}

                                                data={[

                                                    ['Venue', 'Category A', { role: 'annotation' }, 'Category B', { role: 'annotation' }],
                                                    ['Venue1', 25, 25, 6, 6],
                                                    ['Venue2', 35, 35, 6, 6],
                                                    ["Venue3", 50, 50, 15, 15],
                                                    ['Venue4', 34, 34, 8, 8],

                                                ]}

                                                options={{
                                                    isStacked: true,
                                                    height: 385,
                                                    width: 600,
                                                    annotations: {
                                                        alwaysOutside: false,
                                                        textStyle: {
                                                            fontSize: 9,
                                                            color: '#000000'
                                                        },
                                                        stem: {
                                                            color: 'transparent',
                                                            length: '4',
                                                            zIndex: '-1'
                                                        },
                                                    },
                                                    colors: ['#70ad47', '#5a9ad5'],
                                                    legend: { position: 'top' },

                                                    bar: { groupWidth: '60%' },
                                                    chartArea: {
                                                        left: "10%",
                                                        width: "80%"
                                                    },


                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row m-t-16' >
                            <div className='col-md-6 p-r-8' >
                                <div className="card" style={{ width: '100%', height: '480px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '20px 30px' }}>
                                        <div className="" style={{ float: 'right' }}>
                                            <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                                <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                                <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                                <img src={filter} style={{ width: '15px' }} />

                                            </DateRangePicker>
                                        </div>
                                        <div className="m-t-40">
                                            <div className=" m-t-20 text-center font14">CBSD Status</div>
                                            <Chart
                                                width={'600px'}
                                                height={'360px'}
                                                chartType="ComboChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Month', 'CBSDs Offline', { role: 'annotation' }, 'CBSDs Registered in SAS', { role: 'annotation' }, 'CBSDs Pending Registration', { role: 'annotation' }, 'CBSDs Ready For CPI', { role: 'annotation' }, 'CBSDs Online', { role: 'annotation' }],
                                                    ['January', 5, 5, 55, 55, 32, 32, 22, 22, 20, 20],
                                                    ['February', 15, 15, 67, 67, 16, 16, 25, 25, 20, 20],
                                                    ['March', 12, 12, 55, 55, 34, 34, 22, 22, 22, 22],
                                                    ['April', 5, 5, 67, 67, 16, 16, 25, 25, 25, 25],
                                                    ['May', 15, 15, 55, 55, 16, 16, 25, 25, 27, 27],
                                                    ['June', 22, 22, 67, 67, 16, 16, 25, 25, 35, 35],
                                                    ['July', 17, 17, 67, 67, 16, 16, 25, 25, 42, 42],
                                                    ['August', 12, 12, 55, 55, 34, 34, 22, 22, 50, 50],
                                                    ['September', 37, 37, 34, 34, 6, 6, 23, 23, 55, 55],
                                                    ['October', 56, 56, 67, 67, 16, 16, 25, 25, 57, 57],
                                                    ['November', 25, 25, 55, 55, 34, 34, 22, 22, 60, 60],
                                                    ['December', 32, 32, 3, 3, 22, 22, 3, 3, 67, 67],

                                                ]}
                                                options={{
                                                    colors: ['#ed7d31', '#a5a5a5', '#fac030', '#5a9ad5', '#4472c4'],
                                                    // legend: { position: 'top', maxLines: 3, textStyle: { fontSize: 10 } },
                                                    legend: { position: 'bottom', maxLines: 3, textStyle: { fontSize: 10 } },
                                                    height: 360,
                                                    width: 600,
                                                    bar: {
                                                        groupWidth: '80%'
                                                    },
                                                    hAxis: {
                                                        textStyle: {
                                                            fontName: 'Roboto, sans-serif',
                                                            fontSize: 12
                                                        },
                                                        slantedText: true,
                                                        slantedTextAngle: 40 // here you can even use 180
                                                    },
                                                    annotations: {
                                                        alwaysOutside: true,
                                                        textStyle: {
                                                            fontSize: 9,
                                                            color: '#000000'
                                                        },
                                                        stem: {
                                                            color: 'transparent',
                                                            length: '4',
                                                            zIndex: '-1'
                                                        },
                                                    },
                                                    chartArea: {
                                                        left: "10%",
                                                        width: "80%",
                                                        top: "7%"
                                                    },
                                                    seriesType: 'bars',
                                                    series: {

                                                        4: { type: "line", targetAxisIndex: 1 }
                                                    },

                                                }}

                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-l-8' >
                                <div className="card" style={{ width: '100%', height: '480px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' >
                                        <div className="" style={{ float: 'right' }}>
                                            <DateRangePicker autoApply={true} onApply={(e: any, dateObj: any) => { this.applyDateFilter(e, dateObj) }}>
                                                <img className=" p-r-10" src={calendar} style={{ width: '34px', height: '20px', filter: 'opacity(0.4)' }} />
                                                <span className="p-r-10 filtername" style={{ fontSize: '13px' }}>{this.state.sdate} - {this.state.edate}</span>
                                                <img src={filter} style={{ width: '15px' }} />

                                            </DateRangePicker>
                                        </div>
                                        <div className="m-t-40">
                                            <div className=" m-t-20 text-center font14">Average Spectrum Grant by Market</div>
                                            <Chart
                                                width={'600px'}
                                                height={'360px'}
                                                chartType="LineChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Month', 'CAT A Indoor', { role: 'annotation' }, 'CAT A Outdoor', { role: 'annotation' }, 'CAT B Outdoor', { role: 'annotation' }],
                                                    ['January', 88, 88, 47, 47, 47, 47],
                                                    ['February', 50, 50, 50, 50, 50, 50],
                                                    ['March', 70, 70, 28, 28, 60, 60],
                                                    ['April', 90, 90, 56, 56, 70, 70],
                                                    ['May', 50, 50, 88, 88, 100, 100],
                                                    ['June', 70, 70, 88, 88, 50, 50],
                                                    ['July', 50, 50, 36, 36, 60, 60],
                                                    ['August', 70, 70, 88, 88, 50, 50],
                                                    ['September', 50, 50, 36, 36, 50, 50],
                                                    ['October', 70, 70, 36, 36, 60, 60],
                                                    ['November', 70, 70, 36, 36, 50, 50],
                                                    ['December', 70, 70, 36, 36, 50, 50],
                                                ]}
                                                options={{
                                                    legend: {
                                                        position: 'bottom', textStyle: { fontSize: 12 }
                                                    },
                                                    height: 360,
                                                    width: 600,
                                                    // title: 'Average Spectrum Grant by Market',

                                                    titleTextStyle: {
                                                        color: '333333',
                                                        fontName: 'Roboto, sans-serif',
                                                        fontSize: 13,
                                                        textAlign: center
                                                    },
                                                    hAxis: {

                                                        textStyle: {
                                                            fontName: 'Roboto, sans-serif',
                                                            fontSize: 12
                                                        },
                                                        slantedText: true,
                                                        slantedTextAngle: 40
                                                    },
                                                    annotations: {
                                                        alwaysOutside: false,
                                                        textStyle: {
                                                            fontSize: 9,
                                                            color: '#000000'
                                                        },
                                                        stem: {
                                                            color: 'transparent',
                                                            length: '4',
                                                            zIndex: '-1'
                                                        },
                                                    }, chartArea: {
                                                        left: "10%",
                                                        width: "80%"
                                                    },
                                                    series: {
                                                        0: { color: '#70ad47' },
                                                        1: { color: '#5a9ad5' },
                                                        2: { color: '#fac030' },

                                                    }

                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row m-t-20'>
                            <div className='col-md-12'>
                                <span style={{ fontWeight: "bold", fontSize: '20px' }}>RF Analysis</span>
                            </div>
                        </div>
                        <div className='row m-t-30'>
                            <div className="col-md-12">
                                <div className='card' style={{ position: 'relative', width: '100%', height: '580px', margin: '0px' }}>
                                    <div className='card-body' style={{ padding: '0.95rem' }}>
                                        <div className="col-md-12" style={{ padding: '6px 14px' }}>
                                            <span className="font14">MODEL COVERAGE</span>
                                            <div className="float-right p-r-10">
                                                <i className="fa fa-search searchicon" aria-hidden="true"></i>
                                                <input className='search' type='text' placeholder='Search State or Country' ></input>
                                            </div>
                                            <div className="float-right p-r-10">
                                                {/* <select className="form-control rfdropdwon" onChange={this.setRaster}> */}
                                                <select className="form-control rfdropdwon">
                                                    <option value="null">FDD_12m</option>
                                                    {this.state.raster_list ? Object.entries(this.state.raster_list).map((value: any) => {
                                                        return <option value={value[1]}>{value[0]}</option>
                                                    }) : null}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row m-t-20">
                                            <div className="" style={{ position: 'absolute', zIndex: 1, right: '0px', bottom: "176px" }}>
                                                <div className="d-inline-block p-0">
                                                    <div className="card" style={{ height: 'inherit', width: 'inherit', position: 'relative', marginLeft: '0px', left: "10px" }}>
                                                        <div className="card-body" style={{ fontSize: 'smaller' }}>
                                                            <div className="m-t-10"><div className='row' style={{ padding: '0 17px' }}>
                                                                <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>Heatmap Legend</h6>
                                                            </div>
                                                                <div className="row m-t-10">
                                                                    <div className="col-md-2">
                                                                        <div className="legend" style={{ background: '#FFFF00' }}> </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <span>{"-125 dBm < RSRP < -120 dBm"}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-t-10">
                                                                    <div className="col-md-2">
                                                                        <div className="legend" style={{ background: '#0000FF' }}> </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <span>{"-120 dBm < RSRP < -110 dBm"}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-t-10">
                                                                    <div className="col-md-2">
                                                                        <div className="legend" style={{ background: '#ADD8E6' }}> </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <span>{"-110 dBm < RSRP < -100 dBm"}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-t-10">
                                                                    <div className="col-md-2">
                                                                        <div className="legend" style={{ background: '#006400' }}> </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <span>{"-100 dBm < RSRP < -90 dBm"}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-t-10">
                                                                    <div className="col-md-2">
                                                                        <div className="legend" style={{ background: '#90EE90' }}> </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <span>{"-90 dBm < RSRP < -80 dBm"}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-t-10">
                                                                    <div className="col-md-2">
                                                                        <div className="legend" style={{ background: '#FF0000' }}> </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <span>Greater than -80 dBm</span>
                                                                    </div>
                                                                </div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <GoogleMap
                                                id='searchbox'
                                                mapContainerStyle={{ height: "calc(580px - 73px)", width: '100%', borderRadius: '0px 0px 6px 6px' }}
                                                zoom={14}
                                                center={dummyCenter}
                                                onLoad={this.loadHeatmap}
                                                options={{
                                                    disableDefaultUI: true, zoomControl: true, mapTypeControl: true, mapTypeControlOptions: {
                                                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                                        position: google.maps.ControlPosition.TOP_RIGHT
                                                    }
                                                }}>
                                                {this.state.assetList.map((asset: any, index: any) => (
                                                    <Marker position={asset.coOrdinates} label={asset.labelText} key={index} icon={asset.markerIcon} draggable={false} onClick={(e: any) => { this.assetDetail(e, asset, "Info") }} />
                                                ))}
                                                {
                                                    this.state.speedtest_points ?
                                                        this.state.speedtest_points.map((point: any, index: any) => (
                                                            <Marker key={index} onClick={(event: any) => this.show_speedtest_data(event, point)} position={{ lat: parseFloat(point.latitude), lng: parseFloat(point.longitude) }} icon={speedtestMarker} />
                                                        )) : <Marker onClick={this.dummySpeedtest} position={{ lat: 31.255661, lng: -89.916273 }} icon={speedtestMarker} />
                                                }
                                                {
                                                    this.state.onHover ?
                                                        <InfoBox position={this.state.infoWindowPosition} options={{ alignBottom: true, closeBoxURL: "https://i.imgur.com/e07Yvv9.png", closeBoxMargin: "12px 4px 2px 2px" }}>
                                                            <div>
                                                                <div>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold', fontSize: '10px' }}><span>enB</span> {this.state.enB} </span>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold', fontSize: '10px' }}><span>Cell</span> {this.state.cellID} </span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold' }}><span>RSRP:</span> {this.state.rsrp} </span>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold' }}><span>PCI:</span> {this.state.pci} </span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold' }}><span>MCC:</span> {this.state.mcc} </span>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold' }}><span>MNC:</span> {this.state.mnc} </span>
                                                                    <span style={{ color: "#FFF", fontWeight: 'bold' }}><span>TAC:</span> {this.state.tac} </span>
                                                                </div>
                                                                <div><span style={{ color: "#FFF" }}>Download Speed:</span><span style={{ color: "#FFF", fontWeight: 'bold' }}> {this.state.download_speed} mbps</span></div>
                                                                <div><span style={{ color: "#FFF" }}>Upload Speed:</span><span style={{ color: "#FFF", fontWeight: 'bold' }}> {this.state.upload_speed} mbps</span></div>
                                                                <div><span style={{ color: "#FFF" }}>Latency:</span><span style={{ color: "#FFF", fontWeight: 'bold' }}> {this.state.latency}</span></div>
                                                                <div><span style={{ color: "#FFF" }}>Server:</span><span style={{ color: "#FFF", fontWeight: 'bold' }}> {this.state.server}</span></div>
                                                            </div>
                                                        </InfoBox>
                                                        : null
                                                }
                                            </GoogleMap>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row m-t-16'>
                            <div className='col-md-6 p-r-10'>
                                <div className="card" style={{ width: '100%', height: '390px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '0.95rem' }} >
                                        <div className="col-md-12" style={{ padding: '6px 14px' }}>
                                            <span className="font14">RF MEASUREMENTS</span>
                                        </div>
                                        <div className="row m-t-10">
                                            {/* {this.state.rf_analysis_chart ? <Chart */}
                                            <Chart
                                                width={'100%'}
                                                height={'330px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={
                                                    // this.state.rf_analysis_chart
                                                    [['Name', 'Predicted Coverage', 'Measured Covarage'],
                                                    ['-111', 0, 1],
                                                    ['-110', 1, 0]]
                                                }

                                                options={{
                                                    //isStacked: true,
                                                    colors: ['#0059B2', '#E22B2B'],
                                                    legend: { position: 'top' },
                                                    //bars:'vertical',
                                                    height: 300,
                                                    width: 600,
                                                    bar: { groupWidth: '20%' },


                                                    title: '',
                                                    chartArea: { width: '80%' },

                                                    hAxis: {
                                                        title: 'RSRP(dbm)',
                                                        minValue: 0,
                                                        // textPosition: 'none'
                                                    },
                                                    vAxis: {
                                                        title: 'Density',
                                                    },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }} />
                                            {/* /> : null} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-l-10'>
                                <div className="card" style={{ width: '100%', height: '390px', margin: '0px', position: 'relative' }}>
                                    <div className='card-body' style={{ padding: '0.95rem' }} >
                                        <div className="col-md-12" style={{ padding: '6px 14px' }}>
                                            <span className="font14">SUMMARY OF MEASUREMENTS</span>
                                            {this.state.rasterSelected ? <div className='row m-t-20'>
                                                <div className='col-md-3 p-r-0'>
                                                    <div className='float-left bardes font14'># of Measurement Pts</div><br />
                                                    <span className='font14'>{this.state.rf_measurements != null ? this.state.rf_measurements : 'Calculating...'}</span>
                                                </div>
                                                <div className='col-md-2 p-r-0'>
                                                    <div className='float-left bardes font14'>State</div><br />
                                                    <span className='font14'>{this.state.rf_measurements != null ? this.state.rf_measurements : 'Calculating...'}</span>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='float-left bardes font14'>Avg RSRP (dBm)</div><br />
                                                    <span className='font14'>{this.state.avg_rsrp_actual != null ? this.state.avg_rsrp_actual : 'Calculating...'}</span>
                                                </div>
                                                <div className='col-md-4 p-r-0'>
                                                    <div className='float-left bardes font14'>Avg Standard Deviation</div><br />
                                                    <span className='font14'>{this.state.avg_stdev_actual != null ? this.state.avg_stdev_actual : 'Calculating...'}</span>
                                                </div>
                                            </div> :
                                                // <div className="col-md-12 m-t-20" style={{ textAlign: 'center' }}>
                                                //     <span>Please select a Raster</span>
                                                // </div>
                                                <div className='row m-t-20'>
                                                    <div className='col-md-3 p-r-0'>
                                                        <div className='float-left bardes font14'># of Measurement Pts</div><br />
                                                        <span className='font14'>1</span>
                                                    </div>
                                                    <div className='col-md-2 p-r-0'>
                                                        <div className='float-left bardes font14'>State</div><br />
                                                        <span className='font14'>1</span>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className='float-left bardes font14'>Avg RSRP (dBm)</div><br />
                                                        <span className='font14'>-111</span>
                                                    </div>
                                                    <div className='col-md-4 p-r-0'>
                                                        <div className='float-left bardes font14'>Avg Standard Deviation</div><br />
                                                        <span className='font14'>Null</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-12 apborder"></div>
                                        </div>
                                        <div className="col-md-12" style={{ padding: '6px 14px' }}>
                                            <span className="font14">SUMMARY OF PREDICTED COVERAGE</span>
                                            {this.state.rasterSelected ? <div className='row m-t-20'>
                                                <div className='col-md-3 p-r-0'>
                                                    <div className='float-left bardes font12'># of Bins</div><br />
                                                    <span className='font14'>{this.state.rf_bins != null ? this.state.rf_bins : 'Calculating...'}</span>
                                                </div>
                                                <div className='col-md-2 p-r-0'>
                                                    <div className='float-left bardes font12'>Bin Size</div><br />
                                                    <span className='font14'>{this.state.rf_bin_size != null ? this.state.rf_bin_size : 'Calculating...'}</span>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='float-left bardes font12'>Avg RSRP (dBm)</div><br />
                                                    <span className='font14'>{this.state.avg_rsrp_predicted != null ? this.state.avg_rsrp_predicted : 'Calculating...'}</span>
                                                </div>
                                                <div className='col-md-4 p-r-0'>
                                                    <div className='float-left bardes font12'>Avg Standard Deviation</div><br />
                                                    <span className='font14'>{this.state.avg_stdev_predicted != null ? this.state.avg_stdev_predicted : 'Calculating...'}</span>
                                                </div>
                                            </div> :
                                                // <div className="col-md-12 m-t-20" style={{ textAlign: 'center' }}>
                                                //     <span>Please select a Raster</span>
                                                // </div>
                                                <div className='row m-t-20'>
                                                    <div className='col-md-3 p-r-0'>
                                                        <div className='float-left bardes font12'># of Bins</div><br />
                                                        <span className='font14'>1</span>
                                                    </div>
                                                    <div className='col-md-2 p-r-0'>
                                                        <div className='float-left bardes font12'>Bin Size</div><br />
                                                        <span className='font14'>25x25m</span>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className='float-left bardes font12'>Avg RSRP (dBm)</div><br />
                                                        <span className='font14'>-110</span>
                                                    </div>
                                                    <div className='col-md-4 p-r-0'>
                                                        <div className='float-left bardes font12'>Avg Standard Deviation</div><br />
                                                        <span className='font14'>Null</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-12 apborder"></div>
                                        </div>
                                        <div className="col-md-12" style={{ padding: '6px 14px' }}>
                                            <span className="font14">CONCLUSION</span>
                                            {this.state.rasterSelected ? <div className='col-md-12 m-t-20 p-0'>
                                                <div className="fontw500" style={{ color: '#00D56E', fontSize: '18px' }}>DELTA: 4</div>
                                                <span className="font14">Coverage model is over predicting </span>
                                            </div> : <div className='col-md-12 m-t-20 p-0'>
                                                    <div className="fontw500" style={{ color: '#00D56E', fontSize: '18px' }}>DELTA: 4</div>
                                                    <span className="font14">Coverage model is under predicting </span>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />
                <IonLoading
                    isOpen={this.state.showLoading}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                    // duration={100}
                    animated={true}
                />
            </div >

        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComAnalytics);
// $(document).ready(function () {
//     $("#googlebox").hide()

//     setTimeout(() => {
//         console.log("first resize................................")
//         $("#googlebox").css('width', '99.9%');
//         $("#googlebox").show()
//     }, 50);
//     setTimeout(() => {
//         console.log("second resize............................")
//         $("#googlebox").css('width', '100%');
//         $("#googlebox").show()
//     }, 1000);
// })
