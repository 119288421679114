import React, { Component } from 'react'
import Back from '../assets/img/left-arrow.svg'
import BackIcon from '../assets/img/go-back-icon-img.png'
import Heat from '../assets/img/heaticon.png'
import geo from '../assets/img/target-icon.png'
import Lake from '../assets/img/lake.png'
import APMoreInfo from "./ap-more-info"
import closeIconImg from "../assets/img/close.png";
import Modal from './modal'
import "./access-point-details.css"
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import Delete from "../assets/img/delete-icon.png"
import edit from '../assets/img/edit.png'
import SearchIcon from '../assets/img/search_icon.png'
import heat from '../assets/img/heaticon.png'
import heatact from '../assets/img/heaticonactive.png'
import calcmob from '../assets/img/align-icon.png'
import { IonAlert, IonLoading, IonFabButton, IonFabList, IonIcon, IonFab, IonGrid, IonRow, IonCol } from '@ionic/react';
import measureIcon from '../assets/img/Measure-icon-img.png'
import addCPEIcon from '../assets/img/add-cpe-icon-img.png'
import heatIcon from '../assets/img/heaticon.png'
import addDASIcon from '../assets/img/add-cpe-icon-img.png'
import addAPIcon from '../assets/img/add-ap-icon-img.png'
import AP from '../assets/img/Apicon.png'
import { isBrowser, isMobile } from "react-device-detect";
import { requestAPICall } from "../service/apiHandler";
import store from '../store';
import { connect } from 'react-redux';
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from '../constants/actionTypes';
import Acpmob from './access-point-details-mob';
import Params from './parameters-mob'
import Network from './network-status-mob'
import apicon from '../assets/img/apicon.png'

const INITIAL_STATE = {
    card1: false,
    card2: false,
    show: true,
    alertbox: false,
    isLoading: false,
    assetAction: null,
    assetDetails: {},
    assetName: '',
    operationalStatus: '',
    latitude: 0,
    longitude: 0,
    imageSrc: '',
    height: 0,
    image: "",
    button: false,
    button1: false,
    button2: false,
    show_overlay: false,
    show_param_overlay: false,
    predictionsList: [],
    search_list: [],
    ap_cpe_list: [],
    lat_search: 0,
    lng_search: 0,
    bar: true,
    measureIcon: "",
    heatIcon: ""
};
var rad_center: any;
var lat: any;
var lng: any;
var addressPredictions: any = {}


interface Iprops {
    back: Function,
    //asset_details: any,
    addAP: any,
    addCPE: any,
    measure: any,
    enableAssetDrop: any,
    setCurrentPosition: any,
    calculate: any,
    set_values: any,
    set_subcard: any,
    ref: any,
    changeMapCenter: any,
    addDAS: any,
    updateSearchText: any,
    setAddress: any,
    next6: any;
    refreshAsset: Function,
    latlong_apcpe: any

}
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
    upadateAssetTypeId: (value: any) =>
        dispatch({ type: ASSET, key: 'assetTypeId', value }),
    updateNOS: (value: any) =>
        dispatch({ type: ASSET, key: 'NOS', value }),
    updateSectorId: (value: any) =>
        dispatch({ type: ASSET, key: 'sectorId', value }),
    updateAssetVendorId: (value: any) =>
        dispatch({ type: ASSET, key: 'assetVendorId', value }),
    updateCPEparams: (value: any) =>
        dispatch({ type: ASSET, key: 'CPEparams', value }),
    updateNetworkScreenType: (value: any) =>
        dispatch({ type: CURRENT_TAB, key: 'networkScreenType', value }),
    upadateSearchMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'asset_search', value }),
    upadateMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'coordinates', value }),
});

class APdetails extends Component<Iprops>{
    // export default class AddAP extends Component {
    state: any = {};
    props: any = {};
    autocompleteInput: any

    reduxStore: any = store.getState()['general'];
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.hideOverlay = this.hideOverlay.bind(this)
        this.assetdelete = this.assetdelete.bind(this)
        this.hideParamOverlay = this.hideParamOverlay.bind(this)
        this.hideNetworkOverlay = this.hideNetworkOverlay.bind(this)
        this.backToMap = this.backToMap.bind(this)
        this.autocompleteInput = React.createRef();
    }

    componentDidUpdate() {
        this.reduxStore = store.getState()['general'];

    }

    showModal1 = () => {
        this.setState({
            card1: true
        })
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    setShowLoading = (st: any) => {
        this.setState({ isLoading: st })
    }
    hideModal1 = () => {
        this.setState({
            card1: false
        })
    }
    showModal2 = () => {
        this.setState({
            card2: true
        })
        console.log(this.state.show)
    }
    hideModal2 = () => {
        this.setState({
            card2: false
        })
    }
    button_change = (e: any) => {
        this.setState({
            button: !this.state.button
        })
    }
    button1_change = (e: any) => {
        this.setState({
            button1: !this.state.button1
        })


    }
    button2_change = (e: any) => {
        this.setState({
            button2: !this.state.button2
        })
    }
    addAP = (e: any) => {
        e.preventDefault();
        this.props.addAP();
    }
    addCPE = (e: any) => {
        e.preventDefault();
        this.props.addCPE();
    }

    measure = (e: any) => {
        e.preventDefault();
        this.props.measure();
    }

    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }

    delete_asset() {
        let requestbody = {}
        this.setShowLoading(true)
        requestAPICall("delete_asset", { asset_id: this.reduxStore['assetId'] }, requestbody).then(data => {
            this.setShowLoading(false)
            if (data.responseCode == 200) {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
                this.hideModal2()
                this.hideModal1()
                setTimeout(() => {
                    this.props.set_subcard(null)
                    this.props.back()
                }, 500)

            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }
        })
    }
    hideOverlay() {
        console.log("hide overlay")
        this.props.set_subcard(null)
        this.setState({
            show_overlay: false
        })
        this.setPage(null)
    }
    hideParamOverlay() {
        console.log("hide overlay")
        this.props.set_subcard(null)
        this.setState({
            show_param_overlay: false
        })
        this.setPage(null)
    }
    hideNetworkOverlay() {
        console.log("hide overlay")
        this.props.set_subcard(null)
        this.setState({
            show_network_overlay: false
        })
        this.setPage(null)
    }
    listAssets = () => {
        this.props.back()

    }
    assetdelete() {
        this.props.next6()
    }
    backToMap() {
        this.props.refreshAsset(this.reduxStore['assetId'])
    }
    renderer() {
        switch (this.state.assetAction) {
            case 'parameter':

                return (

                    <div className="fixed-top" id="overlay" style={{
                        height: "100%",
                        display: this.state.show_param_overlay === true ? 'block' : 'none'
                    }}>
                        {this.state.show_param_overlay ? <Params hideParamOverlay={this.hideParamOverlay} assetInfo={this.state.assetDetails} backToAP={this.backToMap} /> : null}

                    </div>

                );
            case 'network': return (

                // <div className="fixed-top" id="overlay" style={{
                //     height: "100%",
                //     display: this.state.show_network_overlay === true ? 'block' : 'none'
                // }}>
                //     {this.state.show_network_overlay ? <Network hideNetworkOverlay={this.hideNetworkOverlay} /> : null}

                // </div>
                <Redirect to='/network-status-mob' />
            );
            case 'details':
                return (

                    <div className="fixed-top" id="overlay" style={{
                        height: "100%",
                        display: this.state.show_overlay === true ? 'block' : 'none'
                    }}>
                        {this.state.show_overlay ? <Acpmob getParticularAsset={() => this.get_asset_details()} hideOverlay={this.hideOverlay} deleteAssetConfrm={this.assetdelete} listAssets={this.listAssets} /> : null}

                    </div>

                );

            case 'map': return <Redirect from="/map" to="/map" />;
            default: return null;
        }
    }

    setPage = (page: any) => {
        // this.props.updateNetworkScreenType('detailed')
        localStorage.setItem('networkScreenType', 'detailed')
        console.log("====update network 2======");
        this.setState({ assetAction: page })
        if (page == 'details') {
            this.props.set_subcard(page)
            this.setState({
                show_overlay: true
            })
        }
        if (page == 'parameter') {
            this.props.set_subcard(page)
            this.setState({
                show_param_overlay: true
            })
        }
        if (page == 'network') {
            this.props.set_subcard(page)
            this.setState({
                show_network_overlay: true
            })
        }


    }
    addDAS = (e: any) => {
        e.preventDefault();
        this.props.addAP();
        // if (isMobile) {
        //     this.props.addDAS()
        // }
        // if (isBrowser) {
        //     this.props.history.push("/AddDAS")
        // }
    }
    get_asset_details = () => {
        console.log("get asset calling")
        console.log(this.reduxStore)
        let requestBody = {}
        this.setShowLoading(true)
        this.setState({ image: "" })
        requestAPICall("get_asset_details", { asset_id: this.reduxStore['assetId'] }, requestBody)
            .then(data => {
                console.log(data)

                this.setShowLoading(false)
                data.properties.map((data: any) => {
                    if (data.propertyName == "radiation_center") {
                        rad_center = data['value']

                    }
                    if (data.propertyName == "latitude") {
                        lat = data['value']

                    }
                    if (data.propertyName == "longitude") {
                        lng = data['value']
                    }
                })
                this.props.set_values(lat, lng, rad_center)
                //console.log(data.sector_id)
                if (data.responseCode == 200) {
                    this.setState({ assetDetails: data })
                    if (data.assetTypeId !== 1) {
                        this.props.updateCPEparams(data.installation_params)
                    }
                    this.props.updateSectorId(data.sector_id)
                    this.props.upadateAssetTypeId(data.assetTypeId)
                    this.props.updateAssetVendorId(data.assetVendorId)
                    data.properties.map((property: any) => {
                        if (property.propertyName === 'no_of_sectors') {
                            this.props.updateNOS(parseInt(property.value))
                        }
                        if (property.propertyName === 'image') {
                            this.setState({ image: property.value })
                        }
                        else if (property.propertyName === 'latitude') {
                            this.setState({ latitude: property.value })
                        }
                        else if (property.propertyName === 'longitude') {
                            this.setState({ longitude: property.value })
                        }
                        else if (property.propertyName === 'radiation_center') {
                            this.setState({ height: property.value })
                        }
                    })
                } else {
                    this.setState({
                        warnMessage: data.message,
                        alertbox: true
                    })
                }
            })
    }
    switch = () => {
        this.setState({
            bar: !this.state.bar,
            searches: "",
            ap_cpe_list: [],
            search_list: [],
            predictionsList: []

        }, () => {
            console.log('setting the switch....')
            if (this.state.bar == true) {
                console.log('setting null in switch')
                this.props.set_subcard(null)
            }
            else {
                console.log('setting search in switch....')
                this.props.set_subcard('search')
            }
            if (!this.state.bar) {
                this.list_asset()
            }
        })
    }
    onChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.list_asset_search(this.state.searches)
            console.log(this.state.searches)

        })
    }
    onSearch = (e: any) => {
        console.log(e.target.name, e.target.value, "ddfdf")
        this.setState({
            searches: e.target.value
        })
        console.log(this.autocompleteInput, "ggggggg")
        const predict = this.getQuery()
        this.setState({
            predictionsList: predict,
        })
        console.log(this.state.predictionsList, "list")
    }
    getQuery = () => {
        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
        return addressPredictions
    }
    predictionCallback = (predictions: any, status: any) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) return;

        addressPredictions = predictions;
    }
    list_asset_search = (searches: any) => {
        this.setState({
            search_list: this.state.asset_list.filter((asset: any) => asset.assetIdentifierId.toUpperCase().includes(this.state.searches.toUpperCase()))
        })

    }
    details = (details: any) => {
        this.setState({
            searches: details.assetIdentifierId,
        })
        var coordinates = {
            'latitude': details.latitude,
            'longitude': details.longitude

        }
        details['coOrdinates'] = coordinates
        this.setState({
            lat_search: details['latitude'],
            lng_search: details['longitude']
        }, () => {
            var locmark = true
            this.props.upadateSearchMapCoordinates({ "lat": parseFloat(details['latitude']), "long": parseFloat(details['longitude']) })
            this.props.upadateMapCoordinates({ "latitude": parseFloat(details['latitude']), "longitude": parseFloat(details['longitude']) })
            this.props.changeMapCenter(this.state.lat_search, this.state.lng_search)
            this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, details)
        })
    }
    setPlace = (value: any) => {
        this.setState({
            searches: value,
            bar: true
        }, () => {
            this.props.updateSearchText(this.state.searches)
            this.props.setAddress(value, true)
        })
    }
    list_asset = () => {
        let requestBody = {
        }
        requestAPICall("list_asset", {}, requestBody).then((data: any) => {
            var list: any = []
            data['assets'].forEach((element: any) => {
                if (this.reduxStore['tabType'] == 'outdoor') {
                    if (element['assetTypeId'] === 1 || element['assetTypeId'] === 3) {
                        list.push(element)
                    }
                }
                if (this.reduxStore['tabType'] == 'indoor') {
                    if (element['assetTypeId'] === 2 || element['assetTypeId'] === 5) {
                        list.push(element)
                    }
                }

            });
            this.setState({
                asset_list: list,
                ap_cpe_list: list
            })
            console.log(this.state.ap_cpe_list)
        })
    }
    render() {
        const isEnabled = this.state.button1 == false && this.state.button == false && this.state.button2 == false
        return (
            <div>
                {isBrowser ?
                    <div className='row webonly'>
                        <div className='col-md-5'>
                            <div className="card apcard">
                                <div className="card-body" style={{ padding: '0px' }}>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <img className='backicon' src={BackIcon} onClick={this.props.back} />
                                            <button type='button' className='backbutton' ></button>
                                            {/* <button type='button' className='backbutton material-icons' onClick={this.props.back}>keyboard_backspace </button> */}

                                        </div>
                                        <div className='col-md-9 m-r-m' style={{ padding: "13px 0" }}>
                                            <span className='apheader'>Access Point Details</span>
                                        </div>
                                    </div>
                                    <div className='row m-t-5' style={{ padding: "0px 35px", position: 'relative' }}>
                                        <span className=''><i className="fas fa-times imgclose" data-toggle="modal" data-target="#deleteimageModal" onClick={(e: any) => { e.preventDefault(); this.showModal2() }}></i></span>
                                        <img className='apimage' src={Lake} />
                                    </div>
                                    <div className='row ' style={{ padding: "0px 35px" }}>
                                        <span className='apheader' style={{ textTransform: 'none' }}>Piedmont Lake</span>
                                        <span className='distance'>. 270ft</span>
                                    </div>
                                    <div className='row ' style={{ padding: "0px 35px" }}>
                                        <div className='col-md-12' style={{ padding: '0 0 0px 0', borderBottom: '1px solid rgba(216, 216, 216, 0.34)' }}>
                                            <p className='apdetails'>
                                                This is the description for this access point which could be short or long.
                                                We will have to implement scrolling measures in the case it does get long.
                                    </p>
                                        </div>
                                    </div>
                                    <div className='row' style={{ padding: "0px 35px" }}>
                                        <div className='col-md-6 p-0'>
                                            <span className='distance' style={{ fontSize: '13px' }}>Status:</span>
                                            <span className='distance apinfo' style={{ color: '#E22B2B', paddingLeft: '5px' }}>Offline</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ fontSize: '13px' }}>ID:</span>
                                            <span className='distance apinfo' style={{ paddingLeft: '5px' }}>123456</span>
                                        </div>
                                    </div>
                                    <div className='row ' style={{ padding: "0px 35px" }}>
                                        <div className='col-md-6 p-0'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Latitude:</span>
                                            <span className='distance apinfo'>43.197167</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Longitude:</span>
                                            <span className='distance apinfo' >43.197167</span>                                </div>
                                    </div>
                                    <div className='row' style={{ padding: "0px 35px" }}>
                                        <div className='col-md-6 p-0'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Serial #:</span>
                                            <span className='distance apinfo'>123456</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Sector 1 ID #:</span>
                                            <span className='distance apinfo'>1</span>                                </div>
                                    </div>
                                    <div className='row ' style={{ padding: "0px 35px" }}>
                                        <div className='col-md-6 p-0'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Sector 2 ID #:</span>
                                            <span className='distance apinfo'>2</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Sector 3 ID #:</span>
                                            <span className='distance apinfo'>3</span>                                </div>
                                    </div>

                                    <div className='row ' style={{ padding: "0px 35px" }}>

                                        <i className="fas fa-pen editicon"></i>
                                        <button className='editbtn distance' style={{ color: '#0073FF' }}>Edit</button>

                                    </div>
                                    <div className='row m-t-5' style={{ padding: "0px 35px" }}>
                                        <i className="fas fa-trash deleteicon"></i>
                                        <button className='deletebtn distance' style={{ color: '#E22B2B' }} data-toggle="modal" data-target="#deleteModal" onClick={(e: any) => { e.preventDefault(); this.showModal1() }}>Delete AP</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4' >
                            <div className="col-md-12 justify-content-flex-end" style={{ display: 'none' }}>
                                <div className="heaticon text-center">
                                    <img className='heat' src={Heat} />
                                </div>
                            </div>

                            <div className="col-md-12 float-right">
                                <div className="geoicon text-center">
                                    <img style={{ padding: '5px' }} src={geo} />
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-4' >
                        <div className="heaticon text-center">
                            <img className='heat' src={heat} />
                        </div>
                    </div> */}

                        <div className='col-md-3' >
                            <APMoreInfo />
                        </div>
                    </div>

                    :
                    <div className='mobonly'>
                        {this.renderer()}
                        <div >
                            <div style={{ position: 'fixed', bottom: '270px', right: '0px' }}>
                                <IonFab horizontal="end" style={{ position: 'relative', display: 'none' }}>
                                    {this.state.heatMap ?
                                        <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }}>
                                            <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} />
                                        </IonFabButton>
                                        :
                                        <IonFabButton className='backg' disabled={!isEnabled} color="light" size="small" style={{ marginBottom: '10px' }}>
                                            <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} />
                                        </IonFabButton>
                                    }
                                </IonFab>
                                {this.state.bar ?
                                    <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' disabled={!isEnabled} color="light" size="small" style={{ marginBottom: '10px' }} onClick={() => this.setCurrentPosition()}>
                                            <img style={{ padding: '5px' }} src={geo} />
                                        </IonFabButton>
                                    </IonFab> : null
                                }
                                {this.state.bar ?
                                    <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' disabled={!isEnabled} color='light' size="small" style={{ marginBottom: '10px' }} onClick={this.props.calculate}>
                                            <img className='calcicon' src={calcmob} />
                                        </IonFabButton>
                                    </IonFab> : null
                                }
                                {this.state.bar ?
                                    <div className="" style={{ position: 'fixed', bottom: '231px', right: '0px' }}>
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color="light" onClick={this.switch} size="small">
                                                <img className='search-mob' src={SearchIcon} />
                                            </IonFabButton>
                                        </IonFab>
                                    </div>
                                    :
                                    <div className="dashbtn" style={{ bottom: "231px" }}>
                                        <div style={{ padding: '0px 7px', transition: '9s all ease' }}>
                                            <div>
                                                <img className='' style={{ position: "absolute", padding: "8px", filter: "brightness(0.5)", left: '7px' }} src={SearchIcon} />
                                            </div>
                                            <div>
                                                <span className="searchclose" onClick={this.switch}>
                                                    <span className="material-icons" style={{ margin: '7px', color: '#9c9c9c' }}> clear</span>
                                                </span>
                                            </div>
                                            <input name={this.state.searches} id="autocomplete" onChange={(e: any) => { this.onSearch(e); this.onChange(e) }} value={this.state.searches} className='searchnew' type='text' placeholder='Search Address' ref={this.autocompleteInput} />
                                            {!this.state.bar ?
                                                <div>
                                                    <div className=" searchcardmob" >
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                                            aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>ADDRESS</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                                            aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>CBSD/CPE</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="tab-content" id="searchTabContent">

                                                            <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">

                                                                {this.state.predictionsList.length > 0 ?
                                                                    <div className="card-body searchdetails" >
                                                                        {this.state.predictionsList.map((predict: any) => {
                                                                            return <div className='row' style={{ paddingRight: '18px' }}>
                                                                                <div className='col-2 p-r-10'>
                                                                                    <i className="fas fa-map-marker-alt searchcontenticon  float-right" style={{ fontSize: '20px' }}></i>
                                                                                </div>
                                                                                <div className='col-10 searchborder' style={{ padding: '10px 0px' }}>
                                                                                    <span onClick={() => this.setPlace(predict.description)} className='searchcontent'>{predict.description}</span><br />
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                    : null}
                                                            </div>

                                                            <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                                                {this.state.search_list.length > 0 ?
                                                                    <div className="card-body searchdetails" >
                                                                        {this.state.search_list.map((index: any) => (
                                                                            <div id="indexname" onClick={() => this.details(index)}>

                                                                                <div className='row m-t-10 col-12 p-r-0' >
                                                                                    {index.assetType === "AP-outdoor" ?
                                                                                        <div className='col-2'>
                                                                                            <img className='searchapicon' src={apicon} />
                                                                                        </div> :
                                                                                        <div className='col-2'>
                                                                                            <i className="far fa-circle searchcontenticon-mob "></i>
                                                                                        </div>}
                                                                                    <div className='col-10 searchborder'>
                                                                                        <div className="font14" style={{ color: '#353435' }}>{index.assetIdentifierId}</div>
                                                                                        <div className="font14" style={{ color: '#8F9FAC' }}>{index.name}</div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>))}
                                                                    </div> : <div className="card-body searchdetails" >
                                                                        {this.state.ap_cpe_list.map((index: any) => (
                                                                            <div id="indexname" onClick={() => this.details(index)}>

                                                                                <div className='row m-t-10 col-12 p-r-0'>
                                                                                    {index.assetType === "AP-outdoor" ?
                                                                                        <div className='col-2'>
                                                                                            <img className='searchapicon' src={apicon} />
                                                                                        </div> :
                                                                                        <div className='col-2'>
                                                                                            <i className="far fa-circle searchcontenticon-mob "></i>
                                                                                        </div>}
                                                                                    <div className='col-10 searchborder'>
                                                                                        <div className="font14" style={{ color: '#353435' }}>{index.assetIdentifierId}</div>
                                                                                        <div className="font14" style={{ color: '#8F9FAC' }}>{index.name}</div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>))}
                                                                    </div>}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <IonRow className="bottom-menu" style={{ bottom: '176px' }}>
                                {(this.reduxStore['tabType'] == "outdoor" && this.state.button == false) ?
                                    <IonCol size='4' style={{ padding: '5px' }}>
                                        <img className='cpeicon' src={addCPEIcon} style={{ width: '38px' }} />
                                        {this.state.button1 ?
                                            <button type='button' className='cardbutton p-l-25 ' style={{ background: '#61d76f', color: '#fff', border: "none" }} onClick={(e: any) => { this.button1_change(e); this.addCPE(e); this.props.enableAssetDrop(false) }}>Create</button>
                                            :
                                            <button type='button' className='cardbutton p-l-25 ' onClick={(e: any) => { this.button1_change(e); this.props.enableAssetDrop(true) }} >Add CPE</button>
                                        }
                                    </IonCol>
                                    :
                                    (this.reduxStore['tabType'] == "outdoor") ?
                                        <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            <img className='cpeicon' src={addCPEIcon} style={{ width: '38px', filter: "brightness(0) invert(0.9)" }} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} disabled >Add CPE</button>

                                        </IonCol> : null}

                                {this.state.button1 == false ?
                                    < IonCol size='4' style={{ padding: '5px' }}>
                                        {this.state.button ? <img className='apicon' src={AP} style={{ filter: "brightness(0) invert(0.9)" }} /> : <img className='apicon' src={AP} />}
                                        {this.state.button ?
                                            <button type='button' className='cardbutton p-l-25 ' style={{ background: '#0372fb', color: '#fff', border: "none" }} onClick={(e: any) => { this.button_change(e); this.addAP(e); this.props.enableAssetDrop(false) }}>Create</button>
                                            :
                                            <button type='button' className='cardbutton p-l-25 ' onClick={(e: any) => { this.button_change(e); this.props.enableAssetDrop(true) }}>Add CBSD</button>
                                        }
                                    </IonCol>
                                    :
                                    < IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        <img className='apicon' src={AP} style={{ filter: "brightness(0) invert(0.9)" }} />
                                        <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} disabled>Add CBSD</button>
                                    </IonCol>
                                }


                                {this.reduxStore['tabType'] == "indoor" && this.state.button1 == false ?
                                    <IonCol size='4' style={{ padding: '5px' }}>
                                        <img className='dasicon' src={addDASIcon} style={{ width: '38px' }} />
                                        {this.state.button2 ?
                                            <button type='button' className='cardbutton p-l-25 ' style={{ background: '#61d76f', color: '#fff', border: "none" }} onClick={(e: any) => { this.button2_change(e); this.addDAS(e); this.props.enableAssetDrop(false) }}>Create</button>
                                            :
                                            <button type='button' className='cardbutton p-l-25 ' onClick={(e: any) => { this.button1_change(e); this.props.enableAssetDrop(true) }} >Add DAS</button>
                                        }
                                    </IonCol>
                                    :
                                    this.reduxStore['tabType'] == "indoor" ?
                                        <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            <img className='cpeicon' src={addDASIcon} style={{ width: '38px', filter: "brightness(0) invert(0.9)" }} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} disabled >Add DAS</button>

                                        </IonCol> : null}



                                {this.reduxStore['tabType'] == "outdoor" && (this.state.button == true || this.state.button1 == true) ?
                                    <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        <img className='measureiconmob' src={measureIcon} style={{ filter: "grayscale(1)" }} />
                                        <button type='button' className='cardbutton p-l-25' style={{ border: "none" }} disabled onClick={this.measure}>Measure</button>
                                    </IonCol>
                                    :
                                    this.reduxStore['tabType'] == "outdoor" ?
                                        <IonCol size='4' style={{ padding: '5px' }}>
                                            <img className='measureiconmob' src={measureIcon} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} onClick={this.measure}>Measure</button>
                                        </IonCol> : null
                                }

                                {this.reduxStore['tabType'] == "indoor" && (this.state.button == true || this.state.button1 == true) ?
                                    <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        <img className='measureiconmob' src={heatIcon} style={{ filter: "grayscale(1)" }} />
                                        <button type='button' className='cardbutton p-l-25' style={{ border: "none" }} disabled >Heat</button>
                                    </IonCol>
                                    :
                                    this.reduxStore['tabType'] == "indoor" ?
                                        <IonCol size='4' style={{ padding: '5px' }}>
                                            <img className='measureiconmob' src={heatIcon} />
                                            <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} >Heat</button>
                                        </IonCol> : null
                                }





                            </IonRow>
                            {/* <div className='col-12 p-0'> */}
                            <div className="card apinfocard bottom-actions" style={{ position: 'fixed' }}>
                                <div className="card-body apinfobody" >
                                    <div className="row  m-t-10">
                                        <div className="col-3 p-r-0" style={{ paddingRight: '4px' }}>
                                            {
                                                this.state.image !== "" && this.state.image !== undefined ? <img className='apimage'  src={this.state.image} /> : <div style={{marginTop:'50px', textAlign:"center"}}> <div className="font14" style={{textAlign:"center"}}>No Image</div> <i className="fa fa-picture-o" ></i> </div>
                                            }
                                        </div>
                                        <div className="col-9 p-r-0">
                                            <span className='apheader font17' style={{ textTransform: 'none' }}>{this.state.assetDetails['assetIdentifierId']}</span>
                                            {/* <span className='distance' style={{ color: '#6b6d71', fontSize: '12px' }} ><span className='dot'></span> </span> */}
                                            <div className="p-l-10" style={{ display: 'inline-block' }}>
                                                <span className='distance apinfo' style={{ fontSize: '16px' }}>Status: </span>
                                                <span className='distance apinfo' style={{ color: '#E22B2B', paddingLeft: '5px',fontSize: '16px'  }}>{this.state.assetDetails['operationalStatus']}</span>
                                            </div>
                                            <div className='col-12 p-0 m-t-5'>
                                                <span className=''>
                                                    <span className='distance apinfo' style={{ fontSize: '16px' }}>Height : </span>
                                                    <span className='distance' style={{ color: '#6b6d71', fontSize: '14px' }}>{this.state.height}ft</span>
                                                </span>
                                            </div>
                                            <div className='col-12 p-0 m-t-5'>
                                                <span >
                                                    <span className='distance apinfo' style={{ fontSize: '16px' }}>Lat: </span>
                                                    <span className='distance  ' style={{ color: '#6b6d71', fontSize: '14px' }}>{this.state.latitude}</span>
                                                </span>
                                                <span className='p-l-10'>
                                                    <span className='distance apinfo' style={{ fontSize: '16px' }}>Long: </span>
                                                    <span className='distance  ' style={{ color: '#6b6d71', fontSize: '14px' }} >{this.state.longitude}</span>
                                                </span>
                                            </div>

                                            <div className='col-12 p-0 m-t-10'>
                                                <div className="aptoggle" onClick={() => { this.setPage('parameter') }}>
                                                    <i className="fas fa-cog font21"></i>
                                                </div>
                                                <div className='aptoggle' onClick={() => { this.setPage('network') }}>
                                                    <i className="fas fa-wifi font19"></i>
                                                </div>
                                                <div className='apedit col-4' onClick={() => { this.setPage('details') }}>
                                                    {/* <i className="fas fa-pen"></i> */}
                                                    <img style={{ paddingBottom: '5px', width: '17px' }} src={edit} />
                                                </div>
                                                {/* <div className='apdel' data-toggle="modal" data-target="#deleteModal" onClick={(e: any) => { e.preventDefault(); this.showModal1() }}>
                                                    <img style={{ paddingBottom: '5px', width: '17px' }} src={Delete} />
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        {/* <div className='col-md-3' >
                        <APMoreInfo />
                    </div> */}
                    </div>
                }

                <Modal show={this.state.show && this.state.card1} handleClose={this.hideModal1}>
                    {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px' }}>CONFIRMATION</h6>
                                    <i className="material-icons modalclose imgclose-file" onClick={(e: any) => { e.preventDefault(); this.hideModal1() }} data-dismiss="modal" aria-label="Close">clear</i>
                                </div>
                                <div className='row m-t-20 text-center justify-content-center' style={{ padding: '0 10px' }}>
                                    <span className='modaltext'>Are you sure you want to delete this CBSD?</span>
                                </div>
                                <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                    <div className='col-md-6 col-6 p-r-5'>
                                        <button className='lightbtnmob modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal1() }}>No</button>
                                    </div>
                                    <div className='col-md-6 col-6 p-l-5'>
                                        <button className='btn modalbtn' onClick={() => { this.delete_asset() }}>Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <Modal show={this.state.show && this.state.card2} handleClose={this.hideModal2}>
                    {/* <div className="modal fade" id="deleteimageModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px' }}>CONFIRMATION</h6>
                                    <i className="material-icons modalclose imgclose-file" onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} data-dismiss="modal" aria-label="Close">clear</i>
                                </div>
                                <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                    <span className='modaltext'>Are you sure you want to remove this photo?</span>
                                </div>
                                <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                    <div className='col-md-6 p-0'>
                                        <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal2() }}>No</button>
                                    </div>
                                    <div className='col-md-6 p-0'>
                                        <button className='btn modalbtn' >Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />
                <IonLoading
                    isOpen={this.state.isLoading}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                    duration={2000}
                // animated={true}
                />
            </div>
        );
    }
}

//export default (APdetails);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(APdetails);
// export default withRouter(APdetails);
