import React from 'react';
import store from './store';
import { Provider } from 'react-redux';

import { Redirect, Route, HashRouter } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import Home from './pages/Home';
import './App.css'
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot from './pages/Forgot'
import Outdoor from './pages/outdoor'
import Indoor from './pages/indoor'
//import Heatmap from './pages/heatmap'
import Snackbar from './pages/Snackbar'
import Settings from './pages/settings';
import CSettings from './pages/CompanySettings';
import APParameters from './pages/ap-paramaters';
import Profile from './pages/profile-info';
import params from './pages/parameters-mob';
import network from './pages/network-status-mob';
import Acpmob from './pages/access-point-details-mob';
import ComAnalytics from './pages/companyanalytics';
import NetworkActivity from './pages/network-activity'
import AddAP from './pages/AddAP';
import AddCPE from './pages/AddCPE';
import BuildingInfo1 from './pages/BuildingInfo1';
import BuildingInfo2 from './pages/BuildingInfo2';
import Loginqr from './pages/loginqr'
import { Component } from 'react';
import Cookies from 'js-cookie';
import { requestAPICall } from "./service/apiHandler";
import Calculator from "./pages/calculator"
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Dashboard from './pages/dashboard';
import Header from './pages/header';
import Header1 from './pages/Header1';
import Analytics from './pages/analytics';
import Blank from './pages/blank';
import forgotChange from './pages/forgotChange';
import AppSettings from './pages/app-settings';
import NetworkStatus from './pages/network-status';
import search from './pages/search';
import Notification from './pages/notification-mob'
import AddCPE2 from './pages/AddCPE2';
import AddCPE3 from './pages/AddCPE3';
import AddAP2 from './pages/AddAP2';
import AddAP3 from './pages/AddAP3';
import { isMobile } from 'react-device-detect';
import { setupConfig } from '@ionic/react'
import DASnode from './pages/DASnode';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications } = Plugins;
const INITIAL_STATE = {
  remember_me: false,
  alertbox: false
};
// export const store = createStore(rootReducer)

// const App: React.FC = () => (
export default class App extends Component {
  state: any = {};
  props: any = {};

  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    // REGISTER FOR MOBILE PUSH NOTIFICATION
    this.pushSetup();
    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
      console.log("Found Safari")
      localStorage.setItem("browser", "Safari")
    }
  

  }
  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  pushSetup() {
    PushNotifications.register();

  }

  componentDidMount() {
   
  }


  render() {
    console.log(ScreenOrientation.type); // logs the current orientation, example: 'landscape'
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    setupConfig({
      hardwareBackButton: false
    })

    return (
      <IonApp>
        <Provider store={store}>

          <HashRouter>
            <IonRouterOutlet animated={false}>
              {/* {isMobile ?
                <Route path="/calculator" exact={true} render={() => <Calculator />} />
                : <Route path="/home" exact={true} component={Home} />} */}
              {/* {isMobile ? <Route path="/home" exact component={Home} /> : <Route exact path="/" render={() => <Redirect to="/home" />} />} */}
              <Route path="/home" exact={true} component={Home} />
              <Route path="/login" component={Login} exact={true} />
              <Route path="/register" component={Register} exact={true} />
              <Route path="/forgot" component={Forgot} exact={true} />
              <Route path="/map/:topicId/:functionality/page" component={Header} />
              <Route path="/map" component={Header} />
              <Route path='/buildingInfo1' component={BuildingInfo1} exact={true} />
              <Route path='/buildingInfo2' component={BuildingInfo2} exact={true} />
              <Route path='/analytics' component={Analytics} exact={true} />
              <Route path="/snack" component={Snackbar} exact={true} />
              <Route path='/network-status' component={NetworkStatus} exact={true} />
              <Route path='/addAP/assetType/:assetTypeId' component={AddAP} exact={true} />
              <Route path='/addAP/assetType/:assetTypeId' component={AddAP} exact={true} />
              <Route path='/addAP2' component={AddAP2} exact={true} />
              <Route path='/addAP3' component={AddAP3} exact={true} />
              <Route path='/addCPE' component={AddCPE} exact={true} />
              <Route path='/addCPE2' component={AddCPE2} exact={true} />
              <Route path='/addCPE3' component={AddCPE3} exact={true} />
              <Route path='/addCPE3' component={AddCPE3} exact={true} />
              <Route path='/addDAS' component={DASnode} exact={true} />
              <Route path="/company-settings" component={CSettings} exact={true} />
              <Route path="/parameters-mob" component={params} exact={true} />
              <Route path="/network-status-mob" component={network} exact={true} />
              <Route path="/access-point-details-mob/asset/:id" component={Acpmob} exact={true} />
              <Route path="/access-point-details-mob" component={Acpmob} exact={true} />
              <Route path="/search" component={search} exact={true} />
              <Route path="/param" component={APParameters} exact={true} />
              <Route path="/company-analytics" component={ComAnalytics} exact={true} />
              <Route path="/app-settings" component={AppSettings} exact={true} />
              <Route path="/notification-mob" component={Notification} exact={true} />
              <Route path="/profile" component={Profile} exact={true} />
              <Route path="/token/:token" component={Blank} exact={true} />
              <Route path="/forgot-password" component={forgotChange} exact={true} />
              <Route path="/network-activity" component={NetworkActivity} exact={true} />
              <Route path="/configure" component={Loginqr} exact={true} />
              <Route path="/calculator" component={Calculator} exact={true} />
              <Route exact path="/" render={() => <Redirect to="/home" />} />
            </IonRouterOutlet>
          </HashRouter>
        </Provider>
      </IonApp>


    );
  }
}
// export default App;
