import React, { useEffect } from 'react'
import { Chart } from 'react-google-charts'
import { relative } from 'path';
import mark from '../assets/img/screenshot.png'
import { GoogleMap, Marker } from '@react-google-maps/api'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AP from '../assets/img/Apicon.png'
import APSec1 from '../assets/img/blue_leaf_1.png'; import APSec2 from '../assets/img/blue_leaf_2.png';
import APSec3 from '../assets/img/blue_leaf_3.png'; import APSec4 from '../assets/img/blue_leaf_4.png';
import APSec5 from '../assets/img/blue_leaf_5.png'; import APSec6 from '../assets/img/blue_leaf_6.png';
import CPEMap from '../assets/img/CPE_ICON.png'
import APMap from '../assets/img/add-cpe-icon-img-blue.png'
import DASMap from '../assets/img/dark-pink_leaf_1.png'
import AP1DGray from '../assets/img/status-img/access-point-1.png'; import AP2DGray from '../assets/img/status-img/access-point-2.png';
import AP3DGray from '../assets/img/status-img/access-point-3.png'; import AP4DGray from '../assets/img/status-img/access-point-4.png';
import AP5DGray from '../assets/img/status-img/access-point-5.png'; import AP6DGray from '../assets/img/status-img/access-point-6.png';
import CPEDGray from '../assets/img/status-img/cpe-dark-gray.png';
import 'bootstrap-daterangepicker/daterangepicker.css';
import checkSuccess from '../assets/img/check-success.png';
import filter from '../assets/img/down-arrow.svg'
import wifi from '../assets/img/wifi.png'
import calendar from '../assets/img/calendar.png'
import Calculate from './calculate';
import sort from '../assets/img/checked.png'
import office from '../assets/img/ShopsatColumbus1.png'
import { IonTextarea, IonPage, IonContent, IonLoading, IonProgressBar, IonAlert } from '@ionic/react';
import Modal from './modal'
import { requestAPICall } from "../service/apiHandler";
import { start } from 'repl';
import store from '../store';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { UPDATE_FIELD_AUTH, CURRENT_TAB } from '../constants/actionTypes';
var sector_property: any = []
var cbsd_list: any = []
var installation_list: any = []
var optional_list: any = []
var tickList: any = [];
var status_list: any = []

var installParamArray: any = {}
let checked: any = false
let checkList: any = {}
let center = {
    lat: 38.9072, lng: -77.0369
}
const styles = require('../assets/GoogleMapStyle.json')
var locations = [
    new window.google.maps.LatLng(13.08, 79.20),
    new window.google.maps.LatLng(13.1832, 78.30),
    new window.google.maps.LatLng(11.38432, 79.40),
    new window.google.maps.LatLng(11.17768, 78.34),
    new window.google.maps.LatLng(12.4645, 77.70),
    new window.google.maps.LatLng(13.0353, 88.2037),
    new window.google.maps.LatLng(12.178678, 79.0335),
]
var locations2 = [
    new window.google.maps.LatLng(11.065984, 78.30),
    new window.google.maps.LatLng(13.3754, 80.1045),
    new window.google.maps.LatLng(13.2876, 80.2053),
    new window.google.maps.LatLng(12.15867, 77.2041),
    new window.google.maps.LatLng(11.09323, 78.2039),
    new window.google.maps.LatLng(12.586566, 79.2037),
    new window.google.maps.LatLng(11.03527, 77.0035)
]
var markerIcon1 = {
    url: require('../assets/img/circle.svg'),
    scaledSize: new window.google.maps.Size(10, 10)
}
var markerIcon2 = {
    url: require('../assets/img/Apicon.png'),
    scaledSize: new window.google.maps.Size(25, 25)
}
const mapStateToProps = (state: any) => ({ ...state.auth });
const mapDispatchToProps = (dispatch: any) => ({
    upadatePendingNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'pendingNotificationCount', value }),
    upadateRejectedNotificationCount: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'rejectedNotificationCount', value }),
    clickNotify: (value: any) =>
        dispatch({ type: UPDATE_FIELD_AUTH, key: 'notification', value }),
});
class NetworkActivity extends React.Component {

    state: any = {};
    props: any = {};
    myRef: any = null;

    reduxStore: any = store.getState()['auth'];
    constructor(props: any) {

        super(props)

        this.myRef = React.createRef()
        this.handleChange = this.handleChange.bind(this)
        this.status_hideModal = this.status_hideModal.bind(this)
        this.state = {
            showActivityLoading: true,
            show: false,
            dmpList: [],
            sasList: [],
            sector_id: [],
            cpe_list: [],
            assetList: [],
            image_source: "",
            cbsd_list: [],
            a: 0,
            b: 0,
            approveParameterId: "",
            approve: false,
            files: [],
            aval: 0,
            bval: 0,
            categoryA: 0,
            categoryB: 0,
            categoryCBSD: 0,
            categoryAPercent: 0,
            categoryBPercent: 0,
            categoryCPSDPercent: 0,
            outdoorCount: 0,
            indoorCount: 0,
            assetsList: [],
            assettableList: [],
            assetActivityList: [],
            assetClickId: '',
            progressData: [],
            deploymentData: [],
            vendorcount: [],
            total_vendor_count: 0,
            vendorList: [],
            all_user_list: [],
            start_date: "",
            end_date: "",
            installer: "",
            asset_type: "",
            status_for: [2, 4, 3, 5, 6, 1],
            selected_vendor_id: 0,
            offset_days: 5,
            vendor_id: 2,
            allinstaller: true,
            cpiPassword: "",
            installername: "",
            rejectParameterId: "",
            reason: "",
            netfileter: "Technology Filter",
            instalationfilter: "All Types",
            isApproved0: false,
            isApproved1: false,
            isApproved2: false,
            isApproved3: false,
            installationParam: {},
            certificateSubmitted: false,
            status_show: false,
            serial_number: "",
            value: [],
            p12cert: [],
            isAllSelected: false,
            defaultProviderId: ""
        }

    }
    componentDidUpdate() {
        this.reduxStore = store.getState()['auth']
        if (this.reduxStore['notification'] === true) {
            this.scrollToRef()
            this.reduxStore['notification'] = ""
        }


    }
    componentDidMount() {
        this.reduxStore = store.getState()['auth']
        console.log(this.props)
        tickList = []
        this.getDefaultSettings()
        this.getUserProfile()
        this.list_all_network_Activity()

    }
    list_all_network_Activity() {
        this.setState({ showActivityLoading: true })
        this.list_network_Activity()
    }
    handleChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }
    scrollToRef = () => {

        const element = document.getElementById('activitytab');
        console.log(element?.scrollIntoView())

    }
    getDefaultSettings() {
        requestAPICall("get_default_setings", {}, {}).then(data => {
            if (data.responseCode === 200) {
                this.setState({
                    defaultProviderId: data.defaultProviderId,
                })

            }
        })
    }
    getUserProfile() {
        console.log("GET USER PROFILE============")
        let userList: any = {}
        requestAPICall("get_user_profile", {}, {}).then(data => {
            if (data.responseCode === 200) {
                console.log(data)
                userList = data['user_list']

                var image = userList['userImage']
                this.setState({
                    cpiId: userList["cpiId"],
                    cpiName: userList["cpiName"]
                })
                if (userList["isSubmitted"] == true) {
                    this.setState({
                        certificateSubmitted: true
                    })

                }
            }
        })
        console.log(this.state.cpiId)
        console.log(this.state.cpiName)
    }
    list_network_Activity() {

        var assetsList: any = []
        requestAPICall("list_network_activity", {}, {})
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.setState({ showActivityLoading: false })
                    this.setState({ assetActivityList: data.assets })
                    console.log(this.state.assetActivityList)
                } else {
                    this.setState({ showActivityLoading: false })
                }

            })

    }
    status_hideModal() {
        this.setState({
            status_show: false,

        })
        this.list_network_Activity()
    }

    rejectParameter() {
        let requestBody = {
            reason: this.state.reason,
            parameterStatus: "Rejected by CPI",
            parameterDescription: "Rejected by CPI"

        }

        requestAPICall("reject_parameter", { parameter_id: this.state.rejectParameterId }, requestBody)
            .then(data => {
                console.log(data)
                if (data.responseCode == 200) {
                    this.hideModal()
                    this.closeNotification(this.state.rejectParameterId)
                    this.list_network_Activity()
                }


            })
    }


    closeNotification = (parameterId: any) => {

        let reqBody = {
            'is_parameter': true
        }
        console.log(reqBody)
        requestAPICall("close_notification", { parameter_id: parameterId }, reqBody).then(data => {
            if (data.responseCode == 200) {
                this.props.clickNotify(true)
                this.getNotification()
                this.list_network_Activity()
            }



        })
    }
    getNotification() {
        let requestBody = {}
        requestAPICall("get_notification_count", {}, requestBody).then(data => {
            console.log(data)
            if (data.responseCode == 200) {
                this.props.upadatePendingNotificationCount(data['pendingNotificationCount'])
                this.props.upadateRejectedNotificationCount(data['rejectedNotificationCount'])
            }

        })
    }
    showModal = () => {
        console.log("==========================")
        this.setState({
            show: true,

        })

    }
    rejectshowModal = () => {
        this.setState({
            show: true,

        })

    }
    setInstallationparam = (installParam: any, val: any) => {
        $("#heightImage").removeClass("show");
        $("#downtiltImage").removeClass("show");
        $("#azimuthImage").removeClass("show");
        console.log("==========================", installParam)
        this.setState({
            installationParam: installParam,
            view: true,

        })
        if (val.assetTypeId === 3 || val.assetTypeId === 2 || val.assetTypeId === 4) {
            this.setState({
                serial_number: val.serial_id
            })
        } else {
            this.setState({
                serial_number: installParam['sectorSerial']
            })
        }
        installParamArray = installParam
        console.log(installParamArray)

    }

    showModalApprove = () => {
        console.log("==========================")
        this.setState({
            approve: true,

        })
        this.getUserProfile()

    }
    onCheckBoxChange = (checkName: any, isChecked: any) => {
        console.log(checkName, isChecked)
        let isAllChecked = checkName === "all" && isChecked;
        let isAllUnChecked = checkName === "all" && !isChecked;
        checked = isChecked;
        if (isAllChecked) {
            tickList = []
        }
        checkList = this.state.assetActivityList.map((act: any, index: any) => {
            console.log(act)
            var array: any = {}
            array['category'] = act.installationProperty.CBSDCategory
            array['parameterId'] = act.parameterId
            if (act.assetTypeId == 1) {
                array['sectorName'] = act.installationProperty.sectorId
            }
            if (checked && !isAllChecked && act.parameterId === checkName) {
                console.log("========BBBBB")
                tickList.push(array)
            }
            if (!checked) {
                const indiceABorrar = tickList.findIndex((i: any) => {
                    return (i.parameterId === checkName);
                });
                console.log(indiceABorrar)
                if (-1 != indiceABorrar) {
                    tickList.splice(indiceABorrar, 1);
                    console.log("borrado con exito");
                }
            }
            if ((isAllChecked || act.parameterId === checkName) && act.submitStatusId === 12) {
                if (isAllChecked) {
                    console.log("========AAAAAAAAA")
                    tickList.push(array)
                }
                return Object.assign({}, act, {
                    checked
                });
            } else if (isAllUnChecked && act.submitStatusId === 12) {
                console.log("eeeeeeeeeeeeeee")
                tickList = []
                return Object.assign({}, act, {
                    checked: false
                });
            }


            return act;
        });


        let isAllSelected = checkList.findIndex((item: any) => item.checked === false && item.submitStatusId === 12) === -1 || isAllChecked;
        console.log(isAllSelected)
        this.setState({
            assetActivityList: checkList,
            isAllSelected
        });
        console.log(tickList)
        if (tickList.length == 0) {
            this.setState({
                isAllSelected: false
            })
        }
    }
    handleCheckChange = (e: any, val: any, index: any) => {
        console.log(val.parameterId, e.target.checked)
        var array: any = {}
        array['parameterId'] = val.parameterId
        // array['deviceStatus'] = val.submitStatusId
        array['category'] = val.installationProperty.CBSDCategory
        if (e.target.checked) {
            let value = this.state.value.slice();
            value[val.parameterId] = !value[val.parameterId];
            this.setState({ value })
            tickList.push(array)
        } else {
            let value = this.state.value.slice();
            value[val.parameterId] = !value[val.parameterId];
            this.setState({ value })
            const indiceABorrar = tickList.findIndex((i: any) => {
                return (i.parameterId === array['parameterId']);
            });
            if (-1 != indiceABorrar) {
                tickList.splice(indiceABorrar, 1);
                console.log("borrado con exito");
            }


        }

        console.log(tickList)

    }

    rejectParam = (parameter_id: any) => {
        console.log(parameter_id)
        this.setState({
            rejectParameterId: parameter_id
        })

        console.log(this.state.rejectParameterId)
    }

    approveParam = (parameter_id: any) => {
        console.log(parameter_id)
        this.setState({ showActivityLoading: true })

        let requestBody = {
            reason: "",
            parameterStatus: "Approved by CPI",
            parameterDescription: "Approved by CPI",

        }

        requestAPICall("approve_parameters", { parameter_id: parameter_id }, requestBody)
            .then(data => {
                console.log(data)
                this.setState({ showActivityLoading: false })
                if (data.responseCode == 200) {
                    this.closeNotification(parameter_id)
                    this.list_network_Activity()
                    this.setState({
                        warnMessage: data.message,
                        alertbox: true,
                    })
                } else {
                    this.setState({
                        warnMessage: data.message,
                        alertbox: true,
                    })
                }
            })

    }

    hideModal = () => {
        this.setState({
            show: false,
            reason: "",
            approve: false,
            view: false,
            cpiId: "",
            cpiName: "",
            files: [],
            p12cert: [],
            override_cpi: false,

        })
    }
    propretycall = (val: any, assetClickId: any) => {
        console.log(val.assetId)
        this.setState({
            asset_id: val.assetId,
            asset_type_id: val.assetTypeId,
            assetClickId: assetClickId
        })
        // () => this.get_asset_details(val.assetId, ""))
    }
    assetDetail = (e: any, asset: any, text: any) => {
        console.log(asset);
        this.setState({
            "selectedAssetId": asset.assetId
        })
        this.get_asset_details(asset.assetId, text)
    }




    get_asset_details = (selectedAssetId: any, text: any) => {

        let requestBody = {}
        this.setState({
            sector_id: [], cpe_list: []
        })
        installation_list = [];
        optional_list = [];
        cbsd_list = [];
        var cpe_list = [];
        requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody)
            .then(data => {
                console.log(installation_list, optional_list, cbsd_list)
                var resp = data['properties']



                resp.forEach((element: any) => {
                    if (element['dataType'] === "Image") {
                        this.setState({
                            image_source: element['value'],
                            asset_type_id: data['assetTypeId']

                        })

                    }

                });

                if (data['assetTypeId'] === 3 || data['assetTypeId'] === 2 || data['assetTypeId'] === 4) {

                    installation_list = data["installation_params"]["installation"]
                    optional_list = data["installation_params"]["optional"]
                    cbsd_list = data["installation_params"]["cbsd"]

                    this.setState({
                        cpe_list: data['properties']

                    })

                    cpe_list = data['properties']

                }

                else {
                    this.setState({
                        sector_id: data['sector_id']

                    })
                    this.get_sector_properties(this.state.sector_id[0]["sector_id"])
                }


            })
    }


    get_sector_properties = (sector_id: any) => {
        this.setState({ selectedSector: sector_id })
        requestAPICall("get_sector_details", { asset_type_id: this.state.asset_type_id, asset_id: this.state.asset_id, sector_id: this.state.selectedSector }, {}).then(data => {
            if (data.responseCode == 200) {

                installation_list = data["properties"]["installation"]
                optional_list = data["properties"]["optional"]
                cbsd_list = data["properties"]["cbsd"]
            }
            this.setState({
                showvalue: true
            })
        })
    }



    setShowLoading = (st: Boolean) => {
        this.setState({ showLoading: st })
    }


    cpiSaveCahnge = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    onDrop = (files: any, type: any) => {
        this.setState({ files: files })
        if (type === "p12") {
            this.setState({
                p12cert: files
            })

        }
    }
    savePrameters = () => {
        console.log(tickList)

        this.setState({ showActivityLoading: true })
        let requestBody: any = {}
        if (this.state.override_cpi == true) {
            requestBody = {
                "parameterDescription": "Send to SAS",
                "parameterStatus": "Submitted",
                "parameterIdList": tickList
            }
            if (this.state.p12path != "" && this.state.cpiPassword != "") {
                requestBody['fileSubmission'] = true
            }
            this.submitToSAS(requestBody)
        }
        else {
            requestBody = {
                "parameterDescription": "Send to SAS",
                "parameterStatus": "Submitted",
                "cpiId": this.state.cpiId,
                "cpiName": this.state.cpiName,
                "registeredCpi": this.state.registeredCpi,
                "parameterIdList": tickList,
                "fileSubmission": true,
                "p12": "",
                "p12Password": ""


            }
            if ((this.state.p12path == "" || this.state.p12path == undefined) && this.state.cpiPassword == "") {
                requestBody["fileSubmission"] = false
            }
            if (this.state.p12cert.length == 0) {
                this.submitToSAS(requestBody)
            }
        }
        if (this.state.p12cert.length > 0) {
            console.log(this.state.p12cert[0].name)
            var picReader = new FileReader();
            let filerequestBody = {}
            let fileName = this.state.p12cert[0].name
            let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
            picReader.onload = (e) => {
                filerequestBody = {
                    'fileName': fileName,
                    'fileExtension': fileExt,
                    'fileValue': picReader.result
                }
                requestAPICall("upload_file", {}, filerequestBody).then(data => {
                    console.log(data)
                    requestBody['p12'] = data.file_path
                    requestBody['p12Password'] = this.state.cpiPassword
                    this.submitToSAS(requestBody)

                })
            }
            picReader.readAsDataURL(this.state.p12cert[0]);

        }
    }
    submitToSAS = (requestBody: any) => {
        console.log(requestBody)
        requestAPICall("bulk_submit_parameters", {}, requestBody)
            .then(data => {
                this.setState({ showActivityLoading: false })
                console.log(data)
                if (data.hasOwnProperty('responseList')) {
                    status_list = data.responseList
                    this.setState({
                        status_show: true
                    })
                    if (data.responseCode == 200) {
                        tickList = []
                        this.setState({ value: [], isAllSelected: false })

                    }


                } else {

                    this.setState({
                        warnMessage: data.message,
                        alertbox: true,
                    })


                }
            })
    }

    is_override_cpi(e: any) {
        console.log(e.target.checked)
        this.setState({
            override_cpi: e.target.checked
        })

    }

    sortByValueAsc = (key: any) => {
        let sortedProductsAsc;
        sortedProductsAsc = this.state.assetActivityList.sort((a: any, b: any) => {
            return parseInt(a.key) - parseInt(b.key);
        })
        let asset_list = this.asecnding(key)
        this.setState({
            assetActivityList: sortedProductsAsc
        })
        console.log(this.state.assetActivityList)
    }
    sortByValueDesc = (key: any) => {
        let sortedProductsDesc;
        sortedProductsDesc = this.state.assetActivityList.sort((a: any, b: any) => {
            return parseInt(a.key) - parseInt(b.key);
        })
        this.setState({
            assetActivityList: sortedProductsDesc
        })
        let list = this.decending(key)
        console.log(this.state.assetActivityList)
    }

    asecnding = (key: any) => {
        return this.state.assetActivityList.sort(function (a: any, b: any) {
            if (a[key] > b[key]) {
                return 1;
            }
            if (b[key] > a[key]) {
                return -1;
            }
            return 0;
        })
    }
    decending = (key: any) => {
        return this.state.assetActivityList.sort(function (a: any, b: any) {
            if (b[key] > a[key]) {
                return 1;
            }
            if (a[key] > b[key]) {
                return -1;
            }
            return 0;
        })
    }

    render() {
        let p12_files = this.state.p12cert.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = p12_files.length ? 'dropzone-uploaded' : 'dropzone'

        return (
            <div >
                <div className="container-fluid" style={{ overflow: 'hidden' }}>
                    <div className='row' style={{ backgroundColor: '#F9FAFC' }} >

                        <div className='m-t-20' >
                            <div className='col-md-12'>
                                <div className="card networkcard" style={{ margin: '0px', position: 'relative' }}>
                                    <div id="activitytab" className="card-body" style={{ padding: '20px 30px' }}>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <span className="font14">NETWORK ACTIVITY</span>
                                            </div>
                                            <div className="col-md-4">
                                                <button disabled={this.state.isAllSelected === false && tickList.length === 0} className='btn networkbtn' onClick={(e: any) => { e.preventDefault(); this.showModalApprove() }} >Submit to SAS</button>
                                            </div>

                                        </div>
                                        <div className="row m-t-20">
                                            <div className="col-md-12" style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 159px)' }}>
                                                <table className="table sitetable networktable">
                                                    <thead className="sitehead" style={{ background: "none", display: 'table' }}>
                                                        <tr >
                                                            <th className="w8 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>Asset ID
                                                                <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByValueDesc("assetIdentifierId")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByValueAsc("assetIdentifierId")} />
                                                            </th>
                                                            <th className="w9 table-bg-hdr-color" >Asset Type
                                                            <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByValueDesc("assetType")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByValueAsc("assetType")} /></th>
                                                            <th className="w10 table-bg-hdr-color" >Asset Model
                                                            <img className="sortdown" src={sort} style={{ width: '10px' }} onClick={(e) => this.sortByValueDesc("assetType")} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} onClick={(e) => this.sortByValueAsc("assetModel")} />
                                                            </th>
                                                            <th className="w5 table-bg-hdr-color" >SAS</th>
                                                            {/* <th className="w6 table-bg-hdr-color" >N0. of Sectors
 <img className="sortdown" src={sort} style={{ width: '10px' }} />
                                                                <img src={sort} style={{ width: '10px', marginLeft: '10px', marginBottom: '-10px' }} />
                                                            </th> */}
                                                            <th className="w9 table-bg-hdr-color" >CBSD Status </th>

                                                            <th className="w10 table-bg-hdr-color" >CBSD Category</th>

                                                            <th className="w8 table-bg-hdr-color" >User ID
                                                            </th>

                                                            <th className="w5 table-bg-hdr-color" >Grants</th>
                                                            <th className="w7 table-bg-hdr-color" >Installer</th>
                                                            {/* <th className="w9 table-bg-hdr-color" >Submit Date </th> */}
                                                            <th className="w9 table-bg-hdr-color">Last Modified </th>
                                                            <th className="w8 table-bg-hdr-color">Install Data </th>
                                                            <th className="w5 table-bg-hdr-color" ></th>
                                                            <th className="w5 table-bg-hdr-color"> </th>
                                                            <th className="w2 table-bg-hdr-color pl-0" style={{ borderRadius: "0px 5px 5px 0px" }}>
                                                                <input name="select-all" type="checkbox" value="ALL" checked={this.state.isAllSelected}
                                                                    onChange={e => this.onCheckBoxChange("all", e.target.checked)}
                                                                />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ display: 'block', overflow: 'hidden scroll', maxHeight: 'calc(100vh - 210px)', width: 'calc(100% + 4px)' }}>
                                                        {this.state.assetActivityList.map((val: any, index: any) => {
                                                            return <React.Fragment>
                                                                <tr style={{ display: 'flex' }}>
                                                                    <td className="w8 networkellipsis" title={val.assetIdentifierId} style={{ paddingLeft: '0px' }}>
                                                                        {val.assetTypeId == 1 ?

                                                                            <img src={sort} onClick={() => this.propretycall(val, "#_" + val.parameterId + index)} style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} data-toggle="collapse" data-target={"#_" + val.parameterId + index} /> :
                                                                            <img style={{ width: '10px', marginRight: '10px', cursor: "pointer" }} />}

                                                                        {val.assetIdentifierId}
                                                                    </td>
                                                                    <td className="w9 networkellipsis" title={val.assetType !== undefined ? val.assetType : ""}>{val.assetType !== undefined ? val.assetType : ""}</td>
                                                                    <td className="w10 networkellipsis" title={(val.assetModel).charAt(0).toUpperCase() + (val.assetModel).slice(1)}>{(val.assetModel).charAt(0).toUpperCase() + (val.assetModel).slice(1)}</td>
                                                                    <td className="w5 networkellipsis" title={val.sas_provider !== null ? val.sas_provider : "-"}>{val.sas_provider !== null ? val.sas_provider : "-"}</td>
                                                                    {/* <td className="w6">{val.no_of_sectors}</td> */}
                                                                    <td className="w9 networkellipsis" title={val.submitStatus}>{val.submitStatus}</td>
                                                                    <td className="w10 networkellipsis" title={val.installationProperty.CBSDCategory !== undefined ? val.installationProperty.CBSDCategory.split("_")[2] : ""} >{val.installationProperty.CBSDCategory !== undefined ? val.installationProperty.CBSDCategory.split("_")[2] : ""}</td>
                                                                    <td className="w8 networkellipsis" title={val.userId}>{val.userId}</td>
                                                                    <td className="w5 networkellipsis">0</td>
                                                                    <td className="w7 networkellipsis" title={val.submittedBy}><span data-toggle="tooltip">{val.submittedBy}</span></td>
                                                                    {/* <td className="w9">{val.submittedAt}</td> */}
                                                                    <td className="w9 networkellipsis" title={val.actionTakenAt}>{val.actionTakenAt}</td>
                                                                    {val.assetTypeId != 1 ? <td className="w8" style={{ color: '#0272fb', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td> : ""}
                                                                    {
                                                                        val.assetTypeId != 1 && val.submitStatusId === 2 ? <React.Fragment>
                                                                            <td className="w5 pl-0" style={{ color: '#00D56E', paddingRight: '5px', cursor: 'pointer' }} onClick={() => { this.approveParam(val.parameterId) }}>Approve</td>
                                                                            <td className="w5 pr-0 pl-0" style={{ color: '#E22B2B', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.rejectParam(val.parameterId); this.showModal(); }}>Reject</td> </React.Fragment> : ""
                                                                    }
                                                                    {
                                                                        val.assetTypeId != 1 && val.submitStatusId === 12 ? <React.Fragment>
                                                                            <td className="w5" style={{ color: '#00D56E' }} ><span className="material-icons">check</span></td>
                                                                            <td className="w5">  </td>
                                                                        </React.Fragment> : ""
                                                                    }
                                                                    {
                                                                        val.assetTypeId != 1 && val.submitStatusId === 10 ? <React.Fragment>
                                                                            <td className="w5"></td>
                                                                            <td className="w5" style={{ color: '#e22b2b', cursor: 'pointer' }} ><span className="material-icons">close</span></td>
                                                                        </React.Fragment> : ""
                                                                    }
                                                                    {
                                                                        val.assetTypeId != 1 && ([3, 4, 5, 6, 11].includes(val.submitStatusId)) ? <React.Fragment>
                                                                            <td className="w5" style={{ color: '#ffc107', cursor: 'pointer' }} ><span className="material-icons">check</span></td>
                                                                            <td className="w5"></td>
                                                                        </React.Fragment> : ""
                                                                    }
                                                                    {val.assetTypeId != 1 ?
                                                                        <td className="w2 networkcheck pl-0">
                                                                            <div className="custom-control custom-checkbox ">
                                                                                <input
                                                                                    id={"networkCheck_" + val.parameterId}
                                                                                    name={"networkCheck_" + val.parameterId}
                                                                                    disabled={val.submitStatusId !== 12}
                                                                                    type="checkbox"
                                                                                    checked={val.checked}
                                                                                    onChange={(e: any) => { this.onCheckBoxChange(val.parameterId, e.target.checked) }}
                                                                                />
                                                                            </div>
                                                                        </td> : ""}
                                                                    {
                                                                        val.assetTypeId === 1 ? <React.Fragment>
                                                                            <td className="w8" ></td>
                                                                            <td className="w5"></td>
                                                                            <td className="w5"></td>
                                                                            <td className="w2"></td>
                                                                        </React.Fragment> : ""
                                                                    }


                                                                    {/* {
                                                                        val.assetTypeId != 1 && val.submitStatusId === 12 ?
                                                                            <React.Fragment>
                                                                                <td className="w8" style={{ color: '#0272fb', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td>
                                                                                <td className="w4 text-right" style={{ color: '#00D56E' }} ><span className="material-icons">check</span></td>
                                                                                <td className="w4"></td>
                                                                                <td className="w2 networkcheck pr-0">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input
                                                                                            id={"networkCheck_" + val.parameterId}
                                                                                            name={"networkCheck_" + val.parameterId}
                                                                                            disabled={val.submitStatusId !== 12}
                                                                                            type="checkbox"
                                                                                            checked={val.checked}
                                                                                            onChange={(e: any) => { this.onCheckBoxChange(val.parameterId, e.target.checked) }}

                                                                                        />
                                                                                    </div>
                                                                                </td>

                                                                            </React.Fragment> : ""

                                                                    }
                                                                    {
                                                                        val.assetTypeId != 1 && val.submitStatusId === 2 ?
                                                                            <React.Fragment>
                                                                                <td className="w8" style={{ color: '#0272fb', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td>
                                                                                <td className="w4 text-right" style={{ color: '#00D56E', cursor: 'pointer' }} onClick={() => { this.approveParam(val.parameterId) }}>Approve</td>
                                                                                <td className="w4 text-right" style={{ color: '#E22B2B', paddingRight: '0px', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.rejectParam(val.parameterId); this.showModal(); }}>Reject</td>
                                                                                <td className="w2 networkcheck pr-0">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input
                                                                                            id={"networkCheck_" + val.parameterId}
                                                                                            name={"networkCheck_" + val.parameterId}
                                                                                            disabled={val.submitStatusId !== 12}
                                                                                            type="checkbox"
                                                                                            checked={val.checked}
                                                                                            onChange={(e: any) => { this.onCheckBoxChange(val.parameterId, e.target.checked) }}

                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </React.Fragment> : ""
                                                                    }
                                                                    {val.assetTypeId != 1 && val.submitStatusId === 10 ?
                                                                        <React.Fragment>
                                                                            <td className="w8" style={{ color: '#0272fb', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td>
                                                                            <td className="w4 text-right" style={{ color: '#e22b2b', cursor: 'pointer' }} ><span className="material-icons">close</span></td>
                                                                            <td className="w4"></td>
                                                                            <td className="w2 networkcheck pr-0">
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input
                                                                                        id={"networkCheck_" + val.parameterId}
                                                                                        name={"networkCheck_" + val.parameterId}
                                                                                        disabled={val.submitStatusId !== 12}
                                                                                        type="checkbox"
                                                                                        checked={val.checked}
                                                                                        onChange={(e: any) => { this.onCheckBoxChange(val.parameterId, e.target.checked) }}

                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </React.Fragment> : ""

                                                                    }
                                                                    {
                                                                        val.assetTypeId != 1 && ([3, 4, 5, 6, 11].includes(val.submitStatusId)) ?
                                                                            <React.Fragment>
                                                                                <td className="w8" style={{ color: '#0272fb', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td>
                                                                                <td className="w4 text-right" style={{ color: '#ffc107', cursor: 'pointer' }} ><span className="material-icons">check</span></td>
                                                                                <td className="w4"></td>
                                                                                <td className="w2 networkcheck pr-0">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input
                                                                                            id={"networkCheck_" + val.parameterId}
                                                                                            name={"networkCheck_" + val.parameterId}
                                                                                            disabled={val.submitStatusId !== 12}
                                                                                            type="checkbox"
                                                                                            checked={val.checked}
                                                                                            onChange={(e: any) => { this.onCheckBoxChange(val.parameterId, e.target.checked) }}

                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </React.Fragment> : ""
                                                                    }
                                                                    {
                                                                        val.assetTypeId === 1 ?
                                                                            <React.Fragment>
                                                                                <td className="w8"></td>
                                                                                <td className="w4"></td>
                                                                                <td className="w4"></td>
                                                                                <td className="w2"></td>
                                                                            </React.Fragment> : ""
                                                                    } */}


                                                                    {/* {val.assetTypeId != 1 ?
                                                                        <td className="w8" style={{ color: '#0272fb', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td> : ""}
                                                                    {val.assetTypeId != 1 && val.submitStatusId === 2 ? <td className="w4 text-right" style={{ color: '#00D56E', cursor: 'pointer' }} onClick={() => { this.approveParam(val.parameterId) }}>Approve</td> : ""}
                                                                    {val.assetTypeId != 1 && val.submitStatusId === 12 ?
                                                                        <td className="w4 text-right" style={{ color: '#00D56E' }} ><span className="material-icons">check</span></td> : ""}

                                                                    {val.assetTypeId != 1 && val.submitStatusId === 2 ? <td className="w4 text-right" style={{ color: '#E22B2B', paddingRight: '0px', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.rejectParam(val.parameterId); this.showModal(); }}>Reject</td> : ""}
                                                                    {val.assetTypeId != 1 && val.submitStatusId === 10 ?
                                                                        <td className="w4 text-right" style={{ color: '#e22b2b', cursor: 'pointer' }} ><span className="material-icons">close</span></td> : ""
                                                                    }
                                                                    {val.assetTypeId != 1 && ([3, 4, 5, 6, 11].includes(val.submitStatusId)) ?
                                                                        <td className="w4 text-right" style={{ color: '#ffc107', cursor: 'pointer' }} ><span className="material-icons">check</span></td> : ""
                                                                    }
                                                                    {val.assetTypeId != 1 ?
                                                                        <td className="w2"></td> : ""}

                                                                    {val.assetTypeId != 1 ?
                                                                        <td className="w2">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input
                                                                                    id={"networkCheck_" + val.parameterId}
                                                                                    name={"networkCheck_" + val.parameterId}
                                                                                    disabled={val.submitStatusId !== 12}
                                                                                    type="checkbox"
                                                                                    checked={val.checked}
                                                                                    onChange={(e: any) => { this.onCheckBoxChange(val.parameterId, e.target.checked) }}

                                                                                />

                                                                            </div>
                                                                        </td> : ""} */}


                                                                </tr>

                                                                <div id={"_" + val.parameterId + index} className="collapse">
                                                                    {this.state.assetClickId === "#_" + val.parameterId + index ?
                                                                        <div className="" style={{ padding: "0px 12px", background: "#f3f3f5", marginLeft: '20px' }}>

                                                                            <div className="" style={{ padding: "0px 12px", background: "#f3f3f5" }}>
                                                                                <table className="subtable" style={{ width: '100%' }}>
                                                                                    <thead>
                                                                                        <th className="w6 fontw500 b-t-0" style={{ paddingLeft: '0px' }}>eNBId</th>
                                                                                        <th className="w10 fontw500 b-t-0" >sector_id</th>
                                                                                        <th className="w12 fontw500 b-t-0" >Serial Number</th>
                                                                                        <th className="w8 fontw500 b-t-0 " >Cell ID</th>
                                                                                        <th className="w6 fontw500 b-t-0 " >PCI</th>
                                                                                        <th className="w12 fontw500 b-t-0 " >Antenna Height</th>
                                                                                        <th className="w12 fontw500 b-t-0" >Frequency band</th>
                                                                                        <th className="w10 fontw500 b-t-0" >FCC ID</th>
                                                                                        <th className="w9 fontw500 b-t-0" >Install Data</th>
                                                                                        <th className="w6 b-t-0" ></th>
                                                                                        <th className="w6 b-t-0" ></th>
                                                                                        <th className="w3 b-t-0" ></th>
                                                                                    </thead>

                                                                                    <tbody>
                                                                                        <tr>
                                                                                            {'enb_id' in val && val['enb_id'] !== "" ?
                                                                                                <td className="w6" >{val.enb_id}</td> :

                                                                                                <td className="w6" >-</td>}
                                                                                            <td className="w10">{val.installationProperty.sectorId}</td>
                                                                                            <td className="w12">{val.installationProperty.sectorSerial}</td>
                                                                                            {val.installationProperty === "cell_id" && val.installationProperty.cell_id !== "" ?
                                                                                                <td className="w8" >{val.installationProperty.cellId}</td> :
                                                                                                <td className="w8" >-</td>}
                                                                                            {val.installationProperty === 'pci' && val.installationProperty.pci !== "" ?
                                                                                                <td className="w6" >{val.installationProperty.pci}</td> :
                                                                                                <td className="w6" >-</td>}
                                                                                            <td className="w12">{val.installationProperty.height}</td>
                                                                                            <td className="w12">-</td>
                                                                                            <td className="w10">{val.installationProperty.fccId}</td>
                                                                                            <td className="w9" style={{ color: '#0272fb', paddingRight: '0px', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.setInstallationparam(val.installationProperty, val) }}>View</td>
                                                                                            {val.submitStatusId === 2 ? <td className="w6 text-right" style={{ color: '#00D56E', cursor: 'pointer' }} onClick={() => { this.approveParam(val.parameterId) }}>Approve</td> : ""}
                                                                                            {val.submitStatusId === 12 ? <td className="w12 text-center" style={{ color: '#00D56E', cursor: 'pointer' }} ><span className="material-icons">check</span></td> : ""}
                                                                                            {val.submitStatusId === 2 ? <td className="w6 text-right" style={{ color: '#E22B2B', cursor: 'pointer' }} onClick={(e: any) => { e.preventDefault(); this.rejectParam(val.parameterId); this.rejectshowModal(); }}>Reject</td> : ""}
                                                                                            {val.submitStatusId === 10 ? <td className="w12 text-center" style={{ color: '#e22b2b', cursor: 'pointer' }} ><span className="material-icons">close</span></td> : ""}
                                                                                            {([3, 4, 5, 6, 11].includes(val.submitStatusId)) ?
                                                                                                <td className="w12 text-center" style={{ color: '#ffc107', cursor: 'pointer' }} ><span className="material-icons">check</span></td> : ""
                                                                                            }
                                                                                            <td className="w3">
                                                                                                <div className="custom-control custom-checkbox network">
                                                                                                    {/* <input type="checkbox" className="custom-control-input" checked={this.state.value[val.parameterId]} onChange={(e: any) => this.handleCheckChange(e, val, index)} id={"networkCheckap_" + val.parameterId} name={"networkCheckap_" + val.parameterId} disabled={val.submitStatusId != 12} /> */}
                                                                                                    <input
                                                                                                        id={"networkCheckap_" + val.parameterId}
                                                                                                        name={"networkCheckap_" + val.parameterId}
                                                                                                        disabled={val.submitStatusId !== 12}
                                                                                                        type="checkbox"
                                                                                                        checked={val.checked}
                                                                                                        onChange={e =>
                                                                                                            this.onCheckBoxChange(val.parameterId, e.target.checked)
                                                                                                        }
                                                                                                    />
                                                                                                    {/* <label className="custom-control-label check" style={{ left: '12px', top: '-8px' }} htmlFor={"networkCheckap_" + val.parameterId} ></label> */}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>


                                                                        </div>
                                                                        : ""}

                                                                </div>
                                                            </React.Fragment>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>REJECTION REASON</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className="col-12 p-0">
                                        <div className="col-md-12 p-0">
                                            <div className="form-label-group m-0">
                                                <IonTextarea className="description" name="reason" value={this.state.reason} id="apdescription" style={{ fontSize: '14px', fontWeight: 'lighter' }} rows={7} onIonChange={this.handleChange} placeholder="Your Message" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-t-10 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn font14' style={{ width: '100%' }} disabled={this.state.reason == ""} onClick={(e: any) => { e.preventDefault(); this.rejectParameter() }}>Confirm Rejection</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal show={this.state.approve} handleClose={this.hideModal}>
                    {/* <div className="modal fade" id="submitModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row m-0' style={{}}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '14px' }}>SUBMITTING PARAMETERS</h6>
                                    <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div className='row m-0' style={{}}>
                                    <span className='distance' style={{ marginTop: "10px", fontWeight: 400 }}>Please enter CPI credentials</span>
                                </div>
                                <div className=' m-t-5 justify-content-center'>
                                    <div className="form-group" >
                                        <div className="col-12 p-0">
                                            <div className="form-label-group m-0">
                                                <input disabled={this.state.override_cpi} type="text" style={{ width: '100%', borderBottom: "0px", borderColor: "#CED7DF" }} id="cpeidmob" name="cpiId" value={this.state.cpiId} onChange={this.cpiSaveCahnge} className="text-user img-center" placeholder="CPI ID" />
                                                <label htmlFor="cpeidmob">CPI ID</label>
                                            </div>
                                        </div>
                                        {this.state.defaultProviderId === 2 ?
                                            <div className="col-12 p-0">
                                                <div className="form-label-group m-0">
                                                    <input disabled={this.state.override_cpi} type="text" style={{ width: '100%', borderBottom: "0px", borderColor: "#CED7DF" }} id="cpeidmob" name="cpiName" value={this.state.cpiName} onChange={this.cpiSaveCahnge} className="text-user img-center" placeholder="CPI Name" />
                                                    <label htmlFor="cpenamemob">CPI Name</label>
                                                </div>
                                            </div> : null}
                                        {this.state.certificateSubmitted == false ?
                                            <div>
                                                <div className="col-12 p-0">
                                                    <div className="form-label-group m-0">
                                                        <input disabled={this.state.override_cpi} type="password" style={{ width: '100%', borderColor: "#CED7DF" }} id="cpenamemob" name="cpiPassword" onChange={this.cpiSaveCahnge} className="text-pwd img-center" placeholder="CPI Name" />
                                                        <label htmlFor="cpenamemob">CPI Certificate Password</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0">
                                                    <div>
                                                        <div className='col-12 p-0 m-t-10 justify-content-center' style={{ padding: "0px 35px", fontSize: '12px' }}>
                                                            <Dropzone onDrop={(e: any) => { this.onDrop(e, "p12") }} disabled={this.state.field_disable || this.state.override_cpi}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='dropzone-set'>
                                                                        <div {...getRootProps({ className: 'dropzone' })}>

                                                                            <input {...getInputProps()} accept='.p12' />
                                                                            {this.state.p12cert.length ?
                                                                                <span style={{ color: "#00D56E" }}>

                                                                                    {p12_files}

                                                                                    {/* <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); p12_files = [] }}>clear</i> */}

                                                                                </span>
                                                                                :
                                                                                <span style={{ fontSize: '14px' }}>+ Upload CPI Certificate</span>}
                                                                        </div>
                                                                        <div>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                </div></div> : null}
                                    </div>
                                </div>
                                <div className='row m-0 m-t-5'>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" onChange={(e: any) => { this.is_override_cpi(e) }} value={this.state.override_cpi} className="custom-control-input" id="customCheckcpi" name="customCheckcpiexample" />
                                            <label className="custom-control-label check" style={{ float: 'left', margin: '10px 0px' }} htmlFor="customCheckcpi">Override mandatory CPI credentials</label>
                                        </div>
                                        {/* <input style={{ float: 'left', margin: '7px 7px 7px 0px' }} type="checkbox" /> */}
                                        {/* <span className='check' style={{ float: 'left', margin: '5px 0px' }}>Override mandatory CPI credentials</span> */}
                                    </div>
                                </div>
                                <div className='row m-0 m-t-5 justify-content-center' style={{}}>
                                    <button className='btn cardbtn' style={{ width: '100%' }} onClick={() => { this.savePrameters(); this.hideModal() }} >Submit Parameters</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <Modal show={this.state.view} handleClose={this.hideModal}>
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document" style={{ maxWidth: '645px' }}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>INSTALLATION DATA</h6>
                                    <i className="material-icons resetcloseicon imgclose-file" data-dismiss="modal" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} aria-label="Close">clear</i>
                                    {/* <i className="far fa-times-circle modalclose" onClick={(e: any) => { e.preventDefault(); this.hideModal() }} data-dismiss="modal" aria-label="Close"></i> */}
                                </div>
                                <div className='row m-t-10 justify-content-center' style={{ padding: '0 10px' }}>
                                    <div className="col-12" style={{ border: '1px solid #E3E3E3', padding: '10px', overflow: 'hidden auto', maxHeight: '490px' }}>
                                        <div className="row m-b-10">
                                            <div className="col-6">
                                                <div className="m-b-5">
                                                    <span className='netactid' >FCC ID:</span>
                                                    <span className='netactdetail'  >{installParamArray['fccId']}</span>
                                                </div>
                                                <div>
                                                    <span className='netactid' >Serial ID:</span>
                                                    <span className='netactdetail'  >{this.state.serial_number}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 p-0 text-center searchborder'></div>
                                        <div className="netacthead m-t-10">Installation Parameters</div>
                                        <div className="row m-t-10 m-b-10">
                                            <div className="col-6 p-r-0">
                                                <div className="m-b-5">
                                                    <span className='netactid' >Latitude:</span>
                                                    <span className='netactdetail'  >{installParamArray['installation_latitude']}º</span>
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Longitude:</span>
                                                    <span className='netactdetail'  >{installParamArray['installation_longitude']}º</span>
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Height (m):</span>
                                                    <span className='netactdetail'  >{installParamArray['height']}</span>
                                                    {installParamArray['heightImage'] != "" ?
                                                        <span><i className="imagelink fa fa-picture-o" data-toggle="collapse" data-target="#heightImage" aria-expanded="false" aria-controls="collapseExample"></i></span> : ""}
                                                </div>
                                                <div id="heightImage" className="m-t-10 m-b-10 collapse">
                                                    <img className='apimage' src={installParamArray['heightImage']} />
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Height Type:</span>
                                                    <span className='netactdetail'  >{installParamArray['heightType']}</span>

                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >HorizantalAccuracy (m):</span>
                                                    <span className='netactdetail'  >{installParamArray['horizantalAccuracy']}</span>
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Vertical Accuracy (m):</span>
                                                    <span className='netactdetail'  >{installParamArray['verticalAccuracy']}</span>
                                                </div>
                                                <div >
                                                    <span className='netactid' >Indoor deployment:</span>
                                                    <span className='netactdetail'  >{installParamArray['indoor_deployment']}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="m-b-5">
                                                    <span className='netactid' >HAAT:</span>
                                                    <span className='netactdetail'  >{installParamArray['HAAT']}</span>
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Antenna Azimuth (Degrees):</span>
                                                    <span className='netactdetail'  >{installParamArray['antennaAzimuth']}</span>
                                                    {installParamArray['azimuthImage'] != "" ?
                                                        <i className="imagelink fa fa-picture-o" data-toggle="collapse" id="azimuthCollapse" data-target="#azimuthImage" aria-expanded="false" aria-controls="collapseExample"></i> : ""}
                                                </div>
                                                <div id="azimuthImage" className="m-t-10 m-b-10 collapse">
                                                    <img className='apimage' src={installParamArray['azimuthImage']} />
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Antenna Downtilt (Degrees):</span>
                                                    <span className='netactdetail'  >{installParamArray['downtilt']}</span>
                                                    {installParamArray['downtiltImage'] != "" ?
                                                        <i className="imagelink fa fa-picture-o" data-toggle="collapse" data-target="#downtiltImage" aria-expanded="false" aria-controls="collapseExample"></i> : ""}
                                                </div>
                                                <div id="downtiltImage" className="m-t-10 m-b-10 collapse">
                                                    <img className='apimage' src={installParamArray['downtiltImage']} />
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Antenna Gain (dBi):</span>
                                                    <span className='netactdetail'  >{installParamArray['antennaGain']}</span>
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Eirp Capability (dBm):</span>
                                                    <span className='netactdetail'  >{installParamArray['eirbCapability']}</span>
                                                </div>
                                                <div className="m-b-5">
                                                    <span className='netactid' >Antenna Beamwidth (Degrees):</span>
                                                    <span className='netactdetail'  >{installParamArray['antennaBeamwidth']}</span>
                                                </div>
                                                <div>
                                                    <span className='netactid' >Antenna Model:</span>
                                                    <span className='netactdetail'  >{installParamArray['antennaModel']}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 p-0 text-center searchborder'></div>
                                        <div className="netacthead m-t-10">CBSD Info</div>
                                        <div className="row m-t-10 m-b-10">
                                            <div className="col-6">
                                                <div className="m-b-5">
                                                    <span className='netactid' >CBSD Vendor Model:</span>
                                                    <span className='netactdetail'  >{installParamArray['CBSDVendorModal']}</span>
                                                </div>
                                                <div>
                                                    <span className='netactid' >Software Version:</span>
                                                    <span className='netactdetail'  >{installParamArray['softwareVersion']}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="m-b-5">
                                                    <span className='netactid' >Hardware Version:</span>
                                                    <span className='netactdetail'  >{installParamArray['hardwareVersion']}</span>
                                                </div>
                                                <div>
                                                    <span className='netactid' >Firmware Version:</span>
                                                    <span className='netactdetail'  >{installParamArray['firmwareVersion']}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 p-0 text-center searchborder'></div>
                                        <div className="netacthead m-t-10">Optional</div>
                                        <div className="row m-t-10">
                                            <div className="col-6">
                                                <div className="m-b-5">
                                                    <span className='netactid' >CallSign:</span>
                                                    {installParamArray['callsign'] == "" ?
                                                        <span className='netactdetail'>-</span>
                                                        :
                                                        <span className='netactdetail'>{installParamArray['callsign']}</span>}
                                                </div>

                                                <div>
                                                    <span className='netactid' >Grouping Param:</span>
                                                    <span className='netactdetail'  >{installParamArray['groupingParam']}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="m-b-5">
                                                    <span className='netactid' >Category:</span>
                                                    <span className='netactdetail'  >{installParamArray['CBSDCategory']}</span>
                                                </div>
                                                <div>
                                                    <span className='netactid' >Air Interface:</span>
                                                    <span className='netactdetail'  >{installParamArray['airInterface']}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal show={this.state.status_show}>
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>SAS Submission Status</h6>
                                    <i className="material-icons modalclose imgclose-file m-l-5" onClick={this.status_hideModal} data-dismiss="modal" aria-label="Close">clear</i>
                                </div>
                                <table className="table sitecollapse m-t-20">
                                    <thead>
                                        <tr >
                                            <th className="w15" >Serial Number</th>
                                            <th className="w20" >Message</th>
                                        </tr>
                                        {
                                            status_list.map((status: any) => {
                                                return <tr>
                                                    <td>{status.serialNumber}</td>
                                                    <td>{status.message}</td>
                                                </tr>
                                            })
                                        }
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>

                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />
                <IonLoading
                    isOpen={this.state.showActivityLoading}
                    onDidDismiss={() => this.setShowLoading(false)}
                    message={'Loading...'}
                    // duration={100}
                    animated={true}
                />
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NetworkActivity);
