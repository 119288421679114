import React from 'react';
import Sitedata from './sitedata';
import Polygon from './polygonboundaries';
import Coverage from './coveragelayers';
import Modal from './modal'
import Buildings from './importbuildings'
import FloorDropz from './Floordropz';
import SitedataDetails from './sitedata-details';

const INITIAL_STATE = {
    card:'',
    show: true
};

export default class Import extends React.Component {
    state:any={};
    props:any={};
    constructor(props:any){
        super(props);
        this.state={...INITIAL_STATE}
    }

    showModal = (e:any) =>{
        e.preventDefault();
        this.setState({
            card : e.target.id
        })
        console.log()
    }
    hideModal = () =>{
        this.setState({
            card : ''
        })
    }

    render() {
        return (
            <div className='container-fluid dash p-0' style={{ background: '#F9FAFC' }}> 
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row m-t-10' style={{ padding: "0px 35px" }}>
                            <h4>Data Import</h4>
                        </div>
                        <div className='row m-t-10' style={{ padding: "0px 25px" }}>
                            <nav style={{marginLeft:"-2px"}}>
                                <ul className="nav tabimport" id="importTab" role="tablist">
                                    <li className="nav-item m-l-0">
                                        <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data"
                                            aria-selected="true">Asset Data</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="layer-tab" data-toggle="tab" href="#layer" role="tab" aria-controls="layer"
                                            aria-selected="false">Coverage Layers</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="boundaries-tab" data-toggle="tab" href="#boundaries" role="tab" aria-controls="boundaries"
                                            aria-selected="false">Polygon Boundaries</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="buildings-tab" data-toggle="tab" href="#buildings" role="tab" aria-controls="buildings"
                                            aria-selected="false">Buildings</a>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                        <div className="tab-content" id="importContent">
                            <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
                                <SitedataDetails showModal={this.showModal}/>
                            </div>
                            <div className="tab-pane fade" id="layer" role="tabpanel" aria-labelledby="layer-tab">
                                <Coverage showModal={this.showModal}/>
                            </div>
                            <div className="tab-pane fade" id="boundaries" role="tabpanel" aria-labelledby="boundaries-tab">
                                <Polygon showModal={this.showModal}/>
                            </div>
                            <div className="tab-pane fade" id="buildings" role="tabpanel" aria-labelledby="buildings-tab">
                                <Buildings showModal={this.showModal}/>
                            </div>
                        </div>

                    </div>
                    <Modal show={this.state.show && this.state.card==='heat'} handleClose={this.hideModal}>
                {/* <div className="modal fade" id="heatModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 10px' }}>
                                    <h6 className="modal-title" style={{ marginTop: '0px', fontSize: '15px' }}>IMPORT FILES</h6>
                                    <i className="far fa-times-circle modalclose" onClick={this.hideModal} data-dismiss="modal" aria-label="Close"></i>
                                </div>
                                <div className='row m-t-10 justify-content-center' style={{ padding: '0 10px' }}>
                                   
                                    <div className='col-md-12'>
                                    <FloorDropz >
                                        <span>Drag and Drop files here</span>
                                    </FloorDropz></div>
                                </div>
                                <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn' style={{ width: '268px' }}>IMPORT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                </Modal>
                </div >
            </div>

        );
    }

}