import React, { Component } from 'react'
import { StandaloneSearchBox } from '@react-google-maps/api'
import Heat from '../assets/img/heaticon.png'
import "./search.css"
import Back from '../assets/img/left-arrow.svg'
import Pin from '../assets/img/search-suggestions-map-icon-gray.png'
import { IonPage, IonContent } from '@ionic/react'
import SearchIcon from '../assets/img/search_icon.png'
import geo from '../assets/img/target-icon.png'
import apicon from '../assets/img/apicon.png'
import { connect } from 'react-redux';
import { ASSET } from '../constants/actionTypes';
import { requestAPICall } from "../service/apiHandler";
import BuildingInfo1 from './BuildingInfo1'

var predict: any = [];
var addressPredictions: any = {}

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({

    upadateBuildingCoordinates: (value: any) =>
        dispatch({ type: ASSET, key: 'buildingCoordinates', value }),
    updateFloorId: (value: any) =>
        dispatch({ type: ASSET, key: 'floorId', value }),
});

interface Iprops {
    building_name: Function,
    next6: Function,
    send_id: Function,
    focusBuilding: any,
    back: any,
    setAddress: any,
    latlong_apcpe: any,

}

class IndoorSearch extends Component<Iprops>{
    state: any = {};
    props: any = {};
    autocompleteInput: any
    autocomplete: any

    geocoder: any

    constructor(props: any) {
        super(props)

        this.state = {

            searches: '',
            predictionsList: [],
            address: '',
            search: "",
            serial: "",
            search_list: [],
            building_list: [],
            all_building_list: []

        };
        this.onChange = this.onChange.bind(this)
        this.searchValue = this.searchValue.bind(this)
        this.back = this.back.bind(this)
        this.list_building()
    }
    componentDidMount() {
        window.addEventListener('load', this.list_building);
        console.log(this.autocomplete, "fdfd")

    }

    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();
        console.log(place, "place")
        console.log(this.autocomplete, "dffdfdfd")

    }
    onSearch = (e: any) => {

        console.log(e.target.name, e.target.value, "ddfdf")
        this.setState({
            searches: e.target.value

        })

        console.log(this.autocompleteInput, "ggggggg")

        const predict = this.getQuery()

        this.setState({

            predictionsList: predict,

        })

        console.log(this.state.predictionsList, "list")
    }

    getQuery = () => {

        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
        return addressPredictions
    }
    predictionCallback = (predictions: any, status: any) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) return;

        addressPredictions = predictions;
    }
    setPlace = (value: any) => {
        this.setState({
            searches: value,

        })
        var locmark = true
        this.props.setAddress(value, locmark)
        // this.props.next1()

        console.log(value, "sdsds")
    }
    onChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.list_building_search(this.state.searches)
            console.log(this.state.searches)
        })
    }
    searchValue = (e: any) => {
        console.log(e)
        console.log(e.target.value)
    }
    back() {
        console.log("===============")
        this.props.back()
    }
    list_building() {

        let requestBody = {}
        requestAPICall("list_building", {}, requestBody).then(data => {
            this.setState({
                all_building_list: data['data']
            })
        })

    }
    list_building_search = (searches: any) => {
        console.log(this.state.building_list)
        this.setState({
            building_list: this.state.all_building_list.filter((building: any) => building.building_name.toUpperCase().includes(this.state.searches.toUpperCase()))
        })
        console.log(this.state.building_list)

    }
    details = (BuildingInfo: any) => {
        console.log(this.state.all_building_list)
        console.log(BuildingInfo)
        this.setState({
            searches: BuildingInfo.building_name,
        })
        let list_filter = this.state.all_building_list.filter((building: any) => building.building_name === BuildingInfo.building_name)
        console.log(list_filter)
        list_filter = list_filter[0]
        var coordinates = {
            'latitude': list_filter.latitude,
            'longitude': list_filter.longitude

        }
        list_filter['coOrdinates'] = coordinates
        this.setState({
            lat_search: list_filter['latitude'],
            lng_search: list_filter['longitude']


        }, () => {
            var locmark = false
            console.log(this.props.buildingId)
            if (this.props.buildingId !== BuildingInfo.building_id) {
                this.props.updateFloorId(null)
            }

            this.props.upadateBuildingCoordinates({ "lat": this.state.lat_search, "lng": this.state.lng_search })
            this.props.focusBuilding(this.state.lat_search, this.state.lng_search)
            this.props.send_id(BuildingInfo.building_id)
            this.props.building_name(BuildingInfo.building_name)
            this.props.next6();
            this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, list_filter)
        })

    }
    render() {
        return (
            <div>
                <div className="webonly">
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="card searchcard">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img className='search-web' src={SearchIcon} />
                                            <i className="material-icons closeicon" style={{ fontSize: '21px', color: '#8f9fac' }} onClick={this.back}>clear</i>
                                            <input name={this.state.searches} className='search' id="autocomplete" onChange={(e: any) => { this.onSearch(e); this.onChange(e) }} value={this.state.searches} placeholder="Enter your address" type="text" ref={this.autocompleteInput} autoFocus></input>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                        aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem' }}>ADDRESS</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                        aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem' }}>Building</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="tab-content" id="searchTabContent">


                                        <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">
                                            {this.state.predictionsList.length > 0 ?
                                                <div style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 190px)' }}>
                                                    {this.state.predictionsList.map((predict: any) => {
                                                        return <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                            <div className='col-md-2'>
                                                                <img className='searchcontenticon' src={Pin} />
                                                            </div>
                                                            <div className='col-md-10 searchborder'>
                                                                <span onClick={() => this.setPlace(predict.description)} className='searchcontent'>{predict.description}</span><br />

                                                            </div>
                                                        </div>

                                                    })}
                                                </div> : ""}
                                        </div>

                                        <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                            {this.state.building_list.length > 0 ?
                                                <div style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 190px)' }}>
                                                    {this.state.building_list.map((index: any) => (
                                                        <div id="indexname" onClick={() => this.details(index)}>

                                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>

                                                                <div className='col-md-2'>
                                                                    <i className="fas fa-building buildsearchicon" ></i>
                                                                </div>
                                                                <div className='col-md-10 searchborder'>
                                                                    <div className="font14" style={{ color: '#353435' }}>{index.building_name} </div>
                                                                    {/* <div className="font14" style={{ color: '#8F9FAC' }}> {index.name}</div> */}
                                                                </div>
                                                            </div>


                                                        </div>))}
                                                </div> : <div style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 185px)' }}>
                                                    {this.state.all_building_list.map((index: any) => (
                                                        <div id="indexname" onClick={() => this.details(index)}>

                                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>

                                                                <div className='col-md-2'>
                                                                    <i className="fas fa-building buildsearchicon" ></i>
                                                                </div>
                                                                <div className='col-md-10 searchborder'>
                                                                    <div className="font14" style={{ color: '#353435' }}>{index.building_name}</div>
                                                                    {/* <div className="font14" style={{ color: '#8F9FAC' }}>{index.name}</div> */}
                                                                </div>
                                                            </div>


                                                        </div>))}
                                                </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className='col-md-3' >
                                <div className="col-md-12 justify-content-flex-end">
                                    <div className="heaticon text-center" style={{ display: 'none' }}>
                                        <img className='heat' src={Heat} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="mobonly">
                    <div className="stay-bottom-mob submit-btn-wrapper" id="scrollcard" style={{ height: "360px", paddingTop: '0px', boxShadow: '1px 0px 6px 0px #b8b8b8' }}>
                        <div className="card-body build-cardbody" >
                            <div className='row'>
                                <div className='col-12 m-t-20'>
                                    <img className='search-web' src={SearchIcon} />
                                    <i className="material-icons closeicon" style={{ fontSize: '21px', color: '#8f9fac' }} onClick={this.back}>clear</i>
                                    <input name={this.state.searches} className='search' id="autocomplete" onChange={(e: any) => { this.onSearch(e); this.onChange(e) }} value={this.state.searches} placeholder="Enter your address" type="text" ref={this.autocompleteInput}></input>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="indoor-address-tab" data-toggle="tab" href="#indooraddress" role="tab" aria-controls="indooraddress"
                                                aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem' }}>ADDRESS</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="building-tab" data-toggle="tab" href="#building" role="tab" aria-controls="building"
                                                aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem' }}>BUILDING</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content" id="searchTabContent">
                                <div className="tab-pane fade show active" id="indooraddress" role="tabpanel" aria-labelledby="indoor-address-tab">
                                    <div className='row'>
                                        <div className='col-12'>

                                            {this.state.predictionsList.length > 0 ?
                                                <div className="card-body searchdetails" style={{ overflow: 'hidden auto', maxHeight: '255px', padding: '0px 5px 5px 0px' }}>
                                                    {this.state.predictionsList.map((predict: any) => {
                                                        return <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                            <div className='col-2'>
                                                                <i className="fas fa-map-marker-alt searchcontenticon  float-right" style={{ fontSize: '20px' }}></i>
                                                            </div>
                                                            <div className='col-10 searchborder'>
                                                                <span onClick={() => this.setPlace(predict.description)} className='searchcontent'>{predict.description}</span><br />
                                                            </div>
                                                        </div>

                                                    })}
                                                </div> : ""}
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="building" role="tabpanel" aria-labelledby="building-tab">
                                    {this.state.building_list.length > 0 ?
                                        <div className="card-body searchdetails" style={{ overflow: 'hidden auto', maxHeight: '255px', padding: '0px 5px 5px 0px' }}>
                                            {this.state.building_list.map((index: any) => (

                                                <div className='row' style={{ padding: "0px 15px 0px 0px" }} onClick={() => this.details(index)}>>
                                                    <div className='col-2'>
                                                        <i className="fas fa-building buildsearchicon float-right" style={{ fontSize: '20px' }}></i>
                                                    </div>
                                                    <div className='col-9 searchborder'>
                                                        <span className='searchcontent'>{index.building_name} </span><br />
                                                    </div>
                                                </div>
                                            ))}
                                        </div> :
                                        <div className="card-body searchdetails" style={{ overflow: 'hidden auto', maxHeight: '255px', padding: '0px 5px 5px 0px' }}>
                                            {this.state.all_building_list.map((index: any) => (
                                                <div className='row' style={{ padding: "0px 15px 0px 0px" }} onClick={() => this.details(index)}>>
                                                    <div className='col-2'>
                                                        <i className="fas fa-building buildsearchicon float-right" style={{ fontSize: '20px' }}></i>
                                                    </div>
                                                    <div className='col-9 searchborder'>
                                                        <span className='searchcontent'>{index.building_name} </span><br />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div >)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IndoorSearch)
