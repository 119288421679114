import React, { Component } from 'react'
import { IonAlert, IonLoading, } from '@ionic/react';
import Back from '../assets/img/left-arrow.svg'
import Heat from '../assets/img/heaticon.png'
import geo from '../assets/img/target-icon.png'
import Lake from '../assets/img/lake.png'
import BackIcon from '../assets/img/go-back-icon-img.png'
import closeIconImg from "../assets/img/cross-icon-img.png";
import APMoreInfo from "./ap-more-info"
import Modal from './modal'
import "./access-point-details.css"
import align from '../assets/img/align-icon.png'
import { requestAPICall } from "../service/apiHandler";
import { string, array, nominalTypeHack } from 'prop-types';
import { isBrowser, isMobile } from "react-device-detect";


const INITIAL_STATE = {
    card1: false,
    card2: false,
    show: true,
    asset_image: "",
    edit_serial: true
};
var asset_property: any = {}
var sector_id_list: any = {}
var select_asset_id: any
class APdetails1 extends React.Component {

    // export default class AddAP extends Component {
    state: any = {};
    props: any = {};
    ap_info_ref: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        this.state = {
            assetId: 0,
            showsector: false,
            name: "",
            access_point_id: "",
            user_id: "",
            description: "",
            image: "",
            no_of_sectors: 0,
            latitude: "",
            longitude: "",
            asset_type_id: 0,
            sector_id: [],
            rad_center: 0,
        }
        this.state = { ...INITIAL_STATE };
        this.ap_info_ref = React.createRef()
        this.send_asset_id = this.send_asset_id.bind(this)
        this.remove_photo = this.remove_photo.bind(this)
        this.backtoMap = this.backtoMap.bind(this)
        this.backToList = this.backToList.bind(this)
    }
    componentDidMount() {
        console.log(this.props)
        // asset_property = this.props["asset_details"]["properties"]
        // asset_property = this.reduxStore['assetData']

        // this.setState({ assetId: this.props["asset_details"]['assetId'] })
        // this.setState({ assetStatus: this.props["asset_details"]['operationalStatus'] })
        // this.setState({ asset_type_id: this.props["asset_details"]["assetTypeId"] })
        // this.setState({ sector_id: this.props["asset_details"]["sector_id"] })
        // console.log(this.props["asset_details"]['assetId']);
        // this.send_asset_id()
        // this.load_property(this.props["asset_details"]["properties"])
    }
    set_property = () => {
        this.setState({
            image: "",
            name: "",
            description: "",
            rad_center: 0,
            serial_id: ""
        })
        asset_property = this.props["asset_details"]["properties"]
        this.setState({ assetId: this.props["asset_details"]['assetId'] })
        this.setState({ assetStatus: this.props["asset_details"]['operationalStatus'] })
        this.setState({ asset_type_id: this.props["asset_details"]["assetTypeId"] })
        this.setState({ sector_id: this.props["asset_details"]["sector_id"] })
        console.log(this.props["asset_details"]['assetId']);
        this.send_asset_id()
        this.parameterStatus()
        this.load_property(this.props["asset_details"]["properties"])
        this.ap_info_ref.current.set_property()
        console.log(this.props["asset_details"])
        console.log(this.props["asset_details"]['sasStatus'])
        if (!this.isEmpty(this.props["asset_details"]['sasStatus'])) {
            console.log("====== SAS Registration found====")
            this.setState({
                edit_serial: false
            })
        }

    }
    isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    load_property(asset_property: any) {
        console.log(asset_property)
        asset_property.forEach((property: any) => {
            if (property.propertyName == "ap_name" || property.propertyName == "cpe_name") {
                this.setState({
                    name: property.value
                })
            } else if (property.propertyName == "access_point_id" || property.propertyName == "cpe_id") {
                console.log("=======", property.propertyName)
                this.setState({
                    access_point_id: property.value
                })
            }
            else if (property.propertyName == "user_id") {
                this.setState({
                    user_id: property.value
                })
            } else if (property.propertyName == "description") {
                this.setState({
                    description: property.value
                })
            } else if (property.propertyName == "image") {
                this.setState({
                    image: property.value
                })
            } else if (property.propertyName == "no_of_sectors") {
                this.setState({
                    no_of_sectors: property.value
                })
            } else if (property.propertyName == "latitude") {
                this.setState({
                    latitude: property.value
                })
            } else if (property.propertyName == "longitude") {
                this.setState({
                    longitude: property.value
                })
            } else if (property.propertyName == 'radiation_center') {
                this.setState({
                    rad_center: property.value
                })
            }
            else if (property.propertyName == 'serial_id') {
                this.setState({
                    serial_number: property.value
                })
            }

        });
    }
    calculate = () => {
        this.props.set_values(this.state.latitude, this.state.longitude, this.state.rad_center)
        this.props.calculate()
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }
    send_asset_id() {
        let id = this.props["asset_details"]["assetId"]
        this.setState({
            assetId: id
        })
        select_asset_id = id
        console.log(select_asset_id)
        try {
            // sector_id_list = asset_property["sector_id"]
            sector_id_list = this.props["asset_details"]["sector_id"]
            this.setState({
                showsector: true
            })
            console.log(typeof (sector_id_list))
        } catch (error) {
            this.setState({
                showsector: true
            })
        }


    }
    showModal1 = () => {
        this.setState({
            card1: true
        })
        console.log(this.state.show)
    }
    backtoMap() {
        console.log("============")
        this.props.refreshAsset(this.props["asset_details"]['assetId'])
    }
    bindsector() {
        const element_list: any = []
        console.log(sector_id_list)
        if (this.state.showsector) {
            sector_id_list.forEach((element: any, index: any) => {
                var classText = "col-md-6 p-0";
                if (index % 2 != 0) {
                    classText = "col-md-6 ";
                }
                const element_val = <div className={classText}>
                    <span className='distance' style={{ display: 'block', fontSize: '13px' }}>{"Sector " + (index + 1) + " ID #:"}</span>
                    <span className='distance apinfo'>{element.sector_value}</span>
                </div>
                element_list.push(element_val)
            })
            return element_list

        }

    }
    hideModal1 = () => {
        this.setState({
            card1: false
        })
    }
    showModal2 = () => {
        this.setState({
            card2: true
        })
        console.log(this.state.show)
    }
    remove_photo() {
        var properties = asset_property.map((property: any) => {
            if (property.propertyName === 'image') {
                property.value = ""
            }
            return property
        })
        let requestBody = {
            "assetName": this.state.name,
            "serialId": this.state.serial_id,
            "assetTypeId": this.state.assetTypeId,
            // "latitude": this.props.latlong[0],
            // "longitude": this.props.latlong[1],
            "properties": properties,
            "sector_id": this.state.sector_id,
            "status": "Offline"
        }
        requestAPICall("edit_asset_details", { asset_type_id: this.state.asset_type_id, asset_id: this.state.assetId }, requestBody).then(data => {
            this.setState({
                is_loader: false
            })
            if (data.responseCode == 200) {

                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
                this.get_asset_details()
                this.hideModal2()
            }
            else {
                this.setState({
                    warnMessage: data.message,
                    alertbox: true
                })
            }

        })
    }
    get_asset_details = () => {
        let requestBody = {}
        requestAPICall("get_asset_details", { asset_id: this.state.assetId }, requestBody)
            .then(data => {
                console.log(data)
                asset_property = data["properties"]
                let image_value = data["properties"].filter((property: any) => property.propertyName !== 'serial_id')
                this.setState({
                    image: image_value.value
                })

            })
    }
    hideModal2 = () => {
        this.setState({
            card2: false
        })
    }

    editAsset = () => {
        console.log("asset_property "+asset_property['no_of_sectors'])
        console.log("asset_property "+this.props.asset_details.assetTypeId)
        var obj = {}
        if (this.props.asset_details.assetTypeId == 6) {
            obj = {
                assetType: this.props.asset_details.assetTypeId,
                editSerial: this.state.edit_serial,
                assetId: this.state.assetId,
                properties: asset_property,
                assetAction: 'edit'
            }
        } else {
            obj = {
                assetType: this.props.asset_details.assetTypeId,
                editSerial: this.state.edit_serial,
                assetId: this.state.assetId,
                properties: asset_property,
                assetAction: 'edit',
                noOfSectors: asset_property['no_of_sectors'],
                sector_id_list: this.props.asset_details.sector_id
            }
        }
        
        console.log(obj)
        this.props.editAsset(obj)
    }
    parameterStatus = () => {
        console.log("GET STATUS============")
        let requestBody = {}
        requestAPICall("param_status", { asset_id: this.props["asset_details"]['assetId'] }, requestBody).then(data => {
            if (data.responseCode == 200) {
                this.setState({
                    paramStat: data.ParamstatusCode
                })

            }
            console.log(this.state.paramStat)

        })
    }
    delete_asset() {
        let requestbody = {
            parameterStatus: "Deregistered",
            reason: "delete from SAS"
        }

        // if ([3, 4, 5, 6, 11].includes(this.state.paramStat)) {
        requestAPICall("delete_device", { asset_id: this.state.assetId }, requestbody).then(data => {
            console.log(data.responseCode, "asset deleted=========")
        })
        // }

        requestAPICall("delete_asset", { asset_id: this.state.assetId }, {}).then(deletedata => {
            if (deletedata.responseCode == 200) {
                this.setState({
                    warnMessage: deletedata.message,
                    alertbox: true
                })
                this.props.asset_delete_confirm()
                this.hideModal2()
                this.hideModal1()

            }
            else {
                this.setState({
                    warnMessage: deletedata.message,
                    alertbox: true
                })
            }
        })
    }
    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }
    backToList() {
        this.props.back();
    }
    render() {
        return (
            <div>
                <div className='row webonly'>
                    <div className='col-md-5'>
                        <div className="card apcard">
                            <div className="" style={{ padding: '0px' }}>
                                <div className='row'>
                                    <div className='col-md-3' onClick={() => { this.props.back() }}>
                                        <img className='backicon' src={BackIcon} onClick={() => { this.props.back() }} />
                                        <button type='button' className='backbutton' ></button>
                                        {/* <button type='button' className='backbutton material-icons' onClick={this.props.back}>keyboard_backspace </button> */}

                                    </div>
                                    <div className='col-md-9 m-r-m' style={{ padding: "13px 0" }}>
                                        {
                                            this.props["asset_details"]["assetTypeId"] === 1 || this.props["asset_details"]["assetTypeId"] === 6 ? <span className='apheader'>CBSD Details</span> : <span className='apheader'>CPE Details</span>
                                        }

                                    </div>
                                </div>
                                <div className="card-body " style={{ marginTop: "-15px" }}>
                                    <div className=' text-center' style={{ position: 'relative' }}>
                                        {
                                            this.state.image !== "" && this.state.image !== undefined ? <i className="material-icons imgclose" data-toggle="modal" data-target="#deleteimageModal" onClick={(e: any) => { e.preventDefault(); this.showModal2() }}>clear</i> : null
                                        }
                                        <span className=''>

                                            {/* <i className="fas fa-times imgclose" data-toggle="modal" data-target="#deleteimageModal" onClick={(e: any) => { e.preventDefault(); this.showModal2() }}></i> */}
                                        </span>
                                        {this.state.image !== "" && this.state.image !== undefined ? <img className='apimage' src={this.state.image} height="200" width="300" /> : null}
                                    </div>
                                    <div className='row m-0'>
                                        <span className='apheader' style={{ textTransform: 'none', fontSize: "20px" }}>{this.state.name}</span>
                                        {/* <div className="ap-dot"><span></span></div>
                                        <span className='distance-text' style={{ fontWeight: 400, padding: "6px" }}>{this.state.rad_center}ft</span> */}
                                    </div>
                                    <div className='row m-0' >
                                        <div className='col-md-12' style={{ padding: '0 0 0px 0', borderBottom: '0.5px solid #D8D8D8' }}>
                                            <p className='apdetails-web'>
                                                {this.state.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row m-0'>
                                        <div className='col-md-6 p-0'>
                                            <span className='distance' style={{ fontSize: '13px' }}>ID:</span>
                                            <span className='distance apinfo' style={{ paddingLeft: '5px' }}>{this.state.access_point_id}</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ fontSize: '13px' }}>Status:</span>
                                            <span className='distance apinfo' style={{ color: '#E22B2B', paddingLeft: '5px' }}>{this.state.assetStatus}</span>
                                        </div>
                                    </div>

                                    <div className='row m-0' >
                                        <div className='col-md-6 p-0'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Latitude:</span>
                                            {/* <input className='distance apinfo'  value={asset_property["latitude"]}/> */}
                                            <span className='distance apinfo'>{this.state.latitude}</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Longitude:</span>
                                            <span className='distance apinfo' >{this.state.longitude}</span>
                                        </div>
                                    </div>
                                    <div className='row m-0'>

                                        <div className='col-md-6  p-0' >
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Height:</span>
                                            <span className='distance apinfo' >{this.state.rad_center} ft</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className='distance' style={{ display: 'block', fontSize: '13px' }}>Serial #:</span>
                                            {this.state.serial_number !== undefined ?
                                                <span className='distance apinfo'>{this.state.serial_number}</span> :
                                                <span className='distance apinfo'>-</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='row m-0 '>
                                        {this.props["asset_details"]["assetTypeId"] == 1 || this.props["asset_details"]['assetTypeId'] == 2 || this.props["asset_details"]['assetTypeId'] == 6 ? this.bindsector()

                                            : null}
                                    </div>
                                    <div className='' style={{ position: "fixed", bottom: "23px" }}>
                                        <div className='row m-0'>
                                            <button className='editbtn distance' style={{ color: '#0073FF' }} onClick={this.editAsset}>
                                                <i className="fas fa-pen " style={{ marginRight: "10px" }}></i>
                                                Edit</button>
                                        </div>
                                        <div className='row m-0'>
                                            {this.props["asset_details"]["assetTypeId"] == 1 || this.props["asset_details"]['assetTypeId'] == 2 || this.props["asset_details"]['assetTypeId'] == 6 ?
                                                <button className='deletebtn distance m-t-5' style={{ color: '#E22B2B' }} data-toggle="modal" data-target="#deleteModal" onClick={(e: any) => { e.preventDefault(); this.showModal1() }}>
                                                    <i className="fas fa-trash " style={{ marginRight: "10px" }}></i>
                                                    Delete CBSD</button> :
                                                this.props["asset_details"]["assetTypeId"] == 3 || this.props["asset_details"]['assetTypeId'] == 4 ? <button className='deletebtn distance m-t-5' style={{ color: '#E22B2B' }} data-toggle="modal" data-target="#deleteModal" onClick={(e: any) => { e.preventDefault(); this.showModal1() }}>
                                                    <i className="fas fa-trash " style={{ marginRight: "10px" }}></i>
                                                    Delete CPE</button> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 p-0' >
                        <div className="col-md-12 justify-content-flex-end p-0">
                            <div className="heaticon text-center" style={{ display: 'none' }}>
                                <img className='heat' src={Heat} />
                            </div>
                        </div>

                        <div className="col-md-12 float-right p-0">
                            <div className="geoicon text-center">
                                <img style={{ padding: '5px' }} src={geo} onClick={this.setCurrentPosition} />
                            </div>
                        </div>

                        <div className="col-md-12 float-right p-0" onClick={this.calculate}>
                            <div className="geoicon text-center">
                                <img style={{ padding: '6.5px' }} src={align} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3' >
                        <APMoreInfo ref={this.ap_info_ref} assetInfo={this.props['asset_details']} backtoAPInfo={this.backtoMap} backToAssetList={this.backToList}/>
                    </div>
                    }
                </div>


                <div className='row mobonly'>
                    <div className='col-12'>
                        <div className="card apinfocard" >
                            <div className="card-body apinfobody" >
                                <div className="row  m-t-10">
                                    <div className="col-4 p-r-0">
                                        <img className='apimage' src={Lake} />
                                    </div>
                                    <div className="col-8 p-r-0">
                                        <span className='apheader' style={{ textTransform: 'none' }}>Piedmont Lake</span>
                                        <span className='distance'>. 270ft</span>
                                        <div>
                                            <span className='distance' style={{ fontSize: '12px' }}>Status:</span>
                                            <span className='distance apinfo' style={{ color: '#E22B2B', paddingLeft: '5px' }}>Offline</span>
                                        </div>
                                        <div className='col-12 p-0'>
                                            <span >
                                                <span className='distance' style={{ fontSize: '12px' }}>Lat:</span>
                                                <span className='distance apinfo'>43.197167</span>
                                            </span>
                                            <span className='p-l-10'>
                                                <span className='distance' style={{ fontSize: '12px' }}>Long:</span>
                                                <span className='distance apinfo' >43.197167</span>
                                            </span>
                                        </div>

                                        <div className='col-12 p-0'>
                                            <div className="aptoggle">
                                                <i className="fas fa-cog"></i>
                                            </div>
                                            <div className='aptoggle'>
                                                <i className="fas fa-wifi"></i>
                                            </div>
                                            <div className='apedit'>
                                                <i className="fas fa-pen"></i>
                                            </div>
                                            <div className='apdel' data-toggle="modal" data-target="#deleteModal" onClick={(e: any) => { e.preventDefault(); this.showModal1() }}>
                                                <i className="fas fa-trash"></i>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-3' >
                        <APMoreInfo />
                    </div> */}
                </div>

                <Modal show={this.state.show && this.state.card1} handleClose={this.hideModal1}>
                    {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document" style={{ maxWidth: "335px" }}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 15px' }}>
                                    <span className="modal-title" style={{ marginTop: '0px', fontSize: "14px", fontWeight: 500 }}>CONFIRMATION</span>
                                    {/* <img className='' src={closeIconImg} /> */}
                                    <i className="material-icons resetcloseicon imgclose-file" data-dismiss="modal" onClick={(e: any) => { e.preventDefault(); this.hideModal1() }} aria-label="Close">clear</i>
                                </div>
                                <div className='row m-t-20 justify-content-center' style={{ padding: '0 10px' }}>
                                    {
                                        this.props["asset_details"]["assetTypeId"] == 1 || this.props["asset_details"]["assetTypeId"] == 6 ? <span className='modaltext'>Are you sure you want to delete this CBSD?</span> :
                                            <span className='modaltext'>Are you sure you want to delete this CPE?</span>
                                    }

                                </div>
                                <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                    <div className='col-md-6 p-0'>
                                        <button className='btn btn-secondary-c3' style={{ width: "95%" }} onClick={(e: any) => { e.preventDefault(); this.hideModal1() }} >No</button>
                                    </div>
                                    <div className='col-md-6 p-0'>
                                        <button className='btn modalbtn' style={{ width: "98%", marginLeft: "2px" }} onClick={(e: any) => { e.preventDefault(); this.delete_asset() }} >Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <Modal show={this.state.show && this.state.card2} handleClose={this.hideModal2}>
                    {/* <div className="modal fade" id="deleteimageModal" role="dialog" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row' style={{ padding: '0 15px' }}>
                                    <span className="modal-title" style={{ marginTop: '0px', fontSize: "14px", fontWeight: 500 }}>CONFIRMATION</span>
                                    <i className="material-icons resetcloseicon imgclose-file" data-dismiss="modal" onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} aria-label="Close">clear</i>
                                </div>
                                <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                    <span className='modaltext'>Are you sure you want to remove this photo?</span>
                                </div>
                                <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                    <div className='col-md-6 p-0'>
                                        <button className='btn btn-secondary-c3' style={{ width: "95%" }} onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} >No</button>
                                    </div>
                                    <div className='col-md-6 p-0'>
                                        <button className='btn modalbtn' style={{ width: "98%", marginLeft: "2px" }} onClick={(e: any) => { e.preventDefault(); this.remove_photo() }} >Yes</button>
                                    </div>
                                </div>
                                {/* <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                <div className='col-md-6 p-0'>
                                    <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} >No</button>
                                </div>
                                <div className='col-md-6 p-0'>
                                    <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} >Yes</button>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <IonAlert
                    isOpen={this.state.alertbox}
                    onDidDismiss={() => this.setShowAlert(false)}
                    message={this.state.warnMessage}
                    buttons={['OK',]}
                />
            </div >
        );
    }
}

export default APdetails1;
