import React, { Component } from 'react'
import { StandaloneSearchBox } from '@react-google-maps/api'
import Heat from '../assets/img/heaticon.png'
import "./search.css"
import Back from '../assets/img/left-arrow.svg'
import Pin from '../assets/img/search-suggestions-map-icon-gray.png'
import { IonPage, IonContent } from '@ionic/react'
import SearchIcon from '../assets/img/search_icon.png'
import geo from '../assets/img/target-icon.png'
import apicon from '../assets/img/apicon.png'
import { requestAPICall } from "../service/apiHandler";

var addressPredictions: any = {}
var assetList: any = []
export default class Search1 extends Component {
    state: any = {};
    props: any = {};
    autocompleteInput: any
    autocomplete: any

    geocoder: any



    constructor(props: any) {
        super(props)

        this.state = {
            ap_cpe_list: [],
            asset_list: [],
            searches: '',
            predictionsList: [],
            address: '',
            search: "",
            serial: "",
            search_list: [],
            lat_search: 0,
            lng_search: 0,

        };
        this.onChange = this.onChange.bind(this)
        this.list_asset = this.list_asset.bind(this)
        // this.Bind_AssetList = this.Bind_AssetList.bind(this)
        this.searchValue = this.searchValue.bind(this)
        this.list_asset()
    }
    componentDidMount() {
        window.addEventListener('load', this.list_asset);
        console.log(this.autocomplete, "fdfd")

    }

    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();
        console.log(place, "place")
        console.log(this.autocomplete, "dffdfdfd")

    }
    list_asset = () => {
        assetList = []
        let requestBody = {
        }
        requestAPICall("list_asset", {}, requestBody).then(data => {
            console.log(data)
            data['assets'].forEach((element: any) => {
                if (element.assetTypeId == 1 || element.assetTypeId == 3 || element.assetTypeId == 6) {
                    assetList.push(element)
                }

            });
            this.setState({
                asset_list: assetList,
                ap_cpe_list: assetList.filter((asset: any) => asset.assetType !== "DAS")
            })

        })

    }
    onSearch = (e: any) => {

        console.log(e.target.name, e.target.value, "ddfdf")
        this.setState({
            searches: e.target.value

        })

        console.log(this.autocompleteInput, "ggggggg")

        const predict = this.getQuery()

        this.setState({

            predictionsList: predict,

        })

        console.log(this.state.predictionsList, "list")
    }


    onChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.list_asset_search(this.state.searches)
            console.log(this.state.searches)
        })
    }
    list_asset_search = (searches: any) => {
        console.log(this.state.asset_list)
        console.log(this.state.ap_cpe_list)
        this.setState({
            search_list: this.state.asset_list.filter((asset: any) => asset.assetIdentifierId.toUpperCase().includes(this.state.searches.toUpperCase()))
        })

    }
    details = (assetIdentifierId: any) => {
        console.log(assetIdentifierId)
        this.setState({
            searches: assetIdentifierId,
        })
        let list_filter = this.state.ap_cpe_list.filter((asset: any) => asset.assetIdentifierId === assetIdentifierId)
        list_filter = list_filter[0]
        var coordinates = {
            'latitude': list_filter.latitude,
            'longitude': list_filter.longitude

        }
        list_filter['coOrdinates'] = coordinates
        this.setState({
            lat_search: list_filter['latitude'],
            lng_search: list_filter['longitude']


        }, () => {
            var locmark = true
            this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, list_filter)
        })

    }

    searchValue = (e: any) => {
        console.log(e)
        console.log(e.target.value)
    }
    getQuery = () => {

        const service = new google.maps.places.AutocompleteService();


        service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
        return addressPredictions
    }
    predictionCallback = (predictions: any, status: any) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) return;

        addressPredictions = predictions;
    }
    setPlace = (value: any) => {
        this.setState({
            searches: value,

        })
        var locmark = true
        this.props.setAddress(value, locmark)

        console.log(value, "sdsds")
    }
    Bind_AssetList = () => {
        let peopleToReturn = [];
        for (let i = 0; i < this.state.asset_list.length; i++) {
            console.log(this.state.asset_list[i]['name'])
            peopleToReturn.push(
                <div>
                    <span className='searchcontent'>{this.state.asset_list[i]['name']}</span>
                    <span className='apnote'>{this.state.asset_list[i]['streetAddress']}</span>
                </div>
            );
        }
        return peopleToReturn;
    };
    render() {
        return (
            <div>
                <div className="webonly">
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="card searchcard">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img className='search-web' src={SearchIcon} />
                                            <i className="material-icons closeicon" style={{ fontSize: '21px', color: '#8f9fac' }} onClick={this.props.back}>clear</i>
                                            <input name={this.state.searches} className='search' id="autocomplete" onChange={(e: any) => { this.onSearch(e); this.onChange(e) }} value={this.state.searches} placeholder="Enter your address" type="text" ref={this.autocompleteInput} autoFocus></input>
                                            {/* <input className='search' value={this.state.search} name='search' type='text' placeholder='Search Address' onChange={this.onChange} /> */}

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                        aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>ADDRESS</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                        aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>CBSD/CPE</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="searchTabContent">


                                        <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">
                                            {this.state.predictionsList.length > 0 ?
                                                <div style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 190px)' }}>
                                                    {this.state.predictionsList.map((predict: any) => {
                                                        return <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                            <div className='col-md-2'>
                                                                <img className='searchcontenticon' src={Pin} />
                                                            </div>
                                                            <div className='col-md-10 searchborder'>
                                                                <span onClick={() => this.setPlace(predict.description)} className='searchcontent'>{predict.description}</span><br />
                                                                {/* <span className='apnote'>New York, NY 12345</span> */}

                                                            </div>
                                                        </div>

                                                    })}
                                                </div> : ""}
                                        </div>

                                        <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                            {this.state.search_list.length > 0 ?
                                                <div style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 190px)' }}>
                                                    {this.state.search_list.map((index: any) => (
                                                        <div id="indexname" onClick={() => this.details(index.assetIdentifierId)}>

                                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                                {index.assetType === "AP-outdoor" ?
                                                                    <div className='col-md-2'>
                                                                        <img className='heat' src={apicon} style={{ transform: 'rotate(-39deg)', width: '47px' }} />
                                                                    </div> :
                                                                    <div className='col-md-2'>
                                                                        <i className="far fa-circle searchcontenticon"></i>
                                                                    </div>}
                                                                <div className='col-md-10 searchborder'>
                                                                    <div className="font14" style={{ color: '#353435' }}>{index.assetIdentifierId} </div>
                                                                    <div className="font14" style={{ color: '#8F9FAC' }}> {index.name}</div>
                                                                </div>
                                                            </div>


                                                        </div>))}
                                                </div> : <div style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 185px)' }}>
                                                    {this.state.ap_cpe_list.map((index: any) => (
                                                        <div id="indexname" onClick={() => this.details(index.assetIdentifierId)}>

                                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                                {index.assetType === "AP-outdoor" ?
                                                                    <div className='col-md-2'>
                                                                        <img className='heat' src={apicon} style={{ transform: 'rotate(-39deg)', width: '47px' }} />
                                                                    </div> :
                                                                    <div className='col-md-2'>
                                                                        <i className="far fa-circle searchcontenticon"></i>
                                                                    </div>}
                                                                <div className='col-md-10 searchborder'>
                                                                    <div className="font14" style={{ color: '#353435' }}>{index.assetIdentifierId}</div>
                                                                    <div className="font14" style={{ color: '#8F9FAC' }}>{index.name}</div>
                                                                </div>
                                                            </div>


                                                        </div>))}
                                                </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-3' >
                            <div className="col-md-12 justify-content-flex-end">
                                <div className="heaticon text-center" style={{ display: 'none' }}>
                                    <img className='heat' src={Heat} />
                                </div>
                            </div>

                            <div className="col-md-12 float-right">
                                <div className="geoicon text-center">
                                    <img style={{ padding: '5px' }} src={geo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobonly">
                    <IonPage>
                        <IonContent>
                            <div className='param-info-mob '>
                                <div className='col-12 searchborder' style={{ padding: '10px' }}>
                                    <i className="fa fa-search searchicon" aria-hidden="true"></i>
                                    <i className="fa fa-times closeicon" onClick={this.props.back}></i>
                                    <StandaloneSearchBox>
                                        <input className='search' type='text' placeholder='Search Address' />
                                    </StandaloneSearchBox>
                                </div>

                                <div className='container-fluid apdash'>
                                    <div className="card profilecard" style={{ height: 'calc(100vh - 78px)' }}>
                                        <div className="card-body" style={{ overflowY: 'auto', padding: "10px 5px", overflowX: 'hidden' }}>
                                            <div className='row' style={{ paddingRight: '18px' }}>
                                                <div className='col-2 p-r-10'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon  float-right"></i>
                                                </div>
                                                <div className='col-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                            <div className='row' style={{ paddingRight: '18px' }}>
                                                <div className='col-2 p-r-10'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon  float-right"></i>
                                                </div>
                                                <div className='col-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                            <div className='row' style={{ paddingRight: '18px' }}>
                                                <div className='col-2 p-r-10'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon  float-right"></i>
                                                </div>
                                                <div className='col-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
























                            {/*                     
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="card searchcard">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <i className="fa fa-search searchicon" aria-hidden="true"></i>
                                            <i className="fa fa-times closeicon" onClick={this.props.back}></i>
                                            <StandaloneSearchBox>
                                                <input className='search' type='text' placeholder='Search Address' />
                                            </StandaloneSearchBox>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active p-l-0" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                        aria-selected="true">mob</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                        aria-selected="false">CBSD/CPE</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="searchTabContent">
                                        <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="far fa-circle searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>CPE Name</span><br />
                                                    <span className='apnote'>12345</span>
                                                </div>
                                            </div>
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="far fa-circle searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>AP Name</span><br />
                                                    <span className='apnote'>67890</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                        </IonContent>
                    </IonPage>
                </div>
            </div>
        )
    }
}
